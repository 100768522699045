const EXPLORABLE_CHANNEL = {
  name: 'explore',
  sortOrder: 1,
  icon: 'MindBlown',
  label: 'Discover',
  description: 'Channels to discover on Odysee',
  image: 'https://player.odycdn.com/speech/featured:b.jpg',
  channelLimit: '1',
  pageSize: 12,
  order: 'new',
  claimType: ['stream'],
  daysOfContent: 90,
  pinnedClaimIds: [
    '024d7580ee785f684d83fac05c8c484cc021f5b8',
    '6759d728ffb50cdf934c589c0376a3321df3c441',
    '33c7bf7a27bcf8c584820cadb5a7882c54b0d7f7',
    'cda7b52733c8cc7a78f5ce5b37fca3fbed1f95dd',
    '9a9267142fe4e947ceb54c676ee54ab5ac145209',
    'a9daa33957fa58d01d09de9028ec8768aed58296',
    '69f9b411b3d7db160ea25376aa7f9148eb56f5b3',
    'b9fcdf31b12f8b48312044acb33a67524279a3fe',
    '769c0ee65ef51a08205f774b421bb41f780f3567',
    '4bd1082fa749fd72f956b06dcf52337137d741df',
    'dcc56fad01fcb4e6b9964489887742fed332dabe',
    '6c552647b8cdec1d1ef15480275d4edc52d97388',
  ],
};

const REPOST_1 = '3511b71e5843ae53c35a5fff3e6ef7a3377dd0f7';
//const REPOST_2 = 'bc935e4482c6bf70d14dd872fae159a65c552eb3';

const PRIMARY_CONTENT_CHANNEL_IDS = [
  '1c6dff2c2c2eb2d68b43c8e769bdf2a9cfb7e49e',
  //'80d2590ad04e36fb1d077a9b9e3a8bba76defdf8', //odysee
  'a8d1094b9c6624f59f19f6e139152d1e00caa9f4',
  'd4d17e20bec31c971b1ab6370a11203ccec095a4',
  '5b0b41c364c89c5cb13f011823e0d6ee9b89af26',
  '5a1b164d0a2e7adf1db08d7363ea1cb06c30cd74',
  '7566c26e4b0e51d84900b8f153fc6f069ad09ef7',
  '3346a4ff80b70ee7eea8a79fc79f19c43bb4464a',
  '2b6c71a57bad61e17276ba9b9e4c58959cad1d7b',
  '273163260bceb95fa98d97d33d377c55395e329a',
  'c5cd9b63e2ba0abc191feae48238f464baecb147',
  '96043a243e14adf367281cc9e8b6a38b554f4725',
  '719b2540e63955fb6a90bc4f2c4fd9cfd8724e1a',
  '589276465a23c589801d874f484cc39f307d7ec7',
  '39065ea36ccf9789327aab73ea88f182c8b77bd3',
  '5e0333be82071767a3aa44a05bb77dcec4c30341',
  '32d4c07ecf01f2aeee3f07f9b170d9798b5e1d37',
  '25f384bd95e218f6ac37fcaca99ed40f36760d8c',
  '48c7ea8bc2c4adba09bf21a29689e3b8c2967522',
  '18b0d45be9f72c3c20a47f992325cb0f8af0fe7c',
  'fee415182e20af42122bea8d1682dc6a4d99a0d6',
  'd2af9d4cec08f060dfe47510f6b709ebf01d5686',
  '49fe7ca8bb2f7a794b1cba1d877d98dae520ac73',
  'ba79c80788a9e1751e49ad401f5692d86f73a2db',
  'c1a5fd043a1dbc8ff4ec992aefc482c970e7568e',
  'b6e207c5f8c58e7c8362cd05a1501bf2f5b694f2',
  'fb364ef587872515f545a5b4b3182b58073f230f',
  'beddc710e4a9f8f296fa3a6d7ea13f816422ffa5',
  'e4264fc7a7911ce8083a61028fe47e49c74100cf',
  'f3e79bf8229736a9f3ae208725574436e9d4ac03',
  'f1dff225e758dd5bc8ab8b91894096215297b2be',
  'b6a8abdc754fd7f86d571fd98a04deaac4cef889',
  '3808a556e5994e51b7e6b86f1173fdaf558dfd4e',
  'af927bd2092e7383789df183ff1eaf95c7041ee9',
  '47447af13e4bc96c143a500034a9182cd569ad07',
  'b3c1ec3211a801de8ac0ef979467da4c721c0ec4',
  '3f89fd1bb05eb81f1b159d7f9d3cf15431ede280',
  '15627c8d79e7c45b15fbe726b34d47accf11b8e2',
  'a3e35f723d9ad82159b4858ad628e090d0e372df',
  '6f3940e512a40f2ac8068103bd9195fa07107043',
  '1487afc813124abbeb0629d2172be0f01ccec3bf',
  '76283e4cd0168e392128124a013c8335a987186d',
  'a87ee0c50662b13f11b6fdd3eefd4cee17930599',
  '1eb4bf3b47b07f646d760c0accf7a4295aa89024',
  'a776378cfe529ea86020de64b67ce6d4aeb9a6a8',
  'abf8c3b0426cd89fce01770a569d525c648a92b5',
  '9dc7f2791db1fefb0f4aea2c856b9ecea6f3f5d0',
  '8d497e7e96c789364c56aea7a35827d2dc1eea65',
  'e5872eb7237883e4158cf88e96a465f7a674c968',
  '3e63119a8503a6f666b0a736c8fdeb9e79d11eb4',
  'a29db3ebf677f1fe317ca4ecf0a65a172d4735be',
  'd3f050228497b023747fe18d6639105c89611255',
  '871ba605db0cad46e43081c4b3d942b80696359f',
  '4a7f6709df6770e0786b04599fb00262a98d220b',
  'd273a5d2b57785d19d4c123255bc54f9e45f7e83',
  '7667bf94424f30ad4d2b77d86e9c0cbbc4a9925d',
  '1a3d19924669c4e2cbeb04d879a58fae714386f6',
  '13d66a5f9188e890ef62487bc4a3c785047f7528',
  'd3dcbe03d94a17ef36622ad0e79f7d15ba812071',
  '5c18aa1999e7a659b142407c242279a1c90cd7c5',
  '3fa6a0265533443983cf2f5cbdeb4e6cec9052cf',
  '0d23a55063548d81fc31185f2796793a79ad611c',
  '0ccec4eb91a8d0689eb94436f302b1885281d99f',
  '5b1c267a66fe167ebe9f72d50d5364982dd0dfd4',
  '06423d5a435eb8dd92e72b76981738b9ce8db9ea',
  '68044a0cf5ed7534872af5149d4e0c43a9a1d06a',
  '7ca10b6b6385b588e6b8281d9d969e65ee8ecc13',
  '65031ab204894879eb78deaeb17537445ca23190',
  '9250f5e191fc604d9ca7e7691bfccffaf46fb66a',
  '5831f60b25f5b5cb7d8b04c7887aa65949bdb279',
  '2827bfc459c12d7c6d280cbacee750811291d4ba',
  'aa30b564975bbeed98efb32d6b55f8dc02e82101',
  '0b48fa6c5f844f7b6f2b3579f792de9a6cc07e82',
  '4e791eddc4ea6c7ada0c56b768f27d6af1cb179e',
  'bd5139bff4f30b25ef36fe7da60af3a07787e9e7',
  '4b18abf1846fc88385c2cbf2846fd1b9c3808896',
  '32276d32396286795c19e407259e0bb9904ebb0e',
  '6ab108fc0ada028825a7a500e6f97423f4059ebc',
  'db3201197536bbf929d4ff9981ec54edba9da9e5',
  '59e54182af2a3269995a874a5f04c7f6b617459d',
  '5c7509e035ce97de1152921ec0b47d5f8770105b',
  'b907d35e038e24e3b9fc614cb977d468328ec5f7',
  'fd0c9b84f9281902bbe08d27434029bc3a838379',
  '6844d720c70fad79218f91661a7177b67b79a8d4',
  '0e442f5489c9069c04846ee1fffc9238bd05a798',
  'aa5544b6778d3620d57d8dcd3229c6c59354857a',
  'f809af0a98cba679b483b7c565dda9ba036246a1',
  '9d4c31875f534dc1f989db6c13b92ab1aab85ecf',
  'aa8d66fd987e831b95d036e7d89125a7d4be1e30',
  '593b9b7e8f41f89188f9c4e9e71750f4b767e72b',
  '19b6a8d22c04afeed14b3a6feb43793c66aa58c5',
  'ea26a6270f60b023ba17f990dbfdcccb319d614c',
  '0c978becd7fdcf88c24a78b9ce10b2d30e8a14d0',
  'd5753228b6aa1a34b8cfab7a790890b1e69e3e00',
  '7243e2e5a6ef8a110f07d4f3b633c20c904e6527',
  '64a9305928a97d4d86172fbb0c341e98c1385cd1',
  '772a859c56a72f12284240b66a3d3bbc6fd9e1d0',
  'cfbc605de0dbd72ff34525ee6ebe9e089723bfde',
  '6caae01aaa534cc4cb2cb1d8d0a8fd4a9553b155',
  '73704f657e95302430c859e98adf76668df16412',
  '25ccde83236a395bcee9ad55b759da8684d051bb',
  'a1229255cfe560646813132a561a875b09c6fa64',
  'de33d30e1d7ef04c132d272f256127a112b83320',
  '7e93f0edb0cb63e490931b1586988ccb63ff3105',
  '514df20b9d2cce22725d5305a8ba019547188736',
  'c51745b0aeaa255310603e394079bc8625589736',
  'b4a2e92c40d2aaed1dba99fa430bdf9aca9eb7eb',
  'f83a38db1f5855c02b321cbaa3a6cc8cfc925c01',
  '94fb122085a5f934292bee5547085e4961f847e1',
  '6d3a818d4eef26a5ed74130174aac80644892b0d',
  'fbcebf09b1f2957e0921ba2856c415b9ea03efe4',
  '6b60d9875aec12c950ce6e936cb4e76bb67e87e5',
  '249f7d18dcf193f358d5251c915edb985398e2b4',
  'a63835dd552f2d1ddd09574ccaf0a30fb1bac8c1',
  '3952eeff28a3098fd059caa6c8a5184d0f9634ef',
  'f13b26bb384035f77a717f63b0a8afe9f99f30bb',
  'f262eafa7d66ccea187ddbcb135e18d0619a1be5',
  'd7522129dceca00beed54fad01328c8612012e00',
  '4f5f6e91a4061078272a51b0e285755dc9acb160',
  'afb126638307ccee8c2318a8dedf43d32023b207',
  '892a016cd83fb9af964ef1f870ab62aaaa45c3fa',
  '4a0b6f26c6ad3bde17945439065fd531601f0358',
  '52c5e952789f39a06b411970610c67c516dc351c',
  'b67c820e726d807769f6e229bdead6f229fa721f',
  'd7045d4d8434cf00897a47e3c3591e5e58945cfb',
  '4dd18dd8259daf3a20768e852f373cd93e9db217',
  'c066479bc4a190e364817bdb5f740a1d3a422fd3',
  'bb8ee3f11cb9b49160d68d64cf3fecbf3f558a58',
  'da5534547c15856646c1bd281313ddd55cbec407',
  '740bfd25e9763a4e72d83bb0ab947931ffdc8861',
  '3103d3a825ac42f50c0761817eb939b617c3e3bb',
  '2a958727179f1d204516ce3dc8e901306118c26d',
  '530f4d94ab328234c3a2840204580b76aebd359c',
  'ce6a8b8c3d97159a219fd4f58051946a8c4e652d',
  '95a7c23a3527435f716e8b45558c668e0272e4a2',
  '041584c718c9a26ca07dd75acc1719082c521bdd',
  '290afbacc4432e169a233939aae583c6470e50da',
  '35c00dd34d1b1b0c3c28601c2788cceadc5f6454',
  '4f838b51d08261777a0b1a42f5a53f769357317f',
  '975c9a434468f726a0a4da105c2f73decba8c293',
  'fdf8a4d196ba085871723728988a281df8b3a20a',
  'ee077d1d69ffb1761a3598f058181006a56c5147',
  'a3fd3abcc249770255d028a765f3dd7da1fc96f2',
  'e715c457b4a3e51214b62f49f05303bba4ee5be9',
  'ed9f30c9026d55266006d936be3ea4f38acd7e44',
  '2367dc24e989b29cabfb80d77630716a1713c86c',
  'db672cf848b85e438a1d41a6702b1e36783ab3c7',
  '773229b31c798a739eb94fd959c88fad38d0008d',
  '182e05230c6a723c3e489c40876b5b22664e4a06',
  'fa3a0dbeb5f8c99fbe2571c60db0c3499096da6e',
  '6f7e55c9fc174e27b164f2944ae27e117abd4e40',
  'd8396ef7b6b2be7e9c6cbe0ac375ca7e392bd4a0',
  '245016cc7a9564cadab6e62a0adacc0a6dae4b17',
  'a5ccb3ca176867ea1d65e0a9548edae4969bfebd',
  '3a3af9d672ad2166737758f4e35ac6fd6f1235f9',
  'f7ecc53c06a3d7273cc91ef0f973beda941a6181',
  '617886394bee35c359766fb4d9ae889b8fd39079',
  '2e886dd1455113869be63b6f0cd4ace750defb2f',
  '6a4fa1a68b92336e64006a4310cb160b07854329',
  '7a3e54fcd1cbc45108878a23c7e4dc2eea63b00e',
  'aca72bd71637a8d4698859c0a778aa9c0c30fbba',
  '965437f4fb4c59da07853aff62d4a6f6ac33cee2',
  '1df464dbb302ced815c61431a5548a273e6de8e1',
  'b1241c44d51fc645e96be3a82315686ac42fdd4d',
  '7069c013d90c5651d5ddcf293b7c22ee0a06a6a9',
  'b89ed227c49e726fcccf913bdc9dec4c8fec99c2',
  '0a60026345d27058f970d5918a7b7b23a8ca4c43',
  '7a150778c98abced6df3e51bb30a472ca3641a85',
  '54808df261e5b67f6f9b89af910a894dfaf3aa25',
  '8d0c30f0577faa6602234b8241b36ee28e82bef0',
  'a997231b48dc327b1417637d2d13402b65effe68',
  '4da85b12244839d6368b9290f1619ff9514ab2a8',
  '54e7affac75cc99a1706fbde17d49f578c254375',
  'f3bbf610b6d74a4e4cebf2f297d9abe5d0d3a480',
  '713abbb5894c937f17b42b0b1825435f13a9f11e',
  '2423953fe3994de8265f4d1d0c56b0544b55b0a6',
  '45475d16cfbeb800ba21c771c4c5838ea541fc40',
  '65200fad3d2b6b48666852efda6f5baa7010fd61',
  'afc52c318ee88473929ed1a7fec8810be03a09fa',
  '0ef7709760b2e416199c835b941ee19a88d1901b',
  '25952ee5252ffe41a5a704ec415c77bc5ba87190',
  '6c2b94924de5f1f55ad2082341af5db45782a32e',
  '6e63751c2bca2a60f4e3a26e8e7c8b371e08cf54',
  'e99c0ecc262375abfc2b9548d9bd0c0d11bb3a71',
  'b0e489f986c345aef23c4a48d91cbcf5a6fdb9ac',
  '0a229cdbf1227f675991889a3ce801f912975898',
  '057ca5a46e70cc1cb70558940ba0a7f22bad5714',
  '6e8ef2737bd7c23dfb3e106a2d138d7ea61f231f',
  '6f7abbb849fce52f4878197cbb462e5d28aa3ec2',
  'e7b397dc6829e3a7eea8b7a3aec34fa067633f7d',
  '3583f5a570af6870504eea5a5f7afad6e1508508',
  '227352e020f836dbc994ec56b9846e3c603b5b4e',
  '42e55225b72a5847a92d38ffa2e7bd1bfb5d34c5',
  'b54ad898e8fa70944fcf2866c22dbf8004a4311d',
  'a4b7b7c1c255b1173b0945198997e2678bccb4e6',
  '04fc0c8b831c823b5956cf1c5f1f670d13602e4b',
  '4ee7cfaf1fc50a6df858ed0b99c278d633bccca9',
  'a69bf0953bcfe1b7f8bd079eaf846b772fb9bbe4',
  '96ede5667bc4533ace8cfcbde4f33aa9fe1ae5f5',
  'cdced46693cf1a656c459772a22328f30e8276de',
  '6977c53ca432fd49f5d441a08da01930eb74401d',
  '3e7ab731a8e474fa9061f6ba85871007190c1796',
  '0e60b5e80cdb256ce0758170cad62211374e4100',
  '300f9e8b5b196378dffe4e8ed87bac756489daea',
  '8ab32c436c4d3bdb585bd4908da30addcb6e520c',
  '999d280cca4ceb65488da80cef067e0bfb24f815',
  'e346628f8651c0c06a948f2b8f54dda1ab53eec8',
  '80d83a515872832dff87986f3791a646f52ec1e0',
  '263125bfece84a7860a5af4ede55bbb0fb424c50',
  'f6b100a049d0cdd88839c84e929b4b9aedeadba3',
  'd52154e13c7e91680f69350670cd5873bd62c9c5',
  '057188d8f31b5fae7c7b1eaecd4bbecdbdb47f97',
  '89985db232ec2a9a31dbd985196de817da223fe6',
  '54516f3624a9cb61fbbff173f04097180609a3d6',
  'fd0594a6714a69bdec951ab005c20e0bf46901ff',
  '3c9971c438e91e2b5e18161e69159f835cfc4de2',
  'a475e3dcee2111191eda14af5c6e48e5348bf384',
  '9f376e4ad52ed33779647aca6fcb5b0a12e6c133',
  'd3c0d9a3863b0ad5d0698a3c5f34949c045d1411',
  '99194a98d21c0b2c7b386872db59b15d20205219',
  '17058210514aca2e07c1567f9a9b099ec85726da',
  '9028a5669cb74d5b220d6d421064ca0f4cfee4cd',
  'd6433cd3ea32c968a68cd7444febd945026d8a3e',
  '237f0dcbcbdb9de1d5eace1436fafaa7043b2e93',
  '175ba1b7d4ef800cb1ec573be691ae40b870b796',
  '2c9071800bc35ffc0cec48dfa133c167ace79406',
  '669bfb365041efd709215ef38d1b0bc863bf722d',
  '9d97c3658346e040438c0d7eb01623afe0b8a73f',
  'e3ab467beffb6e7d4046a430c7daa3888763962f',
  'a2c8f8607417845ca695f1781df0dc2af445c33a',
  '56e86eb938c0b93beccde0fbaaead65755139a10',
  '1e3beb67c30d8dd2b76e48d75473fc57ac69bb04',
  '3f8e06a07ee22b8d22b66ee913ccab1e94436af0',
  'ca1fd651c9d14bf2e5088bb2aa0146ee7aeb2ae0',
  'caeca0326ea686c0a6ea7db4174ef5a0e947db29',
  '5b00937848d7c5f9287c38bab30a1c5c87f350a2',
  '695bf734b23fccc50c4a21d9f450c62035b93a16',
  '69a9196d319151accc8e42b422eedf285b5aac97',
  '9df24d19af6219730a014c0a90e192058e5d11bb',
  '5d9ea5ea1e333256ba3f18fce533b8cb26abc06a',
  'b46144501bdcdcaae536b4a2d30250398225d0a5',
  'ae2dafd6c8745337ded381ad8ee5ae4921c8ac72',
  '4beee028e198fe06b45f3e156e5343de31f90746',
  'a3033951d5101da5fc469518c0d1ebc9c998c5fe',
  '83cea506818052c8d8fa7e2eeee43fa4af056054',
  '88c88419f2991692cc754debaaae485f1cf2a6d9',
  '88c5e9532e581e36af6577bd9213e73f82d584b0',
  '4506db7fb52d3ec5d3a024c870bf86fc35f7b6a3',
  '83a8331b9b1fe009f90a6672aac969d05aec48c2',
  'c3241c7a87bd0254aaeb61b9926dd5477c0b603f',
  'b7cdaa58f3cfb85b914847a86cc03cac0e223c79',
  '393c62ff3e004ed0e8a48b803e69578e659200cb',
  '935aba04a2468e2c09e10c64c6deabdd107892d1',
  'bca584685bd0fb5760f46e6a060a1b2c68c99b46',
  'c02e787cadaa22f2ccc21c43c5e3b4e222829e91',
  'f8aace66ce80cdd3ed30e35a7af964764f0d5dab',
  'cdc91967c6c95294c0ce7f72579aa884cbadc259',
  '5feeb5a06322e6a12e3dfed141c33a872b63072a',
  '6184648aab0431c4c95c649072d1f9ff08b9bb7c',
  '863ebe68d40eba6b96ea49fc1cc698e10f871113',
  'c2b97f67ce83a965bf0e5389d099739abb6afe24',
  'd14d05caa35d73188f51e3e84e987bae088d0123',
  REPOST_1, //repost1
];

const PRIMARY_CONTENT = {
  channelIds: PRIMARY_CONTENT_CHANNEL_IDS,
  name: 'featured',
  sortOrder: 2,
  icon: 'Featured',
  label: 'Featured',
  description: 'Showcasing some of the best content Odysee has to offer',
  image: 'https://player.odycdn.com/speech/featured:b.jpg',
  channelLimit: '1',
  daysOfContent: 60,
  duration: '>=60',
  pageSize: 24,
  order: 'trending',
  claimType: ['stream', 'repost'],
  //pinnedClaimIds: [''],
};

const POP_CULTURE_CHANNEL_IDS = [
  '7ee481798b678f15ca7e15916f97508ccd04cc94',
  'b02d29ac27bf59af52e50ac25557a9d29d1815ff',
  'f30052d56d67753f5c018921d8f31942e50cd155',
  'e9300d5046ba6ecd336dced3bd979e0911786bb5',
  '91e8cf3d3fe327ffb1bc99bf3ab06764e58c8818',
  '9954bb9d03dfdf7ad868d5f4d3a8c2736b4941ef',
  'aa30b564975bbeed98efb32d6b55f8dc02e82101',
  'e6047b912ba44878f7a85cb02cdff6d155ccb6f4',
  'fb1195c42946549263999336c9ad360ef1eecb8e',
  '42ba3ddaa3b6f06dccc9e930528d0b5a896ae083',
  'd1dceca9d5df87a6aa1251ef6b1b97d49b37fc05',
  'c4e268225f84070ed158aa33d94fd0a9e6e6496d',
  '9ec62a1fcee24ef70e02faa82be08417bd39ccee',
  '16037d56ed511bab0ef56bc5185fbbb504cdf6f1',
  'bcd9b3268d103bc54596f89710be91be078cac1b',
  '43d285a96069a9f3be556bbc9913ecc5ebabed49',
  '1a3d19924669c4e2cbeb04d879a58fae714386f6',
  'b924ac36b7499591f7929d9c4903de79b07b1cb9',
  '912b9db2c8e9f968e55c5d127280216e90ddc8b2',
  'c824e19cbe285f031f3e0d904019a08dfa9f0bc1',
  '113515e893b8186595595e594ecc410bae50c026',
  'a48f3b05886622f3dc5aebdff00dfe10703e23a3',
  'c2f81c177e631a7e8f42ea5ecc797c839fc2afb6',
  '3fa6a0265533443983cf2f5cbdeb4e6cec9052cf',
  '95920201d9d12becf4dc5ca4c54563edd01724ef',
  '5fbf699fc1dfcff03bf949dff7c34588769c2294',
  '0efd53db89e43c2ab59be58f94ab3d9624412afb',
  '8ac20fe7a5571912561e63f573e9b9c20af25f8b',
  'd739912cdcbefb84ff5dbabc5ece98a4eac14ce5',
  '5b7e7729567be051bd9255b288118057c4a7da24',
  'd7522129dceca00beed54fad01328c8612012e00',
  'fe85d6ee2728d099f8a185d1a7533f65b7c311ed',
  '17c678d1c49ce8d1692a00314d62da0df1279a95',
  'c2108007829c2b1e9fc25b1df893b7d820f66f2c',
  'd3f050228497b023747fe18d6639105c89611255',
  '3772507e49303f6db071f6361494deaafcbbf4cb',
  '119a2e8c0b50f78d3861636d37c3b44ba8e689b5',
  '3df8bbe8d35bb91cc3e465eebd2f8f5e9975e129',
  'beddc710e4a9f8f296fa3a6d7ea13f816422ffa5',
  '242a2615aab8d88a939ffb5f16e01d2e862f47cf',
  '273163260bceb95fa98d97d33d377c55395e329a',
  '2b6c71a57bad61e17276ba9b9e4c58959cad1d7b',
  '3346a4ff80b70ee7eea8a79fc79f19c43bb4464a',
  '342acc33cf2481aa97dc94dd86470d5eed5cac04',
  '35c00dd34d1b1b0c3c28601c2788cceadc5f6454',
  '3ddc1090db671fe0ef11769a2e21e5557c0f865a',
  '4de30a6590a288684ab200409988c47c17a12ef2',
  '7566c26e4b0e51d84900b8f153fc6f069ad09ef7',
  '76283e4cd0168e392128124a013c8335a987186d',
  '8b62ac801b7627562beb06bf6134e1972eafad2a',
  'a2e1bb1fed32c6a6290b679785dd31ca5c59cb5f',
  'eb31fab1fa2a32bf3b21068fd75d6b1fdeda9479',
  'fb0efeaa3788d1292bb49a94d77622503fe08129',
  '0b48fa6c5f844f7b6f2b3579f792de9a6cc07e82',
  '2a294ea41312c6da8de5ebd0f18dbfb2963bb1a4',
  '7a2a5f957f348c70ada8089f75f997b69be8410e',
  '2c6192b50b7553d355a2df21a01bad6957db8e83',
  '380066bbf6bb1dbba68a9409063c6fcf45eee19a',
  '3ba8c86da0f67fa5896a60de5341243634480b4c',
  '4884e30b93b3c4c123a83154516196095f9e831e',
  '50c2bc91e7fc106aec7eb4f006f7e315bac52c72',
  '6d2dac42692c279aed436f000c053c34c3ccec29',
  '7cefac5dfb1013fe53a1c9717770535f1d622c90',
  '7ececb4976a502dffb620d55074f9f1d16bdd6cb',
  '7fa2caefeabb57cf090a2fc3e6eda3a3ce1b8a55',
  '871ba605db0cad46e43081c4b3d942b80696359f',
  '87515affb1d52602a0a456b400482d7db9cf7de4',
  '9190e94b95323e663ea99af67d35d0527c578ac7',
  '93f2b1e77958ceccda318e64d5b294fb717df8ab',
  '941ea9f49e136a45e3c1ebded90aef66dccaa07a',
  '9c1f46a30fffcf614d2ec57ce7857a829bbe8878',
  'a0baedd644cf0b3e5b61fac8ffa7d655fc4689d3',
  'a20ea2f14e1fae6fb896c7bbeb877d001d8efc00',
  'a5c58349f751efd33e247aad9604b58cf4106a15',
  'b7d02b4a0036114732c072269adb891dc5e34ca4',
  'c1296488bc1aa7a8c8890509fd231267b3cf9e16',
  'c8ae70f6790fb1c396003cd2aee4f6780dc1aea8',
  'ce6c8808bd7fcb67b6ebd1bce953e2587bf34bc5',
  'd25ae97a1516f5700fc717152b885f33da47f12b',
  'd719ce0fca4016f28cfefbc950bf5d85b11fd430',
  'd7e3e5d40da7ad5df884115ca8d2876dcd45b956',
  'd9535951222dd7a1ff7f763872cb0df44f7962bf',
  'e6fadfffad8f3e2a1fbb5d6919a5f276d53d0abc',
  'e8c34b89f59ea061907271db02fb5d2de9d7d852',
  'f4e07fc035ea917806a8f1df39af1880b4557150',
  'f676dafbb211c1ab8986bb91defa090c1bf850f1',
  '4ecc029cac207edb5bb7770947ebb9e84f7ebab9',
  '3a3af9d672ad2166737758f4e35ac6fd6f1235f9',
  'f5814aac8b27f401dd90940671fcfe5a8584f0fb',
  'e9f39a0d9a7ef6514617d1a04e7405309f0651f4',
  '0b89a86ea8e294c8de5af08b5aa5c6f5c3ec3087',
  '70d5c5b51e7c9705dbf50c1ca7af49e4c033022b',
  '593b9b7e8f41f89188f9c4e9e71750f4b767e72b',
  '1007cbe0bdfd38e370daec85a834c0a2ce00fbe6',
  '16097c61ea5294b72c5883000d9540c794613533',
  '95a7c23a3527435f716e8b45558c668e0272e4a2',
  'a0ca37ebbac047693f55a9d5b813649c68fbe965',
  'd6b4c5bb4e9394add5cc41e12c539d8c8236b232',
  '07107ce8fe3064309076f334b3c46ebf7d6486a8',
  '450a4952e611f333ac2a5fb08b2a4bb421b4a406',
  '71ff6642b8106ee615a465d9f65912ae1cceba04',
  'cbc754315cdf2a07245eee7c52d356f1f7011b8e',
  'c7fa022125d2c329bdb92e317ee9ead2902a4608',
  'b2d1e6ed1d98f795303855154ced9d8d48712938',
  'bd5139bff4f30b25ef36fe7da60af3a07787e9e7',
  '05f552b6fe38cd58bd02bfe63b9f401cc8731c8d',
  '7573167304b237a01c9a18a1a3db1be8626f82ac',
  'e4264fc7a7911ce8083a61028fe47e49c74100cf',
  '95d7b35213306eec5d0751b4a4a7dccde58a0583',
  '603e17c39048b916e660e4578bbd4d5d0319ad8b',
  'ff75b241232f37a85a79c8089de02076deb62235',
  '7486910b61b2c25b72ce1309ade578de17f01a77',
  '9736174b2c914ab5629cae3ee7c6edc827d0fbaa',
  '497a8bbb2403766ff1955794efb1ec2e348c6d5b',
  '15627c8d79e7c45b15fbe726b34d47accf11b8e2',
  'a86b2c31078e4e8ab9feaf8313f727653982bdbf',
  '7e84c717c1b9acfc534003c59eb2db01dad386da',
  '974a04f26492280e394b798aa0887f307782b830',
  '9ff27e1edeb33c6f1272bdeae7f7a561c0108fb1',
  'e268fee3117f42825bd76557233ec73e46cb3405',
  'c0b123a333d629d3a8fc5416f2e859cd47e5a72d',
  '3808a556e5994e51b7e6b86f1173fdaf558dfd4e',
  'f6b100a049d0cdd88839c84e929b4b9aedeadba3',
  '2f5e2832ae0e175bdae6086156af02cc2801a63c',
  'da78db3e514d8a18b3fd90397ddbf184e6fb82c7',
  'cb67e1f87dd46a9458ad3889954b6580d9dcfd36',
  'ce45ac1ab90324a6793ca82a0a29b2f2229b2fa3',
  'b51db105cac37fff0d6d33950aa16e1dce7662f5',
  'af4e27da48933f4e832aaafc269957a267ceb121',
  '337e665bf9d1a44143d79c157281136c6629a6d0',
  'aa25587f17c87ae40d6ec59eb9623f7d5e5180e2',
  '82940aa6f70aa80b148a82d37065cf728a27e501',
  '981955a3e9937ee363564a70402fcb59c566fea0',
  '93d9a9c4e65e004ff6cfbe1c1bfffc8821514de3',
  '14da8bd119b36ea0fad4bc9ca7651899a8424e9c',
  '0318917aab4b1574a2377b06f27aac2ea201030a',
  '801bb768110efe4af0adebbef7df9e85d93db4ba',
  'b26fece530eaf370c66a789e3722fae118a54858',
  'fb75a0b2c5ba0bc5d2dcde21a179c3bcaa3e8f0d',
  '666f519d09988824d3aa256d9188b0c5246a56d6',
  '35e404aa7e9ad33a9d5fd8ced8a074b5b83ad773',
  'a8f44f2b88b90bc65368dbafc271fdb55477cca1',
  'de0c5585b1f95eb7ca334cc4c491920cb792ed8c',
  '56ad3a6dcb79846da1306b2176186149203deaa2',
  '208fc19232f0acfebfaeca53694155edc33037f4',
  '112a65ef019995b5e79bac17cfe4fd0b1a7486f6',
  '334d9c2701236d3c3fb6f7e8e6aa0bacdff6c35b',
  'dcca08c0a9971c601eb56abe5ffeca67b8982355',
  'fe97f1c41734ac060d00006080f7e44827fc625e',
  '140f0dc8a340cb8d63661cc62add85d55a53b368',
  '2423953fe3994de8265f4d1d0c56b0544b55b0a6',
  'ee077d1d69ffb1761a3598f058181006a56c5147',
  '6bca820edcae1d2afdc7fd0a4b24adf01a1d94e8',
  'e5ff26e0674348f1a445964e9169027a19711b17',
  'c1287d55df3c14e1ba99c3e18628f7b6cbd79e97',
  'ca33c3a601305125a1d84cba2b3536c78d9d8a0a',
  'c20f77e01b4643066d4c9d224b138627cc46c952',
  '64856c4b590784498dfd67656726293bfb7dc47d',
  '289dd3b0343db9412eeaa30b3023cec3b20a9499',
  '02cb082f20bd40ed332f6a2f155cb2904b415d11',
  '62540d04dc7c70b9fd0b8971a06e46fa12401260',
  '49270a98ea6ee8752ec68116fee8d279d80ea332',
  '77c480af914cb9f19533c53c3fa873abe344b93f',
  '3efc475174108dab5ee5de5ff981a2f4fc916977',
  '5c03b505a69bb6ce41b5c930bdc8d41ae3b00445',
  '12fda6708984f98441d955f7698f53b6fbd0f49e',
  '7bdbaa719bfafc1f8b670463179e303928bb7098',
  '818d6976d3c388e8740ba9dcbe90dcb1dbae32c5',
  '94763ea6c2452327ed13b413ed7d1283dcb33b37',
  'd5753228b6aa1a34b8cfab7a790890b1e69e3e00',
  'b830cd6361ef91ed1c1d2124ef191c15a6d6140e',
  '6db2ed65286cd2ea821d2f5feb29bd29137b590d',
  '80d83a515872832dff87986f3791a646f52ec1e0',
  '26a81ec1aaa1856bf63a06f81d26ac58a8bd77bc',
  '1d729d8a4bbed9f3e9bec0cf1e958980569b0681',
  'bb4c084ed330877b56ee376fcc294ec17b05ea7d',
  '855ff371255cd7ab4958bcfc374c8f2d9ef24671',
  '64ad91496026aa2fe782518631964605de53986e',
  'cd444afcd4548e120f66b693b63524f4e0acb8b0',
  'cb50ba51334c879f6b8520d78ecc233fdad48509',
  '999b5de26bf14490b97f4a4c5c76b2c2184a9f00',
  '7659491675245b4b7a2511bc2d7943538a375b0b',
  '3c5793fccd26a3d1d6d044b9220a452656153c11',
  '0e80e31b11b932bf28d2ecfccc12f9437f728e56',
  '312c0dcd2dcc3247fea0a4e82b6ed90054bdea9f',
  '7d3c2663c750c1a28db16aac6711ec9c64b8b3cb',
  '020ebeb40642bfb4bc3d9f6d28c098afc0a47481',
  'ce02e70b81d4e51045d6fb9fb8c658779f164b20',
  'a4d0ed83f1ae29752217e4877c67d1bf57ba40d2',
  'b14dfec6fd1f2bac4670991c75e46b97350b894e',
  '9c5fbb431cd8e6238db859c8d3461180c9a7b3d7',
  '0a31ac6651b01c8795abaae204d071011e0fc594',
  '97499fa4f9f4e8f341abf21d1bc4e93ab5a54df3',
  '96c9fef3114bed766c46f20eb6c689e995858f92',
  '8931a2673697fa9f0cf4698ebe09caa38be5fc20',
  '515d057bf996db18f4eba704c2de858977e2c074',
  'e1ec899b9553417d1c0247659ffef62a58ffdd2e',
  'aae297c837060d9f7ebb78fb7ee93a3d050044e6',
  '6e1106d54a01cffcc0d73fbc4b5454ebc8ce7670',
  '4fa4f18a328f80e245b1da9e74ab802a0935b813',
  '889dfb6db8346f042365a51bcbdfcd0dbd930846',
  '35fc2cdafb8db7933194d110097c95490208f871',
  'e72d9d71deffff2a681f544708a9008f9043b550',
  '9ddbc52cf77252e23ab58f11e4bb753ceb86e589',
  'f848a062f8f0fc9cb757dd14ef426ff63ccca2dc',
  '47532da3c6a1136c8c5e206fee935f26d46ea0a3',
  '8b1b2469556c892f7a183865b14886c607234a46',
  '89f7e78f2cbaa07b994825a0d895c4cfa7e12ebc',
  '9dc3fc810a55d8b05bd2abc1f59e9a23af9b1edf',
  '51c5518757e676245c8d625d80cdef9b001418f1',
  '51f0b20e6deff78302167a5f62a74f14a3beef9a',
  '03242bbe74e401e0856aed12168fa4c9e9d3f868',
  '0ccec4eb91a8d0689eb94436f302b1885281d99f',
  '41141e25d644ab50ef75108c50c4fc36b41677e9',
  'abbb7de20c3fe9f7be7b32f4a922f690af122922',
  'dd5dda04f46407777795f48af0711d4270dd9c71',
  '660603927dd53df315a78545f856cb3ef8878c01',
  '7cc534ec299d478d5211e999b2871cdfac651179',
  '9a62a9fe1d751a0e29755a7c59fa90251f7bc03c',
  'e280fed6a62eccbdc9f28aaa282e484727eecd14',
  '9d3e79b783946c38a5ec30488cdc8aeeaa128685',
  '0a7ee15f0d9db631b8e9d083e8b79eace0f2ec20',
  '173628e832b1d10ba98c1542089e43a5c4ea3613',
  'a9f147e9ff261d5507b973ba8f8c839cbaffb97e',
  '62c38facfbd72b903222732962572943b83439fb',
  '63b19b85ea782c69d9e472bf283c4f09065ccefa',
  'fd33008b7173fef12f91a70c3ff44b39bc9bfae9',
  '29b240a8976b01a7eb286d30c841ad8fa6670253',
  '73805c97d0904e66e5db6c977da689b451d8dc9f',
  '6706cf13a323fca41a100643e79bd3565abf361d',
  '88f71d6c41d659e897ecd80d0555e7319381a036',
  '939188e5c907aa9ac39de15613beea7bb6ca2eea',
  'd7cfed7c3c9944c714bd79429a8d0ffff8056640',
  'af64727faa1188e47aa988b731167a74ba7c7b2d',
  '573a1bb26323489f888453435b86880ecf75a1b1',
  'b3c16cb709efbbacefb08423d68400a24da26164',
  '312d444e079bc48c46ffb2585b1061f1c1e7cda1',
  'ab998a0f0e2a90759c6f4a7e592d8f417a4c6a5b',
  'f220dca0c38de1b5edeb6bc14958af299e2432a4',
  'c3b917e35a7214d8de5f38c2ecce515fb5471ca0',
  '2653c04c121b28fab01b58d2a8d64d3acb0b08b4',
  '345b321ae98a2494235c4e0ea771c264d0ea924b',
  '7311cd9e5038b401aa311ed113c2a40856d76a27',
  'aeae4be18e3aa6f631f2bc7c336d26bf0b4ced6e',
  '187142ea2c3d630a99c0f0fd991dc6fcb3cf461e',
  '72a74fefdc14a561565eab879f2a3bc6c2df96c5',
  '06ffddd028f7d85a57ecb2387adb052ed8ee4039',
  '2d154421c5fbdd15d4032fb5063eac72f4d35b74',
  '9ad0a3265b738bf0c1635fab9ca7a49c62322020',
  '25ccde83236a395bcee9ad55b759da8684d051bb',
  '125626735ac5a8ae7b61dd46f064f8b765eaeace',
  '926ca0c44d6e6e63a7cddc64998181f227ff812e',
  '0ecbc807fb735f17598cf43495aa466a636e5b67',
  'be57f7c84963766a1caeddf6b6a63b977b56c726',
  '7d92c37843272bb14358e7eae649a08565eb44e5',
  '08553821ed12171cf64400b0304d630de8323995',
  '299cad92a864776f761a3c078149219c3a1c0b8a',
  'aaf8baeffa025694abb49419a5c63b14c7d837e5',
  'a8bd59188c52b6369f3c3d6629d237af2cdf5111',
  '8b7cae02353457a5a462ff39062253d6a1391a6b',
  'dcb6469d3410831c3c17ff8a27d72a86479ba903',
  '8c14f57bf0675671efa6af569a39e56199c1eae0',
  'adc2ed519f6dd513b02c39edd93d901528110e78',
  '9cdbc9946e64942939a2bffd3a5bacf8852d0e73',
  '6ce5670063d9946a85279ee6f3026733c3a15ee5',
  'ab3f33cb852be4ded9bc550d0de3c85fedca5e6e',
  'fbad693682ab478ae67fc36cb874856fd4263770',
  'f8aace66ce80cdd3ed30e35a7af964764f0d5dab',
  'a6436ad007f8c924a701aaa529e954b3204965dd',
  '5d5f64c78fd3d56445314252a56f6c816a69a510',
  '48a08ec6a031562f3e24dd02c0c2a4f69006edce',
  '8c7cc21b5c38a53a0a8463a0cf2433850a3b072b',
  'a997231b48dc327b1417637d2d13402b65effe68',
  '59be6c8f6232db8e89de83f61975e35ffb29aaa8',
  '216787c5721a74e65f3f1f2df40b48c4f0eb8a5b',
  'ae8db7fcdce4f10f051b556b3f214fd539bb246d',
  '71136d0d028220bda756393c0440b57506c0ae6b',
  '87cd043cb2505379e053ca82ef52fff4e34c3683',
  'fc1c0e8d1b052542a0a0e5ecba7bf01ab99f6062',
  '47440ae9e4e4f4d2c7664924ccd740708f4f9759',
  'a42831a84e6a934eebad5988504ffeffaa7a7fbb',
  '6ad0566e388a3c7dd902717f6a843af0417bfd18',
  '4605b9e6ef8b6e120b49189e9bcaa8646f6ed202',
  '2eda68d059a4ad9856e3b95af9a55c6e93205e57',
  '5d1e9c08cf202b77348e62035a2989d6acc8c2df',
  'c159157f160c1d948caa5e9cd50dfaf0c0c7fd0d',
  'af8ca054169e35073f5c574548ca6ca70a619411',
  'a581524b8e29259f66259d16df944cfe1bd00a7a',
  '4b1b83a7bbf0967dccf403b7b60304b77b68d9fd',
  'a15880fe277d510cabad9af359b8262b855e66b4',
  '88ca0c42c59870f1c1d0566510814c9db9ca22bc',
  'ce66660fb2ff70f206e2beb23e80fe3df5697453',
  '4c32f24e5ac9b60406db9d865b45fb99fc1ab017',
  'ce6a8b8c3d97159a219fd4f58051946a8c4e652d',
  'f62e0eb6f3e7be83b3701686337a43d4ec838327',
  '1e1629a5998f0b50699b8353625adb91d1904dbc',
  '4e1c8978f68a1ea687a0e2aa1a5a4caa9c020833',
  '926e1d447407abf3f62ab216690c3ecc62e032de',
  '3d263b2245fbe8f21907d7129e1a3eea364ed183',
  '757b0d9f8714769a35cd7f4b7b65ef7212ca216d',
  'e7f090b4a744a6f6d62a52eca502f50acc479346',
  '6c7a920d6f32c5c8773b21b3041dbe284650a6fd',
  '4e61a8f835336145a89751c996f6ae8491505b85',
  'dffe9ded717cd0bd818db1438070d8e91c9a2a87',
  'ca51a92456acfe7a7bd1b366d762031fd8419f16',
  '7bca075b6f90d348f9c847371a5809ba5499fd38',
  '70f913fe9f0a95d14b52af31e75845b64326a4a8',
  '081f5a1a9018e2e1e3ba58540ceea66ef3c8f1d3',
  'd051ea4489c154dc25ceaff9c6fd476f3d98f71a',
  '2367dc24e989b29cabfb80d77630716a1713c86c',
  '062723b9cdb9c451dfaee067ee04dc00fc993857',
  '999d280cca4ceb65488da80cef067e0bfb24f815',
  '162d613b54dc0693180a994f4cc11404d5ced6d3',
  '01515d339924980bc655dfce581df5fb0546b2c5',
  'ca01392f6c1a52733a141e9ef7e347986da65e71',
  '34cf2e3934a52fdfac0080511eeed78422aee2e7',
  '6695f8afd21ce038807dd5866d8605107704ae8c',
  '8929c97704bfbd8a8db6c87b778e3c5edb5182bf',
  'e794615bbcbc7e0f5a096822578b9e298816271a',
  '2f04b613f76bea46e0a699a01e7b9081ad8ae80f',
  '92ac266f0866ec08a3a03458e5a7934dd8cc5d16',
  '7ccbd6e947ebab2f6f739a79216ab6a572fd0642',
  '7f27d61a5f774761d89e0e497db3834aefbd2775',
  'fa9d2a20095635249ddd36fce32b63bb50261216',
  '30b50bab71c412352b3fc1f1bfd804366655d499',
  '27f26406c382d6827b295b5ff664c4b62cf7cf2a',
  'f31a8deec938267cc1a02d7752007f82abee9576',
  '0e902dc2b3e93b3030b712120509104f51829f95',
  'bd23692e7dbe7169ad257b9d24673ecf24727699',
  '42e55225b72a5847a92d38ffa2e7bd1bfb5d34c5',
  '139edf72918b443ad98f46ece5d50e14e6222d88',
  '14b2eb732ea8fffe4b737135e177e16606e5628f',
  'beb6647919f65d848b6564b73cad6e904447433c',
  '52a474ed1d2dfb713213c75d150cc28095e58550',
  '63a61f8b9a13979ec57fad2c5517c5e376f57059',
  'c5c2521c030a40f058a8c677917106b2193c0927',
  '36a1103ea658bb9578ee920d0d0be47d0b5fcb03',
  '44a47984b30be2c9a9a8402570a9a80fc1e534c8',
  'c61f06e671489c58c76f36e3d3937117096c6565',
  'e6ddf0d5a4f7060b411d09d5baf9a288f6575733',
  '9d396b3d279f806d01b565c25b735d63ffb2542d',
  'b2cbec4653488a261d223c9deb3a0998c60941a3',
  'f737c1490094e4a395d6ef43f0494ae072311699',
  'ec356bb08c4465b009d49c526a2eda6509241dcd',
  '6131e29a0d5ea51277a019ee6d2041f4e0080fbf',
  '8f3eaa7b2e8b3e1d25c93164415c78ccb687750f',
  '4bb6861eb125bce2a98bc96d824e8a3532d182b0',
  'ca81b6842ecaba36e55345f9077b7756153f1a5a',
  '5a6d7b42450d40ffe66cff1fc3c908605ae69a8c',
  '544ca030a7dca3b6c231c66702cbb2d39179c858',
  'b61c9d935072d14341701d039afe8b2899ea9630',
  '4a5b0ca5a35873efebce58b4c6bf00fd457f7295',
  '2dfea74282a6a46a143937032ae8361343dd298d',
  '0c047752cb81b40c41714314e88b1d07f3d7619b',
  '931b9d7b85d2d4ea44125e17780cc47bbfbbad44',
  'a2a409b4450a653b1188b016a877d63e65cdac38',
  '89edcc6b5e19a57e7ea1cd1df9c8efbb7ef29252',
  '09f64db539f9a2a45b6eec79d89c0a0fb158f4e0',
  '169a96b11606d4d2097b13206d99174a60ae0cef',
  '7c763e6cebcf5a1600141af7b250c455ee57a8ea',
  '2fdfb829805c588e98362ce9b11f963e7d80506a',
  'b2837c5139a6548a73c4f6bb400b3923c45b863e',
  '7bf2a2e13e06ecbb55a4357d7f3ae605748f7ae0',
  'f6e87ae3cc1691ce23168b3b88af620480733de1',
  '555bbda614a5845e16d890b0f6a4a9d2945b6494',
  'fee3e4e50f5027ac16a205c8a0d78523e15687a2',
  '39f0c364fb06a76ef9510ff49d5535a6558a9d3b',
  '644e3eab857034f0d722f1ecdb954c84b7115e7e',
  'a8cf2d22174263bddf05a7acc527de53396f5106',
  '897feb4282c5890dd51a34130b7ac78d8b325ab7',
  '3fba0352da31a95e2362d3ebc163ca88af508920',
  '1d795a7dd55f9d7f981b8f1d96939f2fefdbdcb3',
  '45f57a2113afe6e8ed811336101c8f744b0c4a1c',
  '10669d0688911a87098e1a2357873d8710bfd2c9',
  '57d360ba2dbc3d0d76c5e4416043305a61f311a3',
  '2bfe9090cf115ec5787f9d64c389657898542343',
  '174bb18f7fd5b6c9429ccfc4fb258b5473a98733',
  'ab824f9b16c5f947f5f6824737d9487e630389df',
  '2357a40c840bcb4a183c501b80ce84207e573340',
  '8b6a66af44304286685775da88b0f1de49f06022',
  'c0b78a755e887eb4304f3e50cebd630ffa095865',
  '9ea16359beea98e6b69a1e61893c46f04e8c5d9f',
  '4537f28131170179345fb99477a997a3bea96a59',
  '52950e70b6f5c57b8160f6cefe48563418f25895',
  '6fa751341bb9708fda703a87efb4709bc6b81cdf',
  '9c6f70888cc783955747fb1419a176c67548b697',
  '9c75e4d39f66ec8553af8e278dc8e101bbacfbc6',
  'd189a7a310916b32c7184bc827390a05cb1c0324',
  '7ac4a67aed37e9bcfbc352329dba9120a969f667',
  'c40de90c163dbf96337d66c759472db8616957eb',
  '5a2678b3b132a99d0cd44f81965dcb9ec2948d56',
  'ce5957a76e7ded11741dc921dfee984e1ff83a1b',
  '507a301b395884ac35230d8ef04bacd51acc2072',
  'd3c0d9a3863b0ad5d0698a3c5f34949c045d1411',
  'a6b451788ddcec4e0bcac0c8b2fd47c4fad84673',
  '52596ce2b9dcb0fb9c39578f0afe73c9c2888c4e',
  'e7e0e6c682b02c40a203f09b2328d2bd36d71c16',
  '91f2d33c50552a3e70a214ec13e391564d8e3cc7',
  '9464980491a77afc69f089d55177e706c25d46f9',
  '80145dc588e72694be364993ffe7fed6a1239670',
  'd6433cd3ea32c968a68cd7444febd945026d8a3e',
  'd50e0c3cf5a21a4706fc58242ff404ddf1594367',
  '2ab016696d1d96ae8f10a83e8e9a94364ffd58ca',
  '5302aea0721fb6e7ce7c6494fd7a1ce52886261c',
  'e1e32b746d3cf2e1ef6e227d0c573e5c7920b081',
  'd66a71e395b5ce9653d6456a3a78b4782c87e04e',
  '7242f6997c80e3115f01633d644976a522e99f5b',
  '1614c55fb5b067c7c0ca93bd4b967421aaa5a2a4',
  '5cd643f7700e2e4056b7d0dbf7c806d506afa7cd',
  'c97b4c1c40b1fbb9a6a67827855ee4e32e21e68b',
  '577371936d48d651a43d0676826199bc0c28d37a',
  '7f10982772544fd819720bf3bd4655363bfe0607',
  'd517a5a03ce12ef508149e9637b325449d41d1c7',
  'a4459e4eef0eff7b8983e9da060a809c87df8d32',
  '6218d4fe76cf8ff62baf369d1e31e9c52048108b',
  'c771f34fff23df9a2627fa969040b7bed9b2852c',
  '2b19d0b23e606ff8b3eb4f268507c2eeaba9ca78',
  'c0ee0f5893c3575485411e8705fad5c347f078c9',
  '8335d092a1c3c8a1f8f1b019bd4f215defe41615',
  '61b4e9d9a244df66dd9526059ef75b27c212eaf3',
  '90fc1057fcc0a8fc71f96fbb4be03a382068e32b',
  'e8c0ca38c8a403f05f032e88bc0ef4a279e4f282',
  'e0913f7cbbef694eede46a67b30d2adac5e4c5f7',
  '8f63232c343ec52775a204a336fac08db76fbcd0',
  'fedf809237e452ed1d15af8702a6c8a822a03f02',
  '26eb21880082ae2e5bb9372b609cdeed8830b7ec',
  '37c187a62d0e20a29064f06999f7a48ebdd4e110',
  '5f931c7b4e4151416365a5f79ea135b1b8e78abd',
  '71ca8545012de62afccb03311364ead2ed8d4f73',
  'd5091f25c9529846601e3259854c97ccadfd84d5',
  '250a2acb067286794842e3182d32d80632bb3251',
  '5833e45327fa6ef20f9e3e672a0cfcc533c49e44',
  '00d955d398926d3689799cbecae240e2f8a677a2',
  '32c308fd9785f6cda126d2584982a3e46abb92a7',
  '15f4b97417861aebbfddf74dd35566a7864a46f0',
  'a1985b3bddd63778fcb2d626ae9af51e54ba92d8',
  '393c62ff3e004ed0e8a48b803e69578e659200cb',
  '2d3da8594ff97b7da07900f2215d683fe3088267',
  'fd54f6051f8f3f449788984362b45e2ed2bada83',
  '5190f00b950ae96607a5f1f1585b06dc1d869ef4',
  '23a68729b5ff2b4e7b30b228da45381e88f9a422',
  '278b8b0ed9c310d098c0ee3adf97eb7029f6be9c',
  '2120e15b38a295d93a13a234a11b830f238b33c6',
  '44622551968c60570c9db75347fa5feed1c37951',
];

const POP_CULTURE = {
  channelIds: POP_CULTURE_CHANNEL_IDS,
  name: 'popculture',
  sortOrder: 10,
  icon: 'Pop Culture',
  label: 'Pop Culture',
  description: 'Movies, TV, Comic Books, and all the other pop culture content on Odysee',
  image: 'https://player.odycdn.com/speech/popculture:0e.jpg',
  duration: '>=60',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const ART_CHANNEL_IDS = [
  '5db7d336b104ea6bf34489bcf3c768e42b71e860',
  'fc439ee7d47a7a5da8b765eac4e8c331ec918c93',
  'b0bc8e81a4b26ec1b0ac66088f0012bf2cd31bcf',
  'e564cdfff3225a6d7671eaed68a87ac9e2475e3e',
  'adb625e59cc14e8d61a959d3ccc789934319c319',
  '28dde4c11afbb3119a76061c4bf27ee1cc1c6168',
  'f28e1bbcc128f0a069f8cae6fcc99c26b9b8e62e',
  'c277825cd5295b3469ae691be25d84a17d4f037f',
  '6c9a5a1a1651db3633131d1d3b3465f296a864e7',
  '4e4ec45ac2952f92be531031becc5e80f8761932',
  '2fd69a6014ef7a6253de3801d1d70c476237fad3',
  '7c89df2468090cb299d11ddc2fdd3494d0ec74be',
  '239e4068eb8c4607e2b204f7019a73fc97636cad',
  'b9e16c0c240eed800a977aef736f5d9cc875bca6',
  'c60c463907721f0ce2ac708fec92265abcf1de5b',
  '480c064edef7098fbb45f801c83486ae79f0c226',
  '8e17b6daaeb9d1e4b1cd587fcd8677d1cf8d703f',
  'd1b4a64904f5957d534c017837230ee063ca7ea3',
  'fbe5861c9bdcf1e7e4c093d9628f045ce8eb5491',
  '6bbbf54bc498024c091140acae840cb528b21cc3',
  '87515c6e938709f1d39b81a618fce1c223518c1a',
  'e5146fe21398c9911f9375646a1fa82da25874b2',
  '744985aabb7514cc803cf153f975f5ca64a91be6',
  'd2b93cd4ee1a1f175f1755cd1580de936c14701f',
  'ab2d5f64d88f991b55fa5b26bb00de1a35f38d6b',
  '9de33823698ef635ad9b50f041ca4a04b62c3f70',
  '1d9a073130dc51f4bc2e79f8d88c230e5cf25fbc',
  '20bfde73f0ba1b4a464d027db910b83ec72dd52d',
  '3b7f43267524ea770019a5189d11a77c68527c3e',
  'c29297d5b4b295efdbb5b16346229f080b376332',
  '70ecd42cd2c074b94aa5b5c5777f6c00d7df0e39',
  '1e9888940124e67a7353f5ca11bd0d1387b368f9',
  'a7d971286d4b2ecd740f179f423edc192700c062',
  'eb3d0f5479fa714ed05b50ff439fb54206edeb57',
  '54ed1d804d83883ed722419c2fca97cfaea14b95',
  'ea0d950e513610642e6db62fab39eb2c9c9251eb',
  '243b6f18093ff97c861d0568c7d3379606201a4b',
  'cc213210116198eb2f3a223b5ed300b1a4b4ab21',
  '739dd06c6fe8112b37db05d1bce21eb5e9b3fc9b',
  '4bc93953ec11329cde14675e535af303f8951a7d',
  'e44f087f92748e418a696ff20584f8508622dfdd',
  '9d9b7d1dbe2daae4609cf29c35fcf7a5cc782a31',
  '1b730ec758a7efc5a3ac83fb8a3eb91b7f154f99',
  '46462d2d4af95a1e9131d54df61fe62c25c4b659',
  '646d4f529dd0ccad07d97aac377a16bc4d7432f6',
  '4778d6695cacf5845d572db69aab3c29da06b18a',
  'b59676736b85e441077ea693136dc4cb5cbfe3d3',
  '6c2e9536703de51d0b23bbcb40232413acbd2152',
  'bc6ad3581c3a1eebdae52a0536c3e618d859688a',
  '1a0ef8944d41bd342795a148c8fb08d10c897353',
  '01d3be669a2b58873b81fe1ff75115960aab06ff',
  '44a1caa1c5376da476ee97ba55bd2b39fa94ac4b',
  '91b6cd1bacede29f52c0fa42bc4e45a0d73692cb',
  '355356e7326aa8166e6d8b7c1a70f9087b823cf9',
  'e639bcc68515f053cb2560df250818357e660cde',
  '4154c8358640e279ab1e91a30611f37baee5b1a5',
  '88efe243bcd8aecaf034612b349efdce923c7f26',
  '770c7d745eb6e4b3133d7386ac522ba6eec48699',
  'a557af8b5a2b575a8c45fdc7b8b4f3c827bed444',
  'f3e79bf8229736a9f3ae208725574436e9d4ac03',
  '7667bf94424f30ad4d2b77d86e9c0cbbc4a9925d',
  '079c8b8510fa041907dd467c478ada57faecdd06',
  'd880a776541d76dcbee22cdbad080d433a3b2bf7',
  '5b8c91cdf40b2b0c14a2ef1a4fbe987a34d4677c',
  '6ab0d775b951fa2ba3770bb72e129663d963f08a',
  '6c169583c11d49965dbda5bd5e6a53fc28e33a3b',
  '7317cdf6f62be93b22295062e191f6ba59a5db26',
  '7364ba2ac9090e468855ce9074bb50c306196f4c',
  '7c42d03a1171de96394ae510b5f176b1685638ae',
  '9c51c1a119137cd17ed5ae09daa80c1cab6ac01d',
  '9f3f751faa031e95e7e2b5269a9e3f2189537bde',
  'a2cc15ac445b94df669f3c263fa5e0119b82489a',
  'a36a7ed4b69f5453858de4a82bc86b8ad85dcd67',
  '4d8e76669009806f6c10c26eb04fae3601d82114',
  'e66b88b5759cca86a19ec852dc5810b4a5015297',
  '72a78d075d8c3e11c1dd38ef3164d21b240fe8c8',
  '2ce76f01af74a5539d09f63e10095fe8ffb3b448',
  '15d9b204f3deb7b4b93587c0da859c518449f0b0',
  '41cbaab4dd7b6fb8edaa053f5909cb2e5a257ba6',
  'ba78cb9c861fe4b147f173ed7e6ac2ab305e57de',
  '2803b8f28109df0e15c92d8e4ae5c4ed884375b2',
  'a29db3ebf677f1fe317ca4ecf0a65a172d4735be',
  '3ad7c5e2187a225bfd356134f6b793c62be5099f',
  '4a04ca9ad2f1cac40254c346d56c48a03f166d4b',
  '2b325ce97a535831db5a7ee4033abea8c987f24f',
  'ca1fd651c9d14bf2e5088bb2aa0146ee7aeb2ae0',
  '4f0a8b1b21e7b2a17ddc55be8918180102280f97',
  'a3f0f239c0dbfc012825f8e58f36d1b159d5c0b0',
  '4e73f2b13a6aa0f8b8eca2f46de7f6988a24b1ac',
  '97fd11d4d024de8d16edf7ef339b97b71e85db80',
  '7faf1fa0e63327c5183baa72b456016af2ba8cb7',
  '858c09bd0281a75fc06b9c013b2893360899cb36',
  'cca259fa3aed21ef134a214a5bcef46a5b545499',
  '5d32e4dc6441186dcffcd9bac34aa8ea21efd88c',
  'a457e44e439a85d6da29e32cb61ef10475e90238',
  'a30740aaed193986986bafb39f9e278acb995265',
  'c14d0170cb85157e4d94f38099d08d3786109dbe',
  'e1bc0ffee1682ab1d634a8016b854d9b7679d1c7',
  'd40da9a00ab230f4451773472f5070ecd84dfb68',
  '1311f5ba008663d3d2b0cccb2bd8bb2ef01685fa',
  'e9cb98c19a22aa6f43321a41dc444ef401e07542',
  '6e57eeea6eafe7dd2d6e58f4ecb4362d399ae0f0',
  'e2598d4092f9bae02dff860851c04fc2674c9bef',
  '23d3877f9332e67ad4dd3ef63b88b9c14572b9e8',
  '1c3b632d69b17ac42f6264a61a92db31f1e3fa8c',
  'f467ff045ed073acbf6bf79e3361eac82f6d794b',
  '40c2a158019f6b0d1ae522032a320aad47538783',
  '5d0c3822efb982c154809fa1e2d6d4cc1023d717',
  'e59775ed7c394e446032ad493e8153083e58935c',
  '522f1b569fe3735c4cc3b5c50937e7cc64fd0f67',
  'd58aa4a0b2f6c2504c3abce8de3f1afb71800acc',
  '452ea6a89b26a9fb141266a401ea82dca14d1abf',
  '9fd2bace0f30839fe4695e71bb37ebb38a05e33b',
  'a3e35f723d9ad82159b4858ad628e090d0e372df',
  '921b3f6b0e1735228b200a7fadf71271045ace0d',
  '64a09093df18844906cafbaaddf98bf5705517df',
  '62c14d85ca11327f4b6b04964048f15d4a06c9b4',
  '0936a8df0c4edb54eb06558d0e829c6468082528',
  'f7c1445c78ecef2368c00f68b60f440d5e5a32d0',
  '078102d07735c7891c45dd3e23b7351ed7592791',
  'f05157b9a36927fd18437c2a9f5e68a84c3b33a8',
  '840edc9d2115a521ca7337f8fc469793d46fbbc9',
  '18542ac301b3474c15470e64136338d5bad73b37',
  '1c6c3742507cb72318ad206d91f7105e7c73c14e',
  '267cfc642d5351e096921fa10577d62bdb54597d',
  '798a53e6651da290d653ec420bff8488d417b07b',
  '31de188735f0e14a694018802baff58b7b8dbee2',
  '275fc7b516daee53deac246c9f795ea558e3f984',
  '115047e96dcf3c65ce378ec1d798da35240cae58',
  '59bfd76bf628cf0c021ebff21fdafbd9dc0efd52',
  '3264777dacecd86cb6b5b2f67b7e52b7b3b9f06a',
  '64142575963e74a6808e64104de4bc1c355a3202',
  '0adbe1755f94165a8ac327ab7ee04ae622675993',
  '64c1f72849a3144c75c43863bb590c44c60b7c99',
  'cfbc605de0dbd72ff34525ee6ebe9e089723bfde',
  '231860d5aa466fd15c8309969cc972d77852ccf8',
  '7e081dc4498da6696e8d65d09968cd05e10e84a0',
  '8cefc0d1e7100403f9765f98537f9df41a8c2612',
  '17e25851448aa4dd6ae97d9c68cca25959ab77aa',
  '85263c6ebcd314977ef1faf56fd4e93278d93cf1',
  '5314c6b8d3a8aaab694fff9b1623b1688cb2adce',
  '7219603a75fc3e41c3e8f385d17369966950d96a',
  '7b458aadbc3c4f5fd681a8b3161b03501a175a1e',
  'd945041ad089ea3c7f4119c556ee1587f35312e8',
  '735d18717c87ce59d3d8039fe64095134a951cf0',
  'fd7b121bce1e4d072a13b2ab817968840608c0fc',
  '5d944eacaf7a04faa062f6ff9c7f695a2a001afb',
  'e41f7a5a8c99fddc7f69370328c3bac0031c1db9',
  'd3d40675dc02c4345798d1fc13c762f630141fc5',
  '5ae0206d62235345e80a469720349ca43a45f058',
  '1415b9c327a9a9b0a68e9941b1b590e0e8674b3c',
  '238a63548749800469ab7b85c8a18fbebf157061',
  'dc4bd697b9c8b62dc658d9249573b75fccac1f64',
  '5f66bfbdae86231bdbb33524588c6748e4894d46',
  'dcd8591b59e80f97fe92f39909a6c358ab367621',
  '79ecc3a455e2aa6cf279d106f67521cdb7e29c35',
  'bf1c46d00decd05b9462cdbd28dcd71074c87727',
  '5b15b3a63ab97d7b0dbb445482d8f32c9fd8d99c',
  '59ba7f2683040f3bd9a09c2242e9589b295c0bec',
  '2ae8a53fd5513a10ccd513e0c7505038d30e0383',
];

const ART = {
  channelIds: ART_CHANNEL_IDS,
  name: 'artists',
  sortOrder: 20,
  icon: 'Artists',
  label: 'Artists',
  description: "Odysee's home for art, animation, and everything inbetween",
  image: 'https://player.odycdn.com/speech/art:a8.jpg',
  channelLimit: '1',
  //duration: '>=60',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const EDUCATION_CHANNEL_IDS = [
  '7926ae5e216293b5f9dbb99461a6027166e0362b',
  '5fbdc05c326a9aea2f87e896b1fc0999ea4d5ae9',
  'a98687cc035fbd823074f9c8b8072034479a067c',
  'c354db74b0c5858faae563ec293a478fc72c27c1',
  '23fda7cade8b35afedaace1c8e27a3e853d60f78',
  '59e54182af2a3269995a874a5f04c7f6b617459d',
  '23b930f012adbbb95487996ccfe12d5094fb2931',
  '598b11cb152092c6565bb1f14992cb889f12bbd2',
  '9b924f37bed614841f79739b5abdcd8d90fff909',
  '582379965e114b28da761331c4a20850adfcec02',
  '4ceac7b519f0cf61b09758d039c5c34417ab6915',
  'e40e03d4ecf89fcf42cd7d38cdc672f89ed9e039',
  'de993257f1f6790e7fea483a84a2df6d76232146',
  '030e1a1aaab89cabf7e4fed2825a29b9521cd6aa',
  '7b825eba7901d5a3237fb56fb594fa7d5679d7bc',
  '8c11ce96daacbbccc877c6dc9525f8067f968b59',
  '7d075ca9ebf0f89db63867180779a5fbd1e002ee',
  '3cb0ccdb7fd337f4af0ef6a75225830d2bb3c5d5',
  'e2a014d885a48f5be2dc6409610996337312facb',
  'fb0372f0caffa6de123110e4cb24942102f6e432',
  '88c88419f2991692cc754debaaae485f1cf2a6d9',
  '5544c116fbb402aa65f7d0b05c6f6ff2cce0055c',
  '3e8f08bba76d28947f44e875af3700792e8c8b0c',
  'd00f47e784ff29d00c0e541025b7c53424688ef6',
  'a4b75c38904a96f172f67e306d8b71e1bcb55ebf',
  'eb0280fe6753ca785c8d71a12bcba9a681d3926e',
  '5c7bc22947c75a7f572f566974e666c3bd841ba4',
  'da26e1804a39e5ef3f86e1c3bf5c2cd4ce973b16',
  'e00b618e936d86c28e6934b288fc72cc2bd35645',
  '85f5a0655d07a48d8eb8213b987a6abadc5d5dc2',
  'b7eef442a1589d69031734e05777b495e4f96fb4',
  '6a6296edd3ba92f086558bb4737159c86e95b75e',
  '0ed5de6bc61142bf069e311d37d30b9bf986a5f2',
  'ed45ba26f0251b85fc2da95966b1ba184130767b',
  '354ac2ee77da53d064cd59c784f2b4aa0f7cb8d6',
  '11322475df12c7df4fe22f6ede12ac8ec72a0347',
  '5149ddb4c98d80935e70dc41eea1b7be54e09b6b',
  '362fe607d0ea909d2c978c26e7e5fe9fc06c6e0a',
  '3a141d019e6819e9509e59e21c295c8fcba90eb2',
  '237e6f36142f3887b9c97fda13823a9da620a457',
  '8db3b5088c099bbbae6e8e981b5cf0149b12c3b1',
  '51d5ac1ca99b8bb9ccf9d3628283a6d794791b66',
  '1fa91fafc5dd2caba9762b13756084626344f220',
  'b27ea0b593f95c68088da7b675d1f3ecdcc5b530',
  '520907b98aeb148615ccea4e783384dabe557d43',
  '6b26cd782b5501ff36cebafebf4ddad8bbefe5b6',
  'ee5d730eab6f282e7d91dc38a7fb791aaa6b28d5',
  '3a1e7e2df8cfb9c71de582581d43065da9a2259f',
  '77ba8742ba0f37d803855c6b210e9b5bcae8ef39',
  '199ab5701b8fcb270dc166f4f2f278386080c2ec',
  'b4d022895e26d77c32b6d1d4560d8e8374088f6d',
  'd25091216fc16eb249d9d2f148df1f98abff88ed',
  '8441bc919f6bd6b955e432b385db9a48c9f0e203',
  '9830efee1f51bcdb0280e78c40b885630d68a69b',
  'ceee82e3ea74ccb5ac39a79c71c65b84a2dc2573',
  '35306e84c59f643e2577f2c3b129b60e0505a4f7',
  '88ce81a6dbfcd2ce366b408f4f0c300dfa119f7e',
  'f6e5a04d387e656df2d18a26ad746a02b7018371',
  '1bd0adfcf1c75bafc1ba3fc9b65a1a0470df6a91',
  '67ef453aabf261e6b2909213d174a79575c9799b',
  '2461c6d5eda5e620e6b069bb8b5b7378c7df96a3',
  '328194376ecc6736ac95b1a8d4b08be51ca86823',
  '44b27975c24e545a996b0e42b9561d2255419b59',
  '077cc10e527edc0a1ce33de758031cf1a9b1fa51',
  '26fd593959c6894bfff6abb8b178a83a06c5cfb3',
  'f87c11850a4de4582c484c2f31d6ec23cdce03ff',
  'a6a3b8eb73f184949add50257e1d1ad2fe2d8425',
  '4c4c9e44dd32e618ce56c7ca2e2e2b53e8027779',
  'e1d6646adad0a70183723c757cd51b06071d4106',
  'b6aa692044b35cc77dc2d92b2f883c078ff3fb9a',
  'fbe4f5f18d4c50361da1a9dc61aa16b13532582a',
  '8d7b493cfa5fc7847ac17dfa77dd5f4ff79004f9',
  'bfb23ad59783aac92ac7acf79566ad43b6715329',
  'ef35367e744a954595c754ade6e1864814e3f5b1',
  '0b4c0dcd4073184827ac95589ced602414aa0e68',
  'b190bcd7cd068a230a42ac96d3e9018ba8fac464',
  'eecea0c6ffe3f37450cd3eb8ede46bccd976ab79',
  '95860835bb09b492cd003253e59a21f79a350637',
  'c54323436f50c633c870298bb374ac8e7560e6cd',
  'a0e99bebc91273a7e9cb6d352fa3feb2f2894736',
  '44af5de8a0dca9424dc72854de06787c018fd6ef',
  '3c5794f775975669745c412b0c30f48991d9e455',
  '55f552c153a6922798ce9f6d429b69c87c4c992a',
  'b2e71c557f487fdf0680c53912323d12643b709f',
  'de25bbc3d564a2f7820ed8d97b191856c0b97a79',
  'c9c515604d0b06dbe5882c9e323b7d72341ad3d8',
  'bda7fd7fb3c3f36093911ab811fd2bdfec228b37',
  'fd782f5b774ac8f2448c8c168af9c65984315e88',
  'fc53a2f94564399d712f1256f38b8e4a05bd82ee',
  '75db0de0ce18ba8b0bee44790b5c91568fe07bbb',
  'ee6062d901ca498e4670a06515ddd9b9571ff157',
  '2fca54aaf7cce2fff2500a9a1d7c46466c277348',
  '36fbc5a6969952484d9594077a1a6f2088991695',
  'a8bcef67f13d7393ad9b0a151bd4f36ac728156e',
  'a72e7a391e6b5d3c078a36b10d36f6bbf6d448e9',
  '76bf13cc43dd7282a147e7c01323f55547dd8772',
  '33cc1becb9515600a579fba173f88a24c0ff0c75',
  'b2f905bc5d372320be62ce275f5aa0e91bac2379',
  '7832099d8f3cee51e8c1287fe6460788d2fc8e23',
  'f6240460032012ce8e688b6979330168449eec09',
  '09818db74cb30350c9c65675bcbbe62ff90cea7b',
  '21370376068d12f52bd2e8b204a2a0b3e3c9dd11',
  '4cbfd0f267164e206bc625b3c78548ac4a4fca02',
  '9f983d92233e3b91a6231cfb2b2c5b6101aea645',
  '5261e43d1def709ad424345252d7979474368e25',
  'c7e424324e88507e5234cf4799ae684d13c77dd8',
  '4c6673d76627c1e9428d6f397888dccc6d2a6690',
  'b71a3305204bca694fb58213ed2ac5059bde2cce',
  '5c665f98f8d1ec4fb1f655f2d760b5aa2a3c4f94',
  '22559d6973a85af7b6b1401d84c372902b5932b1',
  'e3f481c31c8ee781f82e85bb0cfcc87a6cfbaee4',
  'af3e18ff9283139bd885f779d931cf1c8ff9bcec',
  'a8d1094b9c6624f59f19f6e139152d1e00caa9f4',
  'f6502a7390d564c071ccbf77635b9241b2ea8845',
  'fb4d51d8a16d29e3666ed9cceaffb80159693370',
  '073588d77652b2b2070c2838f4e2e5e94945898e',
  'f7cfc3d0a845cbcace1b980c263f468526dbf65b',
  'f74234bae3ada5436408ea75934db17a2c106f87',
  '146f626104a6af5a07d1b0af32146381837ca9fa',
  '1fd85b31e074fd5988c56d57a38fa688ed3afe5b',
  'c5cd9b63e2ba0abc191feae48238f464baecb147',
  '25f384bd95e218f6ac37fcaca99ed40f36760d8c',
  '48c7ea8bc2c4adba09bf21a29689e3b8c2967522',
  '5e0333be82071767a3aa44a05bb77dcec4c30341',
  '64e091964a611a48424d254a3de2b952d0d6565a',
  'b6e207c5f8c58e7c8362cd05a1501bf2f5b694f2',
  'ba79c80788a9e1751e49ad401f5692d86f73a2db',
  'f1dff225e758dd5bc8ab8b91894096215297b2be',
  'fb364ef587872515f545a5b4b3182b58073f230f',
  '057053dfb657aaa98553e2c544b06e1a2371557e',
  '1226c8d64dc52014a7fffde98d28acaab1969ce3',
  '182d7fdbb5a64be6ab6805b6e55f5b4f1fdd8471',
  '1bda3bbdc215fafad9f8bd30ac6ad31b287ea2aa',
  '1df464dbb302ced815c61431a5548a273e6de8e1',
  '3886f3b0df4f25d864b9cc0fc85de3f895c99b49',
  '38d5458f6e34d4b1e8fa1357408d08070e6a914c',
  '3eb0394271d1c052cd0af482a304c29c00562bde',
  '4eb3d4034654f273a324485a3e07d75095d04f1d',
  '5363472c9e89bca54004324d458907fafe0e6906',
  '649c0184bcc6e678d8444125d1bb04c0159cea16',
  '54808df261e5b67f6f9b89af910a894dfaf3aa25',
  '589276465a23c589801d874f484cc39f307d7ec7',
  '5c7509e035ce97de1152921ec0b47d5f8770105b',
  '6c0bf1fed2705d675da950a94e4af004ec975a06',
  '719b2540e63955fb6a90bc4f2c4fd9cfd8724e1a',
  '7913d4b05f7515a5e201d9e29ebb7f2c6a722cce',
  '96043a243e14adf367281cc9e8b6a38b554f4725',
  '9d40b87a50b576183d95ee2642faa4993a2748d6',
  'a663899ab9369fec8efecfde4d11618a9f1f9467',
  'a81f87584fd77b288cd7680cefa4cb6a39fd8dfa',
  'a87ee0c50662b13f11b6fdd3eefd4cee17930599',
  'b8bcc8bd8a9e7b3ba4ed074632601b377f9be387',
  'c248ec227ab0aa80760717ed76a0b38128d41e41',
  'd4d17e20bec31c971b1ab6370a11203ccec095a4',
  'e5872eb7237883e4158cf88e96a465f7a674c968',
  '07e4546674268fc0222b2ca22d31d0549dc217ee',
  '267953cff8ffaf7f43e5e92b48927c9fc03c7a40',
  '2b606fa484378821f63b503c2bc4ba1a53d671f4',
  '32276d32396286795c19e407259e0bb9904ebb0e',
  '59a83111b225f1f1670f761435dcdc3d0cb24ef4',
  '48177f4229f543831c0880f56570cf745a5d03ae',
  '58326307ddf6e9da5c4c09684f3885aad594a1c1',
  '61c4e8636704f2f38bbe88b1f30ef0d74d6c0f49',
  '61f453768cff2993d7ce60db7324e4488682fbb8',
  '6782765e8eb9cf6077892682f09af618034b1fe6',
  '8000936d0bc3adb18ee405d34937ab0099beac23',
  '8418759644fbee548c6040c12a2df89173dc50ed',
  '851f7d55176669b219e1cbb2ebf76badaaf0665d',
  '8e316448fc6bfcbef657930a1eda64be39e5eb69',
  '9415dfd5d053b793b4c8dd51330284d4d7ffde00',
  '9e88485b53d28077cdb0d78d69cbcaf3bda785a8',
  'a555ed811e09bfee67cac69d9803716647f6b6a2',
  'a5eaaeed698912ca1dd935aea69b47e46161ee5e',
  'b0e24a54d054863d27fb425d96a2075851a66bbb',
  'b7f96900af8a50c68d1ac31ec10a1a6b23b91a1e',
  'c876a5c8a48b64ea5a41bbf94bb728db1cacad37',
  'c9100ad684469ad80679004ecd8534f27020ea4c',
  'ca43e7e24420ff5276694b8251643efd97271be1',
  'cdaef89f72dde4ad6d30337b19b29100eb558413',
  '5e50c5f9c4dd580175078c24e96c99ba78f09981',
  'dde807040dfeabe28d580fc2409ded7c32e77105',
  'df06f065c02315ad7e3849cde3aca5d2bebd2e69',
  'f9a480b8de6f39bdf243d08e03345529b77070d0',
  'fafbe9cfa04debc35bcb337d85bac37d8de77cfa',
  'cc8f04f02b15a4de03381f1ef506bd270c7b0936',
  '5fc52291980268b82413ca4c0ace1b8d749f3ffb',
  'fddb293b297417d753d0175be69a11e59b22ad57',
  '918be99daff84a69e1458cfabfda219f2a05271b',
  '5fbfcf517d3df749bd032a44c1946b2baa738ecb',
  '87b2669c65c60a36aa408f0177517a192db194a7',
  '4fd4b60a7f00778ebbd150029164302fe84b7e56',
  'de0fcd76d525b1db36f24523e75c28b542e92fa2',
  '74333143a3dcc001a5602aa524583fc75a013d75',
  'a52425228572850f40651d2f8fe965a7d1f7d003',
  '6e83f36dfc16e44d8f48cd27d698ca49a6cd1402',
  '0e8d245734aab8c6825b6529a47d4cacfb3a53a8',
  '9826a0c0a781ce1beed3067202f8677a2740e3ba',
  '26c9b54d7e47dc8f7dc847821b26fce3009ee1a0',
  '2b6e925856bf5903be02ad1ea68469f084cd3b40',
  '5298af1ba35f126013e5d10217a7316f5c909e46',
  '6768e311f5da5f286da963ff63026d88d215b747',
  '9ed727bfe93f0b740bd46efcbbc86e839b77f17e',
  'b97adeb61da8b93ed506580dbc9f8f4d346e8561',
  'f9d146b096ca639b1b8e9d504f8b1d75142d902e',
  'f309bc78ac52f365a90c06e5a9d1861d8aec61dc',
  '21b62acf175127873dc0d97522d031c64e0b5090',
  '0a6ccc54a036199fd3995b867e40536d89b13718',
  '214e9c52c7b8e871f4f3d490791f524b09e4c564',
  '470d4d4ae13952bb6c0a125d5661aebf185d10e8',
  'cdccf15cb7130bcef93c6435515ad4651ea9706c',
  '55cf643147dc6a72bc9bd3918722cce922f068e9',
  '2f06e50a17e0425b72826bc4827472b694ed46fe',
  '2fd514619ee44ea569006ffe8c115b1462f9c49e',
  '0962a13f9355ac1b6535e0d48e35b6f27033b6cf',
  '0dc5c6594e21b74e1837b2041610cef38885fb53',
  '5f8a628a282e7c9f554173b8284f16b22157f279',
  'a53e94e8d63d88e6abb8c0ba1847b001c8d8930e',
  'f70660e4cb5cb40e385cbb829e45412c23ec248c',
  '19ec35dcb324ee80b86acbee9cf2c613546f6ad6',
  'd51c581682c184eb2abf15eea971529da6ed58de',
  'e19a4aebf5bef7a76ad8560ec51af0388899f6a1',
  '9392b51a56555dccff92288b49590e2b690272c9',
  '053aa5264c64b50a01d334a8c7e0810b5f090bc5',
  'cd918e69aa4b8bda205542fbbea7ad87fd6fa0df',
  '5ff86d5574c2938e9423b67c1e54211adda14bbd',
  'df3baea1d504dbd633c5320095d9b8992b4501d9',
  '1ed8d6c4385666f1f6f0d9aff2c468409674b318',
  '04b2f1d701cc27a86cbc9dba53b166b0452254de',
  '306944d12e99d1ccab7c0f6e97ce600561c6d2f1',
  '9b3df6296a6c27902d873f4384f63e628f145236',
  'f3232affc64b99c259e2214d298e5e0f8f2a72e0',
  '6f3940e512a40f2ac8068103bd9195fa07107043',
  '9457b2558813566d10d619c1a1ddebf68109505c',
  '33adc42b7889b7ef53a8e4e805f9da1135f152c3',
  '0fa8612a785f76d6cd9d11297c6f0d646136a980',
  '22ea20fd6293eae7bc08adfa8874086834c3d4dd',
  '05e3289fb655acaad4890bc9fd3c5d21fa6d0923',
  'de66b4d0d09eecb390e611294eb9c5a648362dd3',
  '199eba05b6ecccab919e26a0cb7dacd544f25700',
  'db3201197536bbf929d4ff9981ec54edba9da9e5',
  '9c960609f0b6a3cd1c029190bca5d2c715946a9c',
  '274badb1917c13ab4d66a2e4582f8a84f08b2bfd',
  '1c7db8410ee95e7ed38c0f997792b18fd5dd9ed2',
  '6c6f50c866e81180eb855c230dcc527a33c110a7',
  'c14a4af9f10f9045ef5459278dd9d3c2bbb4b8a9',
  '9f4da61090b779d856e349fb18433aed3bafad0a',
  'd1c94159bcf2e079e8b28de58df70706ccdcbcbb',
  '364579e069e1123605586f31b960d3ff801d5b5d',
  'c16bc926a499fc38d69dda95af93abcc56fe73b6',
  'ea667fd4a04ba0875a5c4a8a97902ba1e64d61c8',
  '91a6188c652649dc9f5b50d919ed44c11f5a9646',
  '5dcf6d63c25d0bcca713d64601809b221fc10f5b',
  'd7b3ede64bd87f71760ae7a3c7b0dad13027815f',
  '892a016cd83fb9af964ef1f870ab62aaaa45c3fa',
  'c08bb86bd3f8da32c5ccce07c70eb746491422a8',
  'c6226778ce39bc8ac8b3a2c3b0a7739411cf4c00',
  '3b7a2094dec873e54db59e225c55f471c74df7ab',
  'e73670d6cfbe2b381c4ae8f93264752528e1d9bc',
  'b33a803c9988b68a01970a10de72d76bf468faf2',
  'ec6b85b4f4f1dc114f3338a9efeb1f6a2e9bd10a',
  'cb444e4a862479afe2423c4a4cf721f686885d2c',
  '9a97f5386107043bd213ed3736839fc7bcda7561',
  '0bb667f0c0247f9e02984c3d1b43527bcc9a1c76',
  '1e0a043e1c5dd6e4eb0a278b6035d7226e6494a3',
  'aac60123472be650ae7f17f1155a78028fba4d29',
  '8cb8023c61cdb8251919a3633b38823f73d4f61d',
  '13d66a5f9188e890ef62487bc4a3c785047f7528',
  '18d36054a127e6358d26e1cebd6744dacd3158fd',
  'a477ab89900eda501703cdf6a7f1c00fd0844e73',
  '9576be30de21b3b755828314d6ccbbaa3334f43a',
  'cc137ce3abba1d2f860762c92bebb896cbaac9ce',
  '93fbec0f8c55d21129e91e4a1f8d5bff9db9951e',
  '5ab52285b082484e8d238ad1238acbfce8b85c6c',
  '29bde08984bc016779893adb654b3d00a8239c2b',
  '2d84a839317e4a30f63718934db3d64398f4cc06',
  'c9cca866bbca87c654e208a269dbf22c37b89d56',
  'c0adf9952b4c14964c0ef8cd0de757e7c1e494fb',
  '6a599aa38edb2a42099f4a754cb251865ff7a91e',
  '4b18abf1846fc88385c2cbf2846fd1b9c3808896',
  '1698fe7e7cc0e748d99408e67df73f584e18113f',
  'd0ef0761e88df851b1f17bf65579078607a2e3ea',
  'da1b8b2a03f5ecdba7c8f9f6b1a8c2560b8e0d1a',
  'e09d6b15026cbb8d5241c3da43093b0b2b347e82',
  '469fd5ff0dda146cb036467d731845a30fa5fe8a',
  '1b628ebd67072a21deb94c3717c352cee0cee392',
  '3aa8d1cef5f82cab9b9d46e8fc73d989859947bf',
  '42c895cd3003e17cfec6e7daefc5c4b527035df8',
  '0ad4f2d79a72874a3bbce0299cbde35d3c8f6e71',
  'fbe4b6e1f3e184a580a193ff6eebb888cdaadbb6',
  '83078681d339477ff8ed5c98dacf96f8befaf650',
  '496c42fa54a8f8076957ed67bfc0bc4fd6f1717c',
  'dd7acc3af2821df7fa89ed886f342da8c27d1452',
  '4d122ac3beeef23ca9a89a18eb98628e5be55604',
  '316b01ec5b8d457f8d220ab94e1c527172233b04',
  '9cde8d74663ad6631eb825f0918ae4694927c599',
  'a9e8f4228da7435335b2dd570f7e151990eff02a',
  '873beb13984934e326f1ab49ac1cd46234cc5e43',
  '893a9592229514458798470b0ab37a4f41f948a6',
  '11666d839cdd81efe597f439a0dc97877b512582',
  '8906a9826e2b38775259ca23eaed17ea505bae4e',
  '10dffa664c5f03733fa1a1bb35b7fa676c27f93d',
  'ce43c64190e74d1c51471dd8fbc1674794d871ed',
  'e245c035af5f4538553f03ae1114c1fed95f15e9',
  'b3c577b06077fe4c4da43c392fef306d5575348e',
  '8b88375b27651819bd2c61964b83e31d9c24b14f',
  '28390cd25131c1f50f7ef32d4fb58a2d598036c6',
  '32588cb59a2f2a81f0439cb690506924b2b520e5',
  '54d207eb608e06448e966cfd51d22426002a12d6',
  '34bb169e04a8adf27569d1142bce5e0d7eb3f73d',
  'e5b75b503fc805d4d73b125c6691e2a850ef0e9b',
  '2eb4c8427fc5e8e31ccc13a27a0457c401450569',
  '2efabb66be59c1f64e17c46b7d7eee9a62d24231',
  '602e127cdc5f17201a0f85a769268718b16ba143',
  'c9601fbfa594d26c57937ca448d986eb7e78c2ea',
  '2b579a3fb326a1d4a0e82deafbc0c98347d8d3dd',
  '555a1287cbfca83fa0c67177bb80b049fe577f2d',
  '504acae2fa155c65e74cd0e4c335a3a49aab47d8',
  'dc7861620f9f100c22620a2fefd4c23c9f5f8e4f',
  '7b5d88a9384b3ff314d44667d9d79b838fbce0f3',
  '3e50c27e085cf5e99ca5319b8416fa15240e027d',
  '99de2e8cd7f52c5fdd0e9412d9e6dc4dfa0fb690',
  '970dbca86611d75ef7868294ead885d2bd9af208',
  '61f4f8fe63f6bfd293cec52cdd14b3856eb5ccad',
  '25be33c2be23392487de74f76cc81e587ede3a75',
  '2f37783fb196a36cdcb83a7d88e58fca45a6b3c1',
  '4bd2f064402b10f25ac9b22c86793a981c8f745b',
  '01f049415b9e48d77754800567e004d9d44e327a',
  '2630a22351fa8e7418ff6cea0aef81d82276b037',
  'befb2e831488f8701f26f16fb758fa4092fb7ac6',
  '284af027d513c6752ce0e416cdf03ee4b0dd1b01',
  'e894ffaeef4b466442721b7396c79267c2c72534',
  'c99593470aa156e05156c27bc37a7299ba6dcc6b',
  'df048780e8fb6bd91302d3d04b2bc97cdb5a9b79',
  '7294c57050a63e12e4636e976ef26c1467e0fa62',
  '7c616caaa96703d461b4ae07d10561dd4d36e42f',
  '54e85ed6323a169a2d4be2a8e3787b9389657ba3',
  'd8e83abe70d11a4acf1e002627611f6c71c07a6d',
  '82a2c1566448f8eb9ece18bb23dfa639c844c2c2',
  'd8d34275f35f0d6632eb19fe5bbde11b92ecb9a7',
  '33c1eedbf5b0ed9a5d6836206dac403fd02e7964',
  '4c9f617d8f00bfecfdf83c6c082054e5b6da3767',
  'a5b1f83fcb17b60f1d99d84af8fc2bca79117f9c',
  'b681ab06712c060a63f4eb494ea76769ccbd0165',
  '2ae8a53fd5513a10ccd513e0c7505038d30e0383',
  'ecaf6cec9c91b66fbd38770ec93c0ac445953abe',
];

const EDUCATION = {
  channelIds: EDUCATION_CHANNEL_IDS,
  name: 'education',
  sortOrder: 30,
  icon: 'Education',
  label: 'Education',
  description: `Who needs school when there's Odysee?`,
  image: 'https://player.odycdn.com/speech/education:3c.jpg',
  channelLimit: '1',
  duration: '>=60',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const COMEDY_CHANNEL_IDS = [
  '12bf3a1af1404015d2fd162673955cc4402aa38e',
  '3f41843bf59a21a7e6e55599f5ea94d0126a6439',
  'fe1a19e1e1a9f30cf79ec4ff2a805d0f1636cd4d',
  'f112e0f078346db2cb54828562adfb335200bbeb',
  '650009687dcad57031e0d0eaa4b1e24d3b19895f',
  '52edeb5dd67ddc9401694b05850c68544abdebf0',
  '54bf623e41aa46d38a8c07b2e362978893f5763f',
  '0153d96d509e6fa5dab03272f10624cec7bee2af',
  '64621c0a519bf2694c486b3566da08011ccd8344',
  'a8b221cd3665ee39128dc539cc1ba31083630c71',
  'e3a3cead7e981a354a3151c3c1221d75f84eae77',
  'e52471ddaa11652855da6cd733744a24d5787cae',
  '02fc625a35f19c92644ed4deed0dd17947bf691d',
  '19156ff628999eebe484efd1e0e9a5f7ddc4000a',
  'cca259fa3aed21ef134a214a5bcef46a5b545499',
  '0317ecc62f18cef99b0b0bb23f2a1bcbf1d18851',
  '32f5b45b8b82c127faa3fa67b5c48f489d036a61',
  'a0c3cb01bc135a1135fcf710dd2b5f9c5d6cb2b7',
  '2f2ea51d9117ab339dc72d5e25d051f873bf03fc',
  '21c90d16875689f7f8f2ab74158df02dcc340c4d',
  'b453bf4e8da234da5b1d8dd04578352b49978a24',
  'e59775ed7c394e446032ad493e8153083e58935c',
  '5589917b5f7cd42e60a509bd68d5f8e00240e0ba',
  '4a52a4a764c9f204d6bb722f1106f4b53c59c22a',
  '4491e8ab4b23cb4c64159345f1b4ad2d72b71dca',
  '6155cd6aa0913ff049c1087d427a9cb7b6d07da4',
  '1215111663f6b8c824e84a83dda80c01bcf91c3a',
  '107b55ad0a18406bd45802b840f7416418e8d348',
  'a6d8730faa62c9157a60f7612d55116bf3cddd89',
  '8938c1a0839d33f3ec9204ff4f78dc959651cc7b',
  '3a1407959717963fdaa62b6f06381505b1e0f8ad',
  '8c64460a187a090079bef31798eb32f6b5c95e20',
  'e04ece044748d52b0fddde21b5b1a2bd3b4b8b43',
  'd31e47347f6590f36bab24f29a7017d731e83b28',
  'd16e18834d123c5df038358e4964b9a1b5c8b464',
  'fbd0e3891564093c0bf79e030b002856b17a077a',
  '60b4ce1622f65cf98bd01463d5d9606590c0cc48',
  'ab618b01b5b7593ece68e498d9fd35ed1f752924',
  '8ad893320eb77fa676274e542ee3389ec9bd48ac',
  '5facca4f15c52328f8f8557052be6edc2285a697',
  'd19a1eaa58bd0a9767b044c3330ad0d9437a8808',
  '22076b79263e9c2696ca3198843d85c2066ee30d',
  '7602bf6180cac2808ae0a61723be88b281898351',
  'b14418e2ed0b34ab0fed77592a37322748f5a52c',
  'f47b1afe629dfa4d42d386443370fd3de9625331',
  'b5512ec20ff71f5d152bcd2f898c90c22e958caa',
  '36d7f0d50379adb7efa9a38b1ad85516a3e080f1',
  '55c4ff539906c879d8e529bd0cb21d82052e5d59',
  'dcdc22396584baa6a3feb016f5e5f34a58bb085c',
  '9d4c31875f534dc1f989db6c13b92ab1aab85ecf',
  'c4bd82f45e2d37710c90bc31aab28d343acb49d6',
  '7d1112e803f0ccd113756d2f04fd737f5f5093c7',
  '1487afc813124abbeb0629d2172be0f01ccec3bf',
  '73704f657e95302430c859e98adf76668df16412',
  '294f5c164da5ac9735658b2d58d8fee6745dfc45',
  '695bf734b23fccc50c4a21d9f450c62035b93a16',
  'af6f6fa6169906db022930208d5e18db57c17944',
  'a3e35f723d9ad82159b4858ad628e090d0e372df',
  '16fb96ca5b1713a4cc787e9e2542da724a45c854',
  '8a9868c0295993264adaa1cbfb7e39f59f20bf04',
  '5c15e604c4207f52c8cf58fe21e63164c230e257',
  '9dc7f2791db1fefb0f4aea2c856b9ecea6f3f5d0',
  'a368bca6c9b7ae7c14c587e2a6f784e6a92a2d70',
  '3debd8a3c293f4bcb015825266b6b4999a804213',
  '3cdcc0fe5e011d7765faf3c32df91cd1b092953f',
  'c8ab6a6018b7caa7f00977dce1158a1fec14f500',
  '452916a904030d2ce4ea2440fad2d0774e7296d9',
  '37f3125b8ae2a19082300e05951d6c33d65b84d3',
  '0355fabcb9e5977150366d3c9776252b13170d0c',
  '3151569ef709a0ec2721116a21028874e004a641',
  '0c978becd7fdcf88c24a78b9ce10b2d30e8a14d0',
  '7ea92a937f5755b40ac3d99ed37c53b40359b0a2',
  'b48a89b235fa577fab38ef56b57008c2aa0655fd',
  'b39833be3032bbe1005f4f719f379a4621faeb13',
  '3e63119a8503a6f666b0a736c8fdeb9e79d11eb4',
  'a6bb6b4d8ab63b6580855903da440789aec2d5fb',
  'ce59b0c52f73407a8bcf95c604803dd8c9c30986',
  'dc577db3caf5ff83a3b573ba92f2d447f067eee1',
  '21340723c1f8d92d674e21108deed6728cbc746b',
  '5e3d0210ffa962c4c51cb36656d33c909f57ccfd',
  'e5e5351832ed993e287653418805e7cf50445e33',
  '726211a50b6a308ae01032f34474ebeb161f4a00',
  '00edcb7179b433f1df8f52cea7ca9aead152daaf',
  'bdbc4d4c46876be6223df44ce5ca1d0e001659ce',
  '009c1a7750848d029edf32bf465f288c7dad8764',
  '80b0796f577b6d9d28af50a9b6bafab603a907cd',
  '4e791eddc4ea6c7ada0c56b768f27d6af1cb179e',
  'a7fea200897c1bafd2f6915f246f00a453847395',
  '9dc7a9c8023acf1af966dde28dc660f81845f54d',
  'dc6997114a4875dc2835083a99d25ad568e177c8',
  'cdd9b50963b6da5cc28a6e71eb529044509f58a8',
  '6184648aab0431c4c95c649072d1f9ff08b9bb7c',
  '7c25efb7d2ce9bf7e4262fc71f7f1126ca1d1969',
  '267d71ea4a82aded607f6af710688a8578ee9d87',
  'b2faed276ecc23d7c316aa23eb96811ffe8ae663',
  '29d78ae4065294dc47c1bd9e8892eaa068ef15b7',
  '21fc790595a1b79d59087fabaab1a7fe9fde025b',
  '36e6a8937381df34825dfbc8a85f38c30de02eee',
  '2e3da2c36fc47412bd09cc2b8dfdfde77d149872',
  'cd48f7dd5e0827039864a9d5d94b27da5176264d',
  '3c9971c438e91e2b5e18161e69159f835cfc4de2',
  '2d2b26a061c1fd852d227c06cb6726088346d6fc',
  '07e5beb321ad3c7dcabd63d58cea96fbc0f32c32',
  'a66db6682e3fc9641a1de0bb56f3ec1acc5fd308',
  'a69d4a3ad5b7a393b64a1b3298158e577d16f501',
  'c3cdb911daade304f6bedc597a55d20138cecf9d',
  '9db2ee90c050b3211f2f0d923a652faf15f2b47c',
  '07347ea9f264c7b3b4411f7aca5a1293046afa55',
  '8beffcf9717d7c352099a5bf94b59db33860b3a4',
  '921b3f6b0e1735228b200a7fadf71271045ace0d',
  '4c452ad99c637211ad61aadf2d1c098277523660',
  'edb2962ffd27e3908f9664d57c4001f79c2b4fe9',
  '56617c759eb76af0f59228be934231c7159702aa',
  '0ceb14853d7c73f1cb12ad290201cb997f1420bc',
  'd749df5538e449ed79e17306ade4404d2c93294b',
  '8dd30bc2c06add01d275c68490d262cbdaa7adb1',
  '31edbff87c02329c28d5b417bc050f2a81be8952',
  '0a229cdbf1227f675991889a3ce801f912975898',
  '4347d3d6796a833909e961580a4d46d23de14cab',
  '84e7bb191abc8369c71e39d8df2bf7ee708e71d7',
  '177946d040a1e1cb0c608d115c2419e49ffb8ba7',
  'd0a031bc5a5a9b24b265a41ca5bd74f5119d9e02',
  '6e1106d54a01cffcc0d73fbc4b5454ebc8ce7670',
  '7c9be3dc9fd4075b1df96937d198e472766441d8',
  '5c1777eed58b6729c4502c12b4e2d5f0a78450d5',
  'f809af0a98cba679b483b7c565dda9ba036246a1',
  'a4b7b7c1c255b1173b0945198997e2678bccb4e6',
  '5b1c267a66fe167ebe9f72d50d5364982dd0dfd4',
  '546392075e844479b7a332ba19ecbb2684e7c3c2',
  '126e4c617df9f7129a8dfd0b670015505593b561',
  '82a593b468c33262b1fc22ec54e906a746312e76',
  '73d7a45a8f509e9577f26662775ed9e64cd16ab6',
  'f40f4a4546dabc9c47d9c75d0e5c0a9ba8581bec',
  'bca66c87dd9de3edf69c7a7ee92f3641b1dd78e4',
  '52c5e952789f39a06b411970610c67c516dc351c',
  'e058dd15ffbc35d5caf984924905369f63110ff9',
  '187142ea2c3d630a99c0f0fd991dc6fcb3cf461e',
  'bc979c16c93696cbff8ac6b15124241a7db12ec7',
  '93d669e435e44d85d5c52b6da70c6ce4ea42eb35',
  'fc59bdf82a5e6134466ced01d4a93e36ac914f72',
  '08aec9cd153a83e077e7c982c4228180657db9b7',
  '0cacc12d0e051ee075d14ccda11c59d2b333b78e',
  'c43573bd7bb80a05c1628c9cf389fc028472dd45',
  'f85c9989f66e09b8d248e65f838e0d2cd7126cb9',
  '94b634faedf3495f19dc6272d3a567e8b25de7cd',
  'ac44ba6449a2ddb38d5f1a10922b6864908a8dc9',
  '63cdb6d34e69dc52d733dab5c085752f31166f10',
  '1fdae4fd52e1b5fc8c38c202b04da8a240fd01d2',
  'ec76cf1a74dfd35a000eed3cd846360782bdd31f',
  '7db22a82d45e916ffebcd0d714ab4cc739fb4171',
  '4e81a63fa91e37f9668817eada0b963abee9da59',
  '2b64c188c2ca068e04fbaa273625403e3ca8b71e',
  '9eb47c33b941ca12b66db2be6d35efeb95ebb8ee',
  '84ceff6f5bb9ff1834ed1b4559f6aec739406921',
  'bc8f0849d5430f37719d1c44ead992423e1b7f2a',
  '513e75e4fad7b3a07a9a9a181f551fb73d9e22fb',
  'a84eb6ce8a8dd18dce9cf844d770747812e8994a',
  '1ec38bd140282f13a2896a8acf8d7efdea3968e6',
  '7153db11c8cfc6791db42761f2f533cb8294daef',
  '81ef13d52cc92d4a017963e3a0c18cfef4eea799',
  'f563ea0f79772272b36d0d43a88a64cacac602bb',
  'c12eefc036051b3428914c2a618fb6e178e04591',
  '17b2d0c58091320300422d7825c1332bbd5404e4',
  'c0ebab48f5ce4b65332ddde38e3f35cd887494d7',
  'b0e489f986c345aef23c4a48d91cbcf5a6fdb9ac',
  '10669d0688911a87098e1a2357873d8710bfd2c9',
  'ca2dadcd6b43de359d5be99f22ff0ef1a12f3d96',
  '5b2c9107ac70ad0b6932ab18404dc42f1fd31b8c',
  'a44ecf026fec7cb8e1450ad1b27971b739abfa7d',
  '5cd643f7700e2e4056b7d0dbf7c806d506afa7cd',
  '88c5e9532e581e36af6577bd9213e73f82d584b0',
  '9d80c0c02a686974d6a470317348724db2b081a2',
  'c3241c7a87bd0254aaeb61b9926dd5477c0b603f',
  '62c47bb894a31775cb02f68a10cd757338b5f4e8',
];

const COMEDY = {
  channelIds: COMEDY_CHANNEL_IDS,
  name: 'comedy',
  sortOrder: 20,
  icon: 'Comedy',
  label: 'Comedy',
  description: "Odysee's place for funny people and people trying to be funny",
  //image: 'https://player.odycdn.com/speech/art:a8.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const LIFESTYLE_CHANNEL_IDS = [
  'b89ed227c49e726fcccf913bdc9dec4c8fec99c2',
  '53e120456fe6fb781037161c6113d5dbe496a397',
  '0a8b95e85ce491d2165f84ffd2d6a7fb503f9aa9',
  '31b62d7d5009d24ffd6578b1d83c0eb1acb14581',
  'aae553fe5f5089456d6bfca009c7af2eefdee67d',
  '932ced8e9ce5459e1e2094117510ff30cdbae929',
  'ee81416f028a45008359f97c600c52dd08116a0e',
  'b643da4f58c73e3cf4a518c192f201fcf6bd923a',
  '951cd7fa84bcc0e884c4d0bd3488e82f0ac51590',
  'ccf22b6e72dd468f011c556d7029d42badafbfa9',
  'abbeedd139cee5523739a2130294e7eb9520921a',
  'd9e87572523e2fb326c5511c62367bc7ef1fc44e',
  'ea981bb8de134750839a199ffc26978b0ed65975',
  'ac901a72dd5aabce356d9e0ba57ea7a87c9edab8',
  '6e83e570349f5560c04add4e1c308e577acc52ac',
  'aec663017129714bc09feee585c1f5c356a3dbb0',
  '15a7cff9d483a96395708af87f97c1e04e1194d8',
  'b5e57d8c99a91f839e53126136177f1780e0dcd7',
  'dae9d6fcaccc117336cc4172634496260809a604',
  'd3dcbe03d94a17ef36622ad0e79f7d15ba812071',
  'df048780e8fb6bd91302d3d04b2bc97cdb5a9b79',
  '15d3f21f6828cbfbd1faf929c429010bc2597786',
  'a5fb77793f7c388e5738ebe667ec39c31830bcf6',
  '0487997b33da4fc7c8275d7fe98b71edcacc36b0',
  'f916013d3e35b1fdf9d25db488ec9ea5cd15b434',
  '590fced19da8de1f43e170ee36f452d2ce8b14c8',
  '924e434ada9a9191252e83d2e2a359d87aa15e8e',
  'e1bdd88122480ebc67ebfc199bfe55d5c217bdb5',
  '40602ed44d2e5cb358868872ed578eea67ab792a',
  'b015b3ec4fd35727b88f4eb0fd6688130c7c7c19',
  '385229505e3b2f19d6d29971379892d9630d579f',
  '220f3647d6e1775ebf7c276504d5707237b08d53',
  'b88afb8e3bc61a6ebba58a1cbd9f92eef439246b',
  '7835679ca85d0a872fcf969d002f12cb13121d98',
  '5b82fd1938bcdbd46faf24a0b6e7f2aa277744f3',
  '6d352c9283d98d51bac3326b1cbf1ed7e9eb62f0',
  '5e63d69754a19298dfe2a3a85b408cc089a512c2',
  '04ceb4e757c47a98b26b56e6aca6823b7de12b49',
  'ed9f30c9026d55266006d936be3ea4f38acd7e44',
  'f8d97760654871b319249e2f73596f730280cb08',
  'bef0ccea3da2cbaf92814999e2b933797d6a820c',
  'e03ee2ed0dd0be39c114b1ec53bd88871afe456c',
  '89e5814dad18af561bad8ce5ad919633205a7ec2',
  '0d979039301d70d3985f448ef0b16e27c86eb254',
  'f9d7804a6fe33e56d68a0175947540e227049c04',
  'e3bae53dc85da3528d3900bb40c65e44542f298a',
  'e8721de39b30282b35ce07dd00c3e243c71c5f3c',
  '2f44d94af2509efc5fd4d3dc4120521755c91414',
  '34f58826c6b35d173054ed2ede6cb4078abb51bb',
  '33a94086f3bae09afcf9492c2d2d94def72b5ed8',
  '0361cfe27877b6ff2ebf2ee59c569a240d0fb807',
  'fdf362926c8b6c1b48aa69f1bf3dbff2fc473a15',
  '524fd14ec29b3bafaf2156ab189aef741d211f65',
  'b41c20d812ae35c7e4929175098d43ca55d81aea',
  '608027daf3a43b58d70b9e6811d73c4ed18aeefd',
  'd04cd84ce26ccf2c3ada93a2f5208ead39336fe6',
  '958bb4234f4dc2a59f4a12655b1608283946b9dd',
  'e044973cad9611dde67e9a21eebf7654613c522a',
  'f1c2c36723da16b786b9ed99bf8fbe69edcb63a1',
  'a34c24bcd0720375f364bd6b6fe2c03b23b43d7e',
  '1ff59a932990c516bbd769b30db97ed69f8566a6',
  '58618015b5a4242604e68e5500fb05289c1f2de6',
  '687abc855b96dfdf56a16c008c587f4597fb56a1',
  'c02e787cadaa22f2ccc21c43c5e3b4e222829e91',
  'a7518d73db87045945dab4325eb54a90e40ac2e9',
  'd1aad5b26ad8d770852d347bf619488705e34f70',
  'a3dcf95ff5657dc4c6175604fd2fabe1dc098807',
  'f8d42f0b663163bcefe71bd020053e43fab2b3e3',
  '11e4d936804e81a0d60a79be008d3dc8e78f5e11',
  '77d70649b37d85ac62597ec7499fc86e411bf41a',
  'eecea0c6ffe3f37450cd3eb8ede46bccd976ab79',
  '70350e4fe7b4076310c8b1584eda264e7d5bd18a',
  '3ad3d06f397f7a6f905a7cee5827503444642e94',
  '6d3fa49b7de5b918be02b866780426c98a5a0c6a',
  'f9711aef0efe74352c4315a2e2f9aba74d5c0ff5',
  'fc8a66d1debed485a07b6580b54f76cc38a51bb0',
  'c9544287ed9950ad46cbdfed3558b308ec79052c',
  'e3e121b5b5d4544b674a5f81b486f38b72c2ec87',
  'a157cb04b654955eb4b96d91ac392ed71b77ab3d',
  '8f4fecfc836ea33798ee3e5cef56926fa54e2cf9',
  'fd1aee1d4858ec2ef6ccc3e60504c76e9d774386',
  'fa645836a9b694dc525289598cec9d7bc84eb968',
  'bfe029685101dfe45051304163af4e8476818154',
  'f32f020e2885d763627a0c71dae86c7c33e1f693',
  'bc64d819192915956b30bef1c00f2d143508deff',
  'f46e6f6536c02e652487b9025a68cc9410109ae7',
  'af0f5d9e055d07c8308da7f6726562420ff0bb82',
  'b7e57fddace0b9ab111242b12b691394f4a47d15',
  'b516294f541a18ce00b71a60b2c82ad2f87ff78d',
  'cfabf9da70f1d7ffa54c1a8f9ae4076512381104',
  'c3124c6a3637974659fa71bebc41ca7e69b30176',
  'afe4d367f9f9634b6a993467dd19a7baf7b48f7e',
  'bf74ffbd55491041c778d679fb6d88c4bba41935',
  '5acffd48ad1ab923e36e3d7cffee8ea4f579fd64',
  '30722d410585000642c420d8707021da1d38d155',
  '011c7d9c62fc910f6bcf1c754d06837fa8574e25',
  '8d7cc3ea4c8cb39759741f89b132f46eafbc4541',
  '772638e06550ea32813848ee5c662c3900976974',
  '84bdfbb8278ba644867ae2f9569252620e3d2128',
  '998ff1746837e487123acd4d17915d1214c9db5b',
  '95ff1db7f10825325d806a1e336f430a78bde672',
  '9631b10f5fae9e0f068f10651ad7e0f0e8c68ab5',
  '8b01f8fd69743b0cfa4b611bf8d58a4a60c46183',
  '4f84f28b311cc5096541831217ac6c16d97233ab',
  'a6d3c4ac9e6a4dc739c1f7687dfff607973e2d40',
  '7109221d4047efb7e94b1d55c781757702a66f32',
  'a042d1c34974db29f499f63c0cee2eccc59b5200',
  '89985db232ec2a9a31dbd985196de817da223fe6',
  '684533c2249fed96f47d1bdfdbe277dd01b326ea',
  '75db0de0ce18ba8b0bee44790b5c91568fe07bbb',
  '65200fad3d2b6b48666852efda6f5baa7010fd61',
  '761be19c2537769e00cc2078de35ea0a83653299',
  '5fcb9eca22946e79fe9a2cde6fcf536c4e970a28',
  '5dfe76321bde01412ccad93a5a4ff48240c56bd6',
  '5b00937848d7c5f9287c38bab30a1c5c87f350a2',
  '65ef10501fddfae3b90711000ab90047fe9e776a',
  '52b08a58c4cbf86bb98e55685b6e778bb111e6fe',
  '50d9c04b064b52249ceed889c55fff8db3dedb78',
  '453a6a5a9699b85b462b5d8d2458792cdd6e4e2b',
  '2935c30b2b569b8ad66b6495b106a92fde1d1dae',
  '056399cdf0860f95422f854ba95c1759f1d19564',
  '99746c752c280a60ffb906c7af7d905b86100dab',
  '6451f8e94186668a5ad06d4ab68977562aa5c58f',
  'f3db5351ece03ff169e23a785f43b60a3a0315bf',
  '5a814bd051c02169b2463a437ae35cb90dc9cb83',
  '4b602d7a3e268abb45951f623a109d2a131ab0ba',
  '5a638f268c78a312fe69fc1899bf8735bc6f5b73',
  '50ebba2b06908f93d7963b1c6826cc0fd6104477',
  'b5c7d3a77f7353da21278203cceda99fc7a0c851',
  '04ed50d890f105dbfa71287f10b9f0d7ef62c3dd',
  '4a965851985270130dd8cc233ea26bf561ccedd5',
  'e248e8deb53b6e117bbb1f95abccfc88828eccdc',
  '3dd27beb76e7b8d873fb863ba4f2607523866d2d',
  'a44d036a5735c01dd94056ef74b595700db40281',
  'a776378cfe529ea86020de64b67ce6d4aeb9a6a8',
  '429084d6e1374211303e1b7ac7c03632c5380d6d',
  '80c0f9df1915146dca66ac166f30018150697815',
  'd70f46ff7e357d2738a75866d7249e7ca252f447',
  '9d5d8d607fcff505901108494817f5fafdcf6ad5',
  'd558130d3a63a3484aed212dce8da9e1b5c03f3d',
  '017f5a764e4d24a6d21005c77fbf73f9306681bd',
  'ec9da74094c9208a7d2a065eaeab779f93b25221',
  '1932489d2322e4e34e5f6d6727d3a6b33196a835',
  '73e8117f025e1f632274585ed4af06fcd3380a30',
  'e55c0076d73d12a12edf14934d459dab97acd4a4',
  '9edb2af2c4d7e33ea9baa31fd390490d3995417d',
  'b841135309d9daa46d70830f74166c5b8cd3d4e3',
  'f273d2f867f5cf52b206e99e37084fe046f4a597',
  '91f774da5b5cea265b3e7b3fbbf76e9cf4f157e5',
  'e158f1f41fc92b14aea3beb29de38e9de9307458',
  'a93ff4e8da1f64f4c957d2a51c84d56296837a6d',
  'f3232affc64b99c259e2214d298e5e0f8f2a72e0',
  'bc08875ec5e02c04b6cb4c889e799ec2124205bb',
  '32b5dc9f00d5ca82080320ae945d4aa2f3a8722a',
  'b032695b52a78e0f5251b8d7f2f32183a5985d19',
  '08ef8ceb86fa079c9d97712cb801949f6b395e66',
  '0e429ceeba4387adfeacc6a97a0f5be7e3e21f4c',
  'c3364d1e6b8b4619abaabc4fa2cceb0fffe8f8dc',
  'd7045d4d8434cf00897a47e3c3591e5e58945cfb',
  '49fe7ca8bb2f7a794b1cba1d877d98dae520ac73',
  'dc65f89361a46e0fb888b59e324baedbde544759',
  'ffdc62ac2f7549398d3aca9d2119e83d80d588d5',
  '0f1e4e91598af7d14ca447b9563e7730a8dd36ac',
  '3f2dd3f9a343150c2e1d78d96d0d173dcf3f5453',
  '6795cb8bf0bc9c5083306abd06f22460e284394e',
  '51702ba0c481a677e7be4948ffb52d1c43eeeccd',
  '9a5dfcb1a4b29c3a1598392d039744b9938b5a26',
  '59bfd76bf628cf0c021ebff21fdafbd9dc0efd52',
  'fc38147103b673a41d26f77ac663f50b386229cf',
  '7243e2e5a6ef8a110f07d4f3b633c20c904e6527',
  'b8be0e93b423dad221abe29545fbe8ec36e806bc',
  'be757c3bff18e553b9692930a7c1c85962cd69d9',
  'b797dc8d288818347fed3888b52e1abfb1368496',
  'b8bcc8bd8a9e7b3ba4ed074632601b377f9be387',
  '7aad4cbcbb512f15fcaea099fa90658a4fbd8641',
  '8db2b36d98ff8b9f28a6fddee89c8a7a5ad2dc1e',
  'f0ddc7378fc0dfb1e15554e2b5a80905881ca939',
  'bd128fdabcb3c10ec96f570cf3111f1b6e73bf2f',
  '30bf8a8b228785035fa6a629fd6c611bc6ab012f',
  'ea0bb1cac7e30b37913e03d1057d2d1d16d7ee69',
  '491d6eb4ee983d4031e640ecce3cf3b968f7dadd',
  '3c3cab40a5422c6f7fc83dca68423060e06279b3',
  'c5ce6436ef93689436f4baecea75ad7057901b3d',
  '83cd7064473b64eed02fa18d1db37cf0540ea23c',
  '803bde8558635db719d3ae3babc26e86152b52e5',
  '3aa349f7a634faf513a626502be7da9ac5f4195d',
  '7eec023027eca574a37e480734bc0e8e29b51b34',
  '12724b709a2d527ab5dc91afc6a7c2452828bc02',
  '5660cdf1c9c76e5abd74ba0250a393440dc29091',
  '3d99143facb9d1829a36e8ec07fb01322113cec1',
  'd296b6e5febab67df971dfc46419b236f7ede616',
  'cd262d1f51da0b6e11ef7326595fe9c9a6a66cc6',
  '64eb70b71909832a1bf6d8fb582d44dd4ea9cb38',
  '43d86e70ac36e57de9f628ac73c9ad5b6b759282',
  'cc8f04f02b15a4de03381f1ef506bd270c7b0936',
  '5363472c9e89bca54004324d458907fafe0e6906',
  '4fa230f864cc4cd9c7d9962c042c49f35e913ffe',
  'dedc9c9abe0b34f903e224a2c34a05fc8c959839',
  '41f047080e65da5e64ec649eba0b3afb89bd66c5',
  '4175e7d4666412ef0b8392361c2b4364a1da675a',
  '85cb2bd1face54ceff669ceeb49e353e3efb104f',
  '65142a6a13a3615e58dbfe63d5e47ccbaa546262',
  'a29ee5be86e9b79bd89fe127ef8dfd96caf4c032',
  'cef1391a098959e03577ccd2ed5c06e61b5eb3b4',
  'b67c820e726d807769f6e229bdead6f229fa721f',
  '43795b33422dd4fa0bfc9d4fb84028b7615a4b19',
  '29193e9240a71a735639c66ee954e68414f11236',
  'a18ecdbf2e65da974245cd98dc0ec6a29532adcb',
  'c47c41bba5f5fb92cc04ea6762237a40f6624511',
  '229f11749af81f0cf1ae4234f8d923a12bb08d0d',
  '5991abd22fb9914740a622f42f4aff0fcc1f2d35',
  'a424fed9993bbcac4fcdca38aff9832fccadb568',
  '2570d3d42e57f4a3de0c5d3ee6502df4598df6f4',
  'c14a4af9f10f9045ef5459278dd9d3c2bbb4b8a9',
  '379d04f22737a58b726fc7bc68c2371aa624a7a6',
  '4c2105bfc47de0cac5870bb0327fb641e691f4d4',
  'a7131c3e7b38fd1f0786fd1319b18d3a9fd23795',
  'd2af9d4cec08f060dfe47510f6b709ebf01d5686',
  '38d5458f6e34d4b1e8fa1357408d08070e6a914c',
  '300804ef9ff7dcd0b4bf70b5a690071693696b32',
  'c9100ad684469ad80679004ecd8534f27020ea4c',
  'e49d0548de2488813fa4755adb825cfce4535539',
  '9efc6efae597e0efeb6f729a7b388636b7146e98',
  'bc2bacdc7b1981a04c63c181281a91ae8fbd5345',
  '7158ec9e23182461a681f93c2e23f73edadb7b43',
  '2caca1f8ea13a1ecdb6916ea5eb36d421af3c041',
  'c1f34a418baffca5eb59f76825baff51ac95f1d1',
  '69611f33c5c77c00482b17d0be60b2919f7d96da',
  'c2bba8ef7a316d866bdcda22e10ff4cb0fdef6d4',
  'd4b21e7ae91ad9c03d05c2b55507be9069efc110',
  'a42085d8ceaa624e19c77ceafbb46b2165974854',
  'b3272313603fcaa29182bfa817370539730a3fd2',
  '5c3db87ba1f61c9dd274c483da30584752af0138',
  'b8848d22f672e667b34ad1e6cdee1a8a52209dae',
  '2e9a513bf47bfcd5a032b86783f86830d3ffca1e',
  'c1c282fddae0acf74ffc3f0ee62a5f32be1020bb',
  '4a78d87a10c147599ce8f388dfb48d2f70d63a98',
  'd0519f6138d2b6dcc5f653407ffa7259b2def109',
  '834ee34140c460edff2e7ff0279b0934d7d63463',
  '187bf3616318b4bfb85223fc40724c307696f0c6',
  '0081d52e48ac0701f6505231a6465bcec5c60a06',
  'cb8829681f4c6c4a49ed529f41fc9029d6555b88',
  '40ed1cc51e3cf1057f3c24ca4f0f3a89dd3d0ee1',
  'd653f67638d9f9e7bd0f65e75c3b349554e016b8',
  '7c94cef726cfdf593fec6b88164f71984ba98192',
  '1b6651b0fd37535c47ee52f08bdbfcd86a0b57b4',
  'a91ec93a8ffb2e0955dc940297f49f668056f587',
  'c3dd0196908978d9b82485e1debc5a3c03ca3c31',
  '541990d156bcc001c0fadda11545f6911a3034e6',
  '87b13b074936b1f42a7c6758c7c2995f58c602e7',
  'ebd06153d13217c7f7d411205115a4a1b4e86b43',
  '56da2c86d6864532d1d9f374908880de921673c3',
  'a4435caa41eeb8a6696747270e6d3acb362794b4',
  '5fbc510b2f4ebfd66d0e5070046649f8feccc52d',
  'f7ecc53c06a3d7273cc91ef0f973beda941a6181',
  'c2d5ae1c3349a73df191de2f0a6e962ee913109a',
  'fd0594a6714a69bdec951ab005c20e0bf46901ff',
  'dc0736a9dcad79685c09dbbf17e2d75c85503ce9',
  'a30c5c8cb8faf78b7ad82118e8fd7039901a5a32',
  'd689ac2ab1b06214c3539fa4a63e1ec8db24f660',
  '09cab89bbe04122ee1224122a8e506bc0e01be8f',
  'fd9cc07705dce5ee3a2dfd22f0a3ff2a4db1e040',
  '8d0c30f0577faa6602234b8241b36ee28e82bef0',
  '98f269928b6c1f566c760d10bcd94d547059d6ca',
  'fcb5712f5fca4b07f09deb2d1ae171f5aad2869b',
  'f29c9e1864d339d6c149679a9c65ce7c7ced9037',
  'bfaf913e7c69931ce096ead9ff4024d3dc72f96a',
  'afae817ad94c294652a591d23fadb790473c84d8',
  'e0da6aab2376c55f116f0ff8d426e45826fb0a54',
  '397d1b00e7a39569f705f91cf207fce9f5274684',
  '27cb1c91ec900cf951110b96a4d7b785c31dc2b8',
  'dc3c5d9ba8f94bbe454d21875f96ecf968a74e81',
  '56fe5d8210fb13d3bf44dba6bd19e5fd3f7e718c',
  '1e4f9e72e995f2134fb0ceb96a9e1746c247426f',
  '9cb663ec2a31c663be52affceaf43a2257b87def',
  'f76782ffecbf3f1ab93840c6b343678178206676',
  '73574a8a2741679a2db7eab4a8f62f29f067aac0',
  'd0174cf90b6ec4e26ee2fc013714b0803dec5dd1',
  'd199da772365197c0d8ce9796ca2edaf8873228b',
  '84945bbdc42a20e9faf2ae8babdc9ebec58a3f90',
  'a9ea1df303c550fa9500e517026da2bb69d23f79',
  'e7c18fa314bc5f1e1be805cbfdb99dc285996252',
  '20978d05c67fe8e76621e65fe83c5d3421fe7b04',
  '88209e8e2472da8b6f6b10de187268fa2b5866da',
  '6e510d76967abcf837c116b3d56af5fabb26af08',
  'c4924acf8aa4ac744be1621362a11b123ec6482a',
  'e857198e39fa955ddcee81db8113c3875c5387ea',
  'f869e946e99c2f310a1e28d2ce6fc6cda229e057',
  '20d6ed954a41876a492b0241e6f01d96fa84f5b8',
  'c48871f834f8765c76ac02667c5162aadbe3cc36',
  'e284be5aa3cb8c6bb5cca2848cf958d70fe06b0c',
  '193a5d4285b5df019389474474e5972875d54209',
  'bc0ce90f4d888fb16acd500d39f719d4c6230440',
  '4c4c4264d85a13f096eda4c52c0f7511da41c9d6',
  '7ba3a383b228103869406282d6456795fcb27f7d',
  'b981417301dcddd8e90d28b55b61c837460e7c96',
  'c5ab3bbd4bccb4e1e65c10f212f4b57c93170078',
  '57fed4ebb6d197549f3c1e926ec86a4f92d6bb75',
  '62722940b550affc66aca8210216a6f294f085fd',
  '23511dc5a1c5c655c0343dc14df559cf305470a6',
  '97d43997cd39161e702811d416da64599a7eea9f',
  '63e4ee48e01661b80eb13b21310a52030d99405c',
  '497787669f8b2b5ea48dbf112e24f7b3e4153a23',
  '8af62859ffc14987a76f128664ccfa0311e0df1f',
  '9d867cfd6680a70aad65cc86c4da22e5724c31ba',
  '8d5cd56f93161cff23ff9af5248d87c0120f5a29',
  '0300eed7e12cc15a4e9731d7ce4e86872d0712e2',
  'cea2cbef72961de95b5ed3d47e2022da8d3c8c9e',
  '50294a7775f514d1cd5c6977acfe6235f1dd50c4',
  'fa044978e6684ab8fe17a05169345a18eb32a46e',
  'c5e85a38462eb5d6d95412c744f2e08c04518747',
  '825aa21c8c0bda4ded3e69a69238763c8cfcc13b',
  '3dcb3c018538014122a8ce21fd91394358c57fe4',
  '8193cb3614c6550a554346a79c1619e881bb7a79',
  '09f7897787e59f3f7d1a2fcfaf3bda8ff5a853b7',
  '5b88dca0074ff4ddc4e0a8d4eda8c80000af90b8',
  'b6d7e81456478d6ebb796853b6a6c0a00ffd2dfe',
  'c37412ffb6478d5067ec2c426858b5da6ab8dbea',
  '6c6b138828124faf16fd62c236223a524c6b2f7d',
  '1c0129135a23ff8096d533e0241921859f065f35',
  '44492321a13a0087c12394d4fb48c208ca6d4b27',
  '64d370321db76980ca4e566654d09584c6b6cd70',
  '657a0b90a024535f7dd35ba927901f98a3ee7271',
  '6616707e1109aaa1c11b9f399f914d0cfb4f5303',
  '6e423df7767506fcd6976e37277777e8e02eb2d4',
  '74e380497449d9eb13de9bc4038676097e4730fe',
  '99cc8ee7130536d9d49e3fe67ad3708374902a71',
  'ee18e99e666414dcf9b722fcc99e98d2efe64d4e',
  'fc9fa507887b0a9d3b3d087b30dd90368f1559d6',
  'f72a74d78fca219687c4939d642680d583ce5667',
  '99194a98d21c0b2c7b386872db59b15d20205219',
  'f36e8b965a8dfbe973dcfec34735f98ff82968b7',
  '7ca10b6b6385b588e6b8281d9d969e65ee8ecc13',
  '8e47656d664ae7e6c221954c86a71d6c83df2f5d',
  '1e3beb67c30d8dd2b76e48d75473fc57ac69bb04',
  'b907d35e038e24e3b9fc614cb977d468328ec5f7',
  '95047ddf143f9db2e92d57a929e4598be12ab55d',
  '8d43b2653900b511328b6697e68d16fdb6a113dc',
  'c220f505b90586ece17b2636ee787558f2c7ed68',
  'b4c30fe36b79870a79c55e1e909adb5ad23f323f',
  '88e2a1fe83f1efe3c07ef89b49d650645607553a',
  'a653b0cbe9a417389c063b7e815341e636dc28b7',
  '0da531a53075fcb427d44df2a99e2a68f5bd192a',
  'f859f2f051a784007843481262c2bf2edf0e7a07',
  'ecf114f98779c876bf652c131a66ca29e977819f',
  '06423d5a435eb8dd92e72b76981738b9ce8db9ea',
  '5b36b61870b02225171ddd5c2e9cf7dc5b98f068',
  'd524472e7d95e097a6111e450ac8cb6cb7619106',
  '191c6a5d7ed0442cdb7d86387927fb4c7e9a3cf6',
  'ed80fdc89b36d4c759599468bc4772cd7f818309',
  'c433f20d44e38889c1b0107cdd4e3ff769e11ea6',
  '795d048360d8912d0d5f7f2282b225791111833a',
  'f0169aebc2e0a8f6d942a27f7941184e72e54c70',
  '4f0dd03fbd871c243fd1e61966ab403e9638cdb9',
  'a9f147e9ff261d5507b973ba8f8c839cbaffb97e',
  'c08dfd17a64029009f20fb27785095db2289ce1a',
  '1ed3fe3ca75cb519b4e5ab4b5a7524b4139f1dd5',
  '3cce4b926382c0ba0c0f4f3c887300b3e1a66518',
  'f7864ca979ee7febe6066e7bc4f01c51cdc9c3db',
  'ded7742caadd136b14c237c488cd93433b6bed76',
  'ecfdb0c52b48ad621f97b0ce92fba7e75f34aa04',
  '1e09af2b1dae05dc65a35425ba287f1d050010e2',
  '3098a2c490a985f0b6855f673e9ec3ab542015e1',
  '9d122056bb2359db42e1bb3dc6399a1554d5e2a4',
  '591283884dffa2ca4615fc6081e7117f13ed5883',
  'f92e4403b348dd529c54d86a13322014e96fcc14',
  '76f3c2a06a59bbaa22866400a95fd430f84f4042',
  '9920f8535321f570a91db2f53b52962b0db83111',
  '8c864689fca4afcaa322a5d14f82fb83c3818f88',
  'd41dae6c7b12d5f6dcc4370c4d339a08bb180c4a',
  '336b16e80280f6776878e2482b76bc8774ff3302',
  '92294907ad070bc4992930362c9ac27827931d0e',
  '48271e6639ee19e8d87140561e963cd2d68a72f7',
  'e0347e4eddf524c6686f967a2901cb478cc0f912',
  'fdf8a4d196ba085871723728988a281df8b3a20a',
  '6f190c9a0292a7e3a677c4b3c5ac2fd58da94825',
  'f5baefc435d141c526e54f19ef5d50649ea52249',
  '64c2f270733ca7a815e9b67ca6d54c3fe2d81b4b',
  '2b6d1a63dbe6b727c66e48f561fc3e94c9fe1429',
  'fd2959bb7c8babec697a85b21db71b22341782c9',
  '772a859c56a72f12284240b66a3d3bbc6fd9e1d0',
  '82bd9d584b85fa7252643d506cd63a6a1a7e158a',
  '46c0aec986fda5fbe3196dfcf1e788c565aa587c',
  'd435aeec63e929b98bab8d2b4ab913d66a50293d',
  'ac4040f9526037b65bff149487c8a382e451fe81',
  'acf8b11c44e86ce528146084aaba2010074f3b68',
  'b22f369c042a28d58be9a7949b650420a7a8c7a9',
  'f4c0a402b5f103d928d209c2f3e90a8cf4058c57',
  '9d482865b8edd6632286b153954dde2ba42891b3',
  '96b1786304c53c8b457defe3b771342d68e8a077',
  '370fcc3093a4b6c6ed65c6ac7213c8cec67fb26e',
  '16da54e7f45cb9f4d1188a8384c9d28bc4dbfb71',
  '6c2e11e9f5c84bb41b74c97c4733e79a874029bd',
  '303dfedafa31dc001dab0b59e7c03ee091a67f7b',
  '8a21059731c50e9850c74035de5b00b8dba40c13',
  '94fb122085a5f934292bee5547085e4961f847e1',
  '6d3a818d4eef26a5ed74130174aac80644892b0d',
  '6b60d9875aec12c950ce6e936cb4e76bb67e87e5',
  'be730099fa1f750b50761c2dd17dbcc9bb2b440a',
  '04dde1cd948972b7e951e22669f54ce725c996bf',
  '57bdda23fbf031bb0a6497aa73f2b7299387d219',
  '3df0480958bd1285d22145471137a1333e953230',
  '630b1f73a5930d31fc64ba6962f2dc266fb2961e',
  '5dfcb9e3ee169007ae5facb5ace700156700264d',
  '2456ab33672409ceab213df0fda498225fbbe081',
  '4041495b1e1ab3192d0ccba28a6fe61e670946fc',
  '34a51ca9b33416be7c4c17c3bd040da5d4e0569f',
  '029b5fa613075e7a205dd08b142a8aba9fdcbc23',
  '8c8fa5538171de566eff898cc94d5be40e6436eb',
  '90473865c651a001c05410572fd2272eb06a2508',
  'b87806ea9c4fb80a6925fa05dd0a0adef1d3e9ff',
  '6ca87323f0f637bf2e6fc9c465a8659a5449cf60',
  'f9e00b55e5bfce6ff690a613e689b84d5f53a85f',
  'c173d6a50d53a3af6d7ace9d37387aab9717d269',
  '996ea82775fd4e43280d0c2649d5ead2b65af658',
  'cc35f16e19b88262f53f571ed8153f4e3a5d9c04',
  '3707873ba4c6f8294c0f95309b8177aa70dc6812',
  'a6a4078e1d7648cee49d7eabfe205cd12354d6aa',
  '4e36b9533a2417047f7097edde139e3f2398664c',
  'e6007827a1d337ef3babee026ee59f901393f11d',
  '2f5a5fec270c310bedad37feb85d12d1149e0b2f',
  'f13b26bb384035f77a717f63b0a8afe9f99f30bb',
  'f262eafa7d66ccea187ddbcb135e18d0619a1be5',
  'b17265561e7437e2a0f9184f4cf2d753459ac767',
  '2a165b36c3a2a62d0603433e785d216ac6e4e714',
  'c9fd4f622f15390ea0739d97e40c1722d1f6df1a',
  '622691c624217bc25167647121a9454fae9d48ad',
  'f6d54ce58daa3f2c3ac499de82b560527d800430',
  '4170642569d18f4885b9eebd5312db6aadce8850',
  '8382926f2bc34eb77fc3d20615293e86a497ad8c',
  '677ec3f1a9ceb45a44b1228991d86c67b8c21f9f',
  '911d8fbdd145afcadc7047627a6e36fb423a90db',
  '4f5f6e91a4061078272a51b0e285755dc9acb160',
  '266c2ca22cda64104b244dbda9f94c5783073ce1',
  'bfacba92f020b1aa6d535e78d084a89cf5beef2b',
  'be6109d3440e3d357ca495ee70754ef7702a44b5',
  '58988d5af67a63bb3bd10095c6537891ccd3b8dc',
  'a1e12892e0b9534a0c213192813fdd86bb5f860f',
  'a2e44d4ca46de3b1ecd4d45144a93bf3975a5ba2',
  'e21a8a8319296f4afa56d9bd383a74cde1010ef2',
  'b45f9a0d7db001fed32d9547b04c8b0b7f1fa617',
  '02ed953d15dc0bb2bef5c287c6fcc26c115b94d9',
  'a8f56c5123d3798c80f945bd7304453fe40c6757',
  '42fb0c191cc865305a91f1969e1e1753d150ad7c',
  '978ca5a49a2def9ab77527daddae3d9bc68a0a36',
  'e5b75b503fc805d4d73b125c6691e2a850ef0e9b',
  '530f4d94ab328234c3a2840204580b76aebd359c',
  'b5541f70f5508d7242eacb9a42781247cfe948b7',
  'f62e0eb6f3e7be83b3701686337a43d4ec838327',
  '23a7b3c651976aaf0b6a391c092f3bff7ea2351a',
  'bf4ae864999cbe276388219ff15be2dd391df643',
  'f425304e97ab1b7855b8db506dbd6363983e36dc',
  '529978bdaa01f3f6d6d025d7631c90161f8c366d',
  '24a0a6bc74f9c58ad7892f6bbbebbf1914343f7d',
  '6c6084b88e7da7b831657318bbce011174714f5c',
  'f5fcc2a0944b4bd94967ff2b3bd4941a7837d7b2',
  '88f77bc2e17bd709eef38bced2b85750f8db2d00',
  '72c9825c6f8bd870f1290660bf9d916f8eb52a5a',
  '863ebe68d40eba6b96ea49fc1cc698e10f871113',
  'a977b176344ff42495477dd79ef770a6e8464459',
  'be1f38acaba2494f6364f08696c9bec5a450fa79',
  '118ea5d760771124cd94d1e1fbf928060d370e05',
  '48c128b82fb586ffe4fb92e938f0b04a4e66190f',
  '381e6191516c189d8e6db8c86f5e9ffd6dc90456',
  'b6cab7187986416e38afba2d26df230278e0aa19',
  '1f22e30bca90a613a20d4e1fa7a2f594dfda1814',
  'c983af20f920c52277b296c129fd7d16ada43e59',
  '622fe2cf8ef91b2c33ffbee470ffe2b257b46272',
  '8500a80d300472ddea1ba48210e87b46ee8d17be',
  '74783921b674c6e09ba7a07d4908feeef99ea635',
  '44ee3ebb83e2308ae67a1d7b74332e8218b51738',
  '0c899fa89dac75e5e52baf8111ea60c73215715f',
  '0aff9d4d6452e1268341d134958e4db66d289416',
  'd28f5cbd60452d38665b24fdc674edf5397b26e7',
  '5899ac6d714642d892f98461fa5ed334de37efda',
  '0462e4aa0fb42f97396460fff86c8c03a16e8574',
  '5314c6b8d3a8aaab694fff9b1623b1688cb2adce',
  '2ac35837f3535ce8cce8ea9ca80007114db059db',
  '4dcb51e2d7881ac443b7e1fe7bd77acead3afdfc',
  '5b9fd134ea863049f83eb720482954e705cfec07',
  '60e508aa9ba0c589c72ab1cf5bbf5cf07f54a6c7',
  '85310513ec0b3fb3a564a71f76d9b795dbe9000c',
  '4404cae96d553be746690d1de3d31e1fa960ef27',
  '1370c88ce34a83765025ad5dfe111364824f6803',
  '09d44782fb1ec6a5018e2e93c6fa8d61d23e68c8',
  '9c7f0e9b3ac7aa473be74fee28e27ba783dc2720',
  '646e6000e4ad448c363a4a3bd2b15ff19212e8d4',
  'c6167c5b16d5739cfa64125b543e5a29ea35494a',
  '9ee07badb1481f795edfceb7f31d4c286b3cab7c',
  '7069c013d90c5651d5ddcf293b7c22ee0a06a6a9',
  'ed23f197389ea233348d2ee631a9d4b9ab86fa08',
  'f244b990a8e9e48d24aee879c1d8c2ec0a90af47',
  '68d438b095a686048a9320f2be942eb6a4712144',
  '3a537e54c53095d6b34e049b510d7fb9fc600a75',
  'b021d29bdb865cf64141240ed9bd9cf952c97d58',
  'cdb3ab982589e9a4787fc5cbafaa3e4de412b1eb',
  '2557ba7446fe3e792d029cbbc277bcead0e288a5',
  '7a9eb6296a9c1b527ef5e108a709f9011398344b',
  'f3bbf610b6d74a4e4cebf2f297d9abe5d0d3a480',
  '713abbb5894c937f17b42b0b1825435f13a9f11e',
  'be9d98ae686903d6b35c36acd07e576e4d7f1a8f',
  '8584fdf3402055b2e8da0e9c0f96a2ffc2d8cac3',
  '45475d16cfbeb800ba21c771c4c5838ea541fc40',
  '47d9232c86e047844477920c491ac42ff583f698',
  '4152943c83d4549649a412e93cb5f8564d65d484',
  '2b44f17b19542a1b24b3a679c11d123e74a4bf28',
  'ee35fc8df3e630926bdeced00c891341e71b5815',
  'a3f392022c6c5185c6a8ad1156f4a5e548481fa7',
  '0a6b44b212868ca481bf6b5ae9cca81948809b11',
  '355ce5e58b63c6d461d802200b91ecccab30cd9b',
  '30fa9d08f34e5999dcbf2cf3c8758e613a800386',
  '178d090010e8634ee0beff6f02877e96be4ca895',
  '970f2e08f7eccba7cbac57135386dd65a3ba5ac4',
  '51369aead82b8a55687e5482f4d64fca9800e3ac',
  'dd4beaef440fa7b3ef2646add04311f12bd60561',
  'e29215149110b5a9c437ea451ca48881c89d2a1f',
  '219b5a715d2378e5de12a68ec357db2c722e587c',
  '757e711be8658ff72cb9b91fb0eb4b5860116e82',
  '3b3f2312b599aec64e0ea1bf4f8303c401f2834c',
  '6f7abbb849fce52f4878197cbb462e5d28aa3ec2',
  '67e0376a8b7a7f5dcb69d8b325a21e636ec7516f',
  '3fa2371c8e87a04e5edfaaca72e20934bb1dbb1c',
  '1c22a03d0d7722602fde2fc8e324905046b04909',
  '0b7635fdc5221de27a3108fb48da692234916b1c',
  'b9f3c3fb686aeb2675a6e767eafc88441a040655',
  '9f376e4ad52ed33779647aca6fcb5b0a12e6c133',
  '0e60b5e80cdb256ce0758170cad62211374e4100',
  'c6e9f77796a76c5f1fa93b5dc5e6a26c74d0b7b0',
  '386200cd856ad648dae14fbbcbed3126b3349915',
  'ff6b52258fe89e48ea5ffa02374d845cfc18efb1',
  '87dd8aaa9a3a3efb887f4eae36be1f373a5cebfc',
  'f54655d7b745e79a38e2c90cb1824b2cb5b8b9c6',
  '2652eda917dd4f4ecdb8f5697aeade3fa398a37e',
  '970d4acf4888f476197c1131b359d611da1fe070',
  '86f0fa993254f5b623f920c5a173c529e8971966',
  'f3ec4acbb51274cb55900679929cc607546614d7',
  'd14d05caa35d73188f51e3e84e987bae088d0123',
  '0d4312bd1d051877ecbd32b523bdf84e92704f84',
  '80baba56e3683a30a95eed65e38f16653d465711',
  '4cd6c98094aefcd2ac64b89bc1a3f181ffd106d5',
  'bcccdfef8034c569ecee6c9be5c82f6f1be77343',
  '252e0bdd67881e8fb9a3f34d549ac7e03e8943b1',
  'd483d37e3f5133e0ac3a5df750a391c3311e011e',
  '98a681537c6e5c315654f8b4d89274a461b783e4',
  '815c2f0f8bf257af6c4052f0a163df250f01432c',
  '189c2fe418237c97dea916d1caadc16834f9d860',
  'a818af3d865659695d5527d96dc78a4200ed079c',
  'e22ed152f0b794cdd30159fe5f011706adc74ab0',
  'ad082442b53ba97935047b93adf64def065bf38b',
  'a36b159b4b6b3ca25c695eb79e50784cfc649bce',
  '3609681eaeb386d8a57fb72bf528168a2eb5da2f',
  '68f032f8959bbfb823bcf8a239ed2ca77d16209a',
  '11e091fce524e1c86b78e86a05278ab181188464',
  'c3d38500aa4fc65bdda1a6b30b574bfcda821a03',
  'b3b3501aa0852ed8f53dae5d3d4d602f803e9fe5',
  'de7ccfc68594b7444552a810af3b865eff0bb6d1',
  '5b9f06ee3540efd66be294f57b469b4313f18334',
  'ca0c6879f103c540cef433e3c3c6555a3287e43c',
  'f183b7184cb4bacef56bbc20fa53364797f3f23a',
  '71e3ddb2ac325476aa807fe95c21c8f125c82b89',
  '76a06c36916e7d2e7aeab3036e40dd048a3bf2e5',
  '8a4f2dabd9e7b0841334846001ba697efdeeeab8',
  'fd88ff3e4addcdcdd0937c5195996813ab933ba7',
  '35778d879fb5301722f55d5d4b6b61fb711c1765',
  '5427cfa0ff75cd8f3ab7487278202e6af3923fa3',
  '460c130581042d71dc5eb7138c69e41da7cb188b',
  '7725693e0d7ba40759eb82f356cc9ca7b9c8c40c',
  '41222091f449ee9879fd6b4eef3835fa9823cdcd',
  '4f9e15baffa88295df830f677eb897e184ec757c',
  'bff271e8e1316ad599b5a07a5265c47d14453209',
  '039d8ccd80158688101f6b8d344d1cd067e9c886',
  'e3ab467beffb6e7d4046a430c7daa3888763962f',
  '3435184b8580ac8cfc6c083850cb273de83083df',
  '30147909e608d05a5eb42a62d93cf1872db5cc14',
  '0c1ba39f0f77f6b233cfa4f5600df22fbd3a6b54',
  '81ba521814f4a6fc869c998943da34922cdbdf43',
  '7c57c9eb5c823011fb8b4d99e1ff3d44332651cd',
  '85da3dcff2d2728c2778708b2c74e2f287f95922',
  'c0e2565f3fb2aeefe1e97ab222b2adfc1c20dcab',
  'b64caa06e3135cffdfb6d59a357728d1cd56b624',
  'b78ac925183f9cd9b70e2ac8d61fed88693b8ced',
  'c75e0a13df975ad5b51c49426c1a5d2435f4bfcd',
  'c52cd05824ec62d8b8b77dbde6f22135dd2bc6a3',
  '8c199d1e6b040a666fdc5543a7d31ef548251fc4',
  '79f69e6dfc7e5cb3072b714ab1030f6ac03913f5',
  '9bd041d110b512836a8d0454638855b5adda76d9',
  '4beee028e198fe06b45f3e156e5343de31f90746',
  '4a1550f4bd56d6ff568b5745f25b79e6968d944e',
  '7abe0087d719ef679c2707ee82817d3ecd180a21',
  '29e0f2d3fe631dc156b5bc9d119117258e34d9aa',
  'f57d0c4a81b3c6a85329b0cbabd0302a2da7e3e2',
  '185f91b5c2150eb4d3e36a687160176e1efb298c',
  'd27bd765693cdfa62cb35ee894be92877b991402',
  'baafdaf4a8ad361420f87ef6348ecfb007d3d3b8',
  '02892201a0f1c11fc33dcc6ff7c1c46b5047c596',
  'b7cdaa58f3cfb85b914847a86cc03cac0e223c79',
  '2d3da8594ff97b7da07900f2215d683fe3088267',
  '4373b8bf80db45a431ef6a313e4b78cae3acf5a4',
  'f1222db0d7f2563bd015d8ac93c13a7efa7c8325',
  'c1b87ad0b9c0ce865c22c9bc9aff67c80af2167e',
  'a628241c79a965baafdd6d4eb2dc36d36ed515b9',
  '30cd96302e773f7eafee7f8839930a9f8691abc6',
  'ccb20a9f12447f4c4a46fc9215f38da5a38c5fdf',
  '62dc1f3a4258332f6a95d7b45dbe84d1112ed6a6',
  '108bc033188c5695db74c063aadf00ec0e23d3e2',
];

const LIFESTYLE = {
  channelIds: LIFESTYLE_CHANNEL_IDS,
  name: 'lifestyle',
  sortOrder: 30,
  icon: 'Lifestyle',
  label: 'Lifestyle',
  description: `Odysee's home for lifestyle content - cooking, gardening, fitness, you name it`,
  image: 'https://player.odycdn.com/speech/lifestyle:61.jpg',
  channelLimit: '1',
  duration: '>=60',
  daysOfContent: 30,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const MUSIC_CHANNEL_IDS = [
  '2ebe33aea1db9e825cff2c5a043e81f1b99998ef',
  '2ea3d9f254d3b052cf88ed515e6ff560310e649c',
  '6d4df2a2ec0d095975122cbd329185776d7d95bf',
  '5d316fb7b9c38ee312561204688988c6012e2f42',
  '9dcd49312327ba33a68f44bb83f0c708ebc0b39a',
  '0c3da3f46c4164818c29ef53599708fa30bc6cec',
  '4f246a50124655a77725df4dc11f035123cf296e',
  '7568e1e3350374901724ac1ff6aba20a6f35e746',
  '77d8046e59b243d700b38fd30c890006d7ee1b7c',
  'dc2339f42296fea95d905181904a4d0280a4e5e4',
  'cfb30cd9c6929ef84a451c29291f1fc269f71511',
  'c7e6bc64be792a31a48b1afbe4213b3e83d60bf6',
  'e85cf78e149f39c9f2c5189184493404373e165b',
  '556ed6ffdeffbce48d4074ec36941d8ad64e0d15',
  '95236d5b35bc0b2a2f47d2b6a8e8276365bc02cc',
  '53441a99271576240d746019e91d65e76a28eabd',
  '0b094042d10256779029cff65412eb48fe8ad719',
  '2282f77b9d2d0ceb957d6684d125108aeda31835',
  'b2b7c82974e1e4c926c36569425c2a5ba7cab79b',
  '790c4c24691051bb73ed878bba8b814c803892b3',
  '420d799edaa3cf15b94d945fbf1b4ffafb9a9ee0',
  '7a074a23e2befffd04cddeaa187ebfda9d1559f9',
  '27b1afa1c1f9a22d984e50f4364965c45d019e04',
  '1b33954e903c5455b0a02b36ff44c67cc0b4dcec',
  'b895cf78a97dae6164480a154a4ade6306219c4e',
  '056eb349632769d1b7ccd328649cacc0ae0c2e43',
  '226384cc130c3644d9eaf3949c16e42952b96868',
  '0995dd895776ff20b1fea06bb5ee3bf8d1c2ff25',
  'fe5c8a9391181c76078ae2237f947f72e76d7ca3',
  'faecd3cc924077e9b7cea5f30e5e9b63ffe2af02',
  '3a8fbf6b23d2ad2adf368974cf1339519f778fcd',
  'a11dd267569743eb1546855c2075d7626de94900',
  '59d1a35366be54552cb38a704d981e689db2651d',
  'cf9637eeb518db7b6b85def66e18dcc502215f1b',
  'a12e4aa43ce9e60f35f2b15c448556e84ee8860e',
  '1671232b36048f5a8b5391c335a3a737a10c0a57',
  '612d52a8277c6dc74fb1690508e70cee83c136bc',
  '4901055fcb6a4490698bd200911fbdb602c94743',
  '2b67c3117a39a4f9f508be714ff7b6a1656e3718',
  '0cecbc471f9050a064dafbd720ea6645166c44f6',
  '71791ddd833de5995b1db435882e547c5d438dc8',
  '0c93f6cb26c619575b9156cb3f23a23f519ba9ba',
  '6d0e46483b358f66931b00178ab259dd224224c4',
  '884d73b3e7660871a3e6a9ca7d4caf4c05873ea1',
  '6cf7731cc0c0f079b408c18a99d5e032e6f9307d',
  '9fe6463f5989b904493c6db88d905a9c8a586b14',
  'bfc5292fe90317fd8d6072dc12f998a359b29490',
  'ac7a77e65b80d0ccd349489604355c568a831d46',
  '8e2413df12a5d828c8c11dc0c92664bdec4b3511',
  '972dd98187256068b9b72a889e8fde72d28ccfa4',
  '1f3b5d5f6105b26264062700abd2993043e5751b',
  '575431d37869eb4b77da269cf8d186312e504660',
  'd21961c63b0d99ded741828d7048cb9f2824bbd2',
  '3f144f9e39b774bbe9a86cbb0ed0b1b074051850',
  '63512b8fc1e64eaa171ff4a3079869ba6167c476',
  '82242fdd244628e1646fe4c25b747dc11fff05ec',
  '6341537ad16b9aadccf0cfaf5bc7a92746dde5e1',
  '1eb4bf3b47b07f646d760c0accf7a4295aa89024',
  'fb5136290902252c035d10fb3425412dddf9e83b',
  'ce605b7901923714aa0ea082ada13b0e8b099df6',
  'fee415182e20af42122bea8d1682dc6a4d99a0d6',
  '3a72078d6c61e16086d7878f4d15322da3a137cd',
  '4b9b7aa6e92a8111b1d6274482a3c774699e47ad',
  '9d3557b15da0ce44aee7d0dfe1d955139d6da4b9',
  'b8ccc1ba2364639996c36a54ada31b453ee90e73',
  '2d2e27fb55e5cdc187d69a6a2e602ff369016089',
  '01a87c3db94e168e98281685a36ac151fc7fce0f',
  '2b434f2d3bd765651fc61b7f1ef3d21a47dd08e1',
  '2c9485e000eac29349bc4538a91d5a45ec56b126',
  '2eefe1b1cfdcf6c58a0fe37f71dffca36a41030a',
  '39a60ce629e5e38ff1f6063e8e4c0e8e0fca8d0b',
  '443805336b3b57ce0deae431952b32cf7608381d',
  '4c522eea6ed39b8fecdec80e274391ac362d136b',
  '5c3ae8555f9706bff568d28549b8d66ed05fd490',
  '5e59c434b5633b0c3b20062c312e58935724ae2e',
  'a0b4f6245ee98b6a30139217873587887a3b0e64',
  'a47946b60d53f5efffc0f2ed4919f4dfe6165b7e',
  'a77b073817c8b25edcea146feb84c726efba8269',
  'b57d2d9f84c6c350614b8986569ce3f9496fde26',
  'b805863fab03ba14f625a97cc625733def2f3eee',
  'c191c83abf8b5c892ba2e96e629414a0d5acc9fb',
  'dc93bafca2b8146df0670f0ba89d1afb8daa2ff8',
  'e918922b39e09492e0c54828ee58e0cd7165e950',
  'ea1888b5758a23ff3c62b9a3fe07badb3e403cde',
  'f44c73a869cf4052de650d83e51dcbf81c3c4cb1',
  'f58e50a4c5fd2db81e1b5f4f5c36642d45f805c4',
  'fc824b22a178e7337c12a9b597c54e6678e42a6a',
  'da5534547c15856646c1bd281313ddd55cbec407',
  'f66c7b4fa8d6c62cf420e100a28b144d78cda1d2',
  '15d9b204f3deb7b4b93587c0da859c518449f0b0',
  '0a5c43ab4cc1d950b137e131bb5d51abbddb9be2',
  'd25f8965d320143f2fcc46b042d478363d0c9ce6',
  '44a629a37e5e63b71ce564f8a7d2f0db1841a140',
  '94d1f03ed99bf5f3be8c59f5c2a7b7f0af972abe',
  'e74defe9d2f59653228a6b448cce44255f08ae1e',
  '4530c99e576b93898424e50bedfdd180ecbe9ee5',
  '7edf8ee6d4a8e5be25c6ed96131c7ff05fd32c1d',
  '44c17366ae9967da34e99037aee34fed7a000e5b',
  '8d1b158e3ee87bfff11dd8a5d352446458d3e401',
  'b0dce1a37b44a82d0d1a385c4a9da31002126f96',
  '501a431415d4293516a3fcec5cb087db3a1b617a',
  '9fe3dcbeacfcf732525c1e71788e314fef89aa1f',
  'b21e8f4534d09c54e21e54b1f430c60ce6b3bb0f',
  '4157256212b4fda516f6be43ab02adb6a4bede9b',
  '72fe684b30692c25614e232f4d2ffda131cfad82',
  '8264f3355a4f2b62a5b354890f457b499d57656f',
  '4b7123f7606521f98d65eba846bbf27c60ba596e',
  '9926c3be25d06b2245edd8378ece9fee4bf4666b',
  'b098017bb4905063dac6067a1429bf594bca90ae',
  '505317139b0e3d1d2665c89e0245b325d1989c3a',
  '7c320a76336dbeaa08acd1c68977f4a16aa0b80a',
  '82b14b33829a1c7e4a6ef5d70fc4601860574137',
  '1f8a9500bc1dcd090353aa21e8f3338cc8bfb8ba',
  '4464ede2f24d7452c4943dc7379fdc9873786bc4',
  'e290c5c28e353fe30e17fc628785e22dda3afb01',
  '2e7adf0e8c568f16bb075a72305c99ec1ca9b629',
  '628a7b38955484eeffd2f41558c7c823773cf29c',
  'eb80896b5bd2bab2b5ab008631bf3cebd7397623',
  '3acce2ac773e4a8d661f01626d8a94c1817e5425',
  '0d8618edadb25e21232417305c5c03754a528ee1',
  '73b2d9e2b1dcf91a94d9fbd90f9eb7d96d65fd20',
  'cdb6fe516afe08618b91a754f92412e7f98a8a62',
  '858f18a95f6a215e5a803c0c6481282ad9275e34',
  'c71faae6328e1a36fe4bfd4e2cc86634e84d6ac4',
  '80dd9e25540785d7811e1c705740547b54a5b284',
  'fb28b58f6914fa9fb5e59eac6643e93740083e02',
  'de3559ed2e79a9507226dc5d07779b14f7ccbe24',
  '81a46a244e92ec7493740b6df5f2a90a14b2231c',
  '7707590880daa1558bcaacb58f1a6c0357c25afb',
  '36f190d8442e1488ee196d7ce0ebaf2febe9be57',
  '0235d6ef2bcd2c34e4942bf4e005501fc7583a87',
  '822b99bff6b5f5957a86249657bef8cd34fbed44',
  '559f66d20666ca9b7a3126d8f1c4d4a116aa8446',
  '666212850ee32bdd14f7b622651c9932fadb4ab9',
  '887d3e27f8f9002d37802121b699aec83176fc14',
  '796d2a35677c5402409bb4bf7cdc6ba9e2d7958f',
  '2f1ee66fbea5fc08abd33d54ebd4a62186dada0d',
  '858cdc641410f75012bed5ddd1b4e196be4d3ff4',
  '9b08f10dccd201186e120c9049a8109077b2c65f',
  'd74f4a09d253dea04596cf98f107b509d9f209ab',
  'e678d691084d509e316a2d0ad49d910ee397a633',
  'cd56b136739d174cbe54a036e92ec3a66e79d5dd',
  '362f29aedf74f4be09b98be47228724704b08cda',
  '4e84728f3f184656bbab6917040605694de66f20',
  '34ebf1be7c555264d38109750a6f4bee24960c5f',
  'e74681a5d0d16ff76c415a9192b99be9ade2130f',
  '367210c683c391332060144b07ac344bd2fa0f89',
  'e8b9e3ec6a04d4d033408dc153f00c1666707e59',
  '499e108b828b7162a73bcac89caa750cbc4645b9',
  '8bcb08d92bff54dd42f269c5c9371be43d58a2b2',
  '27af35ed2ca7ed26b6ea6df26029740bce1e3e17',
  'ecf0a6be99030d0ad4e10aec11d2c0bab94246ae',
  'e2cded540fc9646b45c2cc9f4bbb09b2b1b734ef',
  'b1e95c9caa3778fb66ec7f17824304372d06ab1d',
  '5b92cc341e6f6736a12de25f1906b9dbc6f3d12c',
  '4eedb0561c51af6cff76e598ceae80fea3cc3b99',
  '2571c3d45310a7e2d17f28635df3a2f672c59331',
  'f3ea3f47375be51b1d7ef48a2b27dcbc6f751965',
  'afbccecfcf3f62a68ea2756d0cb625b082c96ed7',
  '92e654595e328052645407f5a78764edadeb0011',
  '8b67375a3f198d2817ef767a3319c46912838472',
  '1ee3d4cfa2cb585fc6df096e000994814358eebe',
  '02d1184e7ba17a1600eeca56410e63cbc7bf4598',
  '394f0faabb88551c9313e5cda4aad4b86bf88f73',
  'd39d319d47848b32f8460bcc148e860161b0ad99',
  'ae3e34a4d629f68ae8d5f947df3954d797500bd8',
  'acb8c174c4d9ae0e5818c013992e4dae9f575148',
  'fc5bb19983749f7cd0cbe916c270347cebbca5c1',
  'f032c2e8f9507cc22e713787ff6932f6cccdb9f9',
  '1ea40fe61f8d5cc02ff6e48107a87444c5a9ef4a',
  'cbccaeba6814b86e4fc14fddcc9fb5fef4271151',
  'f06aa65273992d3517ef46cdc92fe0e109615490',
  '5e3548927896521dc435f7154f6e04f6597da174',
  '11a40226ec3b107c772c0be6ea10be8fbb4097af',
  '4d297e2d3df6d60d0402999fc69e652757f1dbeb',
  '4c47dc41248ad03e571d2f282cc6002c616f7805',
  'e5bef71b812b954f284ef7d4ca3ac588e91703d0',
  'b591b290f718bc91fdffadc8039a88dcdc73103c',
  '70a744955fbeb1ec84a3717a923a16f481af11ea',
  '77d47715573b25f692c3d48ba845f5d0167fd3b3',
  '0fc9772d85fa6ee684332c10b2f425a072bd56d9',
  '6b155c1bed2eec169ac9e83c3256e2173bda4c7f',
  'c391047780bffd553ac3dbace95a798ed7e49984',
  '991fbc4a1ffd0fe0404ac70b33dcff22a98d61bf',
  '721c4fca4496704e056251492fc4b14576291e6f',
  '622e2e0102516b635a57cc629725efb1b5ebb2b9',
  '30b1a8fca6d4f42f037c7236fba2680ca8e70a4b',
  '1d10a6cc90ee83d39187f014f3568f45d52a7ee8',
  '61e11aee93853badf1f8461c9903cb7443e31aea',
  'a5907653f7100c300254330dbd273feaf1e47b43',
  'a8ba1cc66b8ff77a9b0d776047e33f279b8b2d49',
  '45e55a50627305311479123e0fec171e16a0cd0f',
  '87aa52711402608519f3418e2ec82a41a8c57098',
  '45a665130f45fcb500452205f293dd08506d1ef1',
  '807bfb8a1f1071b8f4864a3102384bf894592ff2',
  'caa6a92aba1b6abe1bec8fc765e1687df3608987',
  'c05d958943c63b3999c15b972e33fb22e7956afe',
  '9044fcc1ddf2aaa2c775fbbf8bb0f174d88acd37',
  '235bb3ba217f6321d37c7bcfc5f3854060908d74',
  '6d1709d1a6f943747166451708e31d226bb1f266',
  '323d895aa15303264664e54650c4e087ab6aaad8',
  '5d4f4927c1cb9400703bdc19da4c1eac9c5d7cee',
  '47a2f7f95dfecb05123d9d3f30d63d50df05a7ad',
  '30cb32bc53e4d2bec683be2141ccbf60fff4755a',
  '1a58f6e3752f160bc4259a772eb13d36e017777f',
  'ee3ab1b4bfad0876ec255c378ef1c55e4de8a377',
  '7499b0f99fe8f8938bf51aa174a2781f63056bda',
  '0f7cf846f000f3a3bbc5a229f1ce19177d7c2de0',
  'd87fe66d669589266f6bfece04278b3724d0a850',
  '5b40fa5f00f7a8b6bb4915ef587644dc67af81de',
  '18d30e9f2b656bae0d25feee20836f3af1160044',
  '3b2329ed9c7f02f16962fc15766c6f073dd75439',
  '2041d80aea5609dd80462a31ae236e5e518eca30',
  'd1f5e3fef84477ba845b382a48488a01701039da',
  'c4d5930e54141e5cba6162a8f41ef7b96a8db951',
  'f9b028429bf23bb235529d5fcbd0d4a37d967d9c',
  '00e9c24633f149aac69a10556e35729ffdaf505b',
  '73f9a02bcbc5b97516065530e8afce862e995d60',
  'bf906b0d72e2583d55b3032052b997154fa1303b',
  'c33552073ca19e8aa5983d4774774c2f38ad335e',
  '06c468c3764e8d6745d936f78a2305793ea3d317',
  'df76e758d56f0429594cd73d3cc100ad8517a50c',
  '6d5a653ff33b117c2f498e1fc1fd0d9afaf6df24',
  '6e38017cd4e25d75d6fec799d52b5cf94a14b44c',
  '15758e7997143b8ea131ac8d53c27c4848747f9d',
  'c7a59c256737cf5e10663d3ab91991987423f41b',
  'beefb27060e54ca229c290e33ecc7c6990d0ef86',
  'b98248cba24e11218777b15c192e5ac9973747df',
  '1747d6613b570aed9b6b524ca0946858f0053d6f',
  '2a2c3a4ec0478e65a184e6b2e4a6d1e2dce54a6c',
  '5d5e6298a73b36c4adc6e4b9e0e3a0396056061b',
  '2af345a67df1c171038b175cf79cb6e5a68fb5df',
  '8a011be3d9ec60cfbece7627de7faaa5d84cf93b',
  '5de9a08c3af7564ac4d694c0d3b3d9f5ba29b420',
  '8e8557bc9a1491e48e2954057b93c566c1dcd7d9',
  'e098d44d1e8e62dde617629c4aa99f124311dd27',
  'e27711fb750a61fe6b6de77c9408d5173126fc61',
  'b60123af250dfc579365ddf8489a63cddac07aa2',
  'fa386914702be63728b982d50a264f63298168a6',
  'a856a9e750cbb3b053b8e43bbc3a6a3270881037',
  '645c102e8760ee2a6473d9abb688b6939ee3a4d4',
  'c36bb0debddb9fc42479eba2ad28f0cd0cac7686',
  'cf9204d4042249401e2944c4eff08250fa02758a',
  'c6c999f6e9e4bb618adc1b87e6d3da292f83a749',
  'ac15ed767225ad8908010b8127c134ba510c4eed',
  '9b6e7c9d26e74939dc1a4efac050926fc8b4480e',
  '5ec57b753dd26945182ee3610b0eded32d58e8df',
  'ec59173e772eb06407790e86f654617688c5389c',
  '3efd8920e0c79cb7149ee967f803910a218b59e2',
  '71d910b1e0d5539e91799e1274b6b7a4f85cb3a9',
  'aab1dfba053b8f057547185c7a58ac5610072073',
  'ffd1633769e1e353c0d30afa4985811af5cd056a',
  '3e9f0a763f9ce2ac46ccf1118e4af57f78df6eb6',
  '20b1e10a32210e61c4277ac9d50ff3266735ed3b',
  'd2e6c4d45344810e83d33b2dabd18f7636d68931',
  'd6dfa87beb6e676ecbcb62a41bb6cc9acd183701',
  '17c9f27bd87f9f11b18b3ce949d5e65100069926',
  '15b96012767d3978ecdf975c8d4180ff0bcb550a',
  'd53113870168e21ddf8c00bc78b32a7a5454619e',
  'b70adfc383fdc31990bc423ebb5b334d4fadf7d2',
  'de63d5be55a12b42c4120d21e9da598ae17013d5',
  '51817b38c1d455f27f4f379a0aa1324243017161',
  'd3f6c7aae17d47d33adb90e1762613231fc7e477',
  '5216adca72a1ff29ed97ce3c9fdfe908597437b2',
  'a937cf0320d8622617a65c3fbe1ce8dc48ad33fd',
  'f249312a463d843f86e97af051ad9898dc2047fa',
  '884f7fc01bc3ffa8b3262c41c18dedada7d98660',
  '951a0da2cc855a09e9d6cb9d0c10a4313ac61a0f',
  '3ca90b3b9dad2d38a331e1f1d23c9c7214d1e564',
  '5a17e1a147a78a3a2741161bab52bf5f0d3cbb82',
  '45c7d48360bf96634dac6900edaf7a7724abe933',
  '077c71e51df134063a67868d971f2583a0fe9f3f',
  '8e7d7c99b7ca95fa48a8d2c148b8b3d7b697d19e',
  '21c17c541a38f91a0c17837e424ab7c058b46a57',
  '93275b2df688f2db2fe513c6a90379db987d66ba',
  'f279a1646e77d0e604c0b9524d2843cb683727c6',
  '62546e28a1a135ae90063025aa03ac6c60a522f4',
  'cc8a25dd6c767822e1c163109b6834322a06392f',
  'd5981c819acc9772c3247b4d092e84409e0ac2ed',
  '0a067d2295d24981f35144ec534980674a2fab7c',
  '4f52db5ddaa1bb6e5e43c661499d42ef572f6cac',
  '8852afd956a2893ad61ecae99058ae36896efe4a',
  'd3daf4bede1fc99bc4e3f691c388601d9a6f6262',
  'a69bf0953bcfe1b7f8bd079eaf846b772fb9bbe4',
  'c5372f3049b621c91c4f008647d41934b91f818c',
  '867469cacda89b39ebe0236f18abddcd4e5a2aab',
  'cc9c914e009a883ab7e8c8f3e2300367685a7995',
  '895f2ccb85ac258da7420256d718b2795ff40961',
  '007083786d228e0ef89f7b3265eedadd9fb3e32a',
  'd2d384e42d0d6fd6c6f584c80aefb976e54753c2',
  '902979f3e1c824b85f9beb7c26651abe00827d85',
  '65948541e982ebbf0af3ca4d7a35d059553f7725',
  '5dc0b287edb5de8e32a209765b4bb45e1d682f48',
  '07858eba57e5f9a129f85dd297461e43babb6766',
  'd60f6846479cc1eb83344d509aa42bb96863979f',
  '2aa9df86cb584b769743f53451242960b1453cc3',
  '5b160363e2ab860847ebf82520d6c278acc1314a',
  'f893fd89e9178fc9f3b4c7841caa2fe2c611e103',
  '4c9bb50ca483a58ddefa54199b50737e96c7e1b1',
  '14731b8a185b078ccb72434e6df3d55ad30daaaf',
  '810a1475f4aa8d70ebea219026c35e9957ee88fd',
  '340ead84990836a483a0a68da3c0ea0576f27ade',
  '2c35f114c12baef9bc0b26676fad0b54bcb5afa7',
  '8d254091942d08661b52bbdd1a8ba9d17383081b',
  '55aed58530699d5638015f9c90c23760818648e5',
  '662ade1c764813237c1d5d3a1aaad627222b71ba',
  '573a1bb26323489f888453435b86880ecf75a1b1',
  'b979e9fc91b23526a7f6a1c6cf99c6cd519a1b9f',
  'e07598a98a0198af86095acc0a00ded40d07f0dc',
  '90e0ec6be0dee1dac31372863984e0363fff98e9',
  '407db472454ed0a0a99fc3ed031f3f6de310ecf3',
  '6184648aab0431c4c95c649072d1f9ff08b9bb7c',
  'ca4afd2c2489f28f0ebec6697a238f2cf8ff404a',
  '2eb58db15f93dfa962f6c3118b3b813379ec29f8',
  '1db43247464a2ea33bce7b672c9b6a2dadf9f9e2',
  '85bfb3890948cf864c1227c1f0a0163f91081c48',
  'ae3ef0af90e0de3863cb3ec9882e60628c39391a',
  'c6d0f33ae17163ce23601f7824251aa11b29d242',
  'f93fcb7b1573b7273cada00b0cd4b80249e8d4bf',
  '9789f9da3dfc604cf660d45d76c8325504d233bd',
  '9dcecb519faaf672459d1fc7c6b0b2e4342ecaab',
  '1c433b150ca9ae58c546e82caf1e17a90bba8617',
  '42e7c74f90a6065d9578f2db149ae9781869723b',
  '68302a2104e2fa6aee36b9f6f6afdf5395f721dd',
  'ec20b5c9b8fdfe874ddef686e2ee3e63f778c17e',
  '27e6f14c02a08744b8a19a48f9472da8d12dcd20',
  '33e7fc100ee5e146ad9d477f0f56221ae2a195b2',
  '261d1fb370fc73910642efddc3955a839d6cf674',
  'f6d87e613d2d6df683d737d3c47afbd5ce58ca85',
  '9be53b99b1b5c00af7a2e9107b83a9b032209bc7',
  'ac41864a64cb83a393ef60d945d4fa1ccc482756',
  '4e5858bdbe217e6a02b3a8f0be80a554198c0ff9',
  'ad8c180d12b32a52724a915ff102f1fa3bec8157',
  '60df030d4313107d49ae56ffeae9ae0416c875da',
  'dd8f199ad7a80d2974970caa2e818b7ce8fe5876',
  '0226899b8a1dd449b8587f2d2edf7118d7a74a15',
  '71abd0abf1b1494d56345204e24e401878e25726',
  '0116f2540d877ba896acea074fbe4e5db33b1585',
  '752e0b5dffaf4e8b0bd52164f887a7073895a0eb',
  '45c1d0b223141971186e9b2e602c032aa03e19cf',
  'e5ed9c5ec1c609cbc472afd9c99093c1f688a9fa',
  '69753c2d7979e86ec0dc993b9bc22bc71a586289',
  'ef2c2e279d97ef337a42887aacd58f61673bc0b5',
  'd99b205f50dc9bf070e933eb2747c45de90e2fca',
  '1f2bb00146f20c13ffda6d383a853d09679bce28',
  '220094d62b6e39508313641c5440929ddf3f1550',
  '0fbb79e7eb2b903b2dbe10461e71adef9d2ea17b',
  '960a29df02743ff55efb9737c5a2bacc8813d2d7',
  'fe1a48f4a06a8d6d422c3b7b91de2aac79768fe5',
  'f38bb519566bed4628550f776894a832b4523a40',
  'd20e33ea70842cc164f2cbf22137188d5341263f',
  'd5a9a5cd982cecdb3425fea106326ee89f67d4f6',
  'b963f71c7f577b8f03f38eb5b11a612000d639f1',
  '314d69b216d4c887d5f0c4f595d87034f3f3eb7f',
  'cbfa58bbe1cb6f499b4a35ffaa7229810c6fdfc8',
  '68bcd4742d3c7387b0c36e90669b38669a0a094a',
  '033fdee2c129347233204b601c3c48fb6c91f86b',
  '68aba6e765c4aca0a7b4d4881e6923a68b5083c7',
  'edb02d5eb584d9022da83ec2911c7011fda5a2c1',
  'd7c4359da56c85b02cd10536bd69dd8ee53b421f',
  'dd9844783c0c738a8fc5ac98641e4744fcf4e6f6',
  'd42b8016683da91a179bc8da900e3c248eeedd41',
  '7d9fc26f85bf858753c786139423ee8a4ec7e640',
  '2b04112d7253f337ad837aa111eed60ab6c320ce',
  '81f700f1d56785672b6a0254d4afdfad4ee6defb',
  '994ebfcb2e76144d3b0831a14a484333b3463adc',
  '17cf7cd3203aa84c4c058f2ba2f00bfa24930146',
  '588c0d562fb853048f9f1b948e948ea1414c90f0',
  'e8cc85c37a34e3e810ebe85d5dbabdf6d13167c1',
  'ed45ba26f0251b85fc2da95966b1ba184130767b',
  '40cc7f2fa5159ba35bb504e7b65084a06457731a',
  '45fce97193508f13e90e7c4a686031d6fffac8dd',
  '43fed56ae9188ecc9cf747864ade7beec8091a80',
  'aac4cb4d0d1b1f876eb2dfd2e83ef0f264038168',
  'a2e4982f901a46cf60eeb9cec91b31997e211b97',
  '4328e247731204e3a824d07d35f4e91ca16c2df7',
  '1c9c9348ec64537db4b06b6618f626cd2f415671',
  '20e2cffa77cfd5ff543ae82342cfec4b92e4920a',
  '120b35d4a2562509e450c55cfaa2460ad047f37f',
  '7d394d55e381d7bec3cfe081d426da15659c952d',
  '6ef9791fa23f78c484d29496b75d6dfc0c6a2f7b',
  '75b585e61c2256e40a950eea6320d7466f3ecefe',
  'e11d4671f758ba2c9328d70aedcf2c7e182b3909',
  '1af0edb0eeb2009e8b8416fe33f3c7627958521c',
  '9104deafd3b0470adb7d90ddce577bd658acf3eb',
  '3a5ffbd309caf421d54fd19cbae3ca155ef1c2d0',
  'a3c84c4becad5e4613672cfc419b7d3295edd953',
  '0ba6e9c36b6c50ddbe1b5607d4b40ba449d7dd2f',
  '8a77dcc9880675a7a06e146927577627e26c0904',
  'c726ed3b20e3c20e22410c3364177b495f239d3b',
  'b55fc8787a285babea38f4065ed977cc59c18824',
  '126e4c617df9f7129a8dfd0b670015505593b561',
  '0a04fe84a6d475ead805038cc86f67a2c24960dc',
  '7559c10708bc6effd0d0be090e751b52de0dac1d',
  '051a5310e83caca00abdd026fabff42d4c274b0f',
  'e00776cc2d279c955eab4aa4679c255b0cd79d04',
  'ac480037b7af596a6b1e72de1fa754c1b1f4d756',
  'b12f65a2967dd701fb77a673524e79dceb81e641',
  '203a5912d33419c536534008cbeb960459edc17a',
  'b67e3a262cd0325349a6774a3bd804060708d403',
  'ce0070216ebefd30a97236f7fe04f09aa64875af',
  '5c531b2d379165470a90abe2e81be8dcdf4f5c58',
  '28afb7afda63955b12a3a67aa9e1b35a1e6afb7f',
  '1600ff466c0bab2764ad64f625526a8022b3b7bd',
  '8a189078da66efc7be8fc40810f42a2cfdc57705',
  'd53178e7bf4932db1f352f04307eeae362bb483a',
  'c4f2097f83778f534154ad09cc2898f87adcb754',
  'fb01de348c9f1d6615d11fc4fc487a3d6e7a3adb',
  '90de3c37c32d3941dcc12ad79ebbd5eaa12515a7',
  '2a1845e4b9d264b7359109266a870f527c18b28b',
  '8ca75e151c8a3cfda634725b410703dfc2804946',
  '13dd5cd290c65640211cb2c6df3fc448918b82b7',
  'aca72bd71637a8d4698859c0a778aa9c0c30fbba',
  '3eefa39cc120005d23aecb5d61d0acd1c959a76f',
  '2f1cf1de3a1f4d06ba71eb802df9e7da0ca10554',
  '2cb3efd81823f4dc52c8f6bd61ba7802abf8e181',
  'acf3ded47c5a5e48bc14399dc98dbd0f8f9f3e9f',
  '26c63ff659f08b34b7e600c4c53ef16116ce185a',
  '8ec650260faeaad34d9b63d9971cee3575a8eb30',
  '49d4a9efd0e4c635e3118a971e21c4614b7c9a7d',
  '14922f3df7967a2d307c4f7e2b3bffc887c673c2',
  'f65c064f5e86ade018e8ca6ea4cbb9b0f9e9436e',
  '559eee0ee1fa4229f0d2e843b70dc9b8f7168823',
  '852f81403936c1a15b7ccca11d68b5d499aa035c',
  '8929c3e175cb7b76d62b7a786d46e427e3d1e35f',
  '336cf3fd4d990a3aa169ec69ab625d4a11206a73',
  '49c78c12fb4b7681ef1661dd799afba06afc896d',
  '9388b80e86cef68be87502cf230cf05fbd88efe8',
  '38e44b459b3aa2ec3f79edd353956953a90fc4c7',
  '964622be664ca3dc376e599383e7767b4cfed163',
  '754793fb5a3ae2ad97f566975c912c99ccf913ec',
  '008a97af7c967f0779be16e9ea1a2b9d422ed1a8',
  '3e112a16fa80b3beb164a7fb2a9a0ed08a899f8e',
  'f64293f30f8ff9ea2ab2ea8c5f6e384d65a60c85',
  'bbbac9ad4af8e5ddd0d2f75d7db928b1b7b42830',
  '0002915c01d0076c5f1d7c62618032a1bbe3d708',
  'e44cc4881e3426d7a272f64dd56cd41782d8a51b',
  '77f1e19d84b7810f11f7659d6dc5d1138a1f816c',
  'c9d30b628cb86ecf7e5b1c6fdc16585285ee9f78',
  '20fbb4161d9eb092276eb9b07074aeab22a16c4a',
  '60553bd10d1b77f5057413d6a130a184d1fab85a',
  'a6ae8a172a82052653e5bc8cd315b3b3a2d30d2e',
  '87e5ee2b257b88176c5864c4d46122d827b7f529',
  'dbae15b00d95c57758c3527fc5f5eb5f535a05f2',
  '680a47cbe8c253c066cf001f0c7d69501bb89d44',
  '655f611b0ab8b5f7b30647c78cd9a7017efa3af6',
  'da0e366a5f5056aa8d984cbc8d3b31f05d706ffc',
  'e103cd72809a5ebad7bee92f956e57be82d23614',
  'bd085bb900c8974c615ef2943020b0a2e74668a7',
  '699f47d0b105522525383820f7a41792c9fc5f0f',
  '2a958727179f1d204516ce3dc8e901306118c26d',
  'db8c2b6d304ee31ac1b8b5fb164c7dc846438e0d',
  '926e1d447407abf3f62ab216690c3ecc62e032de',
  '8cc9b9a4710286086fca5960f15ccde88eb7296c',
  '757b0d9f8714769a35cd7f4b7b65ef7212ca216d',
  '7584e85f728545b0336fc384d728746233dac53b',
  'e81920e95611270ebd391574d303d59be1624fc0',
  'c690925e10bce132c1964f71eea394cf8b99c370',
  '7bca075b6f90d348f9c847371a5809ba5499fd38',
  '283521a042f24d10dbaf069af5a72fbbaf87a11e',
  '9cc58b1700ac453720907a44e42dca641fbbb797',
  'b4b79a5c9c287041214f018903f4956d79a58a0b',
  'b18f7b689415eff0a639f33700a3e103b5136687',
  '41944a96e935f9198761785153f578a2107ea0df',
  '889cfe2ed38d7d9d51862d3ee2d931ca4406a94a',
  'd52154e13c7e91680f69350670cd5873bd62c9c5',
  'd1c5c92cd6346e0e36dc9795d7675f43a7660187',
  'f7a4e99e00df68b9c394918d2f5b67adf413e69f',
  'fa97e7fcdcc2b8ee94f15dc4427322624a1d6faa',
  'e21c3920c92a74cdd1767dd9703401988cb92a7b',
  '6c0fe44e680a9d0196cf37a412ccf244a4d582f8',
  'de395d682fc3c26d5f6cdfe6e5ecc092a9155e18',
  '4f65f0e6fb1580899a66633e3173ca1efc4edc1d',
  '3e50c27e085cf5e99ca5319b8416fa15240e027d',
  '32a505024f1713543a931fcb0e608d3e6102c7d6',
  '13951fff8cb1a1b394d701807dec57134c863e30',
  '9706b5e9583d91db1b3c43e069791c3add1437d2',
  'fd8b12b2029d62a6fbc5260ee160f8d2b69994d5',
  '9ff259922484e429d6dff80bc8d40d43d262ae27',
  '0a164a790e342e6e45ac1dff9bf5cfdec3931849',
  'b38733628df7d31571dbc028389d68b71d75687d',
  'b2ad4f5d23aea634731000c7a4eacbbc36bd9337',
  'adad0a094bcf5015d79e2125cb25ebac655675ae',
  'ca380ee5e3102b17373adea27057abc86b7edb2f',
  'e1306d102099135c68b3a5191c08c79c06f9dcd6',
  'bb0cdc509f9dd592f1fb93c91958774a3f626ed6',
  'caeb01313450d60cc32e103f0c2e87b706c52c2b',
  'f3432949acfe91082f2cba70a3b2e3623358b02d',
  'a192bc22132716d03862a3fb176d9ac7d792d829',
  '3fc4d65bc22f94593d894188da27d155dff75290',
  '403989e0f651582daed6491955250f70d5b820a0',
  '92c054ecdce71e56422f16ce224cf079d54fba0f',
  '28b286d09b759835b571f79e8019428a8540fb42',
  '51c1e6b0654d113989162f7ff4aa82a3871d2d3a',
  'f608afaef87156f3c5f0bd693964990a63a43816',
  '9c90756f6fa06c6cde6c58a46915f45bea96be93',
  '93fdd57c11129d4f66fad57e15e56bd4252a3b79',
  'e346628f8651c0c06a948f2b8f54dda1ab53eec8',
  '231818c3ee4c7fd385e381f20352fba129cc28d6',
  '284d39724fac0e33525e158ca0e8d8dda040d549',
  '9d7a904e061b3b936ed805da91adfd5943b1c312',
  'b44c4a06e867b5231677a3ac8ab81c57e2065c94',
  '20b195ef3372b4f64a39304be3f7ad4f6538382d',
  '9bffe93785332d2f732f770c0f85e6da3201df43',
  'b5583414b10f1cf55737fbe29b15c3f0135948b2',
  '86acbbfcc2c736be8af2960c85d41186c9c0b66a',
  'c02608ab8a7a38b318f54e149f6851211812236b',
  'f4031b286fe8ae6675c46ed247c7006b05afa87b',
  '13d967657ab27db3741fae7048b612ccc7b9715f',
  'd7bddc22d501a4c7fbc1e0029eda2e2c532c55af',
  '15e135ad4a0ca81e3eda5a5daa95bc0491f27917',
  '05add8879d77684c1ecd4caa1a91d8654846335c',
  '7d913d73ddc8af414f5927e520829aea4ca0fdcf',
  'c11b500f586b15b6c9eeb051052a3c8dd6d939fe',
  '904ae468a9efe9e5faf6cbe500e2f31da5655109',
  '3171d0c0e066a48be5e3f0bc225c3db94d3ba470',
  '13dff30fdcdd5840b0118c7a08d0ddd2211efe85',
  '15b4d1d62f44dce8f5ca571cb37d16d2912402b7',
  '38e89824056792e5f9faf278d9887af6fd08ca64',
  '7fada56e9f422af867e3c327d5954099a61010bd',
  'd8e83abe70d11a4acf1e002627611f6c71c07a6d',
  'd8f1f8c7021c7b4cfae2257c8b6c1467052acdee',
  'c0123acebcf96fcc1c426a9f7bf6a1a3601ce60e',
  '95c3a67be1ff92c8365034dfef1074e8f01a6257',
  'f0502f1e4dd803bd4a871c09ab5f15ed4d56c262',
  '8c949193f34311b4fca59553ff56fea96fe2f767',
  'a909ed5f0f59beb0a4ddfb06614832b50a8e74bf',
  '10da6a425b2406439eac5f666fddffe88bbe5406',
  '18968c5701100fa73474a6c30c8fb8286f628be4',
  '265fea8e759e15db8e0886e679855dc3d9a70aba',
  'b3b1c8ba6713dd7917e395c0b3b1677b19e9c132',
  'd1ce7f2ce2271fbf5a5ca77b601416fa70f980f8',
  '8466ab688098cdeab515f385c29b6d7bdb15d180',
  '1af25cafcdaacdf548c3ee7f75fa1eec1014a7cd',
  '148c396de8f9617553df159a1c3f15a4a2fe4fc8',
  '43b87dc31662990afc89dac4f17ff8c88815c8f6',
  'b7d9df2471f523bbdcdb24952ec55ac1d96e19e5',
  '0b0416eac15260777a8888e7e8e31d0a8bd6e121',
  '7d1bd64809c2e268c4b022b5b8f61bd8ed36c93b',
  '89e3e7648faa9477f67710f41b0f8463158a3356',
  '05efae5253cacc644b954d8dc75f52b35d1c3d88',
  'e2e00b71fd3ff551264b028a8131a9e2265a6d52',
  '475bf8bafc268d0963177241d8c0a7bc911e1aa3',
  'd3556641cf3912dfa01935de218b14aeb7017ab3',
  'f9716693228c8f2563866388db17598913968aba',
  'f44a6433ff92f79c6e510cba55da7f20639446db',
  '91707e568daa5838cb744d9c171bf9e12d502678',
  'dbb352e83ec1464ef283c037edeaf1d2afec8d97',
  '4f2611827c99cc750cf9f532aee1b2515c10793a',
  '20a26a98262aae63de618365d944c01d401ce632',
  'b9bd227f3396d0771e382dfd2bafb2a474ba2be5',
  '8f9b005aa318a43e06b25c98973d1c70148886d2',
  'f610f356e9b78335ba0aa8af5662b566d1e07603',
  '68d8de2994f4b30253277524bc7f2df9badebc61',
  'd5b963b870eef35ff2ade4e88d5976b2ac2b9226',
  'ef64c83809b1763ad5a4c98a30527ffb89386b6d',
  'c13c3a10383043bf0a02606fbe47f5b53c45cbd3',
  'bea58ef3f94a36099afee4bb446355f8a6c56334',
  'a88eec55440c3a4f8c6854f0630552d12c3ec3b4',
  'ff61405f541b972ab665d59b10f463772319221f',
  'd83650f8ae2c58b19539ec32d7677cd34fc6fbc2',
];

const MUSIC = {
  channelIds: MUSIC_CHANNEL_IDS,
  name: 'music',
  sortOrder: 40,
  icon: 'MusicCategory',
  label: 'Music',
  description: 'Get your groove on with Odysee',
  image: 'https://player.odycdn.com/speech/category-music:8.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [],
};

const SPORTS_CHANNEL_IDS = [
  '6cf93f07767b70a2057f923fd049ca7f62adccf4',
  '98b97e80503e173229a485b2181760ddee2346f8',
  '25cca3e5c2599928e39d94edd95bd897f65ba111',
  '282aa1e211f3f67fd082d94acb59ef8ed9cf98b0',
  'b790ca83ef1ba423c57eeefb17d129d0f9239766',
  '64096fba316eea58befbb2278ca98fdfd63a6607',
  'b0f111ca5ed899c50a5b6a6e967e4d9af421731f',
  '503785207203c88327e4e86ae73070a934f4a2e7',
  'fc66968225de38b515e431740a008c28937e26fc',
  'fb96ca6af99e1b54f2b4e3be7e66d6a083b9201f',
  '3dabfffbe00a7a31f338cb23b022e5a37f78aead',
  'b73d5699ad4a10fe7ae9908234e0dfc26bda0fd1',
  '5112fbbf9ef452d6d5d51682081c5a28548ee2c6',
  '937b279807400ab903c1c19b67d45a49104a0c82',
  'e9bf9f8e3a63b182b380db76acc245ab511bcbf4',
  'bd282a1c1be33ee57216bb5aee4a1f8037aadea3',
  'b034834fc0bec73cb7e522a49a3327dad2ec792b',
  'a19f881b29ba4080038cf8db6d36f6199e89d1ef',
  '69a9196d319151accc8e42b422eedf285b5aac97',
  '437afdaab6b5fd72d7eb3801af22e42990f41bf6',
  'ae65f066ed09d4cfb38d40df3f0df462161a49a2',
  '718be0a9623a66a8c58dd5d9fd94aa214ad3e5cd',
  '86ef88ca9ab471cf0c704a9f203a5701bfe224a7',
  '716b6d16d5b9b0c89f9bf9288bd9471bf5924082',
  '041584c718c9a26ca07dd75acc1719082c521bdd',
  '2fdaffba65935d11f8925a5b7b6d046d578a8aa4',
  '1b88bd9be5bc00b6701202fec7e26232c6c6661e',
  '6ccc02b2b6d7e47090dd51b09427a73ebe5f441c',
  '82c0b008215160b48bb2dbd88822a9a4f38c03d5',
  'fcbea38817ed149519b386c86a11b1b3826472ae',
  '0ef7709760b2e416199c835b941ee19a88d1901b',
  '63172da17bc89ddd8e72f5625ecef8113351eef3',
  '4fafc88723898c02c4e7e132141569d62082e9ca',
  '70034b2c9e4797a58a8514fd3675edcfecc43325',
  '7c08ae580d91d18cd46798c400a4074475f632ee',
  'b1e160b4e136acac6f69d0ebca9d140ffaf84708',
  '300f9e8b5b196378dffe4e8ed87bac756489daea',
  '8bfbd9825eec0c87542e40036f26747eba661c5e',
  '756cd1642ff6d7f67bfcf418bc63c37000d37375',
  '863d7e62f02ff6ef1b5119cbaf419b47d6c5565a',
  '62f81c3c77a376bee5d0a3dda0b39197b5c7f476',
  'a083416bc2d6dc0a89070c26c49264f739f92426',
  'a26a5cffb09ae18d1fa9648239f2c906f7be4b8c',
  '8736026a75df9d8a147467af66ef511411558c53',
  '9d40e8dd5f49d4a4d0bd512cd64b1eaf3eb2e4fa',
  '5f3739f2e4c7a8328f5783be130a327caced49db',
  '2ef9118e378784a0aa4d118d07b3fa2d6d91d86c',
  '638f9e7b024efc24a14c161f8ccf4bacbcfda10b',
  'f6a6d88ad0c873e28986255ba2370e22fca7c077',
  '8dfc393b1f17d8c434ed52f26e9f773228005d6c',
  'beedf71b32143b9f09f18356bb1ee89517840930',
  '1818efcf72ca68ae6eb11a2ccd57ef19018108b0',
  'e037e740f0a1d40dd152755d4c8426556fc18d0d',
  '03132558d4090b149495e15bac19e7391a522491',
  '91c5341634dec2468b6039ce4d70d27adacbdf3d',
  'acbd4739287641ff59f9887f570749e4ab4f6d16',
  '70e0f9bd11a70a7ee0ccdfd5c914c8076c078770',
  'd9ba8e0469970f5e2415546533a2257597b43a6e',
  '7d77a9868cc09819d04a68feb12d9879d878be0d',
  '0959837e4989a5d33626a46a6624fd77a836b659',
  'd18f7bca1443facc7dcfb1e1ed2e324e09379019',
  '0377d466bba54eb00ab05dc0512457768d9ca625',
  'df61b5de5501e4ea48fbdf27df30afbca678ecf8',
  '3da2cdcdd24471aab16ceea8ccde2ddb0696124a',
  '44ac9085afebdbf3408c039e28b5c5f1f21317ed',
  'd273a5d2b57785d19d4c123255bc54f9e45f7e83',
  'a78c07e2b268a78ce4bcd1086da2151eba57437d',
  'e7b397dc6829e3a7eea8b7a3aec34fa067633f7d',
  'ed911faffc5bc96d756e1032cc0ebf057f00d6c2',
  '75bda5ea3fb7e7a8799df442d3d8adea6411d9f6',
  '92cc8e2b0d7f8a4e5c8f8902ffb63a593bf4da66',
  '4935fb50dbed64b2d7606ac0f02f8f7e84ca1a74',
  '835445b8d3d8e608cea66f11f36606a681405bcb',
  'f6a4accfa19b0a2f41dbb35113b59343c5caeb9d',
  '760d3fbebed4802c88426330d36ba4806c994547',
  '263125bfece84a7860a5af4ede55bbb0fb424c50',
  'a13fe77cb4c52a82bb24478f9ea9d7c44e828952',
  '0409251e98960a2b6e716b14603b3e8f5c578ba6',
  '4e3ad74f5403c99cfd33987757a90d279643f7cf',
  '9d122056bb2359db42e1bb3dc6399a1554d5e2a4',
  '75bb630f17b73ff4246bcaded92900d40aaa8a45',
  'd62d0c11caac137adf10fdb900e586d1940378e4',
  '10e1fc8451873bda6b5c4efaea9e9764a618dead',
  '1f4c47d39e573c96c32d19e26db6b7fdee9167ed',
  '94ea850309a2b3e6133e53870124b08eda10b360',
  'b39cacefc3f2cfc02bd0f9967888a9e39419abe2',
  '6b8f307e8cfe2e577a2249afa623f6ed6e673836',
  '139feb9e6642e289f7d3aa461ae72ed0a49f0a01',
  '591283884dffa2ca4615fc6081e7117f13ed5883',
  'd41dae6c7b12d5f6dcc4370c4d339a08bb180c4a',
  '4a471ab2a6ad0b74dcdb7aeb9ee8946165ac389c',
  '231fb5d35a206c8a557a42dead4619bd63742c80',
  '8ff27cd9d4d743066f138f3c97105957e67efb59',
  'b6b23851bf0d60c0b4bec3784cec488cb1a8cdc6',
  'b8bc1e2666a8f3b25ecf96e6d3487e0934138670',
  '610eebf2e6aa6b9741638d763a281c732e736cf0',
  'b38f5c9968c6a28271d9f75668de6080675bc36b',
  '6394a9a7f7e5075dbafe4d4eefca1228fd129644',
  'c7a2ed5d3d21c0f760cf5c97fd138fa9c2493b14',
  '97175e0508f365e88b1113b6ebbb19252d3d1230',
  'ed07e70d827474097768bf5df3f2f2d1d2f31fff',
  'c4e8254abda8c5871e20a1807a2d641f8d087b16',
  'f8b0a5d808257ea42faae8bd7571e579314fb08c',
  'a8431fa8d4cd349b02dfebbdd98ca5febf76ae30',
  '6c2b94924de5f1f55ad2082341af5db45782a32e',
  '167611396018133aeacf242e67aaa2973aba2851',
  '1c22a03d0d7722602fde2fc8e324905046b04909',
  '3180a24ba80073edb5d7dcbd64b3a289c5c61265',
  '9f376e4ad52ed33779647aca6fcb5b0a12e6c133',
  'dec2af3e4763080f2a7eec2ba41e9174873cff75',
  '82a238f753125e343ad70dca121aeac9089501b5',
  'a099decf5303662b48119ae4189457e340237ebd',
  '9c74851ba86a0a9dc3231cb33ae0facfbb2d8662',
  '6235415369d5e49a9e0997c3f9a4db85bed2a189',
  'fdeab8b0a2e4ecc419f5af7a12dd906576496f66',
  '4c2e20843c6722abfed2b6c96ab40c1b155d3f2f',
];

const SPORTS = {
  channelIds: SPORTS_CHANNEL_IDS,
  name: 'sports',
  sortOrder: 50,
  icon: 'Sports',
  label: 'Sports',
  description: 'Enjoy your favorite sport with Odysee',
  image: 'https://player.odycdn.com/speech/category-sports:6.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //order: 'new',
  //pinnedUrls: [''],
};

const GAMING_CHANNEL_IDS = [
  'c9a6a13c23b98758626d945949ee9f8d2e500278',
  'd825a180127ff68242a170df7cec4d669ed18a4b',
  'ec1d1fb566ab94af25f2a57f36e1a6b2a7553e37',
  '315a0138535fc5ebfdf9404f5c78e54230d5d387',
  '85332e7efa8644baa241e724bbcfae7a663c7c05',
  '35562c62f4e840d0801a314b1e317dd2680438b4',
  'cd069b4561c834ce64ee7415d57072b7da64ac05',
  'b315af2e23224afe9599ec444794cdf1d0239187',
  'd1fbf595516814afde55a4873211ef850b650aa4',
  '26c0269de1e5c581c9e8296fb795cf8b03a2ecac',
  '817a807c9b612c78c9445a03695e14e6aeaf820d',
  '6fa34ec42dc2b47cc4ba789793d3fda8c9082747',
  'ac2549b5bb7c4c50ae0a6622837668ed03e2dc02',
  '04a364910ab9c72db5285cc8f4af9f56cd62ee37',
  '519c3570ac9db767fd2331533cff5749385839cd',
  '417098b306b9282b42ea9d7a4ffc0ff8cd77b4f0',
  '3e1422c7e6c512de39b067253d744256193c58b8',
  '6419ab733eb6df9d9e86799f9ba0c7ff36f95957',
  '203eb2b7decb43c9f863fba54e731fe8546ddd1a',
  '816ea11d0a1e93c6f047ca5c02f75ee8d317de5e',
  '0f441b7edd0c90d0f7a2148b878a6c0afbc47706',
  'a4f6f99e7355aa067be0523f52a6a3990c7016a2',
  '4e897188e67df22227f6a202c03ea563da6f48d8',
  'e6acb206212388e989f8fc37b5da436ffaa7cb3e',
  '21115d049e448a14fdaa53d060b79845fc7f3663',
  '24c5bf87010f25582fd0bf2655e6d6d9efb7a71a',
  '648b9fee166a98e675da46d5b09bc1e4541d04fc',
  '27c171317a71edfc068cd9ba9d829981a7dba767',
  'c8805c773c455295d55014d8ef784e92aaaf73ef',
  'f0895dc855cd6ed78aa3a5b4d510428566e779fa',
  '5cd816c13831c3b87204f59ea7f480959650eb53',
  'f9bd8478478fdb3ef71733825083ab879ca262b1',
  '8600893479ec1bbecaa02f9752869490be16e5ee',
  'a873dd90d9719aaf0730764898e9c4e64256d64f',
  '4935fb50dbed64b2d7606ac0f02f8f7e84ca1a74',
  '4d9097d2415e60ba0681c85f1c578b30e876d2c1',
  '8327078c2469db7a55ed172ddd311dc55a105d5d',
  '8e5fa682b957740bda3ea050605ba02c1790c773',
  '5eab08ac2ff4ebee4a2860b5ad20b48189aa35fb',
  'aaac3c30c11a03bf00b508f693afee5b60a425d6',
  '7cf3d9e6b81ecf6c54bea2a7bcea2d33d8d5524d',
  'bb5f2121820d4e124fded8aafcdd35d54f15100a',
  'c1a79e12a69f8e156c67f974672449bffbe03d1b',
  'aee763352836dbd1de3c7ebe93b0ebe3c30cef98',
  '00cddc5fd556b7710c7a418ff0838d6f54b7c74d',
  '556f1a33feed4745c44e001d90f4566842199562',
  '307999f812b7f05e6e6537d6e395505adc50542e',
  'cb4ab93488332d7eb38454774a3141c33df0bbe4',
  '7a944d8024238c483c08da095d52a34e7cb38ad2',
  'c0696ad867deccec65741495b7c745f37c1eb39b',
  '14d7a6f3840fb7b8fd09d85d3a0bd0c5f20a6867',
  'a4b45a1a6fa3d36094ff38154071b37ec1b012b4',
  '842541d3352917a979a9a933263d172c4260fa04',
  '721db3487bcec81ed1171f10bb5ec5ef81fdf6bc',
  '7e69878d8e9d536cf82e242d761a92536b4c94c0',
  'bfe8e78e6ad7b33ab37f1fe9be75a25ee07866d3',
  '808bddabb720db46ccd1812cfb36b51ebd565d57',
  '4f4791bd9e86ef4bd6cef4ab3fa7af01db8e1d25',
  'ed36a2418a4c21016147e5a3463f1c634d69d16f',
  '373cf9235c162357da03af51ed679d75e30b3e6b',
  '4814cb966f59d2dc3842e679a26a89022289b268',
  '8ab32c436c4d3bdb585bd4908da30addcb6e520c',
  'b5ddc426ac6a723133bbd3ea7dfc8f5e1ab5e9b5',
  'b6b10fd6a5533045051780de1f54f2cff31fc968',
  '44392d55d80804221bb48f5113e1b9260c44e6d8',
  'ea8d6b4ce13fb20f23568a7bb494b1a57b5b00dc',
  '0b84fd8fdda926a154ffdff7de43f909d2bbf2e4',
  'bd614e7a0297104a64e6be898c51d93d2aed5dbc',
  '1cd04dacfd90c881ed2b52eebd6c5695b8452e47',
  '081e3a9060b9060e86186537656eb845ac08e8f7',
  'b91392d144fe1381363218a06504f0bee306d538',
  '24287e50a2b248c2944b4d0515c3d10150917d20',
  'c400b52c0351f38ee0d75ab60d828eeb726f85a3',
  '1d0a1a75a1b5d603d251bef211525eb9efd5a71c',
  '66d36c5f573ec6d72eb5c0bcbdbbc898c3c477cc',
  'cefecd8afa15b280448edf8880b4de0957e62edc',
  '0ca868e18488a11c6fc51d93e8b08de55ab59fe9',
  'cf77454d890682044bfd366271bb588b12895d07',
  '7b7634399d12671774713f2a5d7589c6641e1778',
  'ad60db64cbdf965aff55236c327536aa3b23bc05',
  '24f5d4cac623c3677d50f5a568551239ce062293',
  '04c92a7d1c867b0ffe581a8c20e12f73ddc6c6f9',
  'fa69575972d5035fd8cdc751b62137140321a5a9',
  'cd03ff3bfe3277745e8aea92086713ed6d7f3294',
  '250d4997a13b4f78381ee0f91304d47ef5cd8ffa',
  '811eba68d6c19c2a8855b1eeb20ce6c8758f93d9',
  '3f6a086710e3df2e6dfc49cdcccafeb72898eace',
  '8db40e14bb74dc481864d2cf0ae1fd2078121da4',
  '40ef2f9b2c72895c782e540d0f8d085f3bb7f194',
  '3cf559ada3a4d74a876370f1cee088692674f2cc',
  '891242f42c4675c7b60c53c0025fab194694d75c',
  '347afb181a09e9bdcc4cef15f642774e00d156be',
  'd07b2d3104fda9a00b2a11de341c024b3a5df517',
  'bfdfa5f54f70ff8398b67f28e3f098a46688562f',
  'cbcf740c94f9e79553086f3c7a21340692edb675',
  'f45faecc44cedcfe0661e2e4d0ed70119aa19ff0',
  '5695fee106b4edda38be11f88098393625280888',
  '9d82e24087926db4029b758885e876ce13aa76ea',
  '7da7c62f7604b9a7155e56a6561724465fe53117',
  '67373dfe39d4a81a786501dfe0eedb46ee2b7bcd',
  '87e526087586b4eb083c688abd265b5709aee34d',
  '456fa439e96f0ba615c8e5df0607de4b8c682fda',
  '9b5fb52ad8b1780fd72caf573b72f7b6db39a4f3',
  '10b07e7b431127693989062818b7a74bdd2adb3d',
  '168e0f9f42ec6ddbc1a4a9266ea4e5552c425684',
  '6d0ca57ff7ba425dfa97c28c40d8e9fec615db7d',
  '2785dde71d9a5a24875d84afcf04b9a0a24da189',
  'd0dec51ee295fae711466a6642749217b6e48032',
  '268a843abab4f226c0d27081c4e2a060785ef306',
  '20501dc9e6fff2b7fe9988a359be8bf00b6aff9d',
  '3886f3b0df4f25d864b9cc0fc85de3f895c99b49',
  '4cbfd0f267164e206bc625b3c78548ac4a4fca02',
  'bcdbb3972b8a7c9cf88f5e0061d2a6f0fa357189',
  '523c75febe6a5c4271ac26bdab0c3bd575fd722d',
  'bce750d0173646ba35670a7086acb72543a5159b',
  '405de4aefb51e53da5b67502bcc79db2f0f725a9',
  '14126cda1d3be9e855f32055743607b8a58e8a73',
  '5fbf699fc1dfcff03bf949dff7c34588769c2294',
  '6c268fe791565dc4b65e501e097b15747ba6a77d',
  '524ed7d7ef7aa6ae4512ccb169260e082180ed64',
  'e92040c72a9a8936dc609ad8b7c1397a57b0f0c1',
  '0327e978744d3abe0404a1035495e0cd7e28edfb',
  '01be76bcfb8101557bb18dd2d6138df2fb0628d5',
  '8dc4099092823a3012ab7257f4b28751a5e282c6',
  'f513a04cfaf1d617c00c19cd111aabb0c8237a36',
  'e8a272735b43e8202b8e9cb757cced0138c18406',
  'b2bfb283db6af9f6d2c7b0daf27cbe5470b09913',
  'c06fd6919cd9c6a345bf813e91179c339694b523',
  '8d7692ea2252229fcf3a2b9e89334e2a6ce44f3e',
  'c425ffbcc0c1ae0e0be7435f26f341f5ed4016c7',
  'a738067d2c46e2ae4e11ebcf405a4d076c5eaca2',
  '477c8c2a5d040de516594f5adf98526755355711',
  '55934e8edd61ff8a1308e63910909446579b2332',
  '522c0ba7535c303f837580aa0db56f4e12045f5b',
  '218a61ab3a7502393b730e140bc598287ff22931',
  'b45ce3179871296282e8ea0f9df488f7305018ff',
  'a93ba1185606e9e2f6f4bc2fe1630039e9993062',
  '8f3308945422c45036d6b56bc75d8b9ccc7b230e',
  'd923355b284b0b2c8f247db119d58715eaf008fd',
  '4792ddd9eb1e6e436a3722e6c8eac2c12796161b',
  '4681b5e6ecfd6d738bfb16f88dad647a47fc0807',
  '8487bc9b00426f6b47f722c76866f79d99f89e60',
  'e86bf2431fcec4ca21797cfb99901bd57f9701c1',
  'c9282bbb89d3f9f5f1d972a02f96a5f0f0f40df8',
  'cc5d7ac012475d91faf1dd8b807972b1b224ec1d',
  '71d67c83bcf04f812773897b3625eb3e4953250a',
  '2840e7087b8d57c860b8b6b3ba646b60ce293991',
  '87bb33eef630b7d860e725fd8bc07479e4fff8a6',
  '86eab2e37e10c41c0b778d518f9f932c943695ec',
  '3103d3a825ac42f50c0761817eb939b617c3e3bb',
  '10a9ca2291c8ace61e615dec754ff6599cab7aba',
  'e7b41c83705dc22fdc465f724a1d0202cbb58f04',
  '29f8cfc69896d9f4b64e855ebe688c665375bf37',
  '5769d59b6c9f3058ef0651e71cc783ae8d52d6cd',
  '6b616b05e849a3578e2eeadf978f7a898ab4969f',
  '96615d318a96fe11a3e567a248384c6e1253b2eb',
  'f78f3bc5971e29bdff47ab4161d4c31733fc9f40',
  'bb8ee3f11cb9b49160d68d64cf3fecbf3f558a58',
  '023d63926eff0115059b84ffaedd7c3d2b5fecde',
  '5c5e930529a95a37a01e5ab79da75801a2d07842',
  '5fe6d13a19bfc7faecc55b7d3accde7beceb0a4b',
  '175cface1dc8e90e9eea293a39252c459586ba0c',
  'd5075989ebb708bf5719d364f3eb6ff207698bce',
  'bde30e524320b2cfb2776f34a78d6a70970038e5',
  '0659fa883b1e2da6dbc7f751959e2e34c28ca734',
  'df71307568f1788bc4b3847712015a955f0f80ba',
  '43f37af7a27e4ab7eb789686392b32eaf033a211',
  'b8ed37b926fece83385193b2d67d59be3aba754d',
  '48073838eed2ab7c0aaba37749421a80c3a16504',
  '2ba1ffc9882afcff07b4d5fbf1c266e9994fab9c',
  '2b5842be6115108238f2fa1531a246afe734dba0',
  'e724350c93e88b2617d4eec5c32f3043ec422129',
  'd58500495e04df161584af93c636db5b4f77568b',
  '0d3413b9e63452b5ee4e66396a847d50c5db91c9',
  '499b7b67021b1b28c4550547154b7e8bbb6fe759',
  '8855cc467d27c1a2560eece57b993e8221ab7fa6',
  'f1ded9236e80de8a275752004bb696f3274c194f',
  'beb7780bad1dc08bb3d1dded0f640a8d10fa7592',
  'e6a377521ec00cbca6b0fb3ec8f7d9db3abc1065',
  'ee09d6c4ac3fbfc99635875d18f2aa98037d2602',
  '7e2e40939b250c84e652be5fc3ce2d241a9ad04e',
  'e420ed4be86511eca26d65c12a5bdd32d1f0bf64',
  '3afd3404de73e855146b15cd3383e578ae29cf75',
  'e8a30b812e609f5cba7a49a1e7d5b7f14044e1c1',
  'e9a3d7c2d711e24178c7e1dd0ff094e13b1b7375',
  '95dbbf88d6dceca59a0b0e8195f2ed0a6fd751ec',
  '15e6e57a5bcc6776ab787bf27a06973bd9dfae9c',
  '80ce051091774fec7b03ee1ce7d59c1eed826306',
  '9b84dbf4cb2a3f3c684bcf2b05635065dfcfd928',
  'd7e98a958f5b51ca33131971567d7d1f79555143',
  'ce55780452df41ce72dffa87125233bec30ec9c9',
  'ad2c1f1ebb477afe9b58292177f805038119eeee',
  '16f4b7b8a59a63025d7e6e5a34f0b4d8bad056c0',
  '53f1fe92d5ab7792faac83f71960c03ccb2c30c1',
  'e91b52af82a4f11eaa716121283b1435d1fb461f',
  '2748d32215199424fe7e59fd2b3fbabf0641449e',
  'bd52816fe3b19d8725e96cb307a672be9e8f20ea',
  'e08be4deb0f10901137a11bb5fc9f78058f6cf7d',
  '6844d720c70fad79218f91661a7177b67b79a8d4',
  '20f30e337baadd5c6a8580edd08a82e403871e14',
  '79d2d08a140b068b4821633fce6960fcfc48a26f',
  '51eba7afd7af0b55521d0f82dc79ba93560f7bcf',
  '930fc43ca7bae20d4706543e97175d1872b0671f',
  'f0da9907c8c1466f57c6114997719929b2b96239',
  '064d4999ea15e433e06f16f391922390acab01cb',
  '537305bc1bd71e2a5d76efacd607fd0f4d95da0c',
  '60a3fae23e73c566fbd6cad622a74aa6977eec5f',
  '37681352e17310f107031c26f4607dfcdd72ee4b',
  '6a99cef1b67c7f54ec6b3d43d2602972baf7416b',
  'ba98d13900848f9b3d62a56074129fbdee25300e',
  'fa7c7ef9d487341f806fc5a150486e14565f4706',
  '297a95b5d51d3200da14355498b46666e2c323c5',
  '31818feb6ff047f033abbd753d96277d647f25c9',
  '39065ea36ccf9789327aab73ea88f182c8b77bd3',
  '8b62ac801b7627562beb06bf6134e1972eafad2a',
  '8bce07cc60bba1aff1eb250701cd67eb7b349f3f',
  'd2a3090e87bf6c4a345b906818119cad98bcb3fd',
  '56dbd77df6e7034fad4699736ce200cd74c45a2f',
  'e0896008c4014a68a7c59e09bfb148986da356fd',
  'b7d204c8ac9b0b5df4ffc4350afa432bf579543e',
  'e8c71ade66ffb2cd7be3fa08f1d0a19ac70bfc65',
  '15c88c561f7e4c4cdd6fb4e32c35e593db09a8a4',
  '520de75a0373643c14168536940e596ff8ece24c',
  'e715c457b4a3e51214b62f49f05303bba4ee5be9',
  '36a291fa2c43bc42b8b589d3fb7e7a121868870d',
  '8d0770159e22943c37962b4d203fb85bf3057b3f',
  '9456c69274d4d608971d4d92f6218185d0831b11',
  '489b75de1aeb6fe94e3dd930a231b7587b66d08f',
  '046f085572c0cd72127a3ae7e26b1f9881247840',
  'f0985ae3a3d69ba4ae8696120d38f4dd9cd39fc4',
  '952dbc5e95b87538f3aac71306651f179bc2e90a',
  '05f157d8e8aa4b700b18e244f9813e0bf9fdf85a',
  '8e098d2042ad9b9074f52cc06b89d6d4db5231dd',
  'f6c6531bfadd0535894ae67be0eaa274d7ec7feb',
  '4a48d3f5c2058c9172c1a267beac6dac0b664d29',
  '079b201da1b30115767f4eb1393ca397f94ac947',
  '935aba04a2468e2c09e10c64c6deabdd107892d1',
  '71dcbdcfcbb548b5b8a54ce04f6290abea07b491',
  'cfde892411994074f8c3b3127c5c493924909434',
  'f1b6977e5ca6c115de6361737743b33f3f072ee3',
  '086d2bacf441cef45ff15b5afe163d0b03a9f7ea',
  '32de523ba228dd3f3159eb5a6cc07b6fd51f4dc0',
  '00d1bc8dc16672f7059f24c1cc097bd4fc62b846',
  '7a88e0eabf60af5ac61240fe60f8f08fa3e48ab4',
  '8671dfd2f34302c1a4dcb4dd7361568a0bb23eba',
  '0ddd797e39c5fe706ed4ee62f96becb792aee03a',
  '0f146009c7cf3f321af93dbc64c07459202904b7',
  '2011df2d2ba878bee3edbdec893c1996816f49ab',
  '0e6d460ad5589ac6be39a93944ec08a699f89222',
  '9b83833ba4b3432ddebb22c46061c811c04b3561',
  'b21b72c1fb29f6dcb866cf7d148428d885ad42ea',
  '748896c0e2d1388fb1dead2b7ee0f59a96e8af81',
  'cdd6dfd70b2e7984744f71cb7e9451fbf8bdd263',
  '244521dec0e946eca601a520a324610add18350f',
  'ffd6776d2ea09d5f949f0093b30b59a836fd8345',
  '75868a0cb249e88b402fa821c38accfc63fdded3',
  '5ca270beb592ad7f65ddcde6db49a99c27a38c4a',
  '24d6a9683d2033b041679e176efe982ad585e8e7',
  '96cd179975cbd44b2b2d6e67d065b6622b23e946',
  '4e3440f8a6acc2cf83a3d41780d831160f45d9d4',
  '0c58e95fed76200697002bbfcc65f34594bd16fa',
  '7b3e5c75f31f05dbae08d6e6a71afd13e2206dcf',
  '0dc38b3e1664abca99feb028fd85e7e863065f8e',
  '29df9d1953af295db7ec0b5c7b8a4eed6ddda80b',
  '28e9ab65ee5f733b3f45bdb5e5977aaab222c03c',
  '094d8c44ba6911f237d0262d22cd592a909d28c9',
  '52ae07c30480d22330a2b39925c3528fd4f6b9b7',
  'ef4cf3c9d56f7908fb8aa68ce8db497276e9307b',
  '8136ae81c1a7ed586adcbb862da4408910c7879e',
  'ad5bc51e5eddc16bb6d6fc6cd456a55600d83553',
  'a5741bc6ed3f91b072431aecf8c43630b3bdfdd4',
  'b8a34909a98ed62bcb1b63a81a2aac09c461479b',
  'dff7a83b477ba1bf1ef572e0709e05e489b2e8ca',
  'e7ba13947c7778cd07139eecf039ed4aecc57067',
  '29c0db9b1b6c3961e8898e1c8a700428ecfcf3f0',
  '5379233727a4f5053783abe250d0d1d4b02c55fb',
  '9793ae5cb59112761659e211c661b328d7c90180',
  'ba8358715115ded87fea0d360121260719913647',
  '04c3e72c713ed608380fe07aec95b59dd13ab096',
  '4e33ac6ee191d9672a585962be7165f9c372e05c',
  '3d01c44358477a1f3dc4743179be01eae041b10d',
  'e059884b004f539a0ebcfbf5c488dd6e52d088c9',
  '59544303a9edd55815807f6eed20ce981e80d06b',
  '85212e01f53190b398edee9a78c5952badd088f1',
  'fd0c9b84f9281902bbe08d27434029bc3a838379',
  'a4458d578329dc2fb9d3256a514b6ecb9826915b',
  '43a548150dbe5358c17dde843b288e341cd9cf23',
  '284b1b67734c016c2455cd1ce826e191993afe9d',
  '6d84d63338fa61a25a70e2517094f5d57e2598f3',
  '76ad9a96048f4f2ae76647c79f51ffa82a425177',
  '75d51aea3cdd3152b50f218b8b3f9a94b1b1c9fe',
  '215bf9276ab772df589a75ef9f0351d942dd7dfb',
  'c458e2c44ef97996cec6a5df1445077efabd03ad',
  '7aa56cd3d63aa4a4c0619c54eb22c4d0827842e7',
  '038db2a63cf1e8309d2c63f38729a99dcdd2c6c7',
  'e704411844af40259bd50d88bd88e651bbc8191f',
  'a933bef774848edcf47eb352151f84f2153b2c12',
  '45c5c81d0e4951e6d76f382c1ec21e08fb6adacc',
  '9cb9df2dd43ea8357ee026e6f9c3a21aff97f73a',
  '5985e4ba4b74304d612af57e6255d656c616ac6f',
  '47ee58fd1e2d3566ba292c4752c05807d29039a4',
  '41350e1c2871adc89f0388f8168afea3bd69a380',
  'c247318845a6955a78447beb07490ae447f11590',
  '82c28153d7c0b4b3e3d3a27496b18ddf1e62619f',
  '801bb768110efe4af0adebbef7df9e85d93db4ba',
  '4f6bccf461ba1297670a915e8b9325d8ea282f24',
  '9ac42888f2293fe9e9ca52f387bffb6f0958c1e6',
  'ee87ef03b2332d2c08489de60a855aad9769aa7d',
  '5d444cf8508af4701f76df61716b22650f12a578',
  '066a8581c40a4cc563f155cf0a8d7d51bc684e13',
  'dc5f93fdbb5bac1f44f4a3c32b9e7b235574601f',
  '80772d9cb3b625ac5d813edab03bfea9005e6d46',
  '057ca5a46e70cc1cb70558940ba0a7f22bad5714',
  'f7cb223a342c61fb4774af8e8935d3136749cc32',
  '4508a13fd9ccfd66769e30cfb73c664f47dbc806',
  '7d82463f5492d54ff5a31cb9868926d672074e64',
  '8c7143d9840e174e0ada5cb9380c79fab47d40c9',
  '37d784edc721843b1bfb8345b63b2edeb96a95cd',
  '10fb6858308d45d89268c455b0e0c696bff4d4e6',
  '469cd513603ef1c3df071cc456c6361894eb9e9f',
  '154cbf6700cc76056887d6996f8f503e17297494',
  '8e30ef8456aee4dc1985df43428d1a42731d47e8',
  '2c493fdea74d48f543930abf863a64145daad28a',
  '290afbacc4432e169a233939aae583c6470e50da',
  '7f251c9563af4e09f5ead90ae7c09cc0f21c8bff',
  '9e06161a80cd6a091029f1641a752c0c42221f06',
  'b736381eb09e6c545ad3c63ae2e5d8523de0b79a',
  '7d231d089fb452fcce6755874ab48650365a0dd0',
  '3a6a462f9d4910ac1056608024266616530166c6',
  'dccea64f21bb468a0a185251dfb1547e513d766b',
  '2587b88a2a9a0d359c82ebeed295cc4821bbc592',
  'c69c98ae7815531068ca921df5592c5c0552f2db',
  '41a91e524839566a21e4c98ee51bdd96187b5662',
  '52d59530effcc32056e3646fd0ede54187b1c05c',
  '136d1330a538f7256302e320e37bace0eaec9c38',
  '181bfb67b524b0ce8df142b18b07b9c6689ff70d',
  '8ca43a9b5bfb726bf861236fca171238e1818541',
  '4e05c9534de597818ee15e21b2ff65b042dc05b7',
  '2cdf89299e706d54620c7a5e5bc12ea32b8f7a4f',
  '199eba05b6ecccab919e26a0cb7dacd544f25700',
  'e29c666decafc70abc1205846f9f218bdbce0c27',
  '99abd818b41d41d3078f300d3812bcf11b5f36bd',
  'c19e620496ba0f26b646f4c290c68dd594e89f25',
  '7bfe6319e8d688edf9cf5a662c330072fb9c98cd',
  '8131c31ca4fb9c40dd04a60dfedb2b4643080255',
  'e3770f9d81a4fd6dd7a15e1ecad81237bcdfb1d7',
  'ce9f525cf5251dcfae7c620f02147504f8b9f664',
  '86f2f5cced62c7879443b6a91851b590cdfcecf6',
  '4493040dc2889cd3a4b4901a49b196cf439a4662',
  '616b508b44a5563c6e6cbaab06d42f7ac6ce81f3',
  'e295b3cf8a6f7acc66ccc18d821f3f8026c9387d',
  '8ff2b6f10e96ca3924cde247ba83ccf8738660ee',
  '7162e66d681e177a2f440b177a2996e51704b3f6',
  '911b84fb2b475267aee2dd554f450ab4a551f3fa',
  '3267075043ca69bee38ad890cd327f7b0011e982',
  'ebefde2695c656ab033c4571403f2f2681b27451',
  'bd8e089fe87ce568fa0dfe797921cd97575d3d54',
  'bdbc4d4c46876be6223df44ce5ca1d0e001659ce',
  '660dc84d110844d66dde52528b954a2cff33b8f3',
  '934e8a3a0b35f044f41ef571c3ff075067a646a7',
  'e83764b7e92bbc80f65af1944ed41554c9b65ebe',
  '02c2a2608dc44dc41e8e1ae97cdc600d75babe07',
  '53e8823b5e7ab5526c6365bc3b0567d13421b1e3',
  'a259cb37acbbf9c9e24f189849fef1997992f4f4',
  '5a2d8d1630575cfab4cc8a13a7555268e74a9926',
  '39d21290219991f0abec4589a3bcca49e4237b69',
  '50b9d66ae2dc10b83c8aa50d5216d6ab69832938',
  '50d9e0a27c325e0e2298437766a5af3354f354f3',
  '6f2f022a0c088eb590c28d11cf46a133f9fd28bd',
  '6e6873ed7938052c9e79ae1f3485b1260ef1c076',
  '2acaeae92a30560654201344f4a8ce17b69ec27a',
  'a5c930548c440f55b5ff085d2f4f896c0b3d205d',
  '531182f1610351286e14babead58d6b7938cf808',
  '4257a8203183d71e49fa182dc957481c3aadc160',
  '20f34d82e68153e5b32f8594e1099111e514dee8',
  'f5d89a743f97135c3278bb4270038033900ff464',
  '03f0d1a4ec4f00f01e2b9e5f5b08944aa5154ec0',
  '0c0dd2c4c92bf9e183ed66b4b26f25042f30c1ea',
  'c6a41891bfb189bd07fa088847e8e5b9a3a8d917',
  'bf409d9519e0be9a0d3819cb3faa645daaef7949',
  '3ea55a9480674651fee8a09bf79acf00406a955e',
  '06f37d21ec260e1b3cb105ddd06b3ca9f1431081',
  '24add8f6d15ab15e39eca2a0d2992484f106b572',
  '637d65ca3b7cd04491856a51dcbcb2b9dd4f5759',
  '7ee788bce47b8a682a3aa90c623ccdb684e9ada9',
  '9a384219d57c0f6204a1a8eb3052016c999226f2',
  '99d9742626fd752c4a3cee64450b3c6867573ab2',
  'd577be7f30374e372c8d30604258d926be8604fd',
  '3c0fd63ad472555c5586e1a70a1a00ab1a28b828',
  'ae526ec84b1d1cc7715d1b99136d8e4acd864fa9',
  '4c0c904b89a7e5fbcec61005f816f9843d339cd0',
  'b844c89043c7b96003a81a428337120da35b9f36',
  '12f63af368f57a5c44ecbda3751fdc49d7007876',
  '4bca135984e15b199170582cf48314d87422336d',
  '69509613321412e31fdf98b1509ae94ba875c296',
  '749c890d97906615c039a5ad10fb22e292a148fc',
  '20a53cd46a5c1ab91fc5352d87c96c25b7d1be7f',
  '48b6f875ebfa643cb5cfa6915b72a4315baba3e1',
  '0323ad6128375ac4242bb02483f23f0feb44a47a',
  '8c3fc06461ac3eb1c0a7bbc39d03e7ef24738c76',
  'c4350cc8ca0d5ec9cd8f549aead857426acf69e6',
  '23571f1d43cd1e337bdb896849edb3e73713202c',
  'bfe6d94ad7ab9141ddc041ee6d93a28336121349',
  'e23fd18ce2354e519ff940a66068e037ddf504aa',
  'f0aec4d4df04274f6a8f35490c03557f51067462',
  '287db42bc66a026a32a16b8be5b18d66b6551d23',
  '1891da6dbee4be395c21189d75e949c7cc4819b3',
  '7fade028788557d87feffa29858e3d68055df957',
  '2c1bb2c6146313830131e08365769f6636db7a05',
  '53e6c4250cb22894613c92e2270ac7c0d1a849e4',
  'bfe215726e15ab62da9565c75d7d276e4b076094',
  '6701d6497a2a4318f0724433dc251c5f093bf04a',
  'c40ded326fa6ee82c42b2b874dc2ee4d8aeac35c',
  '836c7f6b8159ac88766a6a1825fdef0449cd6540',
  '033e54ef8875557aba18e7472be79cfc324a4ad1',
  '1b190e41c3c3c665e930c0d40edcd41f91c4ae1a',
  '0df352ae89ac4d006bb25f324eb09ef4c92d62bf',
  'f42303e9bce4d982e16c34543f964c42966f0290',
  'ed0cd4b4f92fa7736748e9f0f7350da9d29936ca',
  '0e9d335c8299b0e95f2eae7fa687137fb29568b2',
  '20de61f308e8ff9fde8a07123679f3e55a7022ea',
  '19843375cb71cb5576754787ac0d364c79632120',
  '7ee31ef61eaa0098e8628a27aa527aebf8796d54',
  'b0b4a59b1b339c759bcb4f47eb9992ece37bf27d',
  'fdcab05ce10ffa40b991c2ff831eb65db55e03c6',
  '589a89353d7e34f99f206169c75e962c5b71ff1d',
  '32f84aab1c6618ff489acae17e82ec719f2c94b6',
  '07a1903b018c3d73ef37f0bac5bb8943997fd6cd',
  '7e93f0edb0cb63e490931b1586988ccb63ff3105',
  '5fb4eb449d2c2c227791ffc735e6a88c271b53e2',
  '6913393b21cfc774b12e1f08d853f00d37b8a26e',
  '16fc5de9068990ed1d664b0700f26b956c65a508',
  'bb04b554b910d54420680eff0cd821ec03ff455d',
  'c6926f3245c318da9366a1edfb3fd8e3e47cb663',
  '87ec3bc5c2481457354d663d2226e2b368fc805c',
  '33f208cb0e89c6ef9f71b94b2037d76df1aa1d0b',
  'f68750cb3a9456abc6a4e49d860843b575998194',
  'a9d4299fb132de5c30fecd5bd70343fc48d4f84e',
  'bf064371adf58556b82575bd64f1e3169661e12b',
  'b3b06a1c3f3ea94727e81a8b1639e4d1fdfbdd92',
  'c97a7f533056f6d76ed401462f85b8b42969279c',
  '1138858adbfc281ad2de65795edd32c1589c2582',
  '266b828619f86d95a1b31f5fc0232f7c46ff0c1a',
  '4c04c6af7aabcc76326ce32890447bba07916e2b',
  'e4d3a87c3748fb73df36458a319d5cdb2bf492c1',
  '1bdec2510df405735e07f468df12e9c97b9827aa',
  '8a0c79558fd1ce00da932738ec838bdc24eb34d6',
  '180db3b7d2a05e7eed6ab064467c8f3116f28a96',
  '0cad1dbe791fa01ac8a6fb274fff2f271bde6fc5',
  '279e1ce07bcbfb68f782d725ed66d368a6d7e847',
  'bf834e61ec80da42b866ff5fbb3330bd49d5bc4a',
  '4815b3762c721ef7f12b1ed0024c577009873666',
  'fdff7980791b29b5d705a728ed07e10f3cd0f69f',
  '5c1558aef3c5e511ecdb6073672de03840353610',
  'c747348f40ed0cb4a88d25c2637c4adda83dd09f',
  '726e476e970b64e481498b432fba463e39ee26d6',
  '01cffcb8856cc8fc05b4ed2115f425e1d6dce3ea',
  '007083786d228e0ef89f7b3265eedadd9fb3e32a',
  '0c00621625e4d6bb2e8c11acfb627b66083719c4',
  'd03f23e69c89549f0b89390732d0d9e63597a2b1',
  '57f10c1c7d69c067fddd75e6ef2f1a42510f0550',
  '9f74acf95eda69451065d426347d7061907f6225',
  '0e754d57e78352c4bb225d365be025ac145a7c4c',
  'ba8c3c3ade830f35e953c8c5304cd979d2c38f7d',
  'cbce4a20bf6c17010840524499995c4d43f2cacc',
  'a807a64db25bd18d059b45610ea3b1d2fa7ce0f7',
  'a50545606be7385399c2a4e9256895b3ebd93772',
  '29cb7384a74fe29f6a89fc1a3c0e54651fc49534',
  '7bbd037cac9de0653ea81693cb98b76b365497b4',
  '82a8dfdaae65f3bed102c61706619afdb5620e43',
  'c51745b0aeaa255310603e394079bc8625589736',
  '193891bf0388253be8f366fad49d9508df066bec',
  '617718d086c97bf1ebb475bcdc3423c098a38f7b',
  '21a4f990169e3014f35d339446eed0c626189626',
  '13c6f792339dda99d599c83a026b299a512a2c97',
  '79e906c5d89e4caedc16a7127aaad8cf5bb6d17f',
  '98b6567685dc1721fbab063faee2f3e732f129a0',
  '27989c713fd0ca789e9b79d836602eda851827e5',
  'd1443dca58b196f73e25fe4d4b791a8c047ec9dd',
  '80e271a7d9bccfb623f8295cb0f4e8827925cf2f',
  '7234063d07fee4c17d1477844a4bcac2a76cdc0d',
  '5eb95509078bbe7fac0315c5c65aca71ea8eea9c',
  'be28964bcda0e7244f2f50581e53036e1d6c8dd9',
  '61140e25746f8881428e999fd1cb56ec59966b4d',
  '61ff1ae9aa8c28d3d127c7e05a108b68f9f8095a',
  'c02f1e204c93e9d3d428a0ba9745ff112fb2ce11',
  'b86fe48542af7dfd98138b1d4a6bc34ee934d624',
  '529bb2bb5eedf26378ca39ebc2fb996153bca531',
  'daaaa015356614e041e0d960ab6e3cd8e0f542ca',
  '517e30cbe1bc6dada213bde56524b6f890d94ab0',
  '29fe5d2eae7182d535252eac057e047253b4ce32',
  'ef74016e88420bb7e6162b0343a4355fc524a7d6',
  '1fcbbcef8b0e08c8a0c5264c6d03cb1505ad06f5',
  '7db4be26b24cdd5ec30f7a7c053c03bad5cbed3d',
  'c75c2433089bfb2edb87c75c6c81916508186cbb',
  '8b6a9c003dadce6ff197f5588b6848e9ea81dbb9',
  '6432ec5735d34706eaba95dc6147da5a5c426076',
  '523d383a5aa501c4875007d8c7bb0c9005a417ab',
  '4fa732b2050917de387bd2cce06a5b7d93858c69',
  '3883f398c2af43311451a0cbd9203419bc23e599',
  'ecfe5aa6407f396519de0f18d3f7c2075d4e9f14',
  'd97c671aefe425fc40ac5802c3494849af979de6',
  '9d907e92f8b6b70bf74cdc9e3a04d84164a957b3',
  '8660ab350eb3607cc9ec85dedb331773d1710fb0',
  '3ff5c85ca048b332af9452bf1b29967dff46cf2d',
  '3ecc7b22ed1cb5a8f774247a44d49e59521fbfd4',
  'af02bdd6515c4b1f96c337aee02b6bfe3c65f6b2',
  '66e7ca6d484788271df5edf8f3d39684556a7bf6',
  '7fb4183f0847257ee334fec791fd4f4f97ceb191',
  'c65c9564d280abc6cc466b38d76e65c7237f7e06',
  'bdc3515d7e2c92d17b25dc5f8c9e6da3d4b79802',
  'b28da8cdf14c83cde92c1faaa4f216f152b55da1',
  'c3dd17314a846c29e3a1ff20bb966c37c300dfde',
  '312acdd652daacee39d00df1930b8945880bcdad',
  '20927b08bb2c24a5cdea8a582f8440ee1a363f5f',
  'cf8ed8c5d252f5f08852222f9b80f40c1d3f6da6',
  'de33d30e1d7ef04c132d272f256127a112b83320',
  '2c40b4b7e4baaa6557af19d698f72bbed203bf32',
  '13b9951bad1615de01c5ff03b803045260d9dc8d',
  '1b18e7097c7c222c0d3ea48db37f420f70d53b9e',
  'dcdc22396584baa6a3feb016f5e5f34a58bb085c',
  '7ae55747afb67a8339047a02f952682f1a6dcc08',
  '15b8b5b7ab02f2639f088d68224a81ad0af8d3b5',
  'd273a5d2b57785d19d4c123255bc54f9e45f7e83',
  '4b5f18aef4cad5c66aae009166d9cb3d5f977747',
  'a001dce4f6b5751c69e383f0064647392797cc00',
  'ee2b666476673f002a468bab0687280444c88005',
  '989e275fa74c8e9de242ec8ae04fa273babc5928',
  '59827787667278d45473b0debf2ccf3c2e46c654',
  'd43eb334e741257606a03cf17573e611e9204220',
  '3728ab82acf2bd80fddc719b414c84b078c99d2b',
  '71fd9fd8f0add0ae9239464d0735f6107f1a0716',
  '3c3fb386d6c3cc0d62218e3868b413e86a8896fa',
  '49849440503dfbff67a8e07c50d36c279d73b559',
  '256e21287075e4d6683f4b8ff57e50e3360223df',
  'def3e0b974d4c6180b64cfd20abf18b2bd794039',
  'b35b55acd6c43d0648a54eb0abdb780fa352a69d',
  'e7186b69373374bcb41240735a27870590f986e7',
  '96dbd0db1083d25626e2d32e47e877e8b2bf0c3b',
  '5db22829f4948792c2f683b28e7f5fd3c21823b5',
  '9b551ad08ef9a349e44548419a1c1d8680b21d7f',
  'f786846e2774a5a05eea2476648d62bb2ace462e',
  '8a38edabe5924fd99928337c44d757f3b92d8751',
  '9f6aaa6bb93c3263f6763931ef0f574b54024485',
  '9e620cdbee9599b28f6b0f667e145ae5d141f9c9',
  'cf344d93de209341c0cafb9b4b1f6dc7c86fd62b',
  '7ac4bc71ac2730964e210ad3a5d213c9af11b386',
  'b690883f4378078aff1f62cb941e0ffe0cf8fcba',
  '896a47aef27115af1d77b4b53f97b98baad3df20',
  '9802a5ff12aa9092ad420de51fa83d2d034d61d5',
  '5d7abdcc8ac6c49fe37246f58f968f660665cbf5',
  '70eed180ac39b24e8af44a081cfde749049e15a3',
  '12b299ab2b314567c501664638958c97e815f683',
  'e3852aafed23032f4afa38040463d763b03aca0c',
  'dd7e126bfd62844d8b3375c4d1db27fe9a07db56',
  '3877e5c7c0912a1c911e6cab99d401f72488b613',
  '9d623cfa95fbf83b326b1954c4e51b9ca2ae3542',
  'a1b2fdaf432b470250c6711c9066eb1243cf032f',
  'd8396ef7b6b2be7e9c6cbe0ac375ca7e392bd4a0',
  '455a2d292dda18bc0387a23eb99dbb99286984a2',
  '37dd37df31da92e789b4ef45c6ad30e6db4490dd',
  'ef6afdc779bdf1976cc1c49b554a98d3fec6923d',
  '799e889a7eda568d696494cf2afc3b50a6be6b71',
  '2415174d49fecb6df2e60bd8f7c5ca9ecf7f7c02',
  'ab49bfeca2555066c48fa37d59a2bae8db42d2ea',
  'b2876db0fa68934e0a8f2ceb6a3dfb081b4be90e',
  '9a3f3fe226fd8d2a914d63abc75aa73af81386ef',
  'b6aa692044b35cc77dc2d92b2f883c078ff3fb9a',
  '9e6aeda24771a0f7b69e5919627b61695ee8628f',
  'd19a1eaa58bd0a9767b044c3330ad0d9437a8808',
  '7dac9d8b683cae6f42ebb4c7d572cde0fa664335',
  'ff975ed63c3df4015501c3cc141d4ca3dd7a0547',
  '14f7c9ee194ec8d7ac97ee5f5795ab98cf32d9f7',
  '3a433d3a474b6455021a85c1cb68c892280aa15f',
  '552b67d14257dcde8b447441dd3d4d19861476d4',
  'b53b1c477e6d4b4fbd9d8336e8a562ae00a0b882',
  '2f60323c43b183ba940eacadedd7604b505d894d',
  'f8f5702b3d3bb239dd693933ff73e40142ecf323',
  'ceee82e3ea74ccb5ac39a79c71c65b84a2dc2573',
  '1d2e206f3605164069ddff811c3af9222b7afa58',
  '0d883ea5f45773171eaa04d8e46098b6f03f615a',
  '04f59ef744947850f48f71c269b27d50398d26fa',
  '5cb7eace4764a1e3ad2c432858e872eed0eca123',
  '371190ffc235b150c7026336bb5c09831cba490e',
  'a3b2c8a79cd4deb9d8db6902a3c065c32c0d3ea3',
  'c13c533efd5e8a6317a22605f64e192f7aa24227',
  '2599d58378987251eec3a2c31b1756075a6835c7',
  '714e0ff432e5a7166f2347616679225743869497',
  'eec10d037a1fce39ae3bbad62b93da45aac0fbff',
  '078a6787ba50fa4c2415a02df77ed1ff10dcde9f',
  '6ecb5b1262df8f2e8adc4ffc5da978f0ed3cfb3a',
  'b3783d482d6e811e5a39599a21a41068b9e58086',
  '0a9c7e9cc33f1405cfa5d4bafe570f792a9cd93e',
  'fa36369fc292cb81407e45a8f801850f8d3cb1e4',
  '6b26cd782b5501ff36cebafebf4ddad8bbefe5b6',
  'd8bfa59ca065e98eda0f660d61a55dcb07ed98be',
  '4e65cf9003f8261a6cc9aded205f8293988c7821',
  '53fcc8b795e7270ba1cf2550bd4d9603d3fc7352',
  '876f74063a2ffc8f00a04c7b4a46b004a5e90b45',
  '85b730af9a9b8db165a82f3044b1da0bf87cb3e3',
  'e024f2225a228584f605db176351c56d0b0335af',
  'b453bf4e8da234da5b1d8dd04578352b49978a24',
  '09409964965385855c71b101a8da13e35623376a',
  '1a2ee6594068d2c2087de7e61c4c412eb5e3e3f6',
  '25cd434ab2a0a794530d37c6f6418c22e8e6dfdb',
  '3da7187b89778dfb44345c951a88e24ed19cf2cb',
  '7639cfe2828e9493104cbd89b70630732a042d25',
  'f8b05fd01f8d832aae35338cf79d8b51d9da6c0c',
  '436b0e6f5ba48fe8dbdea5e225e2fd23dc7496a2',
  '4feb56ddaf0c28dccc49e687c026bfffb5ae9afe',
  '7dee83fdee865a7d8bd99f007ca81eced01cd8e5',
  '64a09093df18844906cafbaaddf98bf5705517df',
  'a1dc15ebc0a7dc5578d84db3506b66ae8db56ef6',
  '6e0df398350394cde69b47ae1cf5bd4dd5b11a3e',
  '2a61c1d6712e5e36a21dcb1ac593a615682a671c',
  '2880fdf91c84a87528b8af1f9e234ba0c3156836',
  'd5ec9c4622b88c35310efcd5266a5e387fa0e2d0',
  'ddabffda3d212102d7ffd1b04e66a5b0266de058',
  '9739207e176a67caa31499afe9b6ba1a241624d2',
  '03efef9088e27af309655537f8e21d97b4189ce5',
  '47349bd609ec7f3a3ef3850082a70017784edd65',
  '47447af13e4bc96c143a500034a9182cd569ad07',
  '617886394bee35c359766fb4d9ae889b8fd39079',
  '0298310ec31a7132e37a8be78288abf9a2bbfafb',
  '0c531d72707d94c17d7f2a2c928fdaeea458dd63',
  'a336b96b17f944add5c6273007fb47a0c4036592',
  '6eba9d3707d0467cd3f18744da1821660dd847bf',
  '0686e45c03a5deb99eaeec8a8354ee43b33bbd92',
  '02c020b2fab7dd1fbd175c3b22947688c0a219e5',
  '0f272614686cf2b70051a22080ba7ecb48673fa2',
  '315d085800c60ec4de3f5444b275ff9216a54e4b',
  '92ace3f39b53f0c2f6e3a144de87c43f877fc37c',
  '02a52f712b0ad017d1a48b009ab33cd067ce6c84',
  'a010a492efce5004cdb41a8ec297fdc6e6085d67',
  'c188b549d0d6fbc23f8ec9f1abe699d7da9083a1',
  '84dfc06e6d471f6c120011c57332f83a738f1052',
  '0adc398bdd19cd4dd16b59d81a6eb07c6e7dd36f',
  'dc05daeb651eda829c96f06b78a35fd67a1c2d2f',
  'c540a17877bf4b47d96c6f1f5f0c702db9960244',
  '650c1b659f57e150ca69e4bad4973ac2d6151e50',
  '412bb061e08c29d46b84dbf0f07a82abb0887ec5',
  '02a6baccd357c6dbbf09ad3afcf3b125ab783ad2',
  '191ab077118b0b1b61e550986f43bb0d5ca2c9b6',
  'b3378cf1b289b479832959319afa30ead1c754da',
  '0f285c11f3439af72a97d6624583374712943400',
  'cb770b3580d7e118e29c0a996d4f9840d9180efb',
  '02f83880ba0ccc39b9b3d3c42ad5cab866446a79',
  '4e37a47d79951df2c59b920353e097e6de3abd5d',
  'c0896e0d148894f0ce5c8e0cff0458135e391fbf',
  '3a96d7da8a3aa0c2a3ddc0594ed95841b6fddc95',
  '29f852477c21e8a5fac50b7dc35a732af4a4f095',
  '3f88d042884788c95da8c6dd142fbac45b9ead71',
  '709bc4615b280c7ff98f25e5e961f6ca5eb516ae',
  '8d42905058b525c96e0dd14a3c17788904fc4d03',
  '504de8e704d72f5090e16836487045e09321b4c9',
  '2bc16d0df00ca94a9097921032d96e7aea509255',
  '700155835d880af3d2081f3ee65e28bf45f7db7d',
  '8584c5b42007b764ca77565fa16f0abe5ccb4aef',
  '3c3f7c8f09f4fdbecbcca86712133c794b5d5618',
  'dbc4376bf1cc88ef923ec06315a24c86ba21452a',
  '5887e53ad517af06dac9e5ce813e20c2ebb6f4db',
  '0637328f61ce508374a0677036b648e9622fb962',
  '40869c64cf7d455f9b741fb769b12fa5874921ae',
  '0491d014c59d033fa2992d1e02551b6ee64737e1',
  'c81314e62ad225e51ccc3da004e9af66dc1836c2',
  '9701e55746d395d03d30ba8192bdef081c439520',
  'edf748fbcb0375d4a6c05eef40cdc4785f69bb61',
  'bcaf4c34eb3e80116815de37ecd583a8106d5542',
  '5244afa9ff8f2bf72fd8a8aef98cf40a4c3b0e21',
  'f417fd484d5b542c82d3958189db80fc31957651',
  '1ec62b359f12547cd1f69a4fa4db0eff37823e79',
  '910cbb74d1adab003e8fecb01b61cd7901d2c4b6',
  '05219377020ae4692a4aba51ec8b94c0ed199c6f',
  '9e34f8e023006b18c4d3f03bb8ae3b7d76c08260',
  '27876c1f8f9a4ed86df7a7ba0fe02c6717e6c8fe',
  '2ac3016f84aff02c30ba392af25f4949304020d0',
  'd14771a5c64ae539870852f9fe4c9ee81fbf96ac',
  'f5a4bdd27074abe8e107a9c21b752d691752f049',
  '5772d1261295d7f1a3020723081b89165911fdbb',
  'a28dac5eff6acedd902ced48816f64c816da7d4c',
  '7712d7981bfb2604cb4c2b09f9962111c5918560',
  'c4af55eaed5adb62528a46471a8ae271359094a5',
  '632f3e0458388e6883fb0b3040bc261c90ac9ade',
  '82e73afcfebf5dcd6c2ba2ab8bdce9bf6beb27bc',
  'a13d26b0c314086f5d5c775bd0604dbf6fe57c22',
  'cc65df01a56f114748dba1c82d3c1e4fa7bc1014',
  'f2713ec13a513c25500da7446f2637ebf99b20b1',
  '18fc95ebdb5602525b8905841f8f8622cdae8642',
  '4f838b51d08261777a0b1a42f5a53f769357317f',
  '45aa497388fb8bc26daf8b235f4d63b84825dfc3',
  '02c66f27484e9b92d511a7ee041a44ba91497c53',
  '350ca7d2916a98e2797c69dadc8c1d3f6417d49c',
  '29582d0deecd1bb1045ff5ae1ce6e1ed9768b1be',
  'ea664c815282fd9fc4b7c1dab0a99525edbd2913',
  '61e2a5eaeeba4553b60f6f71f31633629e8a60d5',
  '241424dab3f67958ffc33de210bbcc77aa731fde',
  '42851c891f4fd231b118ea00db9e6ea4a355e939',
  '8a137d1e66f6479faa012d55ae7ed5c771fd7d9e',
  '3a40ba3c929d9b1fa0c5abfa6004a66c417a55cb',
  'ac1df84d4c9fed015a48b28eb3f3786688b7234c',
  '0c5fc6dc6f86f656dd4463db73852a6306c43415',
  '62c38facfbd72b903222732962572943b83439fb',
  '2e863bc3255a7397d68836315c18617c8f6a5798',
  'a9de7c8e2fbc5b873d7ff03df093322e69acd8a7',
  '60e87750898bef09c445c710b235a50aa6b9c8fc',
  'f2409bd3df49cebc7443e10f978c739492950718',
  '992dcf66d0f73ffcabf26b68c8ed93e8969d094e',
  '968abcaf8050ed54e1e81d056b579040858dd19e',
  'cd2e437ebbbe796b833500860793d547f3f34b0b',
  'b2238acbe4871d0f733c86a92337f21126b315de',
  '961cf6efea5b0caf4e9533fd0dc3b68586e1b2ed',
  '7509c783e95f9fcbb80cffaa9d56254e63df7b2d',
  '63f43dc7776889fc28cbf0b6e32b2cb29657c95f',
  '637942331e096395f4fe3a52abce7c15ccbd2975',
  'e718c144332d1b7719877f5dff414d9795f8710a',
  '8f064e9184633cd471e25051740ef3607d9c5628',
  'acff8782b30640957610943e49e3b51718cdfa12',
  '40bd9bc86f85094c12323b039ea1630a16d9e9e0',
  '963ead6791ad82c052715bde7b030a69a9beec38',
  '5da87398ea4be28a0f1056ccdf714a128f86d7ce',
  'dca812d0e2d5f0e348d7da9680ed70786fb8d9df',
  'd6ae6ff49205c60ac7eb4c74f14734d6cf9de205',
  'd60650f498656d203dc417aa5d9035114e84215d',
  'd021e776403d195611b31da4df9ffdee4ec45e65',
  '5f8818ef9dacf99ecb18b28be3d4372176bc69ec',
  '4c83fd6c592aa0bbae3a2b9dc14dba5248571d8c',
  'b2a47d023f8fae59192e7914f446e475d17076b6',
  '71575289bb817ff8fdba98dc67bbb6f2b56d8ab2',
  '224008202ede7064cf88b4fb29fef3a7664fa7a9',
  '051dad12d797965344bfb0a8fd39cf26b27f0d29',
  'b384b2f47f01a2e482767cd863be222e9b0c3ae9',
  '9c32ef8a64f49d5064c685d82a4d286e8c2fdc59',
  '1232888c115807c8a07de3c94e2d2401b40cf5e3',
  'b185fe964f09eba1716aad947cd23fb0a12ef47c',
  '4740c75f80e5e89c024acb263e4cc8035a36fe51',
  'ccc0ea9a1d00334f4c839429e7f06bbe93d444b2',
  '8839b0b89fa1f5972cd36d15c1320595bfe17349',
  '3601eb6c8ea89ec25507364f86ff4633d85e3908',
  '2617500052757e5b01785ade390f1109ee73779d',
  '4d5dc108a1c68c3c957991fb57bc250e0c416f41',
  'cd4830321ce18ad600bb064abe74f999eb903679',
  '82abd4aebc29d024b352535c1b6dd9ce22a4c97a',
  'cb24e87a6af535b102c311159bdf179c0c220945',
  '78b2b49329077913ea5c89a9210087e229b53405',
  '1dc52a19f6bee1ff3da10edc4498f9f2856d32da',
  'a36c7c593aa51feb201a1ac43d732f9b7899c86c',
  '975c9a434468f726a0a4da105c2f73decba8c293',
  '18ee36affb6fd0ad0875cf3dfed9d9893262e1f3',
  '0dc8c409e2a1e9b984db9d7ed8c6e883e9791932',
  'c659e230b333325bf94ea30af2e69fa2464b7a38',
  'd8c81d1eb54cabaf2b1475cf563fa17cb492c774',
  '7b4df537492dbb82798c7b42fab1a1c67f660a6e',
  '9b60460f8264a09c7e44e9394301335d60ec9255',
  'db672cf848b85e438a1d41a6702b1e36783ab3c7',
  '7a5d7380b89ded9a3250dd6767faf6c851f3f50f',
  'fe221223aa9b3f60775c26274db5251fe7549505',
  'b7bd93a45356a2779723345105726fe9eee79966',
  '01b9288a58dc7afd55875ae0bd1cb1802f0e3a98',
  'a4cd0270896a6af25c6acee23d4f24c8739615a4',
  'a3d38a2754d2254d0df494c7dc98e1065771a270',
  '19b6a8d22c04afeed14b3a6feb43793c66aa58c5',
  '96a65d7e75219b45146b9e1bdc571e919470458d',
  '6a9a1271b780201826bfcc2a4935374c2a7f0af3',
  '98e85dee3c7c9cade204c475a793597db5dc3f10',
  'ea26a6270f60b023ba17f990dbfdcccb319d614c',
  'f2b8678d2815fdc2f60a52a8d13bea06d70da1c2',
  'b0dd2825bc267c6ba10d4ef92c120b61e3eedd97',
  '1732d1f0c5e16d17ed3e9d4c88a3801052fef8b3',
  '7c3e36e25169bc6e4cd67938e9ed0336da3b692a',
  '9fbf1d902ee6227928f4803bd3c9b7270c00d940',
  '4f91de94c23f52b66a609646f757076232e0c97d',
  'a12d28f3210961ea34d787d253894d0dd3ad405e',
  '3a6181fca5ca19b2f47d8abaeb75703c3f39ab67',
  '92124e0bb0c96513e5507f7cfe84dc5d48f4b4ff',
  'c07ecad1d8cf28fa41e625d65bdc74d90363743b',
  '41bcf174f879921e34f8d5cff16158b62db02f05',
  '3b65f3d555326ed6e6db82e37f5c0eca72a1ce85',
  'bb9d79e5f76a62ab2bb6905924704bb9a701c486',
  '942dc99b0c9d192c68177afe6ef87cbfb98fefb2',
  '389a5c2483cb806c11726d86315889b2e77849a7',
  '2df2d0a3e95c6094d3a4b14cd313e95c407aff29',
  'b5d63e74191c28d14ba661523a5821c9fc1587f2',
  '4a1e15d774fa88939443d95cf194411652b7ea25',
  '036e192aabbb130098edcb27d9a9cc5a35045a1b',
  'e2e23e806315ca3e0f9d4be7f38d2e69ca4f2009',
  '1858a1f7f815f9b36d77f83ab978e998fdb7540f',
  'fd2d5156f26e8afb4ea523d32b93376a40a12fba',
  '2ce575c21d732b6101a349acc9a61c12bf0cb8ee',
  '17bbe08be438f36245c52be5bd26fcf88bf87e19',
  'caf17abf6dd1ebfb52ca6c82f4d5d76eec751aac',
  '0ba3cdaf35534ea5c8effb68a9b97f7313905038',
  '84d4cbadf7be138e894334045712ee0347ca7347',
  'f600e968c78289e1e593705fe5b225f0f4a377fc',
  'aef671cc112e9044198e457e9b3a5cea4b9a188f',
  '660ef9d8ffa71cb88dd68bb7350326955ef2980c',
  '828bd3d9f83b83fd0424853b29539581826eec3b',
  '13db08133121ea0c9f3a6fc895c317362761f151',
  '7fdd5d0c3e8f7dc08f8d2ff4f971a0733347c70a',
  'a019f01060952670956b2664b94f9c03f86e36aa',
  '87b00f6c94010267542fa1b36e0df7698b9cf05a',
  '2cbb5179f5f6615ad4d7abb8ebe1b503a3b6e05e',
  '412da60f4326a20b2b5575183a569bff9c547caf',
  '56ab02d2c76ee9ed53d724b8df6b22d379a3451e',
  '09ec5783e3a4b6e0b50ac6c898941e17e57f18c8',
  '554f93c8b86a8de0d8fa78c62d87f68d7eddaeae',
  '4e60c051579f9b5ac2ae79793c18745b3736aca0',
  '5bfce8444ad5d42efdc1cb6a5dfd99dfe6695e7b',
  '80dc83d453ec971ddbbd32e7d21450ad630665e7',
  '4d25bcb2539ca7dbd5c83a6e27bc1e52dd6c7a33',
  '89b98c19cb1617e18c605762ecd4fff9547b448a',
  '80abe2a2b1552aeb27ee4a618d5dea0caff06422',
  'fb3ac6864ad83c29af00aa3c44747cba306b0e36',
  'ef917bb175786ea002e9b788333ffb15d975b5b5',
  '6687ff661aa7f7eeeb18262a1358ec673358d51c',
  '02c33f7310a71eb06eb67469737ef8afb42f8788',
  'd1249e16fae2e0ff63d46dca4284df2fde106963',
  '9a799f5bfad9c482766ef4db885503fd76c120ec',
  'bf4c75418250e0d2c31104e69f4d9c6581c5482f',
  'b7de8c508d4e5a82dea0da006c945eb170970906',
  '2667c4f6204b46e024f9dd320026ebbd8bbdaf81',
  'a7ee5b464e736dbf88bf1b5337028a439ff77875',
  'e2d6297e529f442d54591162ce27fb18549bcb57',
  'dfc74021a0743d5bbfc09fcb73970a6b280bb1d3',
  'f1b4841e7c292862a7eaff4ea0c0bd97ac1323bc',
  '3c73f1af36f7b7bc9d17656b2372f1345ebc0550',
  'bebc2b6b3f803cdf337c6b3688667e5cd992bab8',
  '740bfd25e9763a4e72d83bb0ab947931ffdc8861',
  'd18618218d55b1ceddba278693fc7028fb382193',
  '798f3784acfbc050a67d32af14b45500cbc5e03a',
  'd48dc74204bddf2aad1c88730fd5e6f1a6f4e4e3',
  'd576b74236bcad58b3c769bdce8808934fe85c97',
  '6e428ab88407849876a39dc80e9e9b6cf5718181',
  '222ebc0427471470cc3d6bbefd3f35dc32a9f388',
  'fe6dff95e29da0445462ca04daf8426e9ff33078',
  '9b898b44e81eadba21e84948e45003c8ee8ca147',
  '462715de203caf8af1be06822bef2a50874c16a0',
  '7e28848d1a7ef371b6678dd90043e3bedef5036b',
  '840f33c2092ed8c9d0807fe348a63ea1ac6fa395',
  '065a3c219631160f115aadec26afd7299d40bde5',
  '8f4f0ac421f78511100b3d0bb0ee78a2f809cda7',
  'b050888afad4e2ac546cb760259eea45dc88773d',
  'aba0f1f1271155e3ad462bf4c91c991a1ce5919b',
  'b16677050a0017d1bd384fd0be4ccaf670a1f9fd',
  '453a4b48cc6f06c7b2c446b07c4080153c504b33',
  'bf7af7e8c2455a4ac9376f71d6a28593c9e47a62',
  '7bf39e6c97a9dd254bf506b8cef6513928debdb1',
  '55fec569711d7d6f0cb64d8ec383c797ac3963b3',
  '14a2add8a64b077c84d2d576c80905f364bd182f',
  '123f572c930f0a89d369197ca1cedd257e69ab90',
  'c28f1840160905b885c8620bebb77ef5ff3bb501',
  '4dd18dd8259daf3a20768e852f373cd93e9db217',
  '4ed49770571185cb92aa763a3e159eeabd00116f',
  'f3f403dc1af2a1ab747787aaf31eab37399fa782',
  '0db978deb8eca32e207ddf1db648c1a400769b92',
  'e10e00c2eddc2f9046d426b11cb6a84472cfeee0',
  '2c6aee60b2dbf26de95276e438ce2080d22ae91c',
  '4fa19b260b3509ea7315b5b9bf73183691bd8cb8',
  '55b9dbd9e858ba16014e20cc6362693b0637a17f',
  '756fa6f9ddbbb08b6ddf7e92562ade677e662ce6',
  '261de92aab77ec79ee81313c3e7d909158796e13',
  'bc97a82100c4eb8329185bb0af58fa40445ed0fd',
  '6b0a1de0c61c43851d032814e49e3ee8cb4fe92b',
  '31ecd7f9d12cdac3ec107338f44ba4dfae756471',
  '99b653f3fdddfad2c4ea8e6fa1a51fbf7f58d19d',
  'a2ccdb8761ad0eda697a8b2d1bd636a5580ee1fe',
  '09c758afd65ceb5ea51ddbf699c820c4a6463f25',
  '33a4706240fe281eee6b7b9275725e4f55f4454f',
  'edce213825114e2b4b184e60ae3586e9a79304ff',
  '1b890b0a1475a6ec47f38e5624c8fd9041c04ea3',
  '18f18659b6519bf84f595e62b925cc59dd4b0f54',
  '161bb923b00b39c4f959e8b887be66ce00988398',
  'ee50912572a6433da76c113ddb7b0504c4ea009e',
  'ecf0087f7578e5ec9ed0c38c67ffb5434204df33',
  'e0bc79dfc652db21c348b934c4cb7c8a07a67f2d',
  '2a919f162a4d1203adc4fc249172ca16093e2502',
  'c4f8486ae3e4b391914ed52be24d953b5400fe25',
  'a0863142803f8e08027879f6a9d9b532211dbc1c',
  '4438324c8a954cec99f1ecd44e78f8a9fa4ebfbd',
  '53d7165bf3f4dd09216a5c5de631898757d37040',
  '7f9172ed116846128f7d5b7a9fa4623d0d6f370f',
  '8d4900f80e1df038786601855060f09d4994ad3e',
  'ed240b5148d7ac3367c050683c5dfe33d321a403',
  '0f362f112d4b8e2dedb0a1bc0532bd55a4bfae0b',
  '97a43cc32f70174fd1d9a3625666d40d396f2a22',
  '0834c2ee99caa63d366be0487bc1bdbab8549c60',
  '2f485b95d531edf8591987e9c708e148d27c8ec4',
  '7975575c238e75646a9bcbbc16698beaaf85b4b5',
  '6926b1809c6186b4568a00689179ca564c7b363a',
  '27cc9e0f05b420741a9d55340c11fbb486335221',
  'cffa55e6938c9db1cfc914219c1d791c51c14638',
  '564224a8c78de01881f30a67aa474f50f7c5f150',
  'a0af5c76d82dc7c7227f3aea3df9f1666a456102',
  '056095667497f4af00bad3c327f5dc54404e2aee',
  '98e17c9f62aae7a0af6a14b21526eaef6d36feae',
  '258a35ad9bfd8fb300d0216ad709d4bd29a33076',
  '175bc52102f5f87b0e456ef634347a231c137677',
  '985da85e3cac3342ad566471eb574206f3f0fb36',
  '39101d79421112cda2996fddc27ef5d222265f2d',
  '5977505e93053a3ee69d8489fd78f9f5da58e971',
  'bbc1d20d296c0bf4e50dde2f142fda125d3a21ac',
  '64081b73087e8eb6c804b9f30199e8904fb7ba74',
  '8bf0130cfd50732edc8233ff3fa61812b0b1a359',
  'efeb7f3e37f44f5f5c810fbae27a001afa40c2f1',
  'cdef5101388e44a70ae783a8d4f5346324bb03a3',
  '08ec38e1bc35528ee146e7b4411ad4b0761504e9',
  '465e400fe35303ffba2c0e63c93b3d7f82430cac',
  '8e6da8e5b571b22d2488f113f4f65eb8097a2ac5',
  '4eee8f133f8ca0d9b00465167d0567eb753f69c3',
  'c8496b84370c2e03f1a8e755d38eb1703cd800f5',
  'ba33d554d53c109f9d7a4b046b3daf78b8f624de',
  'b0177d54ef291af701d43f224c84ecd081fa0361',
  '70f913fe9f0a95d14b52af31e75845b64326a4a8',
  '9b17ea30c9dd4198498a32109efba710b2a27a43',
  'd3db97f880fb399fd93eac2438bdd18941cdf5c8',
  '4d09a9f21c8c1365ab12ff809f948e5eeda2c210',
  '091d1014c36b51c7abdcd895884a6f6fccd054e0',
  '06233097c8adab780ea7e3e37344007ef05f8001',
  '75978fd2ee178c72c2c66b2e31ebce0aa1c16888',
  '4c6df80c1ef7d29a1fcaa59b4ad3c553b199ede0',
  'efc691804306104ea0db130e727fe3d7d3b6d1f3',
  'f3cadc535df71749b5dac3ec3d7142d5354b390b',
  'd2f1d44155f4dfc1e069ccff7ea49818425f1cd8',
  'ce9ffc9b939513308dc01947996384d2f57d2402',
  '33d0f51abf3bf0a63981ea48c37dd293bec7d8dc',
  '7705b6c807f17089b5102106957a1bcb3cf84d58',
  '51cf5b18d2292d0d808cc88ccea3fea44e21aa56',
  'a929ae69bbea30df9a5ef7a4902889eb7eea1227',
  '39fd8063bca12afb0523ca7d0fb69432f3297b7d',
  '0cb2720f12e8b696159048c1576389344f1c5d15',
  '10a7b57ba0633effeb6a5bbc935369d88eac0fc6',
  '1eee3acbe52be63a8b54993d61882e0908bb119f',
  'e14c89d8656c09d8c31606d042cd842a92186b78',
  '7e554e23c20fa7e9c073d1126133c1d1895fb7e7',
  'b705c7c56ddd6d2d438f1b46d382c18d18eb5662',
  '023286f3510da64018ff6cdfa866c6f123332d33',
  'b65f96d060a4bf65dabbe7a6809a46ed4151357c',
  'db35d0216ac55ef46420b5228263bb04655599a4',
  '94341d8b2c6b36886f871d1a1d12b042b6b8de70',
  '32f543d6d39dda764804773fce38b1ac2a7c3c39',
  '1ffefa4fba1951eab742bad4736b4bf54f956207',
  '3b2be92c5be6414a4d2c16d33d505f8370c2586b',
  '38f4db327b4af27eaa5be0a970da45cbcd444a43',
  '6c7ff2c27eb1defbc9047b1fe3d3d60dd4a89f1a',
  '5f3f8320f598479ce22c0a42060e34b022486fd8',
  '09d61a634f1b0cdcdc6997c05e9316206f5df3f4',
  '12f36c3cf6a8a71cad2057b90db48ab5c7197923',
  '5291d83db023c3be92046bec37bef36791bd68c9',
  'a901ea0bce6451172fdbc660019ab5eb5682d796',
  '8c591d228fb57dcfca72483eb5cdb13ef50c4540',
  '1b12560addbdcaf7e94ddd634b90b0c66bc55962',
  '170bacee8251c5b276bb905d187d4855d5afab0b',
  '5d588711fa410c070896930cc91b7015bc00d802',
  'ba2a46c758e55cf1384ea7050657839607e4f1ff',
  '727dbcaa0f62fba4bbf4c603b1b71cdb7704f05b',
  'a7d968f9b5f4189e29a5d18a43273c211e0c324d',
  '521ed79cdec8c4658cb64abe7b623a640dafe575',
  'abad97169d2b6818a96439bb1a2bdc1679fdccd4',
  '1127c6e76deaee60d7bfbb8fe972d30677d915fd',
  'f2e8767b794b68f59280e792130d879d6e05d79c',
  '41d55dcac1b45d82720766117b930100b2d86607',
  'cd13a13a5f6e10990a75c4903cc85a8d0c1bd0b6',
  'd56a30edfcb588708e3314d02beb4afa42a698e0',
  'd28394043d851817b0338f84e15a9d846ebc030a',
  'a5ccb3ca176867ea1d65e0a9548edae4969bfebd',
  '18ed6cbe01371b3235016f2c0410d7e2b801d84b',
  '18aae7b53d7e12a807fc97bf75208a3ab7c83e17',
  'c254e73f942da7d425a8e75bf246a76edd86346c',
  'fcce5e5fdf9d89f7627c90e6eed67febd16d481a',
  'a42bd6f9e6b77d0bcc077f0981ce1771e02465ca',
  'ff74ecfa4219bd238c376789d513884fa37a4be6',
  '8d4b91b260ed60f433cabadd187d5f48d8fb51fd',
  '51d09217a22df9dd478b49e6005062fd2b7e3c31',
  '33c88f029af87b3a3f5e1869a0cde0ddf967a0f7',
  '401c3e25ac607df9c917f43aee66d3fcc05bdeea',
  '74625c8376707fdb295458580dacaf521d38ecb7',
  '193d8ebaa7bff8ac49749cf5e1755ae0c7ee55d3',
  '659dc0dfd8de5924fc150c5cad88f82506c817d4',
  'f21d15ae44c0c9912d7e15d085dd88e75f7c036e',
  '122ef27ba6e54752fd35e71438f02654f36e5d71',
  'a3371641c3825d7478569d7a60b50803ef9e897f',
  '07ebb33bf922255eed252000d90dbb4a50ac870d',
  '7b07535842ce4c902c58a09bc3b46f3d65b14712',
  'be20147b4d31c7f0375b613faf148136ce03ba1d',
  '54f86beac6e9fb0824c0d9fe7b55e60675fae405',
  '334dfb4ede5b9c0e1dbc5faa712e171b72cf03d8',
  'ce9a8ca34acd9ef2a226ababe5736aeff5b54007',
  'e4e729de65a3ce15649ee388c80c34c2bcf325fe',
  '7b8dd52fe1791c41ff57efecd06f87d06a3e5b35',
  '5494b1ad0c2b93c13e9f8d58b4ea2267e30ea7dd',
  '82a0f08d981b8ad49cd3e16dfe01c2cb96db28fc',
  '424bd28ea07ecf34da09d6bf95af1b870f792971',
  'ddd965fd155b1fd64b5e167eda0f646acb3ccd7c',
  '58d16f157cd08158ddb7652852ded2e7d365ab38',
  '4da6ad6eab4bdf4edcbd026b161150e9baa42933',
  '7b65697b0987ae6f9c784c35867ce214c1567b0b',
  'e5bb21223fc471769e9fcfdcbdb334fcc7f39b99',
  'bb67a09c8061a374284ba7cfd09e5db97e4554b9',
  '5e695e3120716a70d1718a8872b79a1251cec6bd',
  'bfaefb93793125b01b5c68fde40e9f416b319591',
  'fec13360cd0756e0377f5b6dacf5828d41c49f1c',
  '6cf141bf59ad0f23480da4e401eb7cfeffe26ac3',
  'a46774e963182968ec160e8cfb9cc539a550b765',
  'fde80dc80778aaf06b334d5c042446fdb62a7ecb',
  '51cc4893e4af6476bc5edde1ccf9fc5d23a40d84',
  '5c834a0f377b46f79e93ec2036c2cd883d8188c0',
  'db10fee1f616d7b4d2f919e15a9067f419e4a0cd',
  'a982eaa87e51f97dd74e5110a40419951cad7cf7',
  '6e3851d91a20e2bd0211c693dea25dc97d7b6c8d',
  '6765a2e4f2e732cc00b86167cb37a2ed15877c74',
  'bfd0c5924c0663e601125ac053687471311b2e89',
  '19dcf8362589133264fb4128c3e157d3f5d7baa4',
  '5f3fd67ca2dcff6f8b20a11e34e138a49fca62bd',
  '7a1e5028a9d8f8e3b22cd802cf3f19e45ea7d191',
  '0325503ae599dc7048994f50d90233e9173ef271',
  'cfeb3eb0702cc3746096c5a53a5518f7db36c9c3',
  'ba50a849fcf05daff375f469839bd542bcd725cb',
  '0200641d3948b84ba568569390d554ef4497fbb1',
  'bd276a1b54346c4d960776896760b42bbd862b46',
  'd93bb559e8b654019ba936a0032fe96fe3d1e3de',
  '696c6e66870e54dc54810312192fe2a7ad098643',
  '980e4a8cdf064368b8df48bfb42f54315e810d22',
  'f19d9b267e53d1e68c62ed01553199aedc082194',
  'f76d56eeb8d5477b7d5dd431aabbdab857c2edf1',
  '31b1e34c024b62e2389e649aeaed68f447eb81bb',
  '13dcf76aefe31b5477ffc29484cebd118016a4a3',
  '691bc635e1c8c5730aeb7957e94b9886f8476eb2',
  '0eee9d3b484e489b6cb98b3990b29396d12725be',
  '40aaa9fd0ac83ab4abf4313e4149b5ff63878143',
  '1690d2362ff5818dd2d2138d5392043bbddd315f',
  '74faa2fb2c8cf6c7360dbecf701ced462e44d285',
  '0135a317562b57abd92e3c4646de49a13125062f',
  'd4dc7b1d4b5242a1cfe7228c15b4435bcbd526a8',
  '84daafe9617971b945e37ccfcfdba754ec3981b9',
  'b1af3fa67ad1d5dcede4efe3f7fc53cf6a7e2075',
  'bb1e734a0cc9d3bd9590aab3175a76de9639ae1a',
  'ad14c5c1fd04bd3816209ae007dee6e97f5cfcc9',
  '5c6558026eb1e274049bfaecb93ae1ad3d7e2ff2',
  '91c4519abb2192770d5d65dfa360eabbe6e1e218',
  'b079656e14c0d1105017b0e72020e0cc0f102051',
  'd0e548e9b4863a50b878a4f0c8ce88f104eb458c',
  'f29297b5e9f5f30ff13eec0935dd735d966cd789',
  '9ee55fb0569edf31872ca3ca71a2635b6ee5b367',
  '92d8c47550351487bbcbb2a42bcd30d4a45d83ec',
  'ee772c645287bb6e58669b7623513cb235355b4e',
  '6804810fb622062d25577c205a8ea56b89f89bcd',
  '6d52c68db06b6a9043d29c64362264c6f071107b',
  'c14ada709fe6d1ed93c7359ac9db6189930f58c5',
  'f15730069a7fd49a8a0ca45f057167164baa8b24',
  'e53ad9fffae43b2a4ab052226dc20b7662fc6570',
  '4cc20a0006220488a7f8f80b01f8e7aee476eb06',
  'c466c1a0a75a13470e9ffc16645da97e077b009d',
  'a5cee95825614fd9be195ae1a23776da77a2ebc3',
  '72ca1a1dd645f8ec6e2540915f6de023a5b92980',
  '40a425c43c5638f710c415ff4c603475d4a69555',
  '812437d6c097ea6f20d3882273fff76155174374',
  'b182052c1518c35caa3705ffaa28496412b595a2',
  '849b87e4bbce276cee65e5a73be5725365ba159b',
  '6e8ef2737bd7c23dfb3e106a2d138d7ea61f231f',
  'a2b7ea7cda70920ec53a5660f36d94ef32b41ffe',
  '4639e8299f2c25b940533e61ac1824cae18f524a',
  'b1e8a5798ff34f68a5dc10a77d12b4001d374751',
  '92af906631aea8264d09fbf07e31cf45fa3c4aaf',
  'c886637187b0bb2b94f3dc3d875e5efcfaacaf4c',
  '5531e72d71d69981acd033cc92f79bd115c4e9b8',
  '2ccba1d4289ce26997c7365a799aa087838f9665',
  '28568cb8fa39f6fc70ebe24189568a80c5d2de13',
  '4d29fcefafa43bf36e8e3c860112831d2a6a7838',
  '1d19d45d1fdc927d95c0a0990e677b70c485f340',
  '488e4659cef27b64688317bba624b947744349aa',
  'd596042a364b77f95cbf082d513cd36790f3ea6c',
  '40692099023329b072c5ce3e1c17b54c6042bcd3',
  '0a7a7fb433bf35eb47e75cdaa23c1f4be3d08ea3',
  '8b0c5c20eeaee5c57b64eff11be0bb04ecf9a600',
  '177896aed4db36b344b09f439371409526a717b1',
  '91f4b89a7e949ec5af4f1bc6de4fe2df81cf4aef',
  '935ee52faa32a702118b3c11a5f2a0acda891079',
  'a9d1eef6ccaabd71c103c17fa5dc8356be96b377',
  'e47366f731c4c92821bc4578fa73634cd994a24a',
  '306e63e8ecd018a7072fb637741041366cddc050',
  '1b40182eff4395914b0c62b2055b741ba58d018a',
  '5b235bff8c3f25a469c90a780f86625b895310b5',
  '3daaede3b802ed65eac2a29d8dc5a688a5580245',
  'fb9a1579662e5cfb6817c55c5a697df5364c1785',
  '073f4957812a0db3eaf240ac3a5e89ddacfce18a',
  '2973f0119c928278d4715f9366962205d93a7531',
  'ae7c9d85b4e5202447d37fcef8295e90215eef77',
  '5bdb19a2ecbd0ad5cccbec1546a0d5bcdb698e1d',
  '4ad8b362bdc9620f62e99e4ab0b1137e7a703c9e',
  '4c8246d66f72ba18ec9ba116e9d93f55bc28e171',
  'e7fb18ded0401e8cc270f6575f14b38687ffc46d',
  'c5ca59db546b7d24224b42dd839445c67745208e',
  '4dd510c0588b103c0b0126ba52ce0c4fff01320f',
  '3712a0d7527ddc763ab0344fff6c3fc7e3d4b886',
  '5a1884188858293faf686a47a91e9ad20ab2ad19',
  '7ec2929dd37af4aeb37d416bf25b7b826204dae1',
  '8f2cb0658982c42902e14bb18ea4c9cdbcde3839',
  'aaee0aeecb4714a77cf482fc7838db105aad7806',
  '55835dacbcd907ef58b3bca2e149b2eda1fa4a8e',
  'b23adbaa0e2899a2afd3dd1d76e3fbe02bd4aefc',
  '83f7b5c542e86fd8bf67b396e213234880e8f285',
  '4a6542029c98bfe3acc013642c1ecf49716fa3a8',
  '935e84277be36621e54be705ec2962f02cf39684',
  '69bb6144b4c6a5d84d6fe24595527ddb7a8c46e9',
  '8a412059b021f00a4461d6b10e89bcf7c905f64b',
  'bff29aa584b2a8776f3a8f75b1aacb1e500443c0',
  '4027edce31866d983a8bb6cdfce230ec1f232634',
  '8a3e3531621e5d13507775294f5ea14a84ce5414',
  '1cc7b10471cd9b8c0f6f6437288b7b525e42585d',
  '90c42bd2051d83d0a7fc86c895fd7f8f22a0ac10',
  '526e4a8392e4cc13d8ca09fa78a713ee212cced9',
  'c81326548ba9c726a7f978be6796b2919381b302',
  '636c3fe1d08f3418f0540ca9f884f9ed9c661005',
  'bc7f4108dcc8af1d04c03e091aa9eb63593412f3',
  '4c8d36b5bdb978ac426c0ee24bf5d0c1b23cf8ed',
  '178eb00089e6f9a5375a0d0d2d0c62b490d6ddb3',
  '14a5f3c5f3b6e4f40168582bbf56179b79d7d095',
  '9d86a114219e190156dcd5f4caa46206c90cb2a1',
  '87f77e8750a0d26c80423d1dd2a6e3a83b1be352',
  'f1cff15010c33aaa984151544d3d958eb771d858',
  '16097c61ea5294b72c5883000d9540c794613533',
  '8ab74ad5f74e2cc1df0a7b404a9c8369f837824d',
  '8570c64d3afba2ddb0b7a97ea8d147c7f9ec11ad',
  'cae12abd150991ed5c904f9fcaabda000fefcba9',
  'd3b0564a5799d7f1e7efeb58229707fcda1aae56',
  '3b2025d9ae272b626de47593dffe4db118650333',
  '63537877cb124f7a13dcc371a2029577b6992a2e',
  '310c13830758a92e0b9d1a51e26b79dd79d239c6',
  'c36d8f4eb148d866e854d5a48ee31b4a1bb9c530',
  '0c2763f1e4eab439dfa2826d6b7c067a0aaae102',
  'cddce4800b2b92222ae3ed3f5c1ac140f54bcc3f',
  'a638ec8dc528214eef87cd82bb2ac17a1c1438bf',
  'fa918ead1ee7e76c4775289a84ee83960597726f',
  '237f0dcbcbdb9de1d5eace1436fafaa7043b2e93',
  '90987f353d18403ec8c93356860e67878c765223',
  '7d104bc348af0bbc75d1b595d9b14dac2e1db272',
  'af12cc6f6efc3a016fbd31adece912081c6ff25a',
  '220466503192668ac16ca64d0d424d11d4e9f796',
  'fffa833e2ed4126c29756a5e8ff0e1871d4c98e3',
  '451f5e1c5e3aac6f17528e1aa4fdf2fe8a6e6f8c',
  'c62c74bdecfcebe0da9be694eefa9297ec947184',
  '4938930054e5cd12f6cdb8fe25dcd3726a386a44',
  'a9b3e44889ef1282211128726507cb2b61734f6a',
  '4d1e825bf3cbee12883e072b19edbfd88c6c8e33',
  'b1b8bec608e8f004a7629938570557bfff544baf',
  '04c384211758cbe4996cf92ad4b1d72c1e65d29b',
  '8f984ed8d9eca889b5880e1ebd35d2f8454c4d88',
  '80c6fd23e09bf32616d4d55667e02fa97cf500f9',
  '3bd1f4a9868a64abea7f3311e9d2db75876b8c2b',
  'c4880a690cfd30c5c119206947ce40e060ce09b5',
  '5f2658455a5710a1fa68fc56af07647c82b3d652',
  '9b21fd8fa3988123ffed5329450d48ddf939fef5',
  'b1b99dd073d3a09de3de407bc9dc1b5e90e09f59',
  'caeca0326ea686c0a6ea7db4174ef5a0e947db29',
  '577e0c1be8e88b3ffc4a839f41a603f0bdadc05c',
  '6ae3ccbceedcf575b43ee93cdfca049340141b37',
  '57e3856100cb7285d7c8f8555baf114362c04259',
  'b38370c49470677aa299b86e61883ac040dda52e',
  '543da5e595dc7d6043ba4fe15dd78044a9aca109',
  '7d5d54bdce6a4087f1940332794f6fd036522406',
  '3777eeaf182d840d9249e93693cda5389030b1fd',
  '3ec7afaa58cd3cafa4180dcdf87d2ecd6ce5c799',
  'e7394d6f9db9b13e199d7dcc0103444e4a02af46',
  '86a0d191be8b28d66fb0a7a371472acb0af7a411',
  '54bc86e280a5a13f99295e83aa33945d24bd0ee4',
  '1974096c97fab5bd06517601931336329f48fe8b',
  '332133618fc2b146ccdd9b70c56ac4250e54766f',
  '589046cb6d5fb88dd5e72756e54cf571541bf1c4',
  'e78fe02a706ce03434d672d6a7d9c3775f4149dd',
  'c324189f9730ad0a0890d951f9b7e8004cca8a3a',
  'b4eca6d3dd454f22f54d5124f677e882a737b850',
  '58d8ec4487956aee22133fcaed8a5a55e4bef582',
  'b8498e39c4f8e13b939e23edae42069286f74c83',
  '8a46ec4f6170abdd473f503e40f9a68ca209716f',
  '8a1b316c510711e19560e0f3fbf5099f81dcfce2',
  '9daacb07e1bf2995b6df54d4efb187cf1e4c6bc7',
  'c45ba45b44a0640e795f38c2e03f8b70246ff113',
  '7bd167a0df71f9ddc5aa25448d8e1750e11477c1',
  'f10993de7fc7cee46716321681639e3b78b9d5db',
  'c7f64816b3084dec7ccb0dbdad09bf0810543606',
  '163b50b6c268379096b2246ed479bf2a739ec26c',
  '89ffe83853528b4d6861273ed72cf42cbffa5ace',
  'ccd84a9fdda59a066f79a228981f9087d5362eb9',
  'e79da70a6f5a92dda02bfc82cd6af5ed0e27b6a5',
  '1cebfb2e12894157b72fc0f9c7bd2f112abb3269',
  'bc84c4f8b0fe290595871b55f8523fc2ff087b1a',
  '6d3de365ac15bb996f21c67911082e9ba6b5a3e0',
  '851caf875e875c1c3ff0a989e5bf587f42170180',
  '91f037e4d3be900f43d36553803b0803dfa43298',
  'f2f5c3ea922ab9fe8e2ce0746b86e80392635ed9',
  '6e12e156adae6bdc1b53dd1b11f8125c2868db55',
  'f3aaae92cc6fcdcdb5ad3a57c8aefe38deee780b',
  'd4b55a4e8dc90b37632f3dbeeb8fd47254e26083',
  '1da20753ccfe385eed1004523fd63c45a85a794f',
  'e86e7946b2553abaa022b7aa2c00b31cf768aad0',
  '1f3ea940506fe3cdb8daeac11690a76316251844',
  '46a9f489b855b714c44babc603503e21ac7564b2',
  'a2222a76c51ca059ee820cac4241b04694e801b7',
  '6818de2afdd1cb2e8551af715b7c623e54285ef5',
  '56ff38a74764d83b5cc4f59e586dfa372aa00c8c',
  'ee3c9a0860a64def8eb5485a877354b07b51407d',
  '9bf397d0d0b64ab5d4b8ffde2617198b4604eb58',
  '73a4f90d0826aaf493151c5342ccbb62429e09b7',
  '9fb32cc9d2ec70bfdbebe89361c508cec3bc3569',
  '641408abc7438717f0ec37b7357f2fd352485f65',
  'df03de4cb3a999ff155be2744cf3b1d645a93553',
  '94cbd36202002e4fa14ad8ff965934fa80ea1908',
  'e05ddc8e6b8d1145f27c6fe58d59d682716651a5',
  '5a022ea89344ce063d2f7affbdfa90d8149e11bd',
  '9b6dc3bcb04bfe4f44374e4886500350de40b5bd',
  'fa8334ade22b92a26e5eb7fed1c69130b65eff6d',
  '2ec01a6100e997d26f432bd964b5e8011483923a',
  '8fa26c5166e6db7e84ca3a23553f13ba617bfc58',
  '8469effd5541b1de15b89172bf5be090daac6ed1',
  '4ede8226bb752456e0af66fc4761ba81f22973df',
];

const GAMING = {
  channelIds: GAMING_CHANNEL_IDS,
  name: 'gaming',
  sortOrder: 60,
  icon: 'Gaming',
  label: 'Gaming',
  description: 'Pew pew bzzz gaming on Odysee',
  image: 'https://player.odycdn.com/speech/category-gaming:5.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  tags: ['gaming'],
  searchLanguages: ['en'],
  //pinnedClaimIds: [''],
};

const SPOOKY_CHANNEL_IDS = [
  'f0b970a29fb303b11a5a3cadbec005d35c7e64d4',
  'f868728287330baa39c0a48ecbc3288e29e18efc',
  'acd5796fb358da4e1ef6d4f62fb13e9783c74dc2',
  'c3bcea656270fe587ef9b5ddf7ce85531b888d36',
  '5190f00b950ae96607a5f1f1585b06dc1d869ef4',
  '13c71a85ca45d90c377c773f9d083af70551307e',
  'ce4cf0d21f2538056de760cec780667bc62f1d36',
  'e10c5bab9a8427c132872eba6a56b7bdfe993b9a',
  '76ef5bbd5c42e057e36b2402833111f24c5f9c12',
  '119a2e8c0b50f78d3861636d37c3b44ba8e689b5',
  'c4851557645332a7268fe0340faa656341ec5cb1',
  '6489aa07c4bc480e338dc67071e0895df6af1eda',
  'abf8c3b0426cd89fce01770a569d525c648a92b5',
  'e2773f14ea4b438fa9f8c8e4f962055167f01000',
  'b4ccefa53e0bb5730b56facb9b2ae5ad92c99935',
  'deed21cf85c453e074b95949098fcd0c3dc65d69',
  '4f2e005e8ca6ed57fde10c52571c39e22e1bf27f',
  'c32de3d9a336f20fd4f66d67428d0d46cea06d58',
  '5f2a5c14b971a6f5eed0a67dc7af3a3fe5c0b6a4',
  'fccced5026200bc9f0fa836f5159b47dd1d78061',
  'b016d47e56f0914c2b985d83e051d43ebf7d2b52',
  'e1f6a655de964977b5b2c56ba6b67ba434e03da1',
  '05a7f5f90f7d5979d1d151f9d1e2d05b55eb151b',
  '146dac6dcef9bbfe3621879bd76c30825341168a',
  'a475e3dcee2111191eda14af5c6e48e5348bf384',
  '2827bfc459c12d7c6d280cbacee750811291d4ba',
  'e3199607f9f2bcb89cb0f09ed35747a58fd6c128',
  'af927bd2092e7383789df183ff1eaf95c7041ee9',
  '4a7f6709df6770e0786b04599fb00262a98d220b',
  '239e4068eb8c4607e2b204f7019a73fc97636cad',
  'fa697814b01cbad882e62eb547cf304ad0494d9e',
  'cfcd968df4fd7523ce48e580bc7adb2536d211d6',
  '7579e81c2457f65c14fcd4d1f288c8f32b332d43',
  '8657e17c43187f989a762b4f54868d693ce5e87a',
  'f6b3124d6914978c59eb39176db1f70659ea854e',
  '9cf01ce2a02d3f180b1e0037b25c2760e191ebfc',
  '450a4952e611f333ac2a5fb08b2a4bb421b4a406',
  'b7829928a5d84e12c8ea07de14456d50862833b8',
  'b712d27bf04554ef115fe139aae3b31474872882',
  'fa3a0dbeb5f8c99fbe2571c60db0c3499096da6e',
  '6fc489b1bc7b44b57cba7b613fac21903e6e75c0',
  '5831f60b25f5b5cb7d8b04c7887aa65949bdb279',
  '3fc2b481e9767e9eb362a94b2cf8d0d5af955042',
  'a1229255cfe560646813132a561a875b09c6fa64',
  '4b02df3aa8192bf7dab499edfcf8b291ba8dde1f',
  '5df2339835971f101023082851808d73d043e13f',
  '66a5e121b0ff4de4e52a0c765afc3e8816f5c0c1',
  '1438eb1126db6340225d6863467ea25941c19947',
  '3302aa9254ab9b24e913366603242e55858f1d18',
  '9027ad5f042acbc784830e96d8f8999308ebb884',
  '47e1d179bd2753e6e53f28f5e99fb090d336d2e8',
  'e360f5172ec07302f18a104615bc812c063d5a20',
  'c3fa6119954f85b5765dc6b14c9c6e18d9798608',
  'dd30c9c5c1c1800b18f3d703bcc1e44ba1ece45d',
  '88f71d6c41d659e897ecd80d0555e7319381a036',
  '6706cf13a323fca41a100643e79bd3565abf361d',
  '2ca4ee160266d31ca7baa7e5c220b86059107037',
  '6e63751c2bca2a60f4e3a26e8e7c8b371e08cf54',
  'a9332378957eed37c63f15c307e57c4f40010b6a',
  'c5709bd0db28e4931ff80d420bdbb58badd472ba',
  '442b58187b17d98b0b4577812f6a3184755c2afd',
  'ec528f90637203801f2be318dde134fc64067bd0',
  '476d2bb0aa9bbb301e922bd43d8f71790ac3b359',
  '9b76fc41ba0bf9932052274b95f9395135dd1212',
  'a2c8f8607417845ca695f1781df0dc2af445c33a',
  'c953022dd65cb8cfc613918c606352534376e20b',
  '58b039b4d1a2725aa0ef686767e097d6d2ddd17c',
  '216105ff50f77f907bcd374e8c7e210eeba2f377',
  '54c57c1da48663664297c0af39baca215041be55',
  '2ec65e6422f748a43c4ba280a26088986ae7d0e5',
  'ccc9725d6186406f0b6811c69fd1f012ffdfe937',
  '875c9b80d649bfe596780607cde3166d9a67acc2',
  '69919356170d8d0dc851f18d5ff5ad7289a094b4',
  '01445aae610309da988f7e42fbb7682a8a7d4194',
  'b265a10fcab71f403b16d70662cfd44274d6e0ee',
  '27d0d80de6f82427aa90d18d1681bbed63614ed8',
  'fd163c9ae7474a4eeb769bebda39b2a7ffcd48e0',
  'da474df3b059af4f3af1fe05afe68f3cb7277350',
  'f0dd240f2a5f4dfe18fa3b7e8a2b17a431cc5938',
  'a2c085a6dc74a831d09f34c883ea3da86e68e2a2',
  '08c1623cfeba15b1d2ee7ccb858a3895dfe91bdc',
  '2eb376ffe6a12948aa88740b0969829fd899cdf4',
  '5c6ef1d64e18ef95b6529b1c32f11edfb56f7af1',
  '2357a40c840bcb4a183c501b80ce84207e573340',
  'edea56b017d2b79b0d5131ed4a7a856273e65f83',
  'd867ff1377f825da63589d8ca5e08a3d38936060',
  'e37fbee972448c335e5384134e7b511b0f406582',
  '70e2e271a84ca00c8a161476b670a2eba82a796c',
  '175ba1b7d4ef800cb1ec573be691ae40b870b796',
  '2352ecccc20dde9d8ffa6f32db769c803419cb75',
  'd53a0480dbdf450fc4c2517f9c11eba5ca75ab15',
  '8335d092a1c3c8a1f8f1b019bd4f215defe41615',
  'c25f64b6be2e7d53bb3770aa67649c8fe0e0b975',
  'e8c0ca38c8a403f05f032e88bc0ef4a279e4f282',
  '3efd2eaf9f37c9f856151df25e798791fb8a0fb2',
  '3088f72ef4c0d3079f83d6670cde33e5e15a9988',
  '3ba21b5f2cb9177ddfe0ba54d214f6fb0515e6e3',
  '628144c0ae74b4de46fc1f46b17980fec7f056de',
  'bc7b3d6b91c66c3b1163cf04a5ccace794143621',
];

const SPOOKY = {
  channelIds: SPOOKY_CHANNEL_IDS,
  name: 'spooky',
  sortOrder: 60,
  icon: 'Horror',
  label: 'Spooky',
  description: 'Boo! Scary content on Odysee',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  duration: '>=60',
  searchLanguages: ['en'],
  //pinnedClaimIds: [''],
};

const TECHNOLOGY_CHANNEL_IDS = [
  '604ec1c751082e66a4e449ff42f5e2ed20febc18',
  'd75a95347a3c8217d9c52069101a52cf236d6cc7',
  '87693ebff9b380b00a8a12f289e4c62f2dcb198b',
  '8dc13ea8cbce50ecb6f63f72747b57717fbf4372',
  '0c8d1dfbeeba82ebfec684002e956df537bc0a16',
  '93a429e8c09a335e70f2163f973b157f26dfade1',
  '111b8f37393a2b4bbb1369e0cf2ea7e95868e9a8',
  'd2088f21e7b6a199befe2bb403d7a8ce155c7d17',
  '7ee8b79b82b260e45401f10b53f17f3ebfdd1a18',
  '9f15840190724052aa5abd48581472410fd251dd',
  'bde94965b09c4eed697b47b0bb97b9f8476842e5',
  '9d0eded747499343bfe997d3e7b0dc7a971ad3c5',
  'a50225e876769f50e539475179667c19e81c64a6',
  '06fbbd91330385e0ab06e270bbea9f0838574bb9',
  '48812992e5b8a5f843a3993c32724ee763a455e0',
  '5d66685862c4ae6956d63e058ea30483881172ab',
  '70d9bbc072dbbb04339c75f95de965d8ccc684ff',
  'cfd7d7d210962860a951dbe33ed6dbcd04f6ce9a',
  'c89c623c9a590f1e4a622282bc4b7f968c8be214',
  '29a656f5d2ac58718002adf4da7a2b76b44a6f42',
  'fb1195c42946549263999336c9ad360ef1eecb8e',
  'b4a2e92c40d2aaed1dba99fa430bdf9aca9eb7eb',
  'cffe656c7ddc506c280d7b10d85cffb43ac733b3',
  'bdd1e22fcd83f406dc58e5aeb8ce05d791b06daa',
  'fa3f73a7f563caed540e31d67c58fc306d5f2fdc',
  '38a995474a186de5f2d2ee593d2e3274e7a1fee1',
  '19532802f002525836bd3684be4c8a79da9e854b',
  '5149ddb4c98d80935e70dc41eea1b7be54e09b6b',
  '40b85a2e6666deb68a8556184e6bc113a8cfcf30',
  'a605a1aa0b9a225dacd7dd144ee650611a466fd0',
  '9af174a38a1096c6edf693d4603ac612cdcfe0c7',
  '224e3b564c18a4300d68421eb19c62a6bc4a322b',
  '87b371e723b2b43afdc3323c69db8626659348a1',
  'c9cca866bbca87c654e208a269dbf22c37b89d56',
  'd25091216fc16eb249d9d2f148df1f98abff88ed',
  'ea2f496c9da410c0134cc25a3df64fdbf3abd3c0',
  '0b875d01f2578bde0cbb221eb6a67f23f2222823',
  'f87876eaf119ff933d555a40ac5ead19e12f8693',
  'd45c5a39611182186ee42d4f371b0bd5830d3520',
  'bda7fd7fb3c3f36093911ab811fd2bdfec228b37',
  'abe2ff9c7a64414fd99aed7ee8d83d90df837e20',
  '7a48250e6a58f99ee052a2e3aa56ead35caa1993',
  'bd9fb35847892872ecac187fe62a866a39038775',
  'ac7b84c5615e60f264cebfb384b106f34548882d',
  '93fbec0f8c55d21129e91e4a1f8d5bff9db9951e',
  '0b6fbe0b5476a7cb6f73d6fffbf2d68be3c7b742',
  '403cd55421118093e83d490cf7991ebb477a320c',
  'd1b93ed2c88c0540c1ff54de111b5844f8c46b60',
  'f77a89cd31769d34731ad06cd0c965557826dc25',
  '4da74c91b475aa4501cd254a9b5875264a5e55f8',
  'db537c778bd62163eb8de315c3627b8dfeb01a62',
  'c3011a6d1b3447b41d8aedad7087e9b0dc8c6e98',
  '0c9385c67a8d4e8f727084331f2f91d1765f5b0b',
  '8d497e7e96c789364c56aea7a35827d2dc1eea65',
  'c30c898134c0e320f4281ed194e4c31de77409a1',
  'ef35367e744a954595c754ade6e1864814e3f5b1',
  'cafbbd33c9566b31a1eab0bdf53b59b090c741c0',
  '1e0ab5e9f48f0bae7b8e76f4f9ae6e5f396ee4cc',
  '44af5de8a0dca9424dc72854de06787c018fd6ef',
  'db3201197536bbf929d4ff9981ec54edba9da9e5',
  'fb4d51d8a16d29e3666ed9cceaffb80159693370',
  'bffa932e4f0db3164a7e8a7d210db22a81efbae4',
  '3e3f2a0ec0d6d5060be015e4238feac3ec844708',
  'c8d8e5f0aa7eb657ca99a1c1126ca0822cceae34',
  '37adb8487e19766826a15027e1e1830660984f34',
  '1d01f1dfc0204b2ff4d5e103e8aa6417fa080453',
  '0c9b5678e5f5d00ae4ca043fb8f36275e5988774',
  '423004cf6a958a631070b693011e9c4880a7c7e3',
  '6e710633f5e12d861b63afcbabd36a5de6062298',
  '1487afc813124abbeb0629d2172be0f01ccec3bf',
  'c1a5fd043a1dbc8ff4ec992aefc482c970e7568e',
  '0017fb2ac706d21d2299871b988a121b6d6798b7',
  '167db7973bd489807bafec53f5ec283c637a5328',
  '2305db36455aa0d18571015b9e9bd0950262aa0f',
  '250650fa496eff51c2a6bb42e71a63bb285252bf',
  '52761d617bfc1280cddf02d62aa6ac29fbd98d61',
  '5b0b41c364c89c5cb13f011823e0d6ee9b89af26',
  '6a4fa1a68b92336e64006a4310cb160b07854329',
  '06980eeb4d2d1bc26ba2763177f1a71124d7dd78',
  '0e2b5b4cf59e859860000ff123dc12a317ad416b',
  '118d5abf71473407d12eed67802daa3193d4b330',
  '3883c0e0590d33b0de17683864a219169ce42e41',
  '7a8954b2c09cb835f4b90be69fd4a5fc4f1b5f04',
  '32276d32396286795c19e407259e0bb9904ebb0e',
  'aac4cb4d0d1b1f876eb2dfd2e83ef0f264038168',
  '0e442f5489c9069c04846ee1fffc9238bd05a798',
  '4ee7cfaf1fc50a6df858ed0b99c278d633bccca9',
  'fdc3b71d6e99fe305863ac72721637d2ce49d1ad',
  '8cbef9a7778b528a6183b4bb25bb748bf016b7ca',
  '70d6386888708ce5559ca225120b2801f6e052bd',
  '4f0686070ce0ec410ffa10bd46682f54b8d6d94c',
  'f33657a2fcbab2dc3ce555d5d6728f8758af7bc7',
  '2f229d3ac26aa655c5123c29f1f7352403279ca3',
  '8539673ff55e10a7ef2859d16194ad92c4d3a412',
  '5af39f818f668d8c00943c9326c5201c4fe3c423',
  '70645fd323c8730d7fab5528e4fa5883ecebe78a',
  'd9535951222dd7a1ff7f763872cb0df44f7962bf',
  '3818d442f75be9c3685b6ad58e5ceb8569ade5ee',
  '87b2669c65c60a36aa408f0177517a192db194a7',
  '48c7ea8bc2c4adba09bf21a29689e3b8c2967522',
  '2a6194792beac5130641e932b5ac6e5a99b5ca4f',
  '6e29e20a77e1a6181e60ca19b0f83e8223416aa8',
  '918be99daff84a69e1458cfabfda219f2a05271b',
  'a52425228572850f40651d2f8fe965a7d1f7d003',
  'feb61536c007cdf4faeeaab4876cb397feaf6b51',
  '243b6f18093ff97c861d0568c7d3379606201a4b',
  'a9d289718f3f14e3d1fa8da7a7fcfdb6f40ae2d7',
  '26c9b54d7e47dc8f7dc847821b26fce3009ee1a0',
  'fddb293b297417d753d0175be69a11e59b22ad57',
  'd95fff35a8bb853b9d80c93d7c0dee0b92beab3c',
  'd0b97ba2a5eb024f4cc1d972b1c52896f37c32ed',
  '74333143a3dcc001a5602aa524583fc75a013d75',
  '0d4e104ffc0ff0a6c8701e67cf13760f4e0335a8',
  '7b1c72ba903af4aecdc2595397a9cb91bb7f188d',
  '6c412e4afc8258ab65d83900b46289d68abc3872',
  '9a311e766fcbc69bd9f37fd068843a1a8b1aeb02',
  'c18996ca488753f714d36d4654715927c1d7f9c2',
  '6ce715e99c42b57eaa6303babbfc49386eeb1f61',
  '3b807b82be6fadc0db4a057955766cea230839b9',
  '4e17d248adc0128afe969c2e1327e10afd9cb921',
  '56e86eb938c0b93beccde0fbaaead65755139a10',
  '68044a0cf5ed7534872af5149d4e0c43a9a1d06a',
  'b9288432bd089c6f332145aab08a56eec155f307',
  '4967034d0978c239b6e0bd4b1fea04d918c13a10',
  'edf63b41a82a93bad1d53b159af2e7e1fe119a5e',
  '7d22a93f22e97ea2f2492ea290d044468067636d',
  '5327b233d7128e8d806266f2f486a4e48ed325c1',
  '5ca8477d50ac027e7cafc307139a58b34d01d658',
  '5d9ea5ea1e333256ba3f18fce533b8cb26abc06a',
  '4a334a7388b6996b4148a33947f4bdbe324dce95',
  '2332c0df0bd5e046db04fe7e3e79f756d35a1523',
  '1312bc5e063ba9a14b406b531a7fec1e55b3afaf',
  '02bb55c11ab338ebeafcb6a9d24f99a3909f231a',
  '47c6a778ea4836b0987ccc4ce27b26d5f886ec1d',
  '0adce5aabea4e6f8384a85d831397feefc9b403b',
  '25feb42a7c5351597aa084210a59f348e9467ee9',
  '10cba1b5805f62e8e49ce37c8d614cec7c3c3299',
  '3ce7a61f1171a99b00c123b9ed57d55e56baf119',
  'ff1dae8d9504ee6f5212ccb1b88d0f8d403f3d8e',
  '42594e750ad68e599703fbc6199788e8cabf98c0',
  'd840185c7b98eafe676ce0c6fc9128259335c8e7',
  '67649f8e3edb907d455a1cf08f3e71a6da3c1205',
  '364f077b4e9685aa2f25fbd424f8e1026e73c374',
  'c9f5527c1c802924e552715901fe52af3f2d4ccc',
  'b46144501bdcdcaae536b4a2d30250398225d0a5',
  '5813055f1e69ce033265d175e1268a13d8214610',
  '3f8e06a07ee22b8d22b66ee913ccab1e94436af0',
  '9df4b986a34d5fee2fa61b288b0a783343fe8d74',
  'abb00ab40cec57c6431062fec9ceabe5f4b8724a',
  '068df7145dd57abfae863ac4af9c90e834bc6220',
  'b6fe998984e2dd10a3b32893b92a88cc111007bc',
  '9e86abda3ac1ce3de2d10b15fa087c36278fe5f2',
  '2a1c2131dbf82d3d326b6f156701930925faec06',
  '62c231317c8a471ab770b7d113183219e99d5d02',
  '36e2232b56cd11e37de7e228b13f9afeed8dbdb9',
  '2c93f540b1d44d1d911ea7aba5ea98a0ac3b2530',
  'e7d8b6861d3427e8dd3f1670c34f1168aef6f82b',
  '798b81f392daad83071dbeef80c08dc59c047c07',
  '7e583da4b52061fd7ffd3941ae2d7e4c8338403c',
  '7a150778c98abced6df3e51bb30a472ca3641a85',
  'a53e94e8d63d88e6abb8c0ba1847b001c8d8930e',
  '43826ca45faa93f43310f7bf60f7ca5a931186cf',
  '9ceb9edc99ddabeb3957291d20f741f1746f2dad',
  'ad44457e4b3b73d1c09edf8a20b034770220f460',
  '169fcdb06aac97392e54cfd5d3c0a752eba3e112',
  '7d2808c83ece919fd5482bcdc364705953fbfe8a',
  'ecd1338cfc1470d9c0b946e1c371cc61f1e8789c',
  '0a60026345d27058f970d5918a7b7b23a8ca4c43',
  'aa2609e3ac8ab5f21e28414c305ad60e4c31756e',
  'a656fed1f0494f5ebaa582be8d3b9a1f610cd8a3',
  '42b39a7fbf4c2cbb7674480f033dc0ca3d1e4d11',
  '6b23248e1e86029d0bf803e35d6b9123cbf98bbd',
  'ba8358715115ded87fea0d360121260719913647',
  '7e8f69a05283a1571088791fb8c32d72bdabd320',
  '2c598888af6ab34d2d8f2a5b9835d9f341e34bdf',
  'e7a5900dcd6bc313361c0075b5b926e9c7ed9560',
  'fd60b4b0b82f7f4e870c956ab61e9137b7c54680',
  '0883a259579c331ec83ff59135f7e7363d3153db',
  'c3cf3780c73279f8a20b764f2c7edea1cf380055',
  'b98bc846825fd2db310895d8c55c7e323533f95a',
  '6b3f82a5fef469a371884638df1913b562a4982d',
  '74f9fc37844dc697cdb38d8290b6e10c43f1de4a',
  '149c4686ff0792b8d68dac1f17b6273a85628d34',
  '5a445cf1a7828eac2212cc98a8b03c37fd4cc4dc',
  '90933a8bf873adee1fe2f4d48096badd036eee15',
  '52dac0bae6e7bf915dd7004a658e0793ea8b35b1',
  '46e47d0317c1711d9f7844fd0c418329fb39c941',
  '543e80c0c1acc513c1c0ae67263d8ec685385c6c',
  '19ec35dcb324ee80b86acbee9cf2c613546f6ad6',
  '64058a2e8a39c77065dce80b62703ba7ec70d6a3',
  'bfd7c748ccf7f9c892274ddfce6849edb02fa9eb',
  '128bcdbaa6d890ccf7cfbbe720d8d9285ea52705',
  '757562304f36e647dfd91950b9bcaf4cb7583c34',
  '208a8f675612a4356f66e5de6e77dd798262b704',
  '2a1a3ee42a31e34aedaf191204b1d781281660cf',
  'f5e37a9b8cf3b53c5111dad35b4d4df94807d804',
  '7b7481c5762226d5a9cb4d8ac3b0947fe4a593ce',
  'c2be40dfc43d50330ec2e13bcd67f0591a2b4588',
  'b51e5fba7c4cb1e12b2eabfe89e7cb8f5df9f943',
  'e9b02bdf49287f8c69b8ddff217f6ec692ea783a',
  '7913d4b05f7515a5e201d9e29ebb7f2c6a722cce',
  'f6e35364ba222269222993388f667e76bb498a38',
  '6317c0bdbcf55b0b3aac3a9cc0de5ad4308677aa',
  '9415dfd5d053b793b4c8dd51330284d4d7ffde00',
  '3405c494bea58ee15319d3d997ee434028ce73d8',
  '00da3c9c8297069fe961b44ad58b8bf76c0a0e0b',
  '6920219421ca8e2098465d5e05a7844116010f75',
  '7423ba85dac94d21bc5b824901851762cb785d2e',
  'ef3cac3793be65e5a3d0aec236742d4de7bed090',
  'cf072b196d38762ba9d500a1a4e4afe7feb47d6b',
  'abe85f8301c23b3555ebf4a4c07ec3d2b2d1867f',
  'a2e98dbf0512d154f19319bc866741f4bc6a6c74',
  '7cd595cf7b74cc33bd74b6cb2134b37e6e413947',
  '053aa5264c64b50a01d334a8c7e0810b5f090bc5',
  '1d17033a22d8ea2799ebda514348b872d2a4fa4e',
  '199eba05b6ecccab919e26a0cb7dacd544f25700',
  'bd70f930e75b5886c3b66ace2d0ca31262d43a6f',
  'e4bdfb1141a48b2c35d8b747ab64c7847a98708e',
  'ada94a7e10f5360c87322fc7c764376b290cf3e9',
  '9a4936a5f7dd57fd50b2622f28cb7037f24ba587',
  '121f4b3341e880ea7ef3b26e58bd1ca305bf8b34',
  'fe939303732e340941908f321f5b0bc476125784',
  '233b5214f7a8d013becff05f73f0f83946f35ddc',
  'f2ce1899a4d9fa45b7b7a9e8aa4ed549680fe36c',
  '6f4707a0c30760ec1443f1ac801df91c28c3053d',
  '8494d7eff326f038cbf45c55e589c5e147ee7554',
  'effe4a73136dd8e07778e3ef206e19e8fc004d7c',
  '994c05a5e1c5827b114dff613a67d5e76a0542ff',
  '441119c9aaeb43ab95c9cd061ebbba35992bea09',
  '497787669f8b2b5ea48dbf112e24f7b3e4153a23',
  '0bb667f0c0247f9e02984c3d1b43527bcc9a1c76',
  '7c9c6e374281d1a3fdebf3d04cc1104ea57ee704',
  '0c8b2f24aff10a674cb05766855d8d55fe324fa9',
  'a37c7bfe2def1937d7d594d9061b7f9101c2af55',
  '99fff2ebe620dab48001bbd0d3464c6b83c241f2',
  '2e9fb3e882228eb3fda485429faa046c5d25feda',
  '773229b31c798a739eb94fd959c88fad38d0008d',
  'a69bf0953bcfe1b7f8bd079eaf846b772fb9bbe4',
  'd5843a63db921e26bd85618a85000b08ce8e2d70',
  'e6ca8fa3f11715b3251a163dab38a01bbf5cac0c',
  '2c984df1a532797dea33217f250dc02fa6765174',
  '436d0a2f84b444de71aafeb2daf7afa73f3d7002',
  'ba371e02596d79b0caac5af2fd46b2e155723437',
  '252609af7d79171469233b8fdbeddfedc6a7105a',
  '79dfbb04e46f631c14b70cee146f811f55889a76',
  '1ae3e8c96a346ad294ab4b9384c1bd5aef1d0911',
  'e051911ef128cd339ecc797ac5313da10de59886',
  '9f3094f0c9f3d4f1506807d9ca684b5ceccea404',
  'a3372b594689324cddb7d61163639b2dec541f24',
  '11322475df12c7df4fe22f6ede12ac8ec72a0347',
  'bce31a4f2447514ac2f0e45d725ec063b9209c96',
  '3c3e058923fdc5f91c2b23e79b409c6e98f9ccaa',
  'aa5544b6778d3620d57d8dcd3229c6c59354857a',
  '17288fe15c058b81701e9572c455705f8b52a17e',
  'ea21e87a13248090353ac5b631488aab4cb23e3c',
  'c2684f4145b35d3bfcf6f3b308365b7dbc95e621',
  '4a0b6f26c6ad3bde17945439065fd531601f0358',
  '3e465c0163d8ab5635edb49e6f8f3933fa9cf42a',
  '11f241343243063e9f7bc74c7a4cbe07928287a7',
  'a82d80e043fbfe40189cfd26b09e4ea8803812a7',
  '2c9071800bc35ffc0cec48dfa133c167ace79406',
  'ae2dafd6c8745337ded381ad8ee5ae4921c8ac72',
  '91a2160c8b26314de2929a10e71bce68be61c39e',
  'bcaf4c34eb3e80116815de37ecd583a8106d5542',
  '4dfa3cfcfe417075b37a577dfad32244374603cf',
  '297979b56baf279a63113b0fb36dd40dfe461920',
  '596727046736a4b844b3cde8e79319c19dbf5c31',
  '46833615672c4bf54d8d342a0655b65cc997ebfb',
  '7b5e29852c7bcf661b8ebb0b6bcc01621696b33a',
  'fec4a430e6732406851281ef005bbef751ea8b23',
  'c4b001d7962dc0ba0db8716d62d429da79962fe5',
  '9250f5e191fc604d9ca7e7691bfccffaf46fb66a',
  '7fffe91bca81ffe4236ceccbe4ce0e70c1696dd3',
  'aa8d66fd987e831b95d036e7d89125a7d4be1e30',
  'abc60a14b8457c0f8d3b116ec7a7f5c78d158ca7',
  'b8a1d82766a29e5af0d5c4e50bd438f834ec3e21',
  'd89e9cacdbaa3d1276b4fbe31465bda7a5de8dcd',
  'f0e8bf7d149e771dc1a89d26b8614cf24a410b76',
  '66321855e4c2a756b4c6817e8514bad3d1b0d7de',
  '2e06acb75546040190743df732509a2778c4f7aa',
  '9d387afbdeda1d79ea10e8a7887836a540193f11',
  'c26280f4d937ce5ce9c0cb0b12bae05e083bc698',
  'c69f07c7fcce4a55b3c76ebe6dc13a677c45446c',
  'aed93daac1daf58aabc7ed2776999e2eb5d82851',
  '64a9305928a97d4d86172fbb0c341e98c1385cd1',
  'e7b5f9bf63540afb39e9e15060243584f9c814c1',
  '268676a4029333522be6e007d768a825a743b306',
  '206b507856f0464bf49d486c8a90e07dc949b480',
  'f49d3e5bbfc560e8381b263a371d123c0be44f3b',
  'df428291f1aecb19982367964be8df190a0aec27',
  '943048275ecb1a8d29e159bcc1c74efb64f53bd3',
  'bb3e6bf59a5ac5945eab4126957848f04c739fd2',
  '43a107952135ab244a9cd81d46123b1c24eda52e',
  '55a5e1c9bad949719a3315373ff67608e076a05e',
  '8f1321c1e60475728caaaad69c72712fb3755096',
  '514df20b9d2cce22725d5305a8ba019547188736',
  'bb9a9d8b233ddcb2741cada630e1896ce4d7d24a',
  '3d25f1f10f21441712bee6c8700f924f89170771',
  '57fcc99607d6d58fa9dc61e5d63b5ee98a3b66a5',
  'f83a38db1f5855c02b321cbaa3a6cc8cfc925c01',
  '69e24eb9191452f4c460128df96fb8925139d3f6',
  '296d958d5a69c6909e60f2ad60b188d30acdcde7',
  '2873c67ff165499151213ef2e2f3d8f16fa7d9c2',
  'fbcebf09b1f2957e0921ba2856c415b9ea03efe4',
  '249f7d18dcf193f358d5251c915edb985398e2b4',
  '96ea714d0bbdd020195c6d989a9f6e45cc1d251e',
  'a63835dd552f2d1ddd09574ccaf0a30fb1bac8c1',
  '4f4fb7a173dcad5ccd95a56dac2e8e9efe9bdfa6',
  'ea0cfd834d4503e686cab651e557bffda4adcc62',
  '31d231f5240b2100326a12bd7c702a7071298518',
  '2d4a177352592e30779dca6d330986b10b560406',
  '868ffe1423f9b80d93a77fed05f4856d512d82f8',
  '6f78f88ffa4d11f530d490affc3732f035e68a60',
  'a8a2a9c99baada0441f8cd38260fe347b3aa61bf',
  '64bbb908849fe0d1019a3271a030ae7a7aa23219',
  '32588cb59a2f2a81f0439cb690506924b2b520e5',
  'b864a8836c9bb8d1ce100b51fd54388b47f12aad',
  '34bb169e04a8adf27569d1142bce5e0d7eb3f73d',
  '0fce629c5b9a4c7d26448cb6d3b95d666b1c3d8a',
  '89e475894b519f97f2de4f65a324de25bacd8b9a',
  '0a70037c2f0d68368d3b5abec98fd78669baa7a6',
  'ea78437e84c6a778a729ea14ef3807ae3961ef48',
  '6c94cb65ed53befe1590dfb683245b549bccf6c4',
  '79ce39196e290a1b5d1da758ac83368a461f4ec1',
  '108afb7df9812eedb7b7f22cab78e750795e93fa',
  '0d768ae32949bd64894eaf91f0d035fb4cbda233',
  '881ed335c4fc13f413eb45146652d11530612f2d',
  '1e221f2c70ba646a22a7358ff15ff9f5697a7a23',
  '88f4d8d15537de4e7c2179a9f976c7d8509ef684',
  '504acae2fa155c65e74cd0e4c335a3a49aab47d8',
  '7125a9a12a4fd927d238a4dfa596d8a82b1605e5',
  '3eba62318be7618fcf439c751c364ffc33a3e393',
  '6f7e55c9fc174e27b164f2944ae27e117abd4e40',
  '245016cc7a9564cadab6e62a0adacc0a6dae4b17',
  '51ea9ea2804269cb8e2a5951406a853aa5743913',
  '0db52ceab038edb378393f1702d3c5da8c4919cd',
  '227352e020f836dbc994ec56b9846e3c603b5b4e',
  '9df24d19af6219730a014c0a90e192058e5d11bb',
  '2e886dd1455113869be63b6f0cd4ace750defb2f',
  'de1f4d68fc860b0dcc70b33489a6a267f3b4b3cc',
  '9ee07badb1481f795edfceb7f31d4c286b3cab7c',
  'b1241c44d51fc645e96be3a82315686ac42fdd4d',
  'c6b20984268836f72b302366a83e790802928f3c',
  '5227e6f299833ebaf521d3a776d13a6d24a05ca0',
  '1e9af2ba86ca7f2094d33ecd9ec4a95243d4f966',
  '75a4304d3bbb8a4e42735b3c09115fe4d1ee4f9b',
  'b14fc1abad0035b9c56da8b2e1dd9e4289e7bd4d',
  'f34db35b7f1fbe4bc7ff270855951914f6ee6ded',
  '7d9224543c287ce9aa1fea874ec86eef723128ef',
  '3c8de7af0a52d5f95f9022bc21557e308b01959a',
  '729f1d4ab85b154ddaa0f0206fa3946392b6baca',
  'b74edfff9893976bf7cfc0933d21e347289f1ec5',
  '4d794dc1207d599679f926696e11dc98b78dc6f1',
  '1eb10c7480490a00ee81a02cee25189c7c12654c',
  'afc52c318ee88473929ed1a7fec8810be03a09fa',
  '63eda1279558ac1631a9257b38501bb5ea8188d2',
  '085c2e4ac0a268cbeebdcbe43e1518c95bba3156',
  '25952ee5252ffe41a5a704ec415c77bc5ba87190',
  'e6fc41cbc58ac4a0e09d51162a4447502468798f',
  'e0f722522dda9593171455f6eb53808c36024ad7',
  '5ae0206d62235345e80a469720349ca43a45f058',
  '1415b9c327a9a9b0a68e9941b1b590e0e8674b3c',
  '2b69a439db3f70a8dd25e574f3313ab57d248c9c',
  '2a0912e6ea590d811a8a4c4091f5e020655ca540',
  '3dd1f13bd0fe9f6f030aec6747909a9e24d6ff5d',
  'e2c046aaec9f84ea3acee9ec17f31278d425ec28',
  '0b9ba019028062f81888d0d03870588efbdce82e',
  '04fc0c8b831c823b5956cf1c5f1f670d13602e4b',
  'cdced46693cf1a656c459772a22328f30e8276de',
  '3e7ab731a8e474fa9061f6ba85871007190c1796',
  '54516f3624a9cb61fbbff173f04097180609a3d6',
  '825e1ec64ad791dfab3cdc0d9ed5b0722224ce83',
  '92884cf7afee1eab22cca5feffd1ea17a0464c0a',
  'd2d3ac174a107b846f497be701e232539c4511f1',
  'f99c98ba7da80111cf159d846a8586e1053393f4',
  '9d97c3658346e040438c0d7eb01623afe0b8a73f',
  'faa77d8e18ff9115ed25ebebba166e049d510eef',
  '761d64eb174669af53e11a4aef03f137d9aeea69',
  '311bba77c2fea98ca4defa3dc9b197099a0d0b02',
  'e8f4b035535fa3daa6ce97ef5b28d865debbe69a',
  'b9964b2839f96452e30dc52eecb88d1d3ed5ead8',
  'b9e22540b54d568794313d5dfe4d898bc06014f9',
  'a638ec8dc528214eef87cd82bb2ac17a1c1438bf',
  'e60d0fa5ceecfd8c5e6bf8ddc9b1d0d477cfe1ed',
  'de214bc3b6faff208c893363adcd6519d59d33fb',
  '795d266387b73319d57ddb0abe3a4a0dc27c7d4e',
  '2c519855b19d40a025a5da09ba6af5784c334ab8',
  '6ee058392e54929591f18e220d453d99efa5cd8d',
  '7943182d0bc7c3a98f3017b38e19e4241c5a3aeb',
  '6ab8f21bde0c48187d91438730b1df7dd71bc89a',
  'bb715e6ad62e01e21830dd97b90e5e170cadb189',
  '3a97f328752cf8f4eb2a0c353f94660483349878',
  '039d8ccd80158688101f6b8d344d1cd067e9c886',
  '6e458b87c9b27c4da7b983a3ffac5de8d5c2d419',
  '79bcfa1289a3125307146ea6b075eb10c97631b2',
  '4a989139979519202d8a1280dc01d1e4d26dbc0f',
  'caca75fd559261d045ce52ad1f3a53d3df86cc65',
  '79ecc3a455e2aa6cf279d106f67521cdb7e29c35',
  'cc91ab8bbb9e461ffef01d4086ba9ab5cd3779e0',
  'bf1c46d00decd05b9462cdbd28dcd71074c87727',
  '444bfee91af3fd447082c4b107c5af4f88b22587',
  '5b15b3a63ab97d7b0dbb445482d8f32c9fd8d99c',
  '5fd1b34afaecf1689ff7ecc96a7b0b953a934df3',
  '18697fe843b1f30027bdcf1bdf6c4bcf1a5ed8c7',
  '82a2c1566448f8eb9ece18bb23dfa639c844c2c2',
  '13b9a193ebf54917c726bd35930202c3e6088ded',
  '042802e4509c7aaf55ced5679ff62e99e63436fd',
  '4684cada89bec041f8684b67756df5d66bb5d8bd',
  '1695dec6b5a1bf632b005aecfa352ee6529e1a68',
  'a41d153707903c81bb38c04ed1b36477d463ccb5',
  '250a2acb067286794842e3182d32d80632bb3251',
  '5feeb5a06322e6a12e3dfed141c33a872b63072a',
  'daee28584931020d59f18f250853ba16e2e4f4bf',
  'd4cf14c542ade693a79de689d24ec29ad73aee93',
  '14f812937489fc5f4a1d5bbfdea6173c1e183168',
  '88c88419f2991692cc754debaaae485f1cf2a6d9',
  '5eaf4062f46f3d28692f932c12a859ad642bfbeb',
  'adbf53d91e55aca49a09b4ec401ecc0efe031257',
  '6be74adc8844908e84291c9201355c6b6c4644ee',
  'd3556641cf3912dfa01935de218b14aeb7017ab3',
  'da4b5f530226890c4c4f1142025ae8088fe948e8',
  '5f1bffa206d3b4a5eb2f0d490c17e63ea442d13c',
  '542372f215dec9a8a02f62d556f6e990c93080d3',
  '6fdbe02f82e1fba47d68ab72328baa48a0ba6c0d',
  '03f9c4604a52f927a3330cf315f2310ef5996f2a',
  '6a0105ad80a3feed947852690830255a2fea32c7',
  '25f531cd76e414b16c22286ad9fec7e44f422399',
  'ef7676a5d7d9c4def02b95954a4a70b5ca634c7b',
  'af87014e7445489058c525c129a19179aa8e7dd3',
  'f8d70237a58245432b2532f1c4faaef93dacdce9',
  'c384eb4891eb7644fef5e10c98e038a87491a7b6',
  '8db841197000fe3bfe163ffe303b0401f2e2c392',
  '3e936f4d0d6869132f363795dfff212ee44cfc1c',
  'b123b479412abed050705a438dbc610767366dc4',
  '44e405b61649761a5abfe018ee02b3c77606e12b',
  '63af489a47df203f0f7b95b88a9cffc6900d3929',
  'c7462312ec5e6c454baacbd23d6ac9d105e71e51',
  '9459cf40b17a7475c8ae4aa7308bbed895ca0cd4',
  '9facaab1ea1e12b3114d5b23ba2114731e1f8ace',
  '02595de323e234cadd29d6c90e197236d0dade79',
  '7c766788ed468ce128bd29ea64e41f8bdd6d7406',
  '92f054eef8800b94a71221b3d4f3bb41575f4d71',
  '348a67c44af191a25022597ae667a88a141a6cd7',
  '97453fde37492ea0580ba1e70101b3ffe8a4300c',
  '62918cd74fd0161ca7728de1a2fe3793287e22b7',
  '48bbf23c6d89da878ee0784aefe216986cccfa87',
  '1d93c39afce677f1a3482cd95435104f710e9871',
  'c72a398586676ce0c269996fb2dadbe10128d875',
  'ae8f44c8525bf0a479a48d5f596ac2b79c046b23',
  '65481fd35e675d2c6aa2efa2b4bfaaf6a2793834',
  'c70477f28cb10647149cfa2007cc8d29913daa01',
  '3b861faab7c109fba1bebb3403aa994cb2657bc4',
  '08bee48087f1058a46d773f0e4da803d7e3b98eb',
  '5e0bfffa5cf3a2785f375c311b6fc5696a14184a',
  '7e98e28160c0b9ee8db97665a31f23e92f0fe5be',
  '27647e921bdd616add7c9662838ac60166c08812',
  'a8c9360b76ea183859fad9ac955c66f4537f1d28',
  '75c96400d79181d697f31495081edabd643993a0',
  'ca217bc092edf3f0eaefb00b2dde753915eaffe1',
  'a165fdcfa27e4f14e8ed72ed5ad16543a87b38a8',
  '4b24854ca55b26564c9abbdf9ec9f81efc1518f4',
  '5a30fb03d7f870adef548130e7fcf173de0066fe',
  'f5ee7d6c2f1a2fc1c9576d5f60e6d7304d30e221',
  '975506e9c73ca3ef47d91ea628f065149a314eef',
  '700e127e9c1c9262870cdf07e641a1836fb9e382',
  '324f17bb1e9de445c3a8dbde52913b3e8f927747',
  '14b4cbdec1ea8acde7e21009aa4ed931f4a9b00b',
  '7748fa9fadabe9c0db0bc4343cae2d698af33a96',
  '645bd8d3f0d8caac7650cb2d9b9a6b3283fe1a86',
  '0626e48e9276c3a1aa1705e71857b94cda4b14cb',
  '9b6c538e33beee0e6cb0ecd643e97dc3d3e0bed3',
  'd4070b19c3fc4c964655d3e0cb1dca463c9e8c42',
  'aacc5e64b9417f75017b835f8875dfcc0fbb35bb',
  '0741e18a170fd3addabc58b3b2201dc980dec196',
  'f07b4ddb28fd476155101cf0fdf5678b7929797a',
  '1bd0adfcf1c75bafc1ba3fc9b65a1a0470df6a91',
  '4042aafc4a96e53149f71ba716bd444dea0936b5',
  '4258cbdef7cdf79dd208145e47d9c81274bf812a',
  'c46b9c6a3a23e56ed924f9359bd432c8418754e1',
  'c2b97f67ce83a965bf0e5389d099739abb6afe24',
];

const TECHNOLOGY = {
  channelIds: TECHNOLOGY_CHANNEL_IDS,
  name: 'tech',
  sortOrder: 80,
  icon: 'Speaker',
  label: 'Tech',
  description: 'Hardware, software, startups, photography on Odysee',
  image: 'https://player.odycdn.com/speech/tech:8b.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const SPIRITUALITY_CHANNEL_IDS = [
  '5dd00fc2d4cf5dca0c2402d0b52ac1b79e8b0030',
  'a6697ec8b67e43abf91abe6489bf3db2218e2e20',
  'd3ca14ed7a9f4d7773b43a72b3953e55ca771a2f',
  '0ad9a91c09d1779e1a756c384a63151052e7678c',
  '2c4bb55d142a4f8f9a1ebf3e3e686b8c72726638',
  '9266e3191f3dc7aefb4e80ec62fc5d44405b0567',
  'cedbc4b95325446e9a440e4ddb76c963895cb0eb',
  '58f3cb0adccfb5eee2b57dc33ef4a132b92eed9f',
  '9d2acef1cd2660639c1b0f83dd548c246612fc34',
  'cda9f71dfbea73b973a3a815b7fa170209a6d738',
  '8d2223082bc3deff7f1a0599d31f6bc9700e18d6',
  '3174254520b5ef2c5d0d0a28e54ef7b9a003dbbb',
  '33c6dde1033df16ab609776aa8fea61fa0db53f9',
  '3c4e7e3242641aff266193b575bb79cf6ad65c7a',
  'e6b32109ecc6ab87fd05ffc7c490b136071de6b0',
  '23b274b55fd9c3417df4e13925ca5052ea4b205c',
  'a8280a60215b33cd641e8f93dd473763885ea7cd',
  'b5f019be09d2a32c5a8889a0fb594d45ee6d9dcb',
  '3ee5ef76d5b3389afafab6860fc9844dc788e533',
  'd7fad1b1cb40fd7f8009447c88319f5327f0e205',
  '33264ff9589342a5d6b4ea653d9eb7ebd20815a8',
  '9d9854dbb4803347b66f5de211e6a6881074e800',
  'bc0e0826a5ee02a4ccc1c9939890666a7cfe697c',
  'd02cab9cde98d9dd27d69da1e3c0ae8d61498312',
  '05e8dd4308503aea2e6b431fe863b664714ff776',
  'ddf26257a9b3c4ac082efc66b7d835004acc7156',
  'b89b69a901db9a65a79086d8e9decca5b37a21e9',
  '6bcb362c6f569b867920951a2071039a9eea3447',
  '1b7b816e43896c8f1c1fe75be13d09e3fe4def64',
  '9f488f5ef57e257e594523da7e29f338edaa5152',
  '33746e74353b3dad207f0d5c433786dbb53cf434',
  'd8d0460ce6cb78901ebc666a1bff1c94d0d94cbb',
  '607409260a835b24c7352e00db4388b92ccac094',
  '944119e329a496477105c6bd4c2e66066947b8e2',
  '5a45818c4fe1fc53a67b48142a668c10fa2a0982',
  'f4849763b6ddf7c6a37818370a0741a710ed6622',
  '9aae09657faae4d7e9f10888e7461bd2c522226b',
  '6139c8c3628d1e5c94bc95ad2f1856fa40269987',
  'c8a18f3d0c900e169c847df8390cac9993868475',
  'b7c039a8fa2c05372045ebf7d7bb2d73c537a000',
  '0d9342e7405b4aecf660e9a758cebea1f4791113',
  'dfd89ee4c2b3b5074845d804d848ecb7ce501206',
  'f87c11850a4de4582c484c2f31d6ec23cdce03ff',
  '99ad7de4f35e742de66a5bfa27a5349f79e7c33d',
  'ddc7f2030c474ca4c9c0043bf19ec0bf79e2783f',
  '116d17f196e19681d00d19803d2674cd740391dc',
  '823a54c096b3606ab88a7865dfd09164b4867d20',
  '1dd1f89c9fec0450b8f446d88c8ca7eae806c54e',
  'd9a2c1a1e4b42f5e645953529bea5536f3627786',
  '38a83de67a246c72cd793104073968d7440e3509',
  '09c0d7ada9f49732221883f7c85287641ed85203',
  '483408db97f7450c0096ad566acfcbf7c1b607ec',
  'd880cca11075d8e78e2f1671280e0512ab4ca608',
  'a9de9f30f58331c70a3c399cb2300846f5191ecf',
  '8edad32d4acc10bc8c1cb82a260fd17f3128f208',
  'a6f5d882bd2c36e2d61ba923f293c774ecccff72',
  '5ef4df7e6dc050fd7b65abfe590a3b95be9119b2',
  '0b2060e1576e09e538c5a29d7046bf62f293820e',
  '15b7d41df3d491bbc9c09b763a41577c2c1b0dab',
  'b726c6e5912a17b994cfe503eb2503d2a9dfe1fc',
  '0aeefe41bbc4feb0b2f7eabdca8f6907b4708bb6',
  'e55033459f8319a221a16cee48f604e2b0983a01',
  '63340408f481fb35d0f4315599822e81a0b6215b',
  '3f7fc7dc5d2e9e5a9f31403d97a3ff13bf264b93',
  '1b1d0aace7734749da2bdae54c096ff6f671fb37',
  '28aa777470eb76f25267d2f6c78e58ba913d84b0',
  '7d1ae937bb3aded69c418ca42176f96051cd9ff0',
  '84de15b042e6a59b9f83c60f529e1db706928367',
  'af1480d22229deab0054d58a66eeb01377797473',
  'c9247b52064a8aa9f921c6450da42c3fa2fb3f15',
  '8d935c6c30510e1dfc10f803a9646fa8aa128b07',
  '2edb3f7a754b73c06e509384baf4e69e9923d19a',
  '131713c86c8ee91f028244cbffb1832684fc6c41',
  'd7041a22ed725024949a9bba59cf2606cbc1bc0a',
  'bb06f76907c7767bb5c38ff262592b23a7e2b01c',
  '4e74acc324a15adc788d733f7c6bac1993bb90dd',
  '5da075e6fb35168c88b6261ee5a084785f56c7a1',
  'a4d87c76d9cc0fe4c2baee1491791a526a903a37',
  'c0fbf42197a2c3ed15455601d83efaae4a4ee137',
  'ccfbf1017cc3b662835ec920903c9964959bf033',
  '14af945764d2f24ec9f10c489eea1ae47e5ff902',
  '1a1a1563dd0ab661371e44bb391fbbd846c82f0c',
  '08f31fe3fa6caa14799d7a7d1de8713553f5e8ee',
  '4f274af7dfbcfc15803d32eef9bd36a441aa6de2',
  '1438fde5f3dcbfc0d12f92048782b158a4f16ff3',
  '86e0c66072e975c440001aae0b7a73699008535d',
  'e74c3159aaace29a29c7ccd722b2d8ecd6815a83',
  '0d22988fc5342e7db1ad344c23c89fd239fe46b0',
  '365e2e5d0a92c07ed9992b8a6be42b077bd115b6',
  'aeeaf0d61e52123d7137a7a6201d3e77a5b6555b',
  'ecf540cf176686609a96ee3d610aa772009d7af6',
  '64adb5d029acaff293b2934e870d3976760a1353',
  'fc53a2f94564399d712f1256f38b8e4a05bd82ee',
  '58291820f24eafd8e968e91caf17a92ba94f17bb',
  '08bb7aa40edda75512282e36370b9e86eb35fa33',
  'fb536ffe49acf76081bda3e75717d9898f895493',
  'f9d910ddec1dd61b5ad762701eeed2430aaef964',
  '9c29c8230b93cd4e146293de64f85621f7eaa783',
  '8d408339205e8bb51a1f1c485165e04b2dffe075',
  '07c6b08e30f70c48eaa7dcf82a21e3f5419c924e',
  '7d5bc03f4f2f8b05f7b16e940fa0cdf11d3ed28e',
  'eb95fad0d0606c8e2387c90a4d4cd872c252a7b0',
  'c1dad322b97bbb56c389387ffd1d88835b626a57',
  'b834f446fe8b3257776dd05925a0165fcac5d9e8',
  '5ace87212b008b305b71564ff9a486bedfc00160',
  'cef4f5dc72fbb194ab9007b31fbda55490bfade4',
  'c2a3cdefed6f61a65c14300382a05d7bde73cead',
  'c066479bc4a190e364817bdb5f740a1d3a422fd3',
  '970c68210ac7ffd58cbcbfbda8a8173ad0f93452',
  'aae6d59c967de2887a104e422bc4e67db6735bc0',
  '269a8c29ad080ee3eb90f04a07535db46b9d2d0e',
  '89c1f3d3ad2fe9fd46073320c4220360d84abf4b',
  '445499ff61afc810762e28e58a43c8a1c6dd708a',
  '548f6788167847f16ff6fae7ecf4159794a41119',
  '63ef73befce460f05ecdc4ebc90d3bfc3810923a',
  '7b8e91c868a7299356426ddf99c7653d42d24ab2',
  '51d34684ec97cc7057f2c371d8edbadb6b841707',
  '175ba1b7d4ef800cb1ec573be691ae40b870b796',
  '2ab0383ed6914ea240755bef6e6f6fc18392d706',
  '669bfb365041efd709215ef38d1b0bc863bf722d',
  '56f784fd6682f2b75a64be95f9443eadf2ef4a66',
  'c2cb48117c0f14cc399c9e011a539ad209bf81f6',
  'd219766a01ad6f348dabe007bad2c3fb673de82b',
  '7a314ae8782295a626f5b32b669c08feaa4872e5',
  '057188d8f31b5fae7c7b1eaecd4bbecdbdb47f97',
  'fc1ec17bf8cf891bb680c9963fb13824176e7260',
  '5a7bc5cc4ff1bf4410336e161fa02379d17f9385',
  '30b14ff15126d39642889103f5b6c5c1373461ac',
  '793c5efacab665fa66aa27cb174d169bd055c90d',
  '3e6f0909f8ad2c651006ddda820b4ac0b41bea9a',
  '0a60fec22940b963ade797e7365466aef18080fb',
  '046b4dcd8d0f3e0fd50bb21352f5daf326f7ccf3',
  '4e67995c337215e2e15ba601fbf7805859d61191',
  'aa7bcd1d66d8fb056688e8586fb72767de66459d',
  'a462fad0727fff8dea55cd352165393ba6c64d69',
  'f42bea429fcf9d2b98bc672808215cde9e6c8cd6',
  'c85eefb8dda300915718a126cdf4be51d0fbfb1f',
  '7e1f529fe93732fdbf6f5cac18625b116c40fd6c',
  'a8d7d022fd582a40b583ab262dfd92ea45ab4925',
  '00ceb55920d9fe230991a4052ef91c7fe9a73f30',
  '0ceddb7f1d17c8f059792cee1977d8c8b13e3139',
  '6f3820530f912f145bfc07eb89e72d49cd4726dc',
  '45f8e999090f91fe20439348ff4d271fedff2ae4',
  '4a9fe9b60a1b46446adf122ba5f0d2076290b375',
  'ccda5020d6c9708e771c49ede653cb09b1492998',
  '5bbff34e77fb0c3c930d2a6abe1dc836f25a44d4',
  'cdaef89f72dde4ad6d30337b19b29100eb558413',
  '6cd19dc120a9c2e09dfe5eb62e62a5c9f6d89df3',
  '2039632f27446afc16d8a933ac930774c301717f',
  '338bfae30a9828449616d33549a6995060c489bd',
  'b5a8adeaba481916d8b34ae79b4a3fc61141ad1b',
  '35b5817ca7353f31e54361424500b25b19732c3b',
  'c21a51c7df990d4a049be17e0b2cba9fd45835d7',
  '6259aac793195c93a4dce1940e2581c238fd2021',
  'd57ae63a3821575fee960452c52a9a722c429f7a',
  '42f4776bb84d07257f23402bc01457b869fe4a66',
  '0246b3d05b02b422aba3a1e2242c5e0b65b4a03d',
  'a788a6ddada7cd5091f3b147c825fc9006695d54',
  '851e0216322f805537932efb254e9b6ece83de9a',
  '930ff675bc55021de7a6b8882aa5f7702d0aaded',
  '10c839e60f37ece945873ebca99749d98b776984',
  'caaefedd3455d10a65520cb220541c8c9a3a0526',
  '30b1b17a1d94da0ad8879ea4fb32d7e7d68ac954',
  'b09ed2b353ee6227d149fa734c6330d266d41294',
  '447f5aee68522d0e3f2f1f49b225afff95a13b83',
  '2b338592074acdbc485d6b197626adc419f0b3eb',
  'b007752c3fda2e4f474340625685629a9c477a56',
  'cfc8e83be823fed5d8b3c96eb165660ecec90bea',
  '72e0750e0f0e8e7cd2a575a32dbd67fd76a96890',
  '4a8c0d286005287b86011674edfdd4013b08e22f',
  '6118df9357ea26706e0bc17d6d5b4542eebce13e',
  '062111c6a5bf1d4fe442be4db6d66fa3684a102f',
  'd29ca7dcbdb6908089db525291aa5c3818ca61e9',
  'd4d2235ab7a3fc2a2d461fd30865df7872209279',
  'e9d0e683c4af9bde5b3d907e3a9bf8c8ff7cbf80',
  '541268db723ee0bd15a221af35cdd6985ae497b8',
  'e721b99ddda1fbfa9c9dcf2765819a686a27c579',
  'c2b6c8f86dedbae881117718f5d37250f6da2564',
  'b0c781eb643b9ba43948a477ef5ed2c26f4bddf2',
  '9eda26fa6dc013a3839479d3d23603883f509308',
  '1035c23ea9d903edc7c16350188cf7ecd73e989d',
  'a0a0dfa115c6c4c3d2cff06ff5ba478a2a4a74cd',
  'c4ced904b752964357757e0af340561a0b78e505',
  '27a014aac2b0cdb7f780e151e0dbb1b635dde7cd',
  '902ede4610b7f69f3cc04ec5fa324a516052ffa1',
  'c45c226a1d4ad169858132b89345489b1dbfc23d',
  '55d0d54fc41fbb0d512bc8bdc9914be9f1dad6c7',
  'b40443143e250cf7db4674c2dc92e2bff2f15517',
  '6e52706c95a28f57a6142ea5bfd1c90e276a270c',
  '8a44fa2335e20ebd4673742a2ac88d0691bf31b4',
  'c98522cc010734b1bdfe47b9ddbf06c942a5a457',
  'fb3bbb82e28968657bc878cdb462d73098f613a3',
  '368065e96b70f58c00e048912dc091cc806080ac',
  '0596f978a3ede7fcdbc035d5f7d966fce9cb4b02',
  'c80d62e8668ddc9fbff2a60ecc18d22250acc373',
  'a61d9f6c687ba5b5ca66ef940ec2abfbe4ca9bac',
  'd58dc2d7b5e8bb5abfa65f3998bfa460c14f203f',
  'aca8fd4f6005c7761d160b8c07573e1469663334',
  'de645db53b924a20a50bffb46a969c2faf919510',
  '59dafee676c47dae75ce1f9db7bc0c7bc10e5883',
  '0a8bab154ef4e77b858fbecc4535f18a980430a5',
  '856625ce9fbd11221a29b20f9345915cd80ce361',
  '11aa2392964d82059ee7ecace6beef937eb1d72d',
  'de01911d536a85900757eb722c1e470ceb7eeb2c',
  '1eafbe5256092e09b66ee2ebcb34d14165d1995e',
  'd6e490e2033c557c8edd49b8a0e9642f90b692a2',
  '7f745cf98bead9298afa74dc16fe736e69c74ba0',
  '766fe1299be1271e68ebf98599642c56e1f42397',
  'ccbe98ebf738e6a1f09f046aff734796a11c29d3',
  'e953d8f69e3d7e3db8c3d7c9fcf69bc3c42886e5',
  '1611bab86906013519062372a16b6b5fc9188022',
  '1c274621394142efd2ba95636f26ce3721c91685',
  '628b866453aaa98d2fb27252dbc69c4be3ced672',
  '1f3a6c3790c51bc6110dbab5fdf2701f782cac6a',
  '2e82e981a75fcef36c0db408b1058be9fa60bca5',
  '76837a75600e82b5cc72976c0166469447da6716',
  '946e1e70b094b69c9ec3e6ea1087d1b4f9af2265',
  'e4a14ca4b144476c689fc5103f3c8118d635849f',
  'd297031ddad27d0b3aee9c3b3fdc538a21b41810',
  '8b76cf86a944f4d196ab3f9d2638b2a452de649e',
  '024d4422dd0af6ef7f3a428ed645bb650673b70f',
  'd0d2142986dce9269217500610e81a347c069a85',
  '9637cff97c20c0ebc8fddb05ca6a8a8914a63445',
  'c13052ebf7d02226db3f66d98cbb4c4480bff48a',
  '2d1d130604faa3b4538e119d777106158ad31243',
  '4cb65a2b5de2be25f168e463c27432f5d1e086d1',
  '46eb435b09d6c683adf4d835c0a43dd9445907af',
  '13a173c275c12d2f52a022f8ed1ca1825917625a',
  '66c1fe9cd566e4f1ee2cc73f7e152b53410a83fd',
  'df57e015d7fa2f0d012db4a8e95500870ea64c36',
  'ff0ad2cbe368582e809e1f081edbd1a3ca89d2dc',
  'd943d9ad81f3f739e8469e2e61cc79753c9230ba',
  'c8c338d824249e5937891042420d84aeab0fbc8d',
  '78a267e60643f0f5d4b4ac346941030e81217f82',
  '0fa6e6ac5d7341fa2583dcb556cf7eaf1383fb1b',
  '80cba7deabeee64b2134a5e2c51267032d72e62d',
  'c9de7b0306b8e685900213fe84061ca81f8b8637',
  '29bde127850ef3aaf0becc5754e0141c4c2afa49',
  '4506db7fb52d3ec5d3a024c870bf86fc35f7b6a3',
  'e0d599215eb06c13d68c8f664f15ab946880fd22',
  '7f565717ea3a6a67865a6f151cb40911b022fba0',
  '2584ff66fd63702280fb01652d83dd2d285895b8',
  '97008e29c980a8965220dabf40823c90aab738a5',
  '18037bdcc6084a0a135592cbfebdef9bc6476808',
  'b42375dfb5c767ea13ae0592f12804d00c0f1db3',
  '1c94621eee232c7c42a2ed42af86b23c5b208dd6',
  '8e435a41e138294ff5067776c6e8f764dbb9f700',
  '8212263696d6708499550945ef3f0d720e7efe2d',
  '619ee06d76a1b7acb8ab3b352738a55d72701fa2',
  'ee9f9e0e8b989c2e373452d374175f0418b56d46',
  '068b01a903d8529280354a16d60df2a6970f336f',
  '37b04749481b8356a1d4ff1e0d63687d14750aca',
  '622062db1a8fed180d0183f01b2a3b8bef4461e0',
  'd7721287ae73de6f92e80bb6c4b9489fe186bb6c',
  'e4ba1be4e560c131dcd78fb2c5335d8c0cf149ed',
  'c15f7d4123043965b7adde96e2f976c18bcf2bd6',
  'e413bb6fb4efcee5e1b1a79ae99492b05ffbd163',
  '695351021c26936a05dd63cde8aa511ab2f1c515',
  'a93d406a3c1e06103cdc31bcc75f145c3e625162',
  'c2ae7a2781a0b1aa889add1753695a1039abe168',
  '9d158fe4dae73f20818e996e668c252001dc259d',
  '3c22f307a2e654503f154ab9b943ecfd212cf74e',
  'b2af2c6e8b04c3dcc1c989be368a4988e42ca0d5',
  '3c969f23a1b3edde99afac9e687ce33ed758047f',
  '69b47dc0dccc1b9109442886883246ac2f9ce5c8',
  '0faeb0a1fdf955652f8c21a09f80898ee7cf8694',
  'd9f6537816729ceb521fb680cce8e03f5092c864',
  'b8951ebee1ca81835e3a55d17baf8a4d728498f6',
  'f5b10e89e8cceefc39255345fc4325186423090a',
  'e47c28eb43d11bb64f51945f07c53c6b37024929',
  '21d35c267df693a2ed279e940d0ff06b773c8260',
  '95cbc2b90f95a30673bfe2ebffcd7b4b239afb89',
  '4d2c834a5d2134fecc2b17eb6939f88e3b1ecf58',
  '2467ee17c90f2bf18353bce513657c7e0c52a02a',
  '066929b60c3984d20356cc71faf5c2a0888a656b',
  '11eaf5e81ccedceba27d4431c85ff1bb2af90eda',
  '6f000ebcc6a392744fc348270d1ea634417b6fda',
  '01fb16d43b73db28ab941592c4e55379c5618b64',
  'c43396497d2a30f393c7c422cf57a13a6e33d95e',
  'ecb640692476b57f8f5392f625fe53c4fdd015be',
  '9e1b3d599d86117cfdf2bc08033dfef979e12af0',
  '86ec2fe8b2d7297393d28b12d3b51b9e45ff1392',
  '68f7ec2877254074ad51ba030abf3f467924263b',
  '345e69c2ef10067ea1ba3202e722808af4c56645',
  '632b51e102b54b896b7682a5540687936b485354',
  'ae343af2918556b378f68ee9e0e5a1cf54a3fef1',
  '616cca8a6341042a7e004dd7a8424c9ac4af0b3e',
  'fa22663d5d0610ae8a94577e75de27d71dbebdef',
  '27097187d3af8a3b34107b12e22fd47627b883ca',
  '9f49f8c37f2ab3e21b93da4a88b0080bf5313247',
  'bd92a813ff6f9a857d1e9faf498b9b284a3a918a',
  '3db61da94c803d7c416c8c0dcecc3fd3056f852d',
  '0015cc9b2d91ba96fef5a6d5bccb8af2ab6a2b7c',
  '2694930a1e49a4cb84be08e228620d16b589eccf',
  '7cb3a184a18596c377b62c99c4473b4570f27917',
  'cd73e1c08d9e82f111ee6c6eb0b47a6c9f77ff82',
  'c58ec8200a0b71ce8b91e7a8af588adc9d5d1c01',
  '11c5bfb0421aff9ef2a08dde1680a992cad41605',
  '9bed24ef0429d6e68e326aaec0e816fa4be0c1f9',
  '7f8d73e000170bbb5a45548a00b085e72b0d2623',
  'e32f194a7c4332d4e2ed818dfc7a7d5f91c4c8b7',
  '1eafcc57b9d07cc67a5861acb6de579136ae41ca',
  '842dcc947fd593c3b7c9d73abc08326e1bdb4e4f',
  'c98ea9f9ef25b24891b9d6ccb282e77b68a18e32',
  'efecc349952be43cd6161194d68ca3644ef7d493',
  '850712732a4a2d24a0a170ffb81aa1c844b500f9',
  'dcb47b6c4cd13ccd4f78f64dd2fb4b94b4efedf5',
  '3a541bd7194501df9481a8362a5db56fa2d6e4fc',
  '838c77e51b02c9e924827c9545077642eabcec2e',
  '9303c48bb339e62d2f3adb3b3bbafab9165eac8f',
  '4d49290add07b8241a352807dd25b18453819a03',
  '305e682b180d71cc941a40b14ebf799ac974544f',
  '38c7fc2866fc6b70c66ad6ebab51de473bbb6039',
  '1ce4ac7dad50da32caddb8e568be9c96c991d0aa',
  '9c1d448e990cc6e86a7c6290dde9c464940dce64',
  'abd24daaab78de35e7091182b2bdafae55f7ed7b',
  '9005455186296bbcbf3bdc64d1d609fa3fe36dc9',
  'ae40577b29045df1ed9dd2a09dd477eceacb9d68',
  'da474df3b059af4f3af1fe05afe68f3cb7277350',
  '042f0cc234688ba28746c4d6be9677f21b1b4fb2',
  'bee907bf76dc330e9670b43bf96bcce7a5e5fdd9',
  '99ab2385cc93c947a995209103b0bb68629c11f5',
  '8fd7a4d71c330d1d518e33da09a9eeeeac6a4279',
  'f0dd240f2a5f4dfe18fa3b7e8a2b17a431cc5938',
  'bde1ecc1170a5bbd8a5874bdba6139221de08319',
  'e8fa0cd8c282e2924541f45a23ff8e795d9f8e22',
  'b1f0740d8e7f0ddb24de35e796c59b5ecd71daf2',
  '54e7affac75cc99a1706fbde17d49f578c254375',
  '05282e4d83ac59c5910271fb8aa8eec42983b900',
  '2dc89e0ac501ac220bade4d454d6b61eb4be675f',
  'ee596c8ab88f570b3ef5e8ce7e57b78934d05ec9',
  '08993fd4e7466635e47e4dc39549faedbc11b5e6',
  'b02c63b19454523e3c849b8c22df151082fdd807',
  'af3f713b71ab36889d7456841e8f7ec82c280b27',
  '086be8627962e8873d89aeacd6f38ca4a10daaee',
  'b19d40f7cecb99b5f8a22bdbea92d94fc6de79d7',
  '3afe5b11f8b594d23c4fd5a2c87f99cd3993a1ee',
  'b886ed1e58f41bdc927097d871111872d93574fa',
  'b1d3098fde7af26ca98d152d913229b63556b197',
  '82fb2d403d8eb5a0ce1e30a026423e33a360a187',
  'b9967c388a6e22be4f4709db442b00e12f5517b3',
  'e680c7f0520f434f2ba59b0389175b52ad771420',
  '286abf0cb81ade033ec887a75ec61af955950444',
  '41d0eadc14e21cf88e45cc4053ae09778774bd2d',
  '534dfcc23a8095e210b1f05623d2ab4a13c47ddb',
  '5fca894905d8516406a6d5fc01e8988913a34b58',
  '84dc0828224acb23aeb9ba84bbb64bc7cc4a3afc',
  'a09427cf81085ffee4683a12cc9bb901ab204a37',
  '510719d26fb282d15456291a8f4607ec786f89e4',
  '607369e4481019193d41309b0e5427fb5dec77c7',
  'd16e5b9951aad4dfce60a222f8ba52689d80c27e',
  'baabc6ba08c79041c6d82e00e118157721c4bf4d',
  '04d5fe082368eb2c6a994258c845779a15fde2e6',
  'b83aefb7c1ff6fabacbc31bef661f2767f8a5cf4',
  'ec284fa24e83c849027dc7b1fe2e6858ac2aba9b',
  '2eb376ffe6a12948aa88740b0969829fd899cdf4',
  'ce3b94e68ab72f5892bb8b26672bf654a39b5054',
  '355da0e68a16b7a19166e00baa6ff036fcef611e',
  'a8bdcdfaecb9b490b8a4c7d8da3fc4e8c058622f',
  'c2edc05a0ca0cdd94104e38fda06d2ba09de2926',
  'f45bcbbdabae2a990821583879f26c0e8ddb29ac',
  'a68132c0073d94fc8c8216eb99f6436d7c143ec7',
  'c194425a76088afde3e719ebed4cc3ea45939744',
  'acb217fa1f863bea78499aa137d71ffbea513f5a',
  'f00ef295015ee0647f0d3782e92271f65ad538a2',
  'e2097bcaccc1fb39c79edaf36b951b5783c58156',
  'ceae5f1cd6f47a3e3248981051e0d6c9bb54970e',
  'fc8a893d70db6a890a1e9d3f0147985b7bead576',
  '3b70990452da101bb03320ced20b54bce74139b2',
  'afc9f555e7c8f33cd03c0119b5cd962f5af139b8',
  '805d079e461edb16e5e6b2f4ee88c00e4a4beb5f',
  '6aa4f785f44f03983bd20762056bc1d94512d72e',
  '1ef0195c0b52291123e21c9c990907495d0784c1',
  '8ea04aff5f9a99fe8a3519413d96aa5336eb9531',
  'a37525a5e5b8dc9fe6bc13272390fc3cccbcdae4',
  'd5aa8f78af39d698ec7dca717ebcb010fe41487f',
  'd2f399ffd01c582e4430de710e3f1ab12f248802',
  'c44217b934e683b0ae9a14ff90d8d5670c27d54e',
  'f95008c5d790f05e8442da50ef5860733e20ae2d',
  'c4927b488eb00eb4c59123fde03df22f47ca217d',
  'ef08987d231c746515fb22e2bbf02642577d42cf',
  '4d2047fae3b09776aa43500365378a418c4ae6c5',
  '07ce629a2a1885a5d626373c7583634ea6c78c0a',
  '9601eeb4c789be9ec41ced4840f090de40c712f3',
  'deffec550000a1a5f792374a4ff0fe6a6fc5d3bd',
  '3b003fc37095fb9380aa0d6739eae10548a097ee',
  '8c822abadd29ea9dc5ebd18470a41e0bbae46d1f',
  '677aef124ddf1bf9e5e1de1001ced21d826b0245',
  '5ec47ef5be3745d171a9b9684adf64b631e55989',
  '6b5481dc6e93c396e2f284faa34edd4c7f9d90f1',
  'c9517f560cce41b564709e1122a85d3707689721',
  '3af141a776df6d45e6aa07dc8edcecf89e4e2b1c',
  '3802c76e569499a8d98cda716e74d9a558e81b05',
  'a88990a9dd375801720c5f076afa63fcfd3594c7',
  '207d473dbe8ec67b038441b40c34b6e031ff2115',
  'ae33089fe8bd822fd04a65745fa594083c10a65e',
  'c0e2565f3fb2aeefe1e97ab222b2adfc1c20dcab',
  '9527d400fc0144d7b600301279680df587c3edd5',
  '92565b6ee7d37c9364a1ce357d757820c5f67bd3',
  '193409b2b005ce0890d9fd0d522618374ea993f5',
  '3027eba78bbe82f4b185ad2b254bbcf81167d7ab',
  'cb6c05b2c013372f8d44eae41bae3e13bc618c37',
  '42cb3740dbbf6594bc7258025ed4eba82e4a227a',
  '18dd9689af003832d338b58459db0788bc36edb7',
  'f64180d0814ccc35b83ecf89091c671112e90c8b',
  '7abe0087d719ef679c2707ee82817d3ecd180a21',
  'fb1c406cb19e0fb491ee924d530fee439b28d24d',
  'b0ef1e4d16c4ee3ecbc9c8b2d33e0d83dfbd9004',
  'ea4e80c9c76eade2df39eb3f605c921ba946ebe3',
  '56f9019046b5ba5c7095eb5f815ee1736606df9d',
  'c3cbfc4fd945caff82a3023ee7b5f7e8eef6b133',
  '5981102f1d5bd8fb301fa440a103488793782879',
  'ee7256336319c019ff061cb2e4523cbd376ce8cb',
  '4f1da57d1d935f3e42805a008f054145a15660c7',
  '1cddd04c99ddc3b09682cfd5e5cfa875f2583e5c',
  'fdbe5a3602d270dbb2b99ff5923312c6beaa76c0',
  '33379d331331349e25c4b4de84573ba1c99b52a0',
  '3fb7bda1429cbf0dd24d3956fa0af621e0632f11',
  '04b0f53081b7234172c5d990530096bf43f13192',
  '331ff4cdbe50e7b8067f0f6592bde60076d24d2a',
  '6e3742f449830627ed54b7ae71091415e03f63db',
  '6a2385d2ffda61d93bffca0acaff4d65803b0bd8',
  '2d09d648a286fc122188c1c68f2f7838edecd131',
  '0c2d9da2b2296bc0c77307a708322fc72bb69971',
  'da6adad5398774079d875d07fe2da63b45e35c2f',
];

const SPIRITUALITY = {
  channelIds: SPIRITUALITY_CHANNEL_IDS,
  hideByDefault: true,
  name: 'spirituality',
  sortOrder: 80,
  icon: 'Spirituality',
  label: 'Spirituality',
  description: 'Get your spiritual side on with our Spirituality category',
  //image: 'https://player.odycdn.com/speech/tech:8b.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
};

const NEWS_CHANNEL_IDS = [
  'e197f9ac3ba0693a15ea8367546cca3fe92eff22',
  '0a8be0daee096228770ca05e05a9b92142b9330f',
  'c1e2ac692443a87cb27e30af97e20f7f7056e5c9',
  'c04ab7d0ae3557094a1d658e9dcc7113aab95477',
  'd11e90ef653220b922ff3a4bc9fe967e5fd21946',
  '2a1d2e1f39480d62e6eaf84de8bfc7dcca5f8733',
  '23fe6111cbb78ad178b4e0f1cf0c978508e9f611',
  '29ba04c46d0fd51df4ff751faa6c0ddfc9df66b8',
  '0e3553abdaa2312122086d347b6c762c5148faf4',
  '091f93f2d8ac60e5ac98c63974aa6e00b1501bbb',
  'cf3725ad38d0037507faf68c864cd19acaa8343c',
  '7a2417b7ddefed5198a0e8f7d78bc4eeba574110',
  'cb283d6cf779ea3d1a4b60203501354b55570c87',
  'd2b5df94532f34127da75edb32bd71e9707e42d3',
  '7d104dbdff6758a04c9e65b09a82849af8312a99',
  '87c0cc940f226b721294491add6637b877f8409b',
  '582379965e114b28da761331c4a20850adfcec02',
  '3fc0d419658588d5057fb3b3f09115a9959aee10',
  '44ec057dbb27fc81e5201112ef5f03d5dcd025f0',
  '69fed8b5f45e8b9005d90f4c037fde15a53280a6',
  '14e0f30042a9a75faaa5d825af3315462cf00c00',
  'fed08a75b2422fda97ced64c96ec98fbc8a1b2f5',
  '77c72b3050341ce79454c8f9a963de9632d360bc',
  '8c99db612923b296cbba9adebf5f87967c1edcfc',
  '8bfc4c250f5f4874f0814af239d086203ed4b210',
  '6b9a1e7ef193c6235bef154bcfd983e6d9712e8b',
  '25bc5816753c0bab7f8bdcb6b56ec21c1a2e726c',
  'd0d2142986dce9269217500610e81a347c069a85',
  '48fbb2dbd30e981d98d2b11787fdd4aac42a14a6',
  'e457185b5a1c00092415d67e94891aa2c233db48',
  '05ff2bc0c088660144e3777e4a2697d7c401f95a',
  'f8fc02e3642d7b72af899e237c66bbd2cc27d60f',
  '8f5134b084638b8c91efb591c2767edd991570f3',
  '648530ecfd5ec46085f174ed2581183871711b23',
  'a1f8c167589964d18ee8cc1bc238671a154a48e1',
  'b0218fbed94d8ab4fded663ea07d791b5e7bede8',
  '3e91bf12dfa62b1edc86321f5bdcc5b403af430d',
  '9fcd74efd80181ace357a4283f169d3e478f74fa',
  '92fbbb57e6edf595bf17979cf330ba722b29d262',
  '826f308a3c25b9136a680564637badcf4177d4b6',
  'c718a85496d48288cdefab1f73014875c3e3f29e',
  '7d9e663e7341cc8874dded732b13229a8a29f5db',
  'fdffd6cc8a929deb329b6ebd029c1d6422422725',
  '82d98d1619686434beddfa03bc0831730770a16f',
  '49f1876b382a69414a330995908e326c7b96c132',
  '90e0ec6be0dee1dac31372863984e0363fff98e9',
  '0d6c0685c595bda0d6154504fc6a54c3e30864fb',
  '6d4edd9891e79375d3b70b32cc008b47a9286a01',
  '64b49d4313887584160c1e491b9f469aa5a3318b',
  'b04bd5e53b5d72e912d68e09d7f326f5c14fce28',
  'b325961c50c986f2022313220e5b98da9b0004bc',
  '9d4c31875f534dc1f989db6c13b92ab1aab85ecf',
  'f0b146a66401cd3ed71741556bf90394877a4e26',
  'cde3b125543e3e930ac2647df957a836e3da3816',
  '2e1c5a027c1ee64cc16b5ca7624bd347eb1bf252',
  'de69b9d500baad74b56c4b50cd5f1dd8dc4e6416',
  '2677aa4bb592d97c4be07b80124507db18a529f4',
  '73cf0a45263c0b999d7fb3bad53b87d550ef3f1f',
  '347128e9d1f096c9dc5fdff1a054fc1c0b6cf097',
  'e0174047587a4a534f165969058e6374d00980fc',
  '879045866d54ddc6ec34dc2aa184b79e1d37aba5',
  '90237b022c5be1fef9d003187645da125ea353ff',
  '609bd74a6a72537ec790aa7e10e658076fc0fe76',
  '2f76634783147d068c03f4415bb7674c8b1fe1f6',
  '9fe241d9b270a9ab5b07b0ada82b08b65339c53d',
  'cc99bb39c8d553b10320d781ecf06f60763c132b',
  '4f54ad0b71c10fcb9ddacd116dc3668c14218481',
  '17a490118041aca036b076d6ccd1826cc236a315',
  'f56964900ee1ac829f626d96b6c49321b422516e',
  '54426568a20e41ce499ae8b7240a19d2e38bbc57',
  'bef265cfbe3f3c45769cc9b163a41ac8f2e12369',
  '294f5c164da5ac9735658b2d58d8fee6745dfc45',
  'fc5e33c4e42fdc68b72cc11ea82f754b970fa817',
  '3d84fb4c1d1a65bc38572273bb10580eb2b82f03',
  'c18358e876c11952c14130749fcf4909e45430c9',
  '1ba795b388a8eb286673a1581383eb409dcc42b7',
  '86686aee71b98e6e7849f657fed39cdade9eeaec',
  '92f7d50aa1ff8f14ad2468736322e751a35a6e57',
  '665107828a09e21da855043d2b719d762558ea25',
  '5b00937848d7c5f9287c38bab30a1c5c87f350a2',
  'b8c0540efb042cbc6e9da900a91cbcaae829d953',
  '7983464aeb37b09bf58f46ccbe5d2f6c631b58d3',
  'e76f2d2cd12de2739be54b169a92bb39e7c24b17',
  '0e8644a5d69058271dcdc816cf9cc917c69e0182',
  'f6ec70315c2681a3bad9a0eef1017478f6ebed3c',
  '18c2b13130552cccef4c271465f43b99e2bbfb06',
  'c800b3ca10d8893231f56dac8e4e8eda27a63dfa',
  '496730b88e41e74742fba5b6365435e5622dce92',
  'c1cdc9cbfd556fd0e80776a6d93ee5feddf46c92',
  'e063b0a1c3c738fec204b2eacc5c97953e7635cf',
  '6565000f02b5e9723f6bd76f73dfabe5646a994d',
  '9d4d9550b729bdac8f6f3b9ab9c918491e13996e',
  'fdd11cb3ab75f95efb7b3bc2d726aa13ac915b66',
  '00b993d49de8002be30eda315e5c296709d85d5e',
  'ee1aaf96d05ddf21e96deb681d5ed2f5dea1d237',
  '2ccc51b97c55631a8050c82f73d96a47d0f1aa5b',
  '0355fabcb9e5977150366d3c9776252b13170d0c',
  'f3c08203448c28c41c61dd0d276ab0248407bb14',
  '811836156638dd7c78da8b0c956c526ee4ae3110',
  'a32a4806849b66c520542cb4c6028940436326eb',
  '28a5c47d8c5ffca32b979478cbe9ff1040d92d11',
  '267d71ea4a82aded607f6af710688a8578ee9d87',
  '9614a4fcf9d91e4588eb512165e9c0475f857555',
  '70e56234217f30317c0e67fd0eede6e82b74aea0',
  'c9da929d12afe6066acc89eb044b552f0d63782a',
  'ab524c5a1902d7a0f072ebc4d093a0e500704202',
  '21aab81cf6b7f3eac2a5d934d35328560ba6fc9f',
  '9b8a09c41c834f0d629cea4a083f86e4645feb0d',
  '29d63cc4b23073bb5143b15bfa7872bbce073a34',
  'c1d55ae7b8a67e7a26d1511a462203173122e61d',
  '5a7d88e0f510cdb2355bc4752bf530875d741491',
  'c101bac49ec048acca169fd6090e70f7488645b1',
  '3e4240ee54f465db9e41435628ed8a5895c9a654',
  '8b1c54250e1eb865f657d1b3d553d541a49ee4d8',
  'd34376986bc857846993ff0aa750875bf7cf1b4a',
  '840a5f8f0c5b7d1b87312f9915f7cd7a9283d572',
  '2556ed1cab9d17f2a9392030a9ad7f5d138f11bd',
  '19f251ac6d8f2990839173d932a47b1da62da484',
  'ddb27a205f0c5d17df73277367b6ef8256e0ed01',
  'e325ffd3e6b78f95f96a0e1fe4609539f62d0fe7',
  '5462a0ced61fc3f86225908608872f0fda2cbc32',
  'c4b5c6d71ac9597b69c5559ca0f03691f58370a9',
  '0475ed551e9513cbf81d2bec27488b9f5ea78d9a',
  '8954add966e59c9cba98a143a3387f788a36d7be',
  '79162cdb9d5ae8d7502089a38bdaaf81f902bf0c',
  'b57fb7641efdeb7af52bf96abb280d84c45587cc',
  '833fa9985192d2a185806cad08d4dc9910b1b4ef',
  '5e7a90800637e828ef5a58ec747a68840a4038d8',
  '1576023fb1869655ebdebd9e5b45dc4b06adbb91',
  'bbab55add9f322f353649dd75e5129875d32aa7f',
  '5499c784a960d96497151f5e0e8434b84ea5da24',
  'b88d5fc723c5e26cb48f7aaa2b59963864a635e4',
  '735c841ec371efe8d0d6c5cea937ecfb55f918d3',
  '1f70a98e6d088788ec49192bad900d0e71a6c320',
  '5706f07ecae35b14c0911663033cd255b40935e0',
  'c7cffc68dab8eaedd97348aa0c60767d75a10571',
  'c51219394356fcecf69611ee8f969061f5192f20',
  '87181665a50e0db33488e906c64c5717f5a39626',
  'eb5050ce27665029f5b76bc3499a7ab797c265af',
  '5ee9fbc36807b66c4274417cabf60ad854711ee2',
  '45ea87e331c3bba6083cc6e8eb3a5f4b2e413c60',
  '12f1522d55c06b9dacc4b69b9622c297ff2cdac8',
  '72aba91bfb3caa108b2ab915a80079c35ad2a8b0',
  '2e313fdf44a0f86ffd219844711fe0334495999c',
  'aee8476d102b3495be88dcd8a400d661eae9e42c',
  'c3e07602893f2096631b6179d97c71eab1d99adc',
  'ab00da5da59c7762ffa5fbe343965eff2a1f844a',
  'b543c4d0e05fd38f17814cd51310ceeed209a52f',
  'd4278ba332a768d3fbee367361a80f163608d2b0',
  'ac23da30ec37a7b87ef70fcb21777b8e0d8dbc13',
  '77b4425e0bb0f4f628d196145019e460c4eed882',
  '4b95fb397777e9951f009203e1165826a83856ac',
  'f33b63b8e6e43d64b9438c55183581fa72ccbcd8',
  'dbc3cdf6e45e1b5fd493baa5a92b97111e2a321e',
  'c523ec016c6bbd8a5606659471095afe8d10da86',
  '2c4d9f4b50fbe402f6a25df48b60c09fe2334a2b',
  'b72f83bdaad3f7c242abeb22dd7c64fce521a92a',
  'a96f6c5e5ac3cf02406e02282e9838efa4a40c0a',
  'ee453f8e3b92124c8fdbe7ece735cece47d2a538',
  '4c5229af2548a1f5f77ce9ce408b85dc1532f96f',
  '9ec3e7af22b15925d06eeecc95ef3cfff4e33934',
  '8d14799be9010136ae4e352c19456c1580e68d8d',
  '84edf7e474aba8a285d42a2ac1792801c5a2ca40',
  '1059bfd4d5d843ce7c2f1ea5ffe8c5f8b3f0600d',
  '1b2b7d354eeecbc369df4a215bc62fa769bf8979',
  '9176e6a8ce7854b7639f5e657cb48d3ef0e11063',
  '020bcc69a25b575d6dd7e139fb489d90055ba202',
  '381c10c66ff7a104b8f8859e9f54e34991cb23bb',
  '11c30ffdea4e45ab764e5d29eb4ed7f88e491f76',
  '9bf8f12ad4c6f555fc4a92e8914df6b254575375',
  '22b09b16001fdfcac1596673defd76e576295257',
  '00bf03150c1b20698783cca7265af9c78d9a7246',
  'bdf4873619fe8ea85ca64bfa934239b1a6d1d5c1',
  '9a9133c5306f500ed98caa6e944ac433c554045a',
  '9a1cdaeee5d42f39a26cd2e1aebb838988881edb',
  'ca7c39448b13ef42688567dd468a88111dc53e89',
  '29531b013aaee87b5b38557cadd56f84bf025372',
  '6382292613cc86893a9c2d949c394ef70fd3e072',
  '42e0913aacb8769fddb2bfaa3790c24e0aa6a43c',
  'f456460846ee417b9d8d333a5d71a5bd7867cc61',
  'af0e1ab44d9d29477da311d6d662bcd29b95595b',
  'e6315c25446e80bd0bc6077126dbb93019055532',
  '60300386be0010b7746179face10f65f4dee4291',
  '8a2b4065ac21b3ea19f0f6782d4e869ab60b3fac',
  '6caae01aaa534cc4cb2cb1d8d0a8fd4a9553b155',
  '70c822b0b0a3f046e965ebfe700d69f95b55b148',
  '8a2223c9fb649314c7ae5c28953d8e686b5f4e5a',
  '35427f39b01ce296dc8e42f987f485162160317b',
  '6f86f75f67d74ac8d42422eb96cc32e6245b9ff0',
  '76fe3579658f9d09dcf735766b9b64e19090379d',
  '7e4feb7b361caa5c667fea99a203ddf5c4c0c0e6',
  '66d79d3d0f877e617acbcba7b196cbf09ecc041d',
  '656af8ebaead31b631e779ff2d86de03c2ac5609',
  'cf3047f58f96099cae696f4e04771020a36aff5d',
  '5435094db4cb902b48937c5131ef80787b5651e7',
  '27cf5d9c334f674cb4782c787898230c80775526',
  'ba5ed20a3b65859424a5e9ef500271f326cf5c62',
  '6167806f58aca150dd1721c63d769a009283ddb1',
  '77aacb120e1e6fd2d7e7092adce4862eaa0c9965',
  '7e3794fd44e6e696a12782b0326970bb4359d4f8',
  '9374f08e819acf0cc0338c7fb80a22cf1627926a',
  'd5dab8874e1752bbc139a884331899ebe568497b',
  'ae12172e991e675ed842a0a4412245d8ee1eb398',
  '3bfb2c2650151ac21ce2d6af2b0559eeebb0407a',
  '0af6267c66d95d8dad144d1c5822633b312b0462',
  '154fe78296fa4ece0d3e6d308905ce1a9342543c',
  'a23896c20e8d86145cbc30c75697829933c65ac2',
  '223fc76ee1de4fb57cea678c78b57e9a622421d8',
  '7907235004e01dc112cee07f1915d15f5c15d22b',
  '934ab920d288575b5b24ddb6fcaa98c01b40df45',
  'bc614074d3d37b953898936988dc6744de9a7633',
  '274ee717e6bbfa91c9457b2e5b5d7c19a6558ce7',
  '897be9fe2fe0ea311030c63eb5b58a9365e1f07b',
  '4a01b7503c7844e266e928c0edd0c47bcc229e9c',
  '4c1a99be1ed15c6108b0210cde4740bf64432255',
  '5d3a65364963bd86865a540370eeba87e3ef0024',
  '28e34532c435c0a2ec6ed79c0c38165ceb40ce95',
  '25f6f40235bee8325f1821508d57c1af8e2b6f7c',
  'b06d6a817011fcbd065bffe25236e7c29f7044c8',
  'a906698b9a6ad29e3159bfd72657bbff07d0fb52',
  '79340a63409a1e60a4941c86358967edcc5db1da',
  '53d1950fb08d3a1dd5efc790ca869dcd1a32dc5b',
  'ac290360e299d3bca8843e6d4cce90de9ff6b9cc',
  '69d1f327fe669cac7e41c9ab23e1d16acccacbc2',
  '6bedf8137d81e9b622b51a97d50cabde5856bbba',
  'b29487e5a883bced206851bf976280b61f16dbda',
  '7928dc79dd8e8711c1e17809692e6176ddd591c6',
  'd3cc8e191458575532f495ad6ee547ab6394bb9c',
  '81e19bdec3dae405492bf83970f7fd21d912f55f',
  '123b420474adef3d142fc375a5cf94d5020664cc',
  'a81f87584fd77b288cd7680cefa4cb6a39fd8dfa',
  'c5724e280283cd985186af9a62494aae377daabd',
  '9d92f21b55aa33b149231a9d1dc1db6217b6555c',
  '7c046debd80ed66cd2ea529caaf5552facb81d26',
  'a47c8ab1b56f7a74116f2ae8a716f525fc0315d1',
  '11ef4045587c753d4892f7f1e41317ae06cb8746',
  '7318bf7f9883b1285067dbd41ef7fdfe2975325d',
  '85235fdff97e27d5b8ed82e6f605e13e495d4561',
  '9b625da99b5aa1fb7fbc1bc3eb99e51f276e4c39',
  'd96378c3e752302ba332dfbad27eabda3ef021a9',
  'ddd467b2f6a777007b1b8719bbdca6122a8c7805',
  'e262325d25d468570d082cf6b6b22051c697e841',
  '13b951ee9fde736f732e5b27d8e16fc3bb5f876b',
  'cb46a600ee192c497f26b26588fdf500f178dc82',
  'd4d20f077745fdec7876e1f66fd27f5cd35b0915',
  'c83c2b5b9f2c42623b7d345a6fde943cbf0094d0',
  '08bcd667fc1ad46e08eadc0ceb585185bbffe748',
  '6d1173b12c14d756ffd268a401431cd2cd2401c3',
  '33e1509a783091a041485206386ae39239d13b1f',
  '5774289bd81edf5442aa23693007e611d9be14e4',
  'd6934ecf47581114b9d98ef0f4cb5b37a23d71b5',
  'c64f446ba359272f36c5b4abe02109d0a25bbbef',
  '211cd5ccf2e6911928f9009099a9f81986912b28',
  'ea86138fdfac9891a2e15cec9b2143ef64805435',
  '7f07b28908914885de58a94f847378ade9a77515',
  'f9efaa9351d3c8551bc330403516fe711e5edc6a',
  '0ed18be69abe94681f893f392bc6a32aabb4e380',
  '6317cf5874e1582f6a1994ada5e73f9c8eb3c9c5',
  '839b6a51afc17c1bb6199ff1a6ed3a092f8dafc8',
  'b6f8acd0f6e5fe589e63375eff8ddcea8bb3be6e',
  '93a92900bdb3cc3b5a1a3997b706b38d3c7ea538',
  '2a315473f886c2eccd417c3d55c517899dc7aa1d',
  '0f5a23d3795364a86efb152e045aa19a63088646',
  '82a225a148f1a8aa20393ec66fc8c1a66c02c797',
  'd504cf542d5e93af2812426aec690fcc111b6d83',
  '0f8078f7710ce929ac176e3df0e08cf0b6ab2125',
  '780abbacb30bffd0554a3d6e79764cdc3551a0a5',
  '68eb3f3e884400f60080bb910055bc11fecd26d2',
  '9dc6a41946cda5ae575afc3ef270a46aa4852131',
  '016949999abbbd688ff0be40fade586508763a38',
  '37466939fe403fa0b16367cd46a729f174f9eaf4',
  'e11ea408007fcdb3c4ae25989324272549e09000',
  '19168d9085f63465a4a9892315313754f435a4ee',
  'd8cfc073e8cc2a5d28d8ad855b9975a5091afc3c',
  'd9e5803167ef386de6a4af5ecb14f95139eb343d',
  '73704f657e95302430c859e98adf76668df16412',
  '0302ee8d3f9ade5718fe4878396b90764b7d062c',
  '4ff2921c46b963accad73dbe0d9ec17a542c5f1a',
  '6eb10f1866a5c7a308cea372627d2368e33b21a9',
  '05c323258d4d1e42e30888941e344f32ca3cff8f',
  '8c6e0f6686e4fba7687c467c9effd16eb57e9a71',
  '5640bb7d84d8953d367841909967ab345f2c11ca',
  'b1929b2bcd54c7584e0dcec1315deb312806448c',
  '0a6a4ac5d0eeadd3a13c995f995756e7de8f86c6',
  'e9998fbd700872e9dbae140780fa29836ff431c8',
  'c3bdef99349834b6edf0f791335ec092b901707f',
  '6745f9744fccac265da5557046252cceb1294526',
  '82b6fcbaf041320ee5eb71c2b70a95c6a0aea2a2',
  '4903d8a8c232d5aa1bb9ea6e956e73c0e80a4af5',
  '3378dc7894ab6b65b02e7aa16248be582155a26c',
  'e29800d741b3cf53fc34a2f19a1b9cf77ec00888',
  '74b89fee1a57f2e098d7e9ea2d28f1e09e69ad9f',
  'ecfa3868f134b66caa0d9291685c05a727340d00',
  'ba87ebc911224076e97aebfa1e824c68ceca1244',
  'c1e94fbfb9e63d591d77203e6c00e5cc527478a2',
  'f418b58b855fad3a667cdab2dd4b3f61c0ec2ef3',
  '06050a3a79767879bb2a2458e3a0de2e24dcc7b7',
  'a23384da6040ccf391e93ae264696b1fe9253427',
  '3f5b0b03c7724b2e12ed46757e8d9c56b731fe0d',
  'b16c95ecb216481a684d09a815b8abc3a06b0d16',
  '53bb66633a6fdf94cf097154ca4c7df81ce207c0',
  '0ede987cbf194f41b69d8eefcc4ba9b6f4b5a22c',
  '24087a9d06704abb98331fb98f9fa7c7e5562310',
  'bfb19e47d48eb29dd6ff30ce5523af4b640ba582',
  'a30298040cbef7917547d02c594a85b1c5f0ae41',
  'bb7fea7f10f32a09e0be08107fd8da05a52b5801',
  '1f49564b0f11dea4ce1864645fc22aeff097c53b',
  '30b325db59a4d37513eabd337dbe7628ff0b38e8',
  '097bfd5bf87d1312cb1a1a6674116cb6085e2561',
  '4e791eddc4ea6c7ada0c56b768f27d6af1cb179e',
  '48fbe6ab86325a151f3f4775d396580949dd7a78',
  '25a320d8fad2601a1f4eca47c243254c2caaf93e',
  '871671f98cbf2b7ff21966a38b35cbea4c6e4d2f',
  '53bf8af65d28c53dbe9901ce75349756391a1ecf',
  '34b67f9385146a3f5a420b40841522fe98a2887f',
  '58331f948ed57448b2e93dc9de3d59679ce02ea7',
  'c3cdb911daade304f6bedc597a55d20138cecf9d',
  'd4a319f9309441d56b625ebc39624479c37ae766',
  '65b82db8e97c9a194dde2a5f3f1ae5b10487af5d',
  'ee8a52e766146316ffb69f594f9749791f7ab516',
  '479aa593f91b283271f17be94c906db4b29de41c',
  'bc979c16c93696cbff8ac6b15124241a7db12ec7',
  '9ab4e0719a681da31fc73219bdec16630f4c69c7',
  '2450827666d8a8bda6a825dc10e61deb58e6d6d2',
  'cf12321a8212b55da9b2cfdf1818f2c2194da146',
  '60196621a599c24e63ac8d81027f14948b59ebb2',
  'ad1daae124cdff8756dadf68d265331c87cd994a',
  '4b99e7ca8a0fc863184d91d6b5e17de7c7e6c76b',
  '779e1cf563cebc8d169b592cb2af5fb862e8ecbb',
  '0c1b5f7519a207fefcca1164c1d8e17fe742ffa8',
  '79f2931f11504f5b7ba3c9a7555c74fd5833930e',
  '548fd38bcb3d9e93a052b2f7e876a21a54bd7a02',
  '903df88f6a57c24335de4809a031ab9ab0e2908c',
  'a136f7cf3fde9f922e2b53d9267cb91059ee9ad2',
  'f0e1f06231ad30d51bef1153ed819a05166c7451',
  '631049e4f69de3d1e200dcd83ff85948b9ca742d',
  '1b83bb53f2145771d59478a9cd17cb3ead59f0b7',
  '34fe3be095adf4c09e21fad74d757d9ad1132e7f',
  '5c71af08ec1c350e487bdb98b54ccce2c4dd511d',
  '00e9afd11177d96f93aa8c29fcbcd082e38f7a38',
  '9f749b8da1f1db7c525696cc3cd9d0a9ae28e473',
  '9afd189b9c8c650b84a3e19d6a288c0c62621b40',
  '76913ac296d1177e996666d596f9dd5db6c49f4e',
  '609532098a19cc05c9cf1bb8e074913953809954',
  '3a1466440bb7d9bc14414a9462d5627cdd9e92a5',
  '1667c8351b81a71dea7e4e941e6977e2d5c4d303',
  '8fd4eff7132dbfe2e36237121ee70de6b89e0a56',
  'e6067d2122e53cad6a50127b402e3debd7ca491c',
  'b593234e48895f18b94a2a745747904e7ef5be10',
  'ba371e02596d79b0caac5af2fd46b2e155723437',
  '70dba42abad0d3712f4b6513a0115ae4441e9a0f',
  '8131e02150b3a974e262ea944f21c46899ed720b',
  '058e6a86fa08e772968bc742cef6f93d194f6c91',
  'c0b6fc4b966a4fe1044339d787da00e4292183cd',
  '0e8eb77b7abcf33d98729fd7a5adf090b8020b6d',
  '115d5ffff7d949496d7b61c62c5508747c7d0a42',
  'e9c52b53254452519fa16cf63df3c03ddac250c2',
  '66a65d1c47348c10dc41f667d8fe1cbd68e93939',
  '3a18fdd59f673cd08273f344a62f08c7e2145d3d',
  'e3db6413605d15612b85b516aa62ee02dc58e04c',
  'c34f932acf3f26ca521e2c952dd4632bd80d2ef3',
  '8f6ced495ccbe6a1c16ccc44e864211c5af0a9ce',
  '42ac3eceac6e6b6a53319929b8ea8f68033eb7cc',
  '5501e1e8b692b365903ebbb63930c1b53007d68b',
  'a8922ca5ed6ad26d8bbed56719c4d7fb74b0febd',
  'ba9d3532d10c1d4088b0d8a35667fc2eac35af2a',
  '9ed5b003eaad2c3d9af6158bc7abe61ad706d42b',
  '069911a2cc7df374e8f868dbd42d7f8219548886',
  '2beb4ed8985dcc5b11334722abb024af1556415e',
  '3583f5a570af6870504eea5a5f7afad6e1508508',
  '5c443d8d125443e58692e4ed1f3788d278f06b55',
  '9d4a654f48cbf4ef83771cbd6349c8c98559ffa8',
  'c4d05bee08e7c4c497fd6cefff29ef332095ba08',
  'ed85441875952e8ba6a6106d26512548f2c1d7c2',
  'f1dc9388b10ee7a7e9576edc8a567f79539d9c60',
  'f399d873e0c37cf24de9569b5f22bbb30a5c6709',
  '994e9f0b0a4dde8b4c08df93964815aac74a45c9',
  'f26e2532d54bbcd8715525600061042b01a65e50',
  '8e96b823501edbd6ed02f627545d73ae0bdf84e9',
  '374b68fc8f3cb33757d60d04ebb47512b1e722d3',
  '513a839756126f1f4ae4df2de07f0da897ea5350',
  '28287dde54b62b956c20c0e680e25a918248dcbc',
  'ea223cefb22af78982ec4fe54cc1bb67cc454c49',
  'f52c1ab94680be87e897a81c7384d0c8ad74d426',
  'f9c15d49ac37ded1964702dcbf8586cfc1d82938',
  '5164f0d5d6f9b785679a61db5ad01ad3687a232b',
  '4f52ce6bb30287d8c00def25defc9c0502177ba1',
  '49f36c9deccdea34ebdf2b7b95658f8a3742ce0b',
  'fddee369cc824a8c692d1de8656c45551a3c0202',
  'b802a70ccef6f93094b47ff13fc914cbaaa5e050',
  'f511ef9c1e28c792886d4c10b5982d8cb0c707d2',
  '077f4ba9a810534273e4c5ca25a80bc5c65c6d89',
  '70c714160461c10f6e8df6b4e7cf286b666fd8a2',
  'b8aae776791fe449cfc8f067b414d57756b42cd4',
  '68cc1cebfef64da98f37f3a865b0e44de15dfcba',
  '8a0def6406767981068d8d37d621b18dd244d33a',
  '7aba3561ac167b93c2b7995bda771f02f48bd73a',
  '9b0d2514796cf72619ce8f658e8981ddeea9bae0',
  'a7e2f2157c95e4566f147951ba13e0ea409edc66',
  'e2769d62b6d79c1155721fc8cde2e4f0698bf74b',
  '249617c6393a9df0099e122f72dfefae39c60dfb',
  '1be15348c51955179b7bf9aa90230a9425927ef6',
  '5d2dbf441cece6d014b6acfa6aa9affda9531e79',
  '2349572175322abd904de693dcd16ce04b6fdf2b',
  '228aa62d666f6dac1248d6d115dd4336490e28ea',
  '1c13e20d8b8bf035145dd7abc28915c3083f4f94',
  '275c7b098e0f4eef5216994cf97601ad28bdb296',
  '522532d3eef6b29589f0e698c63e86393eb0442d',
  '17f92aa6ea495d37e8345b55588be844ccd1c2be',
  '07bfd6eb2935e9be50bdec56d34d9227c511f8c6',
  'b226c622f08f5b47056b45185f512dafd7ef5180',
  'c45c2c1651407dec07985e3f6e329eb3bacfd3a2',
  '748ff881b67058240f244228af30bfa112611b8b',
  '984c26272bfbec4e1ced2db0a741d31dbbdde4d1',
  '92b7f813e4210a06fc55968dbea36e5f1f095e6d',
  '88343471cbfdedc3fae040c293500c9fdbc10356',
  '5cad54ea797c4ec339cc3437a12b144a97d63c08',
  '16fd929429986a9281c54b4b02bce294984eb83e',
  'a513614987952fe701d657c73b2f392b7cc0f419',
  'e1e52658881d4fd75c7aa06879d9750a33463025',
  '8c6d23447c1d1b887a297de88e6819cc8d4e5f3c',
  'e5335287649b30009ed07f65cfc99f777af57784',
  'fdf81f41ff39b7bde9ee24736072a6772b908e0e',
  'aaa10f10832928ebbd97067239cc74258f433f96',
  'e6f45b85b84c1599098ce91b7e8071f8dd7f6f02',
  '99bd0a2594f9725da1d2a0512cf5306c37f8a5ba',
  '65ecfd29fd0bdca05e0b5dfb1160f1e3306db429',
  'bafe27a3c24d82e0b41bc65fb4a38dda9c2ba7b1',
  'f86aae62ddf79cea26d697260551b285193be1f9',
  '7c75c8ce7b3c1292d3d59414b637260a1f8172fa',
  '05828011823e924e2d51d83cc2c5cca550238b97',
  '590af51c396ab4b034c9b8b45586f2a547e66ce2',
  '8d4c89a68035ff800e05ced95abda13b0cb4fcba',
  '7143f78453f81b55b825061733e80a9115122968',
  '3479ced09893440157be4bb3ca8fc4d56af991c2',
  'd6a8c16dfd08d0e44c83698f38b21f5995e996a5',
  '7c51d5acd125cae8a666a4ffc6fbfa8792810c1a',
  '6991c85ce378522cb0e9b2acb3e7db27b1bd42df',
  '49080e38d8a5acb527fcfaca9f1519a328c4a948',
  '5ea30bcdaddd1177e2588811b14b82c549ff49b9',
  '02f3c6b00fcaa365c5858352ca1ee149aea7b90f',
  '5bd218cdbe386f608fad90cb877584a9a34996e4',
  'df95308ca7db304b8942ae4d499f25915497802d',
  'f476595ad4f9b93c16f58809a40bcceb5c5ed813',
  'dc8c934decfd2b0ea7235f84c41541e2383d712b',
  '7f1137707c918dab287ac91c9dbb28748696a36b',
  '8c4a65a55a2d3e11c02d785910a89a27e4019d66',
  'df34fd6644a515eedd9c494257a0570b48e07e48',
  '7efa755d0675c647e682b2a657cae5d4f2508bc9',
  'd15492b53d6ff146aeb0c58719f19a2c28c13787',
  '880df1c0027fb966234d5178889613629351f957',
  'ef5b2aa8a7329b772cad5fc0ec6508c4f49bfa01',
  'e407eea71c905827274ea9007cebcaf642be2368',
  '48c6ccbda87f03a6a9ae22b834e62218d6578298',
  '429920a0a10a4d250bfb8bd5ccab71a6cc30edeb',
  '221f9a3aa7f8d95ac53bedd168694467da2ea049',
  '9729fd0558167b5b18391f09c0ec2be47de3ed41',
  '3518d20c900d9a4688247740992a9fd9a0248e75',
  'cbb4f276a6eb27ab3d838609290a893c6725bc88',
  'f979dc56a82219dc0b76cbc999373cb1537458e4',
  '184c190fca35e5f752988fda377607dff5bc6bd5',
  '51bdd48b29b35965e0e0db056d1a542ae25e1e1a',
  'ca02ba2d0ded5f4fd143132cd9156fa609966e76',
  '76be8910d3a2cc5276209aad8315d7fd49a255b4',
  '6e0bff0915c6c9857040e4d8e41e6446c509fe56',
  '1a14bc90caf41f8da85d13091f31d40890c59abc',
  '25195d21c4e1b8059409157d14ac37c011348c9f',
  '8e727864c251cb6c381293545d4221155eed506e',
  'e467d2c995d5e047b6f0a9166d30a2a273a3dd45',
  'afdaa5a0e009519c9fe0be1e8cf80e7b70c4aa74',
  '9028a5669cb74d5b220d6d421064ca0f4cfee4cd',
  'dfd04927ef02a5dec773fab6f8976a56c500df5d',
  '698ecbfdcc38584dfbff2158c2937b4ed1d0882c',
  '70efde441783b81aa3ce5f133198086f487f72f2',
  'f12ae07aa8caa27259795b0b7de382a00067159e',
  '55628f029bedcfcc66a130af748fa329773511c5',
  '52920faf272e273fbca28c378baf53a3b73b92e7',
  'd9eeaaa8ea47ae617506da9794d2eda8a37de401',
  '0e1778d0b784ff13b13a94867e85477eaa3510fe',
  'fa2c074e7675b24700c44493100787975438cb6c',
  '97941b157b6a6c7eb29a131c4c5ae5df35ec96ca',
  '1aa689021757209e0a84ef51d6733cb7545eb676',
  '4a471ab2a6ad0b74dcdb7aeb9ee8946165ac389c',
  'fbd49af000ceb3f87efb45bb1065a64bcf56e3c0',
  '6b48e0679d1b193c98a2363b7eae0d7895683694',
  '7837a83ee77b7f6814aa575912e7bba3502abaf4',
  '93d775a01b85c0862b990a8fe4b252db14ed7bb4',
  '31d87ea2c60d934c9d8af9e94b99f2d0f77bec5d',
  '6434f9d1a3bb6802d91c635ef8c59135901f48c9',
  'd7f4fddba25bb48af5212c449cb69bcf32422ad0',
  'bb43f0527a9ad3a6b43af5cd2dae17bce196440e',
  'a3fd3abcc249770255d028a765f3dd7da1fc96f2',
  '019295a753bc14c2318d8e9a1aedb8649cc3fb6d',
  '8abe35652b747943421ffc91426abcf00d5c7bbe',
  '3bb7ba29558294348d6c32fbc902145954c87898',
  'bef032c11292a1a3ad6f36743a61bf8e6dee5c28',
  'edf394eaf2bc0a1b5538325c03c8b8e88a24d1ec',
  'c5856d9a5de3a7c2838cef958dbc2318d446149c',
  '8c0a5a5df92a05d7ae7ddfc25ae8461282381c95',
  'a38b84c297dee0f7bd32fdb36924bf766b67ab4f',
  '4abe9b92260067d96031d79feb336c90f8f72d6e',
  'eb33bc92f098bec7e56c5ddac388f0af52d77e01',
  '83579f1adc38162f98d2314fd534440bee742340',
  '795bac9791d50c21b2727507df159e5af0df8bfe',
  'a5af6aed685fcdca092f7601532173ca277d8e49',
  'd7e796c02cd23b5036e79ee2a5a11eb5ff7f7283',
  '6da61e8593e35269ea70522a908777e06172b256',
  '7bdb8c385c555e07df0788871bc176eb613c2ab1',
  '97f9e09764c0cd76b6a1b8567c5a261b49a41dae',
  'f35340920e79679b00ec927f7eff45145d415780',
  '8c5e0e49df3d3d1683d7bb095c61d1bd622447bc',
  '101615277bfa31d9f188ec7bf4447d213d83db7e',
  'aa4f8e1b163659ecde370508845602f7db415675',
  'f325f06e22e20a325c1fcdcdb653cafb143f6412',
  'ca2019946f00ae97850ea160c9cee488e5b2be7f',
  '82c87ef9a94a94cd7c30c66298621c7764830529',
  '0ece9e28589f654246118692d62584aec7c9cdc7',
  'a782ac6b28af3937d54942327ae6a51878811544',
  'f0c1fdfb9c413e5edca3390ea19bcf0645dfd632',
  '2a05f2e908cd1103b959b9f6dfad9a0b85e40dbd',
  '8fec3a0e48bd4a997f701e3976df696d32f7eab1',
  'd22f0142d9379fbc9b518b2fb02061d78d7b4ad0',
  'f8b9ed8d12152b3a4d9f88e6415703dd88b0ceb2',
  '38fcf531f4696eb495b18d99d5308fe19d5ab18d',
  '2cd6613017753a56398e5836a37bc72c7f241dd0',
  '5b075f6e4298d8b55cb5a40c45600cae45b93722',
  'b032c0d3d6333146e64bc1496aad02d665cf4736',
  '2a4c949b3ecafa12cd1b922f95a2b2b5f9de32da',
  '44fa8d53bdbc63b831a204180b22f3567373a9ac',
  '272710f20cff725106ec7dd459c21dab6c4605ec',
  '168a62aae15a02445420db98f22d729636e13962',
  '6ab108fc0ada028825a7a500e6f97423f4059ebc',
  '9b60c8462d3e0ae76510e8a88b1a9f84ece87392',
  'a372c820a8fce63644714f6d5f99574b6c4a3f6f',
  '4245fe72d7ed9d4f211b2e2d2324bd51d6bc6209',
  '715d7e592eb032fe58c61f26b32740b5db4df165',
  '8dede4cffc9714760fd2ac6fedd9f4068028f802',
  '86ea1db8a348c8cd90716f54c5d7e426872cdbc4',
  '494120d93779d0c6cc1b8f3ef50511278dd7c0fb',
  '40170ef2dccf6b747b356eb28797e5a2e29c8218',
  '7c811ed8a40db1e52e600632391543e7cbd33db2',
  '8f4178d8d8ea2c28ac9377caa8d217d22500cbb5',
  '23522e983da12344b8c9f2d76b7fb05379ef0d52',
  'e35bb030c9b6cc29bb50269420782787d4d9dca5',
  '238316d75c02f1263f1fe0ca725f461f744702da',
  '317856cfd605ebd3c9e15077d4847e42c4549d2e',
  '5eaf6d0cfcba042da27f8ced2a9e923dd21a9612',
  '08a4055c8dfbc7f40e74f8c34821b1b6c9ef5e41',
  '8e7e224a03455dfd90213c915d4c5bf61d3a23bf',
  'ff3a6d949bbfb39ef244771345f91719787fa56c',
  '2c61abc678a326775fb374930bc2ea64c85d1c82',
  '0e94b9be02c8267954bf519ad980d20c36708cd4',
  '0568d4dedd22b6128b081170b6863154bb338352',
  '96ee737634979ef1ae25591a2c33d8a7c201e3b5',
  'ffb76536928a3f4d040d6d71447205276c438852',
  '04722670173a2a5eaf22df9a86a9fe5af09832ef',
  '65d4a6b8262a3ba2f8c8fdc8088ba48f5d81e7bf',
  'b64e3bf28be61702b9f6552f41077d663c435886',
  'c0b72c54a5aa7e8181e8d5b948e6d5f706b07775',
  '8732eb799f6f10fe5d13174d6b3bb5d79312c059',
  '93fe5450b6d4fca1be575c928a011cb80517f85b',
  '6f76a93a3e51954d43d921ec785d95d57e930ab6',
  'a88703bd1936a23317cd2b5359dba6a67c14e7d0',
  '2bbfb387258b4cad1e2eb75a137465c0965bd396',
  '0e5c196a2230164e93e9fa5de1f7c49b755b0d00',
  '88454a9f88f1204811aeca0326daf23ef1f08521',
  'a3e578219eda21639f5988b80f74b33a4720b09c',
  '2dc281ec351e3f1c7079f0d91cc312c334a16b1a',
  '82a024664a7c785b150865b6a7c6438cfcf4b84b',
  'fa7a8f36d5811c1eb6b82101a06955e411be08a8',
  '99e1610dc5f511ab37dd73cc22162f2656d41862',
  'aa3750492d3261f17e15b343ef1d1f60137714b1',
  '8abde39809d2032ff14da8fbe31178a37c4eb2e7',
  'afc900b844c7a17a8841070b2babc111093acf63',
  'b5f2e08807791cf1f8e402789248265381239d74',
  '336ed48c932e0ff5f044d0dddaf4fbe77ff0be60',
  'f47339a756f1068c1fb43bdabeb4d3744f137e23',
  '6788146a6ca5241a4d816ede2278f1e47506dc0e',
  '73ab31b680d9a8443c7bf2606594038914fff1b0',
  '57757d6ef11f1cb25e6f33c9e8bd9248931e7a10',
  '6ea7d20324a5176a34b08f84362d4d5ded7d9940',
  '956c348eee9250b788997f5e5d1d53f1322c224e',
  '173a490b80e2514ba8580fc8587db924cd23d3bf',
  '1a08a72217cc1e27fa95476adc69d3ce627477c7',
  'fbe92e813cf3d4e849bef1fcd022ab7da71c0096',
  '5b4c89559c8002b52073e3ee4c8054475e33bb39',
  '7f79d8c3e969b7b69c7717a7ba303c0ca4ff185d',
  '3cc0ef07e9370507b69d73c100eecee2707bbc8b',
  '8e8942644035cc9d72df6c87e8e6a68c26a36d8f',
  'e2e4c02507efee366e6d97473d667910f67735d3',
  '03c05ab71be65d11d7f0fc186a296884e3a9a785',
  '2cde3f01ac9b66cbea88596f423f984a4fe171d0',
  '15b4b224972b5319430d59d2a76fae5eca099580',
  'e689e1e40b0b72e8e4b7219fbde2118610a3adb8',
  '4d41c19323f95adde0a5ad7e1cd4968fdb50b075',
  '4e2d6e0949b52436905134fb497d6e9a49675ff2',
  '753e3aba6101bbe142041d36f68a022b00aa64c4',
  'e95dd12e8cfc448cad1270feb3743938fefc3549',
  '13d177bbd55ceb906666e00c9dc9654edc69ceaa',
  'f3069a0d3a7be2ed3a0c66c3e7261a9f7289e337',
  'd4c96aadc0de7e8b5faf7e4f99963644c8087514',
  '15560006e8a665f8211a18a8d603857379ce4d56',
  '005b3f77fc651c39703f26890735edc06a03f379',
  '238b188b7ea21e991c475674b2bf8564868e6391',
  '5b34d53b05238f8dbbe7efd395d4a0757a9f3d82',
  'f129ed22d348ffca834298800498aa8fe7a3a994',
  'db1ec7038cdd4539f4310caae4c1d23cb3d729b5',
  '9d7db1ac9fcda18827b84ba404181a76f06006e9',
  'd19bb68c49fdfe79cff5d7820cbd35017ca6c935',
  '3081bb0dd0bda9d8cc187a1057aa9eafc7bb1143',
  'ae6b0acd3623ec3f101aa2754bc2553b194fa39f',
  '828f9bdb59ce634f5f381301b531baa1e30ac077',
  '99aeef8c441a383a19bf1463a01c9eb6f746671d',
  'a788f66e45de2d024493347dd5378645c85b94c1',
  '0980e1050f9d93cfe4614d40215cd4109b52c019',
  '9e72ab957d73e1a6876574b4d677be9bfc3f21e9',
  'b1795f0baa2a01ed1316359f11b87c56242555e4',
  '13be6b0074e53d17f8bf2d422fce3f1bc66e8925',
  '19ce06a77c5c34266ca038303cd726d414b15ae8',
  '6e162ff255540a50912154231bf7b65c839144ed',
  'be7bdeb6de6d9c6f90dfb432aa82fa7cd81c7696',
  '2bae0c971d81a80fdfe4c42d42287995467a3f19',
  'ed96de69847967f2698716954f6ce4d8f95383f6',
  '97c359a9e9de5ed23e2bae67d2bdb7ca058033b6',
  '364095a31ff9b1bb22b3a05c79ce877114759f25',
  '89ab8d62dc3c04269df1551d2c49ae49df0e0ae9',
  'fe565f1e9d144f229857d5050ef2f43856c01ec7',
  '9c77eda857985cced58a95f5f080ef22d3998405',
  '4f0d1071242bbc2f9977b451644355701c90b1a6',
  '14b20d81f501ebab81258f03438239b46c5471ec',
  'c40e99ffc8a373834e9e93471e539ecc07f751f6',
  'bafed434056935e98913d4d1ac1647aab5f79ad5',
  '936617522d3f1205a51bbb1e23d57d72de740736',
  'e1e9407c4cee54e8fb819b6c5d179068bd0a2ae5',
  '4637d13915638a1496124c358b094d34cbe685b7',
  '8b253acc8b5bcb5da77d6e850381bdd72452113b',
  'f5bb0226f7eaf697966f8a2f6280a101e32b16f0',
  'e0fb92ef30eec5a254984edee03430454277f930',
  '6d7efe259148f9dcea8058bbbc7c900bb7188817',
  'f1c59aa9feb1881ffc17c92b62aa52e4e5c59558',
  'db141c0b3d3585c864bc2db242b4ceb358bde3aa',
];

const NEWS_AND_POLITICS = {
  hideByDefault: true,
  channelIds: NEWS_CHANNEL_IDS,
  name: 'news',
  sortOrder: 90,
  icon: 'Aperature',
  label: 'News & Politics',
  description: `Stay up to date with all that's happening around the world on Odysee`,
  image: 'https://player.odycdn.com/speech/news:57f.jpg',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const FINANCE_CHANNEL_IDS = [
  '8520645bfe25547b31a55a7327c6d9ac15f417e4',
  '55d08bedd4bd761d5842f573c6ea13a89501a0f5',
  '3ecfab1ae46caeeeff39bd780c7744980de152ac',
  '0c8d82d726d769d3fdbd76b27ae47e8aee8be9a9',
  '997e45c453abf384eed7a2d7c7922f00eeb8163d',
  'e9637820bf82927acbeee3c879e74fc17df14911',
  'ba73d2267f1404aaa524ec1f6d35fac4baf284f4',
  '583e6b56fd405bc37a6b2c362f426926cad58dfa',
  'ff4fefd5da59effe4b87043b91adc6dc70b3489f',
  '2030808aab52b1d7d856ae694c1e986b006b4de8',
  '93f7edc417042074476fc3312906186c685f93ba',
  '7be1496500dc33946800dc359e060dcf7b2b63b0',
  'd8a755c1798b65603350bd6783b3902fdc690de3',
  '2c238ed19bcb9ab9c13c634db68f43353c435307',
  '6e0ddde6e12e9cc3a503c2047cf5d648a272bfb2',
  '87b321ee9c1ccf7e6a950383b72f62222de16a76',
  '3ea3a2cde914855121e1581a558d3eeef67fa74a',
  'f3b0724eff8065d36b29a0652d1c2747a9e218ad',
  'fbefd3754e09101f893ba33fbf2ae5f7e20cf68f',
  'dff34c3cafd0ee8c06057f3425c6c70cacb86119',
  'a621d886ed08d6b5e30093929100c8af6fe80ca9',
  'c457261e5a90e9742c4cd8b2588ee1d9110995b7',
  '7998c9a7afd81ea10ab796233882d3a7b797f90a',
  '07b6fa567690ab21acf762da875b26b4ac4af12f',
  'f62608adeffa7604101f43d54d35323d277d56ef',
  '968f6e3d29a6bdfaea783c6a318a929873fc338d',
  '6d3c9853a276d02c7a0ce13ee7a519ba5baca3f1',
  '7979f685e66c8577dfbc784389f8271cd1d5c901',
  '70200c7021e90667fff4b7621c70d95fa6dd91b1',
  '064849f9579949f5255c18d37ad7fd567077292a',
  '5b86c009374ea20e3310856b3eb96436c24c321a',
  'e7318621cde124eeac9eaab7b5f5712b918ddae1',
  'efa7fd643d8225e3c9eb251a507ccff564ef07e8',
  'daa70c5cfd617288c0ef0099f890a2dd13e49831',
  'd6c8ca278780af4c853bc34a76429411e0f01f81',
  'eb301db6f1eaa433217d64ae0ba5a0233d9b60d8',
  '7d5d2baa71407fbfee4c771e590a965406317ac9',
  'd9e615504b5d06ef0739279045bf83b4d8c2061f',
  '5590b06dad1e6b9aec25b07cf4926997b50bd660',
  '033e8372fda0d4a5cf34356abf74f9a6aa9c5623',
  '2f629b7ef9cb1562a1f0ffd7931f454834214e0d',
  '50a2657fb816a1b85dd5e6d51a62f80b2b9b8a68',
  '9acb7be530b3e13ddbf213ef8983c67506135094',
  'b99120d1120a42fbecc629e8912c76b0d5ccccc3',
  '98e4d0ab1c72e81b888bd868d46383fdbf3ffcd2',
  '95104f5f52a61ea61614d46c6217724ecfc8cbcb',
  'd39d89f4a5a84a322ff863c89a455dde24b02046',
  'c438d0fb3fa42334e8e39e84bd7a1f6e0a2c9522',
  'bfad842494ce956db5e35d0c9d2e3b44d22a8154',
  '4fda65bc148879cf28fac8b0af0c42bf5aed6769',
  'a1431abc5dffa52ce68515bdb92b0e14fd4f8271',
  '868d02edd1f77a25849cfa2cae564de8dac63192',
  'f6c7ab22aef0c6ffbb2feaa78427c4306cd4ac27',
  '1244a0771df7c7baa7fb6b2f8dfeffbb17686b5a',
  '9264b0f6385c70f9ca2474e39a159ae0fc28985f',
  '5936180046482e4907a83d2487159f3c5097e46b',
  '0659610191c5013c7b2980b844339f88d46a80b4',
  '2f20148495612946675fe1c8ea99171e4d950b81',
  '4af3d3703eed60f328e773400714adf6b86bed1c',
  '5bc05a1ac6a562e6f87fac6087a4bcbaef676b3b',
  '916cd292f3c46b5b0470b480b85c930be3d55b83',
  'e5f1c4928d794dd8c07a91cec6e7f114bb6848f0',
  '671a69aa27a5e72ad0f255f53b69b57b2a962c7a',
  '9f3a372f045227a90510ae1a6bf997d1b0744244',
  '4be131d5563b5d44934ff34ea603e1bf910dca67',
  '4c1d3468512ef50fc147780afc74d7c9c7c3429f',
  '18e07197657378922a7aede89d36a734775634eb',
  '81a8257f7b1740b288520c4acb299eed23298a40',
  '76ddcbe2bbd301b1b15cd6a6e0b969b767bb356b',
  '8a891a7e3a9305565e4f7292316d390858633cb8',
  '33e6fea63a06b72ee5a25902c68f1d704d14b1e5',
  '1f45ab3495df2c3be7d9c882bca9966305115cbb',
  '29dd91896150d5383a5daef66247b9eb2523673f',
  'ac471128a5ed05b80365170b29997d860afa33b7',
  '4284dc016523121128f4ffa45b983c24cc9136a3',
  '74067192483939e846e1a8dd7b4bf1678ca5b0c5',
  'de7eda0eaaac4b29968af67d2d4b4ede54251699',
  'f0cb90bbe9ba6cf958a3fc2261a6743997e13551',
  '1c9434d6111e27189fe023bb02a4b3e434209a62',
  '7b6f7517f6b816827d076fa0eaad550aa315a4e7',
  'a938670c73275d9c6f1b70daf0bb1a5414e88c0b',
  'e50beec197c7d1cd2f9e6ce0f4176fee9d4949b9',
  '26a76095b3add8301cc15641b5c5bbb3029f6e06',
  'f3c286bf1d9c440a479d5cea3e59fca67f4cc571',
  'a6bb2c7cfc4633f53941713bd0e6fe62c7646a6d',
  'ff80e24f41a2d706c70df9779542cba4715216c9',
  '7a9c2e888ae46f26e8c3be98a0e38da1743f473c',
  '4de2b2d04c7734a863f217951f66e265294f2c24',
  '82679841636273c8b73abd7927b1d49769f146c9',
  'bd02ed31604feac8665f8694a10c95a7690d3088',
  'f08b8f02d8031a1ff114840857e7c40ebf50d1e9',
  '680f16c5a8d0108239108cfa96505d40601ae7a2',
  '9f298dbb29674fe4959b0aadb7d8d88a69331892',
  'a181bdf7ced0a7c9159906c27f71f80309944bfa',
  '8e5c01528ba74261ee8b1410214eacd12c27fdc8',
  'b96d57fbb37ef76cb9a3a9d4a6dbfa4f427b5108',
  '0fc0fa43e7d5c95e701add3e71358b979a5f4f9d',
  '8e83a89570b0b24b28d540c88594adc3a19d1483',
  '11836afd765f135d7ce51b66d2af6b30aafa687a',
  'd5606663658481f3452cf6ba8eabf77451d0ce07',
  'ebc4214424cfa683a7046e1f794fea1e44788d84',
  '0edad9e2b95e178ace67dddcd3c01085930b296f',
  '29cc7f6081268eaa5b3f2946e0cd0b952a94812c',
  'c38f46508c77fc375898ef97de979e18c3ea82f0',
  '828174a6adcdeee74de5211db1d006716aa54d07',
  '3247ca511495e06cc5e4868ba6c3dfb5d0f10008',
  '4da85b12244839d6368b9290f1619ff9514ab2a8',
  '50ad846a4b1543b847bf3fdafb7b45f6b2f5844c',
  '9ca6e62b04b6355ee15fb6a00588358f3847e37a',
  '3134864016d0be0b831dbe9a3048242d00a42475',
  'ad4dca5a634a89bd5410c009665e9daaa444c7b3',
  'd7ea64522a3b7fccb182cd275fc8556d512a720f',
  '579563946c1123a67f6dd417c2ed19d24c135a80',
  '20ba0b4afe9ca31d7fb37742519a987fad31fb12',
  '53d679982abe3f17ea2691b37fc757518d0417bf',
  '9d09d9e9545bd4de0a55625afa43cd8ca123d10d',
  '84342ae85d216d5ffc0ef149a123aae649d5c253',
  'b48863a2f343c3d1869cf02691862f1cee3ac2dd',
  '060940e41973d4f7f16d72a2733138e931c35f41',
  'af9547659bec3fd214c7ddc9f0c7fc86bf5adfff',
  'c5b0b17838df2f6c31162f64d55f60f34ae8bfc6',
  'fb7209b5061de8776bfe1df0795a8a2588ef4108',
  'b1923f8c6a01f69c6980b193a2d8d2c47a354328',
  'a403ce7eff82b75a530dffd211ddc69847be4425',
  '7a95519d4beba3b7657713fb020f180af807d309',
  'a0692fd6261e2c77827321705056838fb49fc951',
  '8972a1bd06de5186e5e89292b05aac8aaa817791',
  'e73639dee45f76c8ca8275d29792d64b4a4525f4',
  '04784caee866e077d46ad9f34a14cc84a289b80c',
  '96ede5667bc4533ace8cfcbde4f33aa9fe1ae5f5',
  'bca584685bd0fb5760f46e6a060a1b2c68c99b46',
  'd208b2677c169a4d90b4c97250dde031da6d3c10',
  'd6e2ff2e46822d5ad9a9df89549f7ea5a6bf5255',
  'e06849202121aa4ceac73da1b581a552197e5661',
  '726fde854edc58a0d385aad398bf416a0316d748',
  'c62ee910262e0a126181dc454b0556a174bfb120',
  '87ef9ba36019f7f3bf217cf47511645893b13f2e',
  '699b0ad4913a31b3315b6f1c722685dfaff426a8',
  'cda9c4e92f19d6fe0764524a2012056e06ca2055',
  'a21c363963e130dc77cd62bbde39e745206654c0',
  '77ac5ad623a63fd526b8c67f5fceb95ccbfdcbb1',
  '9576be30de21b3b755828314d6ccbbaa3334f43a',
  '1e86953852705f11b8f01ed62eb3d4cfa3443597',
  '97f52bb1d107c32527326650fce8b5a652707227',
  'c9a3cf2234af0888b6be256adc13613d76e74fe2',
  '097591ffe010e7b9b1641b967f9f94e66c683acf',
  '9b6d9cc2783d1586763370231e7fac4b3b97ec70',
  'd000b2fec8d46d9a7cb9a12ee3cf598d11b24918',
  '6977c53ca432fd49f5d441a08da01930eb74401d',
  '183638482bda8aca67a57f577c927c0649062c9e',
  'ca1727de1ccaa2de2fb74fefdfcf3d630f3cae12',
  'c6571998fb5cef27fde1fd5831882a6c6816cadb',
  '4474b52aeb12e69607aa34656d1d7a351e8a09d0',
  '83cea506818052c8d8fa7e2eeee43fa4af056054',
  '3439b2f5566545ea37c9a7444c93022985a97784',
  'bdca435ae7938d9f76ccdbb9f73aa8a7f2ba3468',
  'b030cdbd0945700fb34bb3e42b2099bfe5e10623',
  'a6f6d66c8d760e0dc805cb5e59146f94d0d2d6a8',
  '5327771dfcd710bdd859e8d2cd6eab25eb5a4aca',
  '2585698b3dbac857c43ec9aec8345cd71dad68e8',
  '40b75e8c83ef1a9467d163760d64896efdf96d23',
  '83a8331b9b1fe009f90a6672aac969d05aec48c2',
  '686ac4e7b1b5b94cb0ab8e75873fc7819d97417f',
  'af63966ffac242eb77cae6170424bbcab9fcdcda',
  'e024bd187556662e6999ee266b43e28c6163239f',
  '56b6ac09515fa958fb483c63ae79d842d448f2af',
  '167fd6456ddfb967498d1b216da8ba894738ddcb',
  '2feae5c0e5c62d5533e0dd78e58a7684ca94f215',
  '2c088e04540d47e1550785f8ac97d8b614b014f9',
  'f5c02d8484646df941e704c72cce9104cbf88184',
  'cf68e70f2e29bbb357d64df38c412f9fa28978e5',
  '4d43672f087bca1532e0020a9f6417134926c950',
  'ab0e2651ba05affb70baea9aa8975cf5f0fa4fce',
  'a9cf992aaa2e519a1eb76c27851ff85e2d031444',
  'd662160443727adcd872174a9fc7ebfe30f478cb',
  'c14380a9e77bcef7915de3d357c58246e1b2a46a',
  '0183bd7bd442b6e595f568a42e9d24e7c090955f',
  '76259771bd58368cdde4a3973ea23644aa2256ff',
  '5d006edf6dc52626781807496a42413701385e07',
  '35038e61661e3af3ee29596d25d8f9919777e586',
  'b65821d6976712189c71abd29eebf9ddeb35e135',
  '43d3f6a44b2d51b1965c3c9b6a0ce99be4c50ce1',
  '9c650b50028acf839d75f0d15928ad7d03cd08e5',
  '409e2b28b9d3cb1af3c9e5b8ec99b324e04c4109',
  '63fa841c33a015f95ca24a947ed6330a84eefb61',
  '13a460bc733c8d979eae263524fb6bf121d954e9',
  'ba2e383e4767f058c50a0097abd6b37aabc9804f',
  '009b6d55acd283ee1b59643c60fc28d2ab5b594f',
  '4f1c85fd0e02436954d19228bd25562903421bfa',
  'cfe9d1954a2c9495c2c28080ca4d890ddda47e74',
  'a08a9e6a660f2f1fd4f1fdc394bd4aeb798ffa44',
  'af66d7d133ecbb9f51955fd6591959289a101c01',
  'e3e02025a0fdec17c60375dd09182ba86e219096',
  '26fed01630c04b830ddcbb964cc097c3924f0973',
  '16d0f521955ef05c08b9ccceab9429ccf543d3d4',
  'fdd8a3325934ef8bad6826509f2fc724fed3aa66',
  '8b8096bfe1aa39443a37a05ba574e896c1be0730',
  '797a528c49b6535560f7fd8222b121b0223287c8',
  '21e05426f95f06f908d66d13f2cca4345d926924',
  'cf10b8527d72a603d654a46ced320541b407a43c',
  'da2b29e43d89f12351a25fe24d6cbe034684f7a9',
  '50329afd8d0550811a338412449b2f920bd3996c',
  '9e3a5171cb8ea1dec3133236869da998c825295e',
  'fd66eec982c2a535885dbaf5320b53b3aab527d6',
  '03c9f721d9abbc5e9e77a607aafa3d39895004ed',
  '3cef81b9a0fd277f49e95cd7fa6a27d33c7fbdd7',
  'b9c40b595a1d3d24a8b83df239e9de3ab721414b',
  '5e0333be82071767a3aa44a05bb77dcec4c30341',
  '93666c4e1754a36f1a18b9529475238c271de72f',
  '52a10587bab79e37a541e1cef1b9bff82308ca60',
  '5df035d0b782a41cc426ac1c9147dd0cd82fa8fc',
  'f8b0af16c0128b11b05da8c3d9a198b13f1ca524',
  'd6f36ffcc452a76d830e67ae1e86580aa9df530e',
  'ec7a9521f47a7048073da5fb8cfacb2b0e38615e',
  'd0ca838b96441fea9ce7887ff5e226f40eeb02de',
  'e31ad67202289133d4bdefbdff9dd3bdf776d329',
  '0cbdd788f496495c260e0cc65a64fc6021d6e7cf',
  '3ef59d2b4484e228532a24a9305d6be1fce9f850',
  'd5ff8d388a5538ccbb40add349732371ec8247d3',
  '4bc65d9ac60ac0acfea8b99736e75d0878ac3084',
  'bea86dc23d0d7f73b6fdb00683c886f9197d6086',
  '45dda99c61a924565dc61ad0d7a119d929b79d57',
  '34c676bfe6f5870633d0f9d3ae2a7fff5f6a92d4',
  '8edb6440bca498602ede1f601688f29d61e5a904',
  '6e9e74149d52f163c63bd6a7f7b98073316388a5',
  'a32ac093a9f108abb8c10fbdea6a108dff323522',
  '83e2a16f1a6d5eacc105ecbc55b27d7d2ea96853',
  'b435d8378b657a1d11648502f8f0c8e1765bc75b',
  '462a6748794d6c89bbf3a09762916309bc8f8292',
  '1e851196a3ac728f40217373ba85727340086f70',
  'b3e37a61307a738907d082dad13d08232a2517be',
  '42e0913aacb8769fddb2bfaa3790c24e0aa6a43c',
  'f853e392b665c10f2bf4048267083c4f37881524',
  'afc47b024fe681270215f245f0bbd05e056187bf',
  'c8176867e33bc24f5dab0f566b00a80cea0ffcc5',
  '89e2e3b9e15d52a91d04319774e626c158124511',
  'af50e18dfb68d1736349e7973ef0ff6d29a419a4',
  '7a8fba6ba8f0cf915f3787d9251fb3c0d0e50295',
  'd3ad1ccf682c519fda1dd7f8f496ea2566d1cffb',
  '8560606089ff383c5652133caa979a5c848e0631',
  '370d0ba4c3c9a335bbc430c0b7d85a8949cd07e3',
  'e46e0afc1ed3b343c39aa2fd850325f2f89d537a',
  '01077f412d74bbc5e91b363fed88cabc94f38ac5',
  'a965e05d050daa32cbb2c28535443de7563406d8',
  '030df07e782dd3a2305e45854aa992d6398b45a4',
  '3e0aecad76a8dd33e3cdabdbb01f454ff647531c',
  'fb0c5c9f46dbec947364665461e58691315d8057',
  '5e17423bb4f0db90c4781dc7734dbf4fbcd39888',
  'cefc15c6d60b4701ed82cc477b233797e04b24c3',
  'f6de4eb7cad2cba77aaf496bf24eea3f670428f5',
  'ccde01b4866cad1bb425472b8bd53d4e9567045f',
  '7d91110f3bb7366d91b99ead67c2a38bdea3c112',
  '5a7393e662b7b1bce3b30778344fff26ab60b863',
  'f0370fad16c0400b7f05eeb9e89b2d6af645d951',
  '10c2d27f9e6272650f297e66047f7740f191da2b',
  '82b65253ed01681cf066a952c92fc2d7ca1e18ee',
  'e92faa7cdadb39f6358388bafe53654b3c80fd0e',
  '1b6f85b4cd33159d8fd786364a72fa7d71c93642',
  '98dd25fbf63d397e9df8a0d54dba7a39df2ed14a',
  'f3eab9d2c2511a3be1695d15bd0a33d5e48d06a5',
  'c23358479decd10108b13ffaa3746163528870d1',
  '23e388486df8f7b498ba279ab65cc669442a98c0',
  '3c3b041f5023d61189b9d7a2584dff16c7a1cd3e',
  '65e7863de3d4561814cac0fce89e650ec1164b16',
  'ad9d98dadf90188fcc7446a45522a20ca360a2ca',
  '5d5dd8523c69269e5b91a064027e187bf8e9c8cf',
  'ae028337590be77a89c904a913996cd0ad3fa29f',
  'c43bcf26c6a0347ca7b1759ab192ba67c30aee68',
  '3e39c995c2d79ce2c572392f9cb8427a5c9dd5af',
  '64ffb92c7ef09045fa015a59adb536060acc1a15',
  '6a6fbaa3c223ccd175731e90d11b4418515093a3',
  '59b359ed72e979a0560851a6f6acc61ab3f5ec9e',
  'adacd006f26f0fa2a4f4866ddce070971970d179',
  '89cb725e21d646ff7177520cdc502e61c29b16a4',
  '01925d15717446c44f8d622f3c04f5f0dd23dac1',
  '46b34ec0a9f91db206d70394828458d59987d858',
  'edafc4d45f40d28f712763bf84215d04ccaa58cf',
  '0501e1e56edf7ff98200f8f5a736d2608af35174',
  '51822e86f48439dc52a5c3f3936918c3c7afc7e5',
  'f373492ee4a3e6df4102ffedfbdc7906613f02e4',
  'e4cce27fe85caacbe5d7a861b6c042f19675ad68',
  'b265d701524472e3a983baeb80301a7944e97aa7',
  '81b6a4207ec75a566669c0e8a1e7527abfbe0ecd',
  '877cfe3816fd8f5ec8713d3308afa6a0541be5bc',
  'b039e810fb2a008f9af3d4f3eb61fe72e73480b4',
  '83402c7da98790cda08907699c4178142c28c795',
  '9d0355915847c20c3f48d87694193d27bd305031',
  '70d0495ea46072bead82429fb405d60438036524',
  'e16dca19f8ae28975af3627e4284ff96737d0418',
  '2e39612a99f5d4a5d14719c66af7f775cea9e98f',
  '2703843ad4f9f0fae66269bc396374537892083f',
  'e0998c780ac5c5a3f45b46591844e3812a92f44f',
  '8a0406c31a91f36fcfd138678eaf102e44ee54a5',
  '65031ab204894879eb78deaeb17537445ca23190',
  '02d5475f841c5b788913ad52e83fd9cee4422e93',
  '474b9c8754245280ca4ee327c639abaf16e68623',
  '13413033f66bb0b58e25855aa4e99d921c15b122',
  'd14b10dc4ac41ae33a1b1a3a70cb688df91e33d8',
  '6cb87ee42e2f5a3db4bf8285f66c58e197de6fa1',
  '2e694572cb58914cd14d389e86ab74bba70da1ee',
  'ae30fde04f162e98e4bf846118420274f22c1d31',
  'fc5ce538ac435e9ce14cd17874a683a421531fd6',
  '32ca59ad7f8909084c0c37c5ec8e5101c928c9d5',
  'ef1a193acd9de1ee6fc653dbd26643d72efac783',
  '15acb4dd1c11257276d59a0cd1e3cd2be8667f5f',
  '2d6cca1ddb86969f80373f9ae0eabc25637f9bc6',
  '3952eeff28a3098fd059caa6c8a5184d0f9634ef',
  '48f27af86a9469e0e0459d3c79913f7cd489b14f',
  '26a70c42ce2c3ea2ef43ddec4e6e67315b37f84c',
  '91cab31553a8795208bf0857dfdea25e32fc2dc8',
  'c13ed1aaac02366cb35d1177078c95ce5aeaa4f2',
  'c2079078fa907da862f99c549ecf507d5caeffd3',
  '4b4b63cb8e424e744e68366886d09e8179f26e3e',
  '008e8c044170318342312384c24748f0525637a4',
  '843ac212de2e73218e63e958127cb5fdf8b711e7',
  '33f16230d3eb0d02cb695b2bdd4b165fe4bae524',
  'adcacbcea6f60f501fe9c6a743501e242d3b705f',
  '58dc86781067434b2dfd71da8d6e0b9b142b65d7',
  '337520891088537cebac6ce19254e4f21f0aee92',
  'e144f443c8e4a9db620a3174873bfb99af5aac3a',
  '4ce149df056f52fa95bcf497d2ab236ccd89051f',
  '8aaa404a0d0bb42f7d0def2d15b5cf3d9b46d5da',
  '93641d897da7f93857d9f6b80739588b337f1544',
  'fb0695daba51e609ae8295ce0ba7e2abd57a8422',
  'b7c1e3c63d1bfb9678548dbc3b5e7142a73eb7c0',
  '4cdc6fd540a32f8d4fe8832976699d4881e57b23',
  'fbe67a4a4f28e886998cc24a2c6b80a740aad1bb',
  '1d4874b5314c7bfd00584ca86799047f8bf85eb1',
  '064ae575eccb767738a143e3f0cc938ec41dbf41',
  '5599ca8f5c83f3344f73541a8626fa79d6e85d16',
  '5cfbcfbacff6fe0c18d7a64b90378e51f2765b15',
  'a4a11bc57b105a8d060d9747fa3d29907a621b80',
  '9c1b50cc15a5c92859cee0b7724e59a72bcb28e9',
  'bd456abdcb9e72c7d03d4453c01aa513e078808b',
  '65b514181c794df6656ff758d221c6e261c858cd',
  'bae561a07c9b652b8c005fd7b518a77750ff6ae7',
  '42506f3d57334b1f115fdf54dd98ffed99267aae',
  '42829094034660fe02b4c573c1695194dd7e9b8b',
  '0d47b5ba820f935f87bd584073fee2e852e812d9',
  'e98243d370b3e84898e14f2c0af76bacdbd6a5da',
  'edb4245db47f8da84eba8b2e73cec5f6b78976e1',
  'a5a046396f1ea7edc345ee7c2c16e458e9f9c8dd',
  '597ffedc517813729062e79ffd323c5dc214e566',
  '40aefd1d170688442cc4447f5df4b162c7a3dea8',
  '722ba3933173605a61ec157cf7b355a7ceca3d24',
  '9e5b1bd900839dcce9026933055027017220f256',
  '454c6f437720cddcee0438d9642dce1bddee5a33',
  'c4b7da8efdae37e5bf1841f1ab5aa377175e9fcd',
  '0ef56f13446a71aa66371c6cbaca7bd9a2b3077e',
  '6f41ce1f40e330451a8ab4f6ee522009e8cfb2f6',
  '070e098763570da24229e347a83a92f136f5dd07',
  '00e27190de587c70e6dbf2205a9b3be1e2e41c3e',
  '91d514f472d816756c43f3ccf4a1f413b658cb26',
  '395719dd3be4c8da13f358ae74c078ce6ecd6d82',
  '9eac010cac09583a8d580cac7a7fd6c110b1e67f',
  'f2a6ad9d33e65b1f9da673be358e5c1b6cfc90dc',
  '4d3a298ac5fb2b9abc16835ecbf4350c4ab7a7f5',
  'c2de91eee657f9c7da112dd9c296fc6e50ca73cf',
  '7c15a93ae55b7a8c80a12b6f5e56781271941bad',
  'dd421e48c3eccd043b06f6a43a1515320a0a9d7f',
  '8c4c9a45a0927e356ecc0a5d00bbd5d86b7aa43b',
  '2fc77db4f1b327b0a384f31b76afb0ca2ab5c415',
  '1fa3d9156d471cd0a19d5abab2259cd8bff26d83',
  'd9f9f337c1ecb1e09d2563b36945a0b41cbe5e6f',
  '80bfb0ccea37df3f20686229ed8a3f0b161f715b',
  '41d4b7e1cd4dfc1c38caaa93b932e2f777499c07',
  '675720abf9e958f727a281cf39cb0d1b4247393c',
  'dad73ff653c66f498cac84fb412160a59dc05bdd',
  '67b29caf34d732dadaa0755661a890302373c7cd',
  '82746b9bc64648b31aa2b6262da01d42f71b03c0',
  '2bc27fb82ef377b4e02487e5a2a8ef965cf547cf',
  '9398e405aa68e5916edad7dcefdb98422f43513f',
  '197dc2fb0b091039785edee7805b03b018e2d6a9',
  '042802e4509c7aaf55ced5679ff62e99e63436fd',
  '2916028da582b5850a9b41f11e776c5651cd2bfa',
  'b0a87e02bbc7397ed2b84f08bf0da5d4f309d1f4',
  '4042aafc4a96e53149f71ba716bd444dea0936b5',
  'f44a6433ff92f79c6e510cba55da7f20639446db',
  'c46b9c6a3a23e56ed924f9359bd432c8418754e1',
  'd9cbacfe761e2df34239a7d3b4aa12749dc828e0',
];

const FINANCE = {
  channelIds: FINANCE_CHANNEL_IDS,
  name: 'finance',
  sortOrder: 100,
  icon: 'DollarSign',
  label: 'Finance 2.0',
  description: 'Crypto, Money, Economics, Markets on Odysee',
  image: 'https://player.odycdn.com/speech/category-finance:c.jpg',
  channelLimit: '1',
  duration: '>=60',
  daysOfContent: 180,
  pageSize: 12,
  //pinnedClaimIds: [''],
};

const UNIVERSE_CHANNEL_IDS = [
  'b167c173c1d1dc6873f3fa8125e8521e419a8506',
  '965eb8f48da8393f244b95bacc03b2739be96a01',
  '1321b71f9b1094a094a2770cdeade71e7f233928',
  '5fe84bd5ae1444ae4e6377fb6a781a4a0fccba97',
  'b1a5d2225f6d7ce2ca450939cbaeec7de4b9c7e2',
  '3dace394bdd3387fb2d0175594fd79e2aa898773',
  'eda07e88ab774fb20d6edddb7f52add223f1cd73',
  '2bee586cf17ab0c5a82b5e887d1441232596e07a',
  '9c1ef4dc145389bae44a9114f9a1a129466def51',
  '98ef3f6db4cd804277f684873b6e255e0a4897b5',
  '62560dbb6b576ecee8fb7c99b7ab4ab1a0362e19',
  'dc55f9916dae4e3e92ff13464595b82ce8dfcb91',
  'b54ad898e8fa70944fcf2866c22dbf8004a4311d',
  '9942456e80c49575f886fe2e4d581c7846e52378',
  'c559b9c1e8be8f28aa9fc4f3b8f2032e8575d9f7',
  '2ce55ca6f9ff2d2255c955687e57f22e6422a93f',
  '610487bb5e15c8bab69165a6a3b46a268760b6cb',
  '41b60011a50acf70bef52ecf3f84aeac8eb7906b',
  '89549f4ff7898e0217964c77f9b8dd08760bb7c0',
  '8eb6438dda42d9ca4661ba57623b92fdce45c041',
  'a9212e89deba915196fad9377777cac19ac56ba5',
  '161c3998098a2dfdec367506c0d1ab6e87fc8232',
  'a6e1273f7d0670077cc2ab9cc2dab7835e95ea27',
  'bb8811f382afeb4b8e302a2e2d1e077d95335adf',
  'e022dc016016236e36c8bc4e78140a8e47cb0dc7',
  '7aac18ac283ae236f5f94b1ac9584651bebfc2ca',
  '923c93020720c842bcdeba7b91b7ea5ac7afe82f',
  '5688c544a2962ff436339af01ae3f8e11039633b',
  '13c524b1925ba74da7fd3eaf01d1febaaca4c055',
  'adf2d6f517c9d76f02c97d48e453b627e8827762',
  'bf4a93023f1af594976cfe6ef3511f97fc30dc74',
  'a1a1099a8bb7c885aad563c0fe27be053f35c76f',
  '95c3aab566a4def2a4163cbcca5a4bf69afdcd89',
  '0739af4338a3e9c2307c4170107b30c0af1e1a1f',
  '6fc489b1bc7b44b57cba7b613fac21903e6e75c0',
  'b87aa271e6a1368b2b868d767f436f0c513bf3f4',
  'e9a07a03e46dd63f2701ac66409564dd4eb017fc',
  'e3199607f9f2bcb89cb0f09ed35747a58fd6c128',
  '4a7f6709df6770e0786b04599fb00262a98d220b',
  '14250916e9f3b0d37ad42fa339be792a65b28ac7',
  'cd6df5e9e5259dd94a399c8a3ebbec6f9c0e8d5b',
  '01c7bfdd1896629293f02b7ac5b3c4670b78c0ac',
  '1ce5ac7bab7f2e82af02305ced3c095e320b52e5',
  '1fa91fafc5dd2caba9762b13756084626344f220',
  '3f85de98c22a9ea0407c6421ba43f21701768bd9',
  '44c49bbab8a3e9999f3ba9dee0186288b1d960a7',
  'a2d44a00ae8b646efb13a1c7d3d360510e19d148',
  'b4ccefa53e0bb5730b56facb9b2ae5ad92c99935',
  '0c1f43f2b478261c7bdf92b48e37f318eab78343',
  '21b062891eaa1b3cebb147b881641ad2176111cf',
  'e2773f14ea4b438fa9f8c8e4f962055167f01000',
  '5c7cff25319213130c12d561e230dd2299d321e9',
  '7b23cca3f49059f005e812be03931c81272eaac4',
  '4c1174e09c0e06814704d96c84a5cd61db86c936',
  '9a0e11d12a0520c08c88af8d0780335dcb948d5c',
  'a2e402423bc549067318b7997e1ca9bc9c3206a3',
  'a734462e0dc6a920a4b600e32179381c1f4b92bf',
  'babed348a70d2c83fd35e6a974bacc7174d51831',
  'e329ce51a201d565f6b87c6c260df51396e18a40',
  'e33372c0d8b2cdd3e12252962ee1671d66143075',
  'f14e64d962c226630702f9a61f48ab4f55ecffb9',
  'f5d601f4c05bb53cc7ed57b91149308a7e6f4245',
  '5cffc592e9b7db3406c4507ee8fdbd7e6ab0ae53',
  '7c80fc70ce3acb9307da0b0cd2e71c88e7825aa0',
  '921adee5c047eade893997f5267d06f3054ea1d5',
  'a21302a6dcea7c80abfeaaf54b9c294d484f3abf',
  '167a3d266d89d364d533a2c4f0e92d0ad56214b7',
  'e410e5348ec7ffc6bfb5afd97f9fcbba75e44cb8',
  '312cbdf2dd5fa06997b9b8471871dc573aab975b',
  '7b3a2545b55351c73a458d99f42f806cc5a315ce',
  '3b91fdbaa7bf5f8460024de7687ae7ef005e3149',
  '743d1558fff565f87be7818b82f4542efcaa7b39',
  'ae5f134d84d3087da24455fcd93bc0432e0593df',
  '91a3b031a1bee4d1c60588f5ae0ea805029dac9e',
  'be4dc9daed9a20142e52bf16fe25eee63372eb21',
  '266a1333de49b45a2606ea5595014f7cdc108e2e',
  'efa7e407a190a7766a15b15726de1386676de6b1',
  '37597c99b2a28a2cc64e6ec349c22c31e5c9239f',
  '0d1898c7e62581cf75838107a5c35c2b2ed2f26e',
  '14c0cf900ebbcbf92374e5fef7bdbb498857eb1f',
  'c93267be27d3670fe09f9212e9412c9da432caec',
  '3b6c35aad274239bc61bf9cd06f7412e0967eb50',
  'f92bc0fdb66ce141007cc8939a1f4fee20667b4f',
  'fdbb5d39b18087c4617d3dbc4cb791452a6d768e',
  'c573ead04dab057538b8e54dd4fb0d354699a5cb',
  '4fc6c9fbbc09f49c6f7e2fcede32d488d48b6aa8',
  'a8d77d815a3138d761ca7e755d469f10a9957b5a',
  '7a3e54fcd1cbc45108878a23c7e4dc2eea63b00e',
  '88addbb33c67b52941fa71a2d88a19ed12df1516',
  '968aadb8205becc89ecf808f82c3736f1d8ac8cd',
  'b12e255e9f84d8b4ed86343b27676dccbc8b6d8b',
  'e228ccf0ee188ef41372a8d7abdf9ce1c3408442',
  'e50f82e2236274c54af762a9c2b897646477ef62',
  'a014aa4e3f455dc03acadb674b81a915d5a23c1b',
  '99fc44afd928eca04d3fb0c93224d90c4980a7ab',
  '0a8a522944420542eea83a4a8f1b9c658711024e',
  'e25afb9dc3f53243831a535008b0eaa5ca0cdf53',
  '0f1caeba4f57085d4b967592f40251588510262c',
  '223ddeb31bc6bd4e03cf83660e9846030e484949',
  '22a170da8fd33ab341eecaaa827bb59e925c59a0',
  'c5709bd0db28e4931ff80d420bdbb58badd472ba',
  '0a8021998531fb7acf0022653e5660211bf09f9a',
  'afb126638307ccee8c2318a8dedf43d32023b207',
  'db037bf26a73751fe56e712c9f547a87b3afe0b2',
  'e6818dfe5c54a9f5b0727047f92241eeae26a940',
  '17ab18dd54cec0a87bc0d27696306f6695890fdb',
  '01dd9cdb50711503fd5d85f6dc26823e5adc03f0',
  '433882d9593c8b807b40bdd367afed545b6f5840',
  '5d86b9c00d3a920ab9448ec2dcd39b2fdecf5408',
  'b8935adf9cf26fb0db5fd0ab2232b13c10fe850e',
  '14541b47e10941a338e38889ae8f679a0db03698',
  'af62f83e35b6372c23f86c43cb537aeece75893f',
  '24849d3c1775043f48debcff358063571815a95d',
  '04c4477a9253476f4f21bd464318754571871c06',
  '6175f135650778103c72ae3bee8561b75f264586',
  'f9818ad0c2af0b75b6367baceb464934d24073cc',
  'a96d189246d3e86e02f26e4efbbf86607495cfef',
  'b8cd31d368b94364442600d5541a41a7e2f3fc57',
  'cec54cfc1b67da757bcab8a8643328717bbd339d',
  '3bdbdbe9c0d64069554b398278360057699e0c2a',
  'e99c0ecc262375abfc2b9548d9bd0c0d11bb3a71',
  '0d23a55063548d81fc31185f2796793a79ad611c',
  '41d34b18739ccdc6ee19fd9c48972acaac97aa2c',
  '975ce45a8a02cff9d8bc85d9b8c690d0ee41d6d7',
  '35b5817ca7353f31e54361424500b25b19732c3b',
  'cec2bcfd5c2c041d659847f5ef2e51b543499324',
  '07c35cadd43ed68a7f88130d65b20ece598a773c',
  '641f845f2d4de6426b1780e36cf9df1e96209267',
  '3001ecfacb97966924f4b38374bfe56fd27872ad',
  'e36e6c5fca2dde6648943a6903fa60d643f11f7c',
  '3d8603927089e6133b9b29314859f785db1ab17a',
  'b016fcce9dfc6e68cc9292dc420a6ebbaa6dc334',
  '25f09420f13c2fb5e399a6d3e10d860f15ce392f',
  '166adf7f526acaf71e1014d815290a95f42f2a17',
  '914660585491a12152aafcca2e6f69b71a5405be',
  '105d343dbaec0c3e2f18f8c3f9f7f778ace6f48a',
  'be5b2c06846a9feafc86a90c159efd3d2cbdf59c',
  'd88946bd73fa90c751c5699f9bb9ae5e18506f70',
  '44af5d3057b9dcb81bc22342d703b7dfcebc0a27',
  'c9cabba87da0887516c7889ef81bb1088d02406a',
  '61196f9028a4da415ad8cceef96fca250b6c758e',
  'b541d03b30c3fea017280d8ae1758a9f2035f44f',
  '824fb70dc7ab5bf133b39d5539e1f362791cf703',
  '0f36a2de8b91b645f6cdbb72b741e0c791871301',
  '110050978084a8ed1fcb1839c0f1c0055aeb221b',
  '8d800daa930ef0daa7c60462dab58956e38ec2fa',
  '36ee91738989891e3820ac3ae1f828999cd40151',
  'b0a35b92502ce723064a076142fa34b66742635c',
  '41b07d4529ef8552be46c306f33de3919f42991c',
  'bcd2e65a27338adca2ae0632f076c523f1164836',
  '06fe7bd32b1270150edcebb5c7395154782a832f',
  '3ee431e773fe81186382c0703623d8991c6f3a19',
  '24cf928b8704d29c238bf467f56cd7f3e7994e58',
  'eaaf295050f9203868347f7d3870874c56a6a8a2',
  'd4f5f6ca46967ab5073433e51dff158a69004c89',
  '655a4642cfad369432f92227895a375ae7d30530',
  'fb018622d5bb03c1e209997da930ec2307a13841',
  'aa980503b93e5c5be4abe74c7a641e1d0045d547',
  'e200ebd47a21e6aa3f4b9e8ac35ba1544631ad5f',
  '8f1321c1e60475728caaaad69c72712fb3755096',
  '592808dfe32fabe17fd497621008d456c7df226b',
  '1ad27a6c30413b85a630af721e155a49695e63aa',
  '4134b030a3047a7ebbc55146ab70ac22ded674c6',
  'a24cf222eab19c4f61db5592dff974461bef81de',
  '4651d91cc32c01243069af1f39468928102750dc',
  'de8e14dd54f4edba6207ae336f769f623f46495d',
  'f822390ba89c999f466df7c1a2b26f707196ce2b',
  'b46580483c6a8eb1ebc8a63a65a6a4a7c0899a39',
  '7b5c2852085081c53a33b5d3415e5cc8f1506f83',
  '6295500724de4201c92db80b3650b3f9cc740cb3',
  'acd2705d45b595bd5d6b0083d783ded4bcca2cf2',
  '9eb9785757949239b28a3bdc1f7b6a86c6466013',
  '53a3aac4d1f30812d2ea13879e763a20e8040f17',
  '5dac1ea678488fb348c16e24c0f7b584b3827204',
  '340eeefc5c1ccf9cf7a88f45f92a725f319a911d',
  '8e63b90de6753e3d67bb0994c29a8df7ffecc849',
  'a61d042a0b2b5c50a48a8dc9e4e882866be42eb1',
  '5af7988d1fcfc082e2e53e8fe13821dfdd7853c5',
  '6f3d20c0f872abb32701c07d5e1b667a64a29a95',
  '3a89c4219b3e78a72e066155d974b4956c3f4d6e',
  '4f9859e126b37d98f98706149a6b889137eafdce',
  '669ce2e3066e0b3f7a113dd449305c56cb88cf65',
  '84c099e782adebd27004ca50e37d820da91bfb8a',
  '5e269bf68c4f1c078d95925eeb71f782ab1ee387',
  'd5c4131c648d26083b5d1cdeff4b9d7587c4a7f5',
  'b2e7b8249718f1d3b0af1236e229c112eda826c9',
  '305ee150b2523d237d1a180abfc48ec6bb088b39',
  'be652e54d08b77c396b93956d9386e0b78a3ba71',
  '3bc4a6675bccb2f4fa48547d257f6a3064e0bcdf',
  'dad6e6f8af190158e7411d7bd47c96ced855d79c',
  'fc6fe2810fa5d35b45e6b2490108ef7142433d22',
  'de65c51424f8db43d98e51803178026a392299de',
  'f39cafc95c7547ec9d64a58fd5f59779ce102144',
  'f663c352f7dc5ea002ee04d9c763f243d9fa49eb',
  '5f100ab31f8068bcabb60afbf20e0c872c3598c9',
  '02187fe7f318f259b30a9f7ad25fc50b5ad12759',
  '5c5ebe0d9e67009e8a0e121b642ef5e4a023536d',
  '965437f4fb4c59da07853aff62d4a6f6ac33cee2',
  '962019ce48d84a91a56e1b8006aedc81fb487996',
  '5d616fe96d7ab4dd1afb92944d79da4415215479',
  'b3225858390cbb0a920054677f8538daeac70193',
  'a8114e7868f74d3bc8b370adf42fd50ada674b37',
  '1dc2d84eecaad9d3c1e7f444a87280be7d5cdbe1',
  'b51088865d3378e2d405ff516a723cca623a127f',
  'b4179daf6385ba38d144baa4940cf873e11fc4d8',
  'decee25208ae2dc3f3b473d01ebe78640d4b91bb',
  '0008f084d8bc16f7319f8ee2f8f41c5519faf759',
  'a4152b7358297a363577b27c9341350dcb5814ca',
  '093e78c3d1177f808a7710c60135e93e1d2e0355',
  '50ef59fd430c920bac9bc5401b88e8561f61f34a',
  '2b111e227f3cd7088b3e54342ba1e706cdacdca7',
  '9295e041a6a96413526fa88987f80122938fb632',
  '056515c4fd5a08880c9dbb461170903e8b1b656f',
  '1b9eb48ffc6a72c556431d6a11677d0a9e2073aa',
  'e6e29297d5cfb481f4f4af7091a8046dfe832996',
  '62dab2514dd3310c210a6392c81340ba82094942',
  '3088f72ef4c0d3079f83d6670cde33e5e15a9988',
  'b8a7b0b2d149f84fa868c674f01a818fc82a6247',
  '1d500415dcb73daadedcb145bc1eeb15bba3d3c7',
  '602b047de9fd8becb6b174eb1c6d2c870398eb37',
  '7f0aa593976421881ac91be16b46160ed32a57d3',
  '99a4eddcf9650517a6153e9e6cd837cd456d7ae7',
  '8fd495f1fcc208aa690c3f2edead22cc8a6d75e7',
  '59457e3095af969c634c2cafaf91dbb312df0415',
  '47b7208caeefb799390672a869527cd3b25afb5f',
  '2885c8f2770745e39d60a482e1b9b7a9376fb829',
  '9758618e156d655a0da98a891dc715a95215f4df',
  '02fdc73b127a6724718b979b6c1a334e03cd7105',
  '11143c9284ad4311daa048e19654d13019c281a6',
  '738cea5479cfb990decdc1e0f9e7e8de32bdc187',
  '45a8229188ea45148dbcdca593330c8231a22bbd',
  'dc88aa9e065600b2caffca4058588ce36e37597f',
  '98c7f6c208820ff5bef1fda6d542a66b4f1e086d',
  'eb2f973a8e67e0e5d357da62936524629a0aae57',
  'e7b525a5ede59d39aa103497cf10fd29828bbb7b',
  'f5e1f7eb98e65aa8e2ee9c9d0fda580ee0fa1c47',
  'd6639334eeb4b1526cdd160a272556f26a97e673',
  '2dfc2f388a3ffcf42c044bf6161660023ea4d9cc',
  'b6d0fefac156be1c97d7fb703f3b82dcc93f062f',
  '80854aee1ff6144ef1b8215a5b30e07431cf1a8e',
  'b72a19958386983bce447ce2a61664ed24a0a7e3',
  'c42b80daf4c98b9c9fb330a821310c9ef55b969d',
  '05471af1dd5b3dd713a41c0df24cccc7927ebc9c',
  'bb35e6ec7ea3e6f7ed375b983ffcc81cc2fe12a6',
  'd2134615f1b74a540484f4190308cb49d8a230b2',
  '42bfa51309d5f100db1f390aa7475c7c8d7f0f97',
  '12c6379dc7180abc64c0208aa6cebe6c5f8209e4',
  'c909ec214ea2151725d16f94933cc01fbe4564b4',
  '0777ef974da0564f3473d1c2b6b9ecbb418a1e18',
  '9d52066dc5b9e5fb763f56d62e71d3df5bdf70e8',
  '624ce9998efe636a1d3a58d57f0ae89c9462fcce',
  '27f9027d29df886fcee2f98b17ebacf08006a0aa',
  '8092cdf434f83174cbe4c63fd08afe5414676ba9',
  'e88f4d71704c15eb9eaff084d04810f1013e94cb',
  'b21509eee797eadc7b6e8c5e01917056c6519f73',
  '0809659bf87393adf57496553757c89dcfaf56cf',
  '2f7fe5b4dd967b650f4247125d1169e7d8e52995',
  '1228bc01742e72b08eff190636b81ac0a0fba120',
  '591f0470dc6c3c966b878d5d5d83a764c645f845',
  'c0333fb8ef22319be2837bc80adccbe84f5d5e0e',
  '3224948d1cb5f9fc53aa4dc5e6d7d5b52fb98d85',
  '82346c150ab27abec0f31ee8044a41180b99200b',
  'c455fc5036876d53f7ab373fa0815282376be262',
  '9d56c09a3521c43af36a6daf891e9ae62cd14b17',
  '94eb40164bb69e76a60f28a66cdbaedf4ec053f9',
  '34c6329e149279334ab4d21cd231842893a008c6',
  '23cf365abc87514fef5ed18cadcba4296d4a0cc3',
  '6733bfb9ee5213a611da67f5e0d0efbcf0076baa',
  '11f5b59a8a2f1f796e254b6f87c6a050f4bd6116',
  '52cece6469007bf8ac21f14c2b9df80c80da60ef',
  'bcbed166d317c47d3284320f08ad796c8b0e5d97',
  'c9b0e56829852954b8d47eb420b081d4327582a5',
  '59c46e4d5d45f4068f621463cdb75bd0de920c33',
  'd170281cf82ba12eb678a7ce564591f5dd65c189',
  '14a2c31366112a7a6fd707cbee9ba74d1715fe8b',
  '74463e04dc08b974b1935905cbebf4835b9029d2',
  '90c47453da23617cd188a8dd3c71671c816f2146',
  '8c9aec964b705f53e9b6f95f0040c2b313f0b760',
  '1aebdd652dbdbead45cba56b05385f0c0d81fa8c',
  'd68b57da2f26e485b2ecd8d195a5ccc2b9dec07e',
  '46010c6740c3dcd5c9b80473607f1e09a3455bcc',
  'a30d329171bb4be76e415746f0e04d47929a2c72',
  'fcf5cb055550014498f06475223c12ad8b3e2982',
  'ce25c841e068d7c11d64da45aa1f7c1ae628805d',
  '0ba2dc07d1a209380d716924990fead1743199b3',
  'd41b25358ad494c8de0bb19de73a98b375219e11',
  '2bf240cd8edac3800e94d5e4343842bb939f4f86',
  '8b80a20e2551d01b43fd4500c60eda00a64da5ea',
  '7630d140a28a705206408e88884f5fdaccbddc0b',
  '9fa07dc92e56ab74c83015394b74f3c2895a4b37',
  '05f2be6fc48e097b692ec7aa59a79c22d8650e0e',
  'cd64a8d6d52f3f06e0e7ece52104cadd26908f91',
  '7e294fe89f38ba1a9c3315e36a8d4ffd11b996ba',
  '1197bcfdfd64bf11a66b730429aef9dbe84920a1',
  '581276f70a96a34b641f7c66afe894e066392772',
  '201cb664ef80e38eb21b9bbb416dbd0ff99c9b8f',
  'f593970db0e5fcce3c97e5bee7308c626a7ab705',
  'ab5a117ac14edc7e570b24f99a9d754b0404cb77',
  '4c4bfaa6e3435b4a037457119e6313424044c0a5',
  '5026b422cdfedc16607f278ac1271cc8e6af2ba7',
  'd57139b6d98e86d13c79ee7d14a708bf7ddbe622',
  'a5ce60555e421c6584499c75efe9950276401ee4',
  'c775d778bbb7fb20c6654f3bd7f9ed8c15874dc0',
  'd2e58ac96e77babdd34e45b5cda39c75ac9be1fa',
  'ab57744622d113ddb2df35a491675d4aa0565bda',
  'b265a10fcab71f403b16d70662cfd44274d6e0ee',
  'a7c51770cbf8e8817500829741ca10a50ee63aa6',
  '4fd7514fe016b49c765748932fd4d1a267b610b8',
  '8ab7a8aba821d16aeaeb774892bd7e9f92b5e958',
  '2f56daa2da89a3c68e9bcf344f99ea14ef300b46',
  '9544a14150b590b869b495dd022e74418c38cf88',
  '9c2974a9334fcd2d8008d7343794f3ff5ed6a29c',
  'e56169cd9b41dd909f56e6b85ecd495d0403537e',
  '77f6a60388b3ff596ebd15eb6340fad9d289fcbb',
  '3c0997fa27a9320a7e414baa8e9d6e82c13b4c5c',
  '23dcbbbcda68a8d9af2e1870021465747d75ddad',
  '0239da885ca0e625216daa91ec4bce7611ffe0f9',
  'fe59cb11a4146abbb37d9c7f6d173efe9af3c2f0',
  '9d97ed709a6c5a06ef9ca571af66336d174173fd',
  '2f683662fa2e738c890b7c57d4bbb64fee792dd6',
  'a73eae7016d684a86c65b1d03739751250d75253',
  '53c6a17ac1a7cf6e6d820311ec2a86ef89f064cb',
  'e59d30cb61cd1aa7e3fe1080808fbf0b86f67acc',
  '55adff2325e17e4a1c32893216c01bcaff2138c2',
  '8469dcf262c58a3182ba649696efd415465d6376',
  'a3af208543682583195320cb1c210f2bfc241c4b',
  '55a6e1772ee46ab4a8a67536f169cd6a25397afb',
  '6249f3bd40ef5a0175f838a92fe5ed2f80bfad32',
  'ac8e22189042535e2e2dca50b370c471862fe095',
  '096849e2220a6a4562b050625d99589b26c21e22',
  '729b52f5fde914adb43db1998aad2f33d8fd1191',
  '9d2850e179185e1b4a4780d435797ac16e3e57c6',
  '53150b066af40e8a1ee2613c01ba9f79c5afecb5',
  '1aafe9b439e6f607d94d65a2e511b3ee005366e2',
  '9f06339b01aa3deabab84a6bb91dc6a15e80f1fb',
  'a517a98ff6fbb793c688e8bbba4c422ce8d7c394',
  '3ac93841e8b7f557a2e459fb65dc4c4906be6e05',
  '424bd28ea07ecf34da09d6bf95af1b870f792971',
  '3ae5aac033c20d09365b5558869f73e8a7adce7f',
  'b126b709e359c4846c3cf7d7595b1e6dc7602bbb',
  '80e6c811728f4a0e5af8556b71a37bc1228446ab',
  '8bb586e7427a4eca46954d3c2941711147bcf871',
  'a235ecba0600183ea7a4a4eaf0da7dfb8a683ff0',
  '83ce06777fa17e72b3e9d3d7f2670a3263998c0d',
  'c042155dfcb5c813345248bff18a62d0f585718e',
  '2f5461d2748f3ba6f20234c98f2521b35278be18',
  '467230e9998f72738f78f89ee64bc7ec1701e252',
  'f03715c5eb0dbd046b321df1e95602667644d6ec',
  '0bf6c9ffa5a59898b7e7e15e70bc28a3be0b65f0',
  '2f8e57aabff7734f53720089d2cf04c861e8fde3',
  'f840797c8fe26d119808e358d074fc8db81a3ac0',
  'c5c625632927689a0e25ab97da7e7cd54c9b5378',
  'cf1d3b8c6d3eaa4ef622d85a71e5dddbc4113102',
  'df2ea6f9abec4a57ed3f31d07291d2c2571f55e8',
  '6224ff53fdcf822f59e8e77635dca59866e01794',
  'f8e810492d3a5892dcb516d1922e867aef4f68af',
  'eca456f788a677c4a2ef6f24483dc8a9c943585f',
  'fd87795a21ffc7e7acf50805631e51fc68ae6600',
  '1e390c5f4a04ba0dcc26d5ea3a5cbdc879b2d2a3',
  '38546df723484e99425408b7fc1580e1a4f6384d',
  '86d816d3c247c2c3f62ec4612783c78eed58056b',
  'ccaeeecde3bb44ab69a7a27385713bd189977230',
  '30f86dd403d73a16c6b3ce0c9006143d287a4f0c',
  'be6061663b852e4427b704f38c2ca958d91f7ce9',
  'e6d0fd8f6d0ab760950cd925b641e0cac0fc576c',
  '52b36282625f50fdb106a3b5ecb303a2815bfe53',
  '20386a7d587520dca9b350eb55f64a7c4c2036d3',
  '59659f0cabb5dc36e1cb8529969fc4e91f8de6c3',
  'e7c86853ddbe1fb56cbae595388e0a461f4fe350',
  '257e45836650c93146da3b32ab43c503b30098f4',
  'bbfae14f187f99823d31fa7c09cb7441819f6030',
  'b55e23ea3dcc7157ca9d2bc9a81177d3ef324222',
  'f64ae2c864cced0de2a71e8c0371e53d9ffe9e70',
  '295861b0f27c2de79003f524b29831e74c289ddf',
  '5b31978996347f3f2f00afc55694b5eb145fc99f',
  '9be37260a6147e944c431e22785abc1dc9271cb5',
  '8be439eb432cea627804829707697b779f06f23c',
  '2327411f0fc2b3150b73ec7ce34d802b0f4efc49',
  'a5113150c4335e24871d3236f97e062fe2f1f7c9',
  '75134c44304d2c2cafbcc92a69ed33a8f48b6ca1',
  '65dea351dda098154e10cd5be7cb7d48aa89db32',
  'c14ada709fe6d1ed93c7359ac9db6189930f58c5',
  '5514fe4edcf11ac9a7319aec2aa756e8b92a6686',
  '47df9fbc96ca07713e1a0cd0defaa016c788126b',
  '20208a6183f7ed1168606a9856a15092f12385e7',
  'da699ed457a84ad4c75e5ab7f8e4a724f9914968',
  '4dc74327ebd182f63199c3f4bf1c2e2a9de68835',
  '5e9110c98bf9d8cd34ecf06fcf719e4803b783a8',
  'ebdee96e9a17c9e5fe7627cc644eb75bd068a6ec',
  '23ba34a2e6b6a4c328d648ab3f1c6982bbaba394',
  'c26cc5c0684fafec4c85df2c2a4236ab63146aa1',
  '0cefa1d479bd45c7f8cb224e097e63b5307c3f88',
  '09a2de432779a5339c1e676d629665fdc6095999',
  '3be4e8e9d1477f9b8d71c6f81a5dfc3634a3407c',
  '84ea3326fe16f6b102da5a06d004a3c8313c907d',
  '56b5d209f759a6d8b5aac1285d60fedac3e72a44',
  '7f73fe5921471b4c5f36c7c94c36e222deb57c01',
  '090e8c2f8c93f791984b3548897c3d03c2fe5665',
  'b7952b6dbd4f6f70c60be0d5be42e136e0ed2ba7',
  'afaa9b65877bfeeebddef6884ef11e34af4a9764',
  '32eae6a395efdd52a98e722d46df4f4966a26cb3',
  'd5202bb0a8cceda0466c982d88cd853fcf84089a',
  '71402275bfc25d90e63ccde4d615c23ca6c754d5',
  'b782d07a67d357566dcc7cb59e85c7e3a7214709',
  '72df4fbfa97da5185421f84a8174398030c9e88e',
  '2d580705df05d2ee3cb85d2a312f9535f58b53cc',
  'a68132c0073d94fc8c8216eb99f6436d7c143ec7',
  '8534f7a0abc0813164ebe706b4e3ae5a6cabb278',
  '4e2fdb77d2529e8682f7aa9a1cccaffdf1f2bba5',
  '29b2141b0ed9493a91cbfb2326f4b724655798a0',
  '4b90637381a33d218e139507987572ec52c7fccc',
  'dc9a76c7e76ee659e3ee20174ece7935bcb97bdd',
  '26659dd69c6c87a0ff4a5fe010750bc9bf02c9e3',
  '0ab7ed071270618d25d7e51edb15b90e1938e42c',
  'a85324eb864183c3031598d4801f8afe28cc519a',
  'befb2e831488f8701f26f16fb758fa4092fb7ac6',
  '37647698105f1113cf806a313c0972ed79645785',
  'ceb454ed153f1d4a5e6a3e888c23644b483a50dc',
  '0abbe881562a10b6d2ad730577f5e9ae9b2059ab',
  'f551b292b59550b23ab3df3d7fa96f241cf23acf',
  'e430e907a71c6481a0fb7a630a63c9846e567a85',
  '9a2322d4940e1e3564113aac031ccb283e24a9a1',
  '5b4d315ace4b1f0e5f783a6cd383837f90f0c72d',
  'b49a479577015bd2739cbe0c3a93a3eabab59921',
  '999fd8985272943a1e3750cdde1df95aae85f22d',
  'ea5810ca7e60b4e906b021eaf3c55c61d2aea6f6',
  'debc3d3a9f50fcdffa81eca8f6486bd2e0dc56de',
  '2f04823d34317bb4f4c9624440667ea614942237',
  '17058210514aca2e07c1567f9a9b099ec85726da',
  'fc95f0d06f18a8530b2acd143a652ecbd16bb958',
  '28d2e1ab1dca5762ddb93d061b686ea758ff13bf',
  'a628ee29168e3ac5ac819f421ed830856f8b074e',
  '539b43aada0ed7b5b08446e0b3ef4bff71745a5f',
  'cf40e1ba084f5a975340c4e0e5be969a4fdf0e23',
  '9a4704c4526ae043aa73c773d973ded358539f47',
  '87197b7e7a595497a80eaa1114de84f9b69b056f',
  'ac74ce3cdd41ec8c1acefe0edc7d42d33cc414f9',
  '9b485b6fe2c1ee490de4b2c3c6977878b7a16452',
  'ace6fc7b7a60c1850f87019c30d0cd9d5fc7d106',
  'a029a02c9f1736942abd04e34ae2045614e4da82',
  '79289039a2cfc38defe703f158ea3d9d6c5d90b7',
  '4184467a4c24c79a53049475128176c94dc9ed59',
  '20ec99ae99ea58dd8c0759a4681dc455ef0ee74c',
  'eb1cb47729d195e2cdc2f3087611af4146ffce0f',
  '1b206901714eefb36566f7a95cb25f048462f236',
  'bea589b52eea79d20263a001f35dc11988d44294',
  '640f4264461803a008583d651e5d902fc4b39ba2',
  'c9335644c0eca3b06121f29817bab028af0a3aee',
  'acddc448a75d7598e3a01b14b2e2d314a0338a76',
  '135dbaba47182488648d216dd1c3313d27fe36b3',
  '750f56f66cdb549cf11c26fb0eed4f13bb33b4db',
  '8b06870613e403f43ae2e8f74a68545d922ee53d',
  'ae5fb4cdb270b59f3de149e59647273d81694d27',
  'ce50739d8966e18c22c5576f5585d45544bf127f',
  'e90792a6e7507d7e3ffa37f3d5378e5e12271717',
  'a3033951d5101da5fc469518c0d1ebc9c998c5fe',
  'd8ee25e24f8081d658863739be29a1976270f519',
  '2e1b8fd358604a12d481e95681d6ec67c24b1b88',
  '084a7a061a7437afbbe7ac1e3ba2c2e4a6b09845',
  '738f5db3462cda404b8219b53e836a1f658de624',
  'd9c552d38238f00503741e8e468cd3da612b42c4',
  '44ef86809334e9a9a52ccc03aac9959b0c440790',
  'fb7883241ba34a1397f773ed495509d583f2ab3e',
  '4c378e17575c78c0da8cc3a3660c8226507fa4e2',
  '1980f79d8046dcdab19b6d9560d46734a501c1af',
  'c5334d06092df17c795caea5cf312bc0f461ccf4',
  '3812ed11b8163623f01651ff7641414415730479',
  '731a548367b2b31d7e4601ab60d18783d5652056',
  '38c02f1ebe12bd92db952a2cd67516d6aec6375e',
  '0a876877a7737030e55d71c9471285180a2432f6',
  '448fc3ad3aacb51358e34c3c00e065315173797f',
  '12e6dbdf298ab81b42d3056c499512ab11746dcf',
  '515d8b71ab9c9923ced45cabdd76e31c13886b6b',
  'f7dc4983e5266b8aa414dcae2c7280873affd610',
  '7c57c9eb5c823011fb8b4d99e1ff3d44332651cd',
  'd9b32662bebac1f29d2ba4ded5edf51251c268b7',
  'b6c510d82213de884c9bddbca8c2ade24763ac80',
  '6f045007793a324cdc35cb680b06b109131d8421',
  '1e8c35e4c65cdc6b23eedfb608b76f37481c4524',
  'a105b166ea354554ef13ba32e65ddb1817700a9a',
  '35f18270344ba0d93f07d0e68eb764dab81bf019',
  'dcab0c6b4d21acbd8e96e6e51d01eb32549b16ee',
  '0736134c56bf055d1d99ab8808eb9dc291715fda',
  '881d6a58f95007bfaa5c709d97baf0bd98af09da',
  'c47d99ea9d4614c70a59c72181869c55d8e40b99',
  'ec9efa0f1f4ea50878064d7811bc9c8572005eb2',
  'a3daef9bbf1b4d6a948e22395ce42660e9a8603d',
  '2c553479a11ee32d71d4d2e7a09f8508a6a4c269',
  'eb1d87229c21f28fbfcc7e6d8e842c1976cd15f8',
  '34738536748787bb894c8fe84e5adab400ef3e1a',
  '250b4cb8830ef221ace304de89876872be9c477b',
  '6021a9319a24928a8f6d5d49190d526d7bf0ae08',
  '6235415369d5e49a9e0997c3f9a4db85bed2a189',
  'e5f1f63e2df40ca316b8f92db45bf54ca982436c',
  '2c75c60e8b4eaf1e35614942f55b8a62ffcb65d3',
  '4a02e20ce579812e7007fdbd66fc85bb359a4e9e',
  '6cd088e822c2a225de2ae2ceabeccb779ba6dc4e',
  '2d8767394678795cf4887ed8de0be5b73b496f85',
  '305d580e8edbe80555922a0082940ce19357eb1f',
  '6d5a9e810472bbc710ec706716b1bc70a2d48d4b',
  'cb3d3a7c9c61e7f37398e4708c8d580679292e15',
  'f853c14697b1a26da8efb66d5fc9d8c9fd488f06',
  'a91cdac1cf962ab87153ca262cb8493147c9e355',
  '9a4a4735027a9240e36ae21a55057e9926c32a7c',
  'e2725393de766fbf6748d4152c3a39b33245b726',
  'bd36f0472dcb53549c9f2200ff0e4e863495b5bd',
  'be822b2a6c9ddaa9df4bc5bec481d7247db403b1',
  '214ca1deeeeea6f248eb811d3955f816ec90b5dc',
  'bfe9174110ce291d95d2459a965a7bd32e81189f',
  'ce4c8dbc823102e0a019da2722f3f02d90194ca8',
  'c145a475a806c7ccce0a81d22e81a88af6ff5c42',
  '66da9c37c826080dbd070ec54d6c0a84150b9857',
  '8dc0fdb3b77b7db500ce3ee820d57addbae94765',
  'c7ca2511d43a1355015238c5e811cac1c2d789c4',
  'a879aa30bf91970ec0294f2f073822509dd5010d',
  'a1718980c0be5eb40789b6122525ed5c33f013a8',
  '9a0e015f8df9ab5ee3ce1a2dd7abbd3e9c12c7fb',
  '3ba21b5f2cb9177ddfe0ba54d214f6fb0515e6e3',
  'e4e4366f9fb3d0a269ca49fcf183afea9a1bef78',
  '1b30c072567019a3a8a59973008133ed475475dd',
  'c9b0b4a50e5b31517259f90fa8acd34e1319c937',
  '73f6e64f9aeeba42297b589b3185363bf259dc87',
  '393c62ff3e004ed0e8a48b803e69578e659200cb',
  '90e19a2bba5c35d0ed143e944e649c09f10c08bd',
  'de717472f187747583ef1856fbf5791423178713',
  '0c7a35997d3c85bce437230db16befb61fb3ca4f',
  '575586d86aa4ff7807f42f998a91da664e331f72',
  '94ed5c567733401047c1ff406439bdf68b221e60',
  'a3caea7ec3e4cb47db632d1e9d938efaf688b71b',
  'ea0354161d8ecf82d8ba8fc804088168d1be2604',
  '099ff7897be7363ffce48675acfd6ebb589ae7f8',
  '613a03faf6881e1656a3454dcea70f03c11f09de',
  '03b926a620fd86191d3178a19689ec28f05aa59b',
  '6f0b1141a83f8fc7c71d0add5f09b16684f48b15',
  'f3845a2eb98625bdd1963cf783fdc606d92b2daa',
  '20d563a23846387a5c00d3a6cb8813357c42dd61',
  'e8cd9a82c946d1f17fee463c8f7fb603b82e0d6c',
  '44bc476f36b188b07c581e98cfb50b1121921f3b',
  'f69c9f03ba851f955f8c24c65914703848796b47',
  '58d402902e63419bc610d33d1120c0f0ca285fe0',
  '0f9dd1724863961b1166545a2a7e9d2d185630d2',
  '7917c8dce5571734f6daa2a37be186c90ed2b1c2',
  '165d9d95c229f51e5842b9de83e39060319105dc',
  '0c4a8b59b2e1bf396210be96f690d8d37251d39b',
  'ecabe62784fa2d133ad65e575a08f70a545f2825',
  'e1059441473f4fce11b2944825784a6a3479d1b5',
  '13edd7e7e2fbaf845699cf2f8f0b1c095bacb05f',
  '85d3119d0ce629b2a42562354ed2056c93e062f4',
  'd60c800b68e404b11f000afe5c579d69911ebca8',
  'b8e816ded3f30ba66b7beaaa0852c903d5df2ad6',
  'cdc91967c6c95294c0ce7f72579aa884cbadc259',
  '8265a6265a9c7d96edcef303d999b6825a1836a0',
  'a9feaec7436353f8adb08e3ef6f885d23759b68f',
  '15df43c6f7f3d698393e8060505f6147d1897778',
  '034ae02cf188974e534f996f660e312b247ac8d3',
  '628144c0ae74b4de46fc1f46b17980fec7f056de',
  '571c74d6d786c47b4e2cd1ac9e3a0aab76c1e502',
  '61db830ae9e667c44d89546339cdb5b31f5dba25',
  '55926882065b3f81d93d79768161c67cf1c737bd',
  '6f09fba378128cab52ab830b964c908b7134f96c',
  '634fbec21808ca358744dca8269ce8534539ef80',
  'ecf887457cfb039729f50abef5ab9a8a0bf37ba6',
  'c90ee0a03ddef1a9fa995b0a785c22f99deaca20',
  'b805f36d2b5a3728d0960f570c12689f81f90ad5',
  '417c24a7ad455fe0927d3741bf19bafc1c09ad23',
  'ccb20a9f12447f4c4a46fc9215f38da5a38c5fdf',
];

const UNIVERSE = {
  channelIds: UNIVERSE_CHANNEL_IDS,
  name: 'universe',
  sortOrder: 60,
  icon: 'Universe',
  label: 'Universe',
  channelLimit: '1',
  daysOfContent: 180,
  pageSize: 12,
};

const DOCS_CHANNEL_IDS = [
  //'2ce55ca6f9ff2d2255c955687e57f22e6422a93f',
];

const DOCS = {
  channelIds: DOCS_CHANNEL_IDS,
  hideByDefault: true,
  name: 'docs',
  sortOrder: 70,
  icon: 'Movies',
  label: 'Docs & Films',
  description: 'Grab your popcorn bucket and start watching films on Odysee',
  //image: 'https://player.odycdn.com/speech/popculture:0e.jpg',
  channelLimit: '999',
  daysOfContent: 180,
  pageSize: 12,
  hideSort: true,
  pinnedClaimIds: [
    'c1d0e80650845cdde2a688d7eceec497ffc303c6',
    '126c52d53c0a4027f537906bd500d4ff63e3637e',
    '10513a89df534c97711f6031d7c2e1ff14861470',
    'e1f872ad18247bc3ea9a080037ae666371bcacb9',
    '76770293a8a6a37841941ce13b4ae10b43ee456a',
    'be9167810873aa9013fc91ee6bd034ea13e84c9b',
    'c6b4d005268e0d7edf5b3f28d61a828f00acde49',
    '36eb318b1f3724b594d2dba49cb47842c62acc97',
    'bcc4fe5580a1e084ed3b14d5276f73a2c998f936',
    '99a399a15f916e2c4195dca063d7761fff420c04',
    'c142158b22f2649e0172435b8951392cf43d19e5',
    '4f289752737799c27381f30512263ba73f8b5500',
    '1b82c46948dc63f39e2125d78397139e5e09f1d2',
    '56dc6699aa30f916331ba6dd66c3d0a1239bb076',
    '322158b929e94b23a15bf1de2658b19e02c11bb7',
    'ad689aff61ad0fa539aec087ab6afab1e264f9be',
    '4d538d3ecf43d022de95c9bb9b3c8df8b660a15d',
    '50743e8b751b660c9e04c9be00a6d757c6c1943e',
    '87aabae426d4478ddf07452b80b268975bc26ef3',
    '2e1444764621f6e96ac058cbab8e8910a3029f09',
    '8da27296f0a53e08c7d25d1edeea1811a4bacf67',
    'ce0b90badf06bd2fd40770f126a2c6b8f65dd365',
    '7b5b813490c79169868982601cdb0eea6f4f2556',
    '9e87d36a1bc09c209d55493d9ba7dabb3ecb02e9',
    '2baab47d321b8dae76e3b7150ce336a8689b38cb',
    '334ae9b25e36a0ed429462c0e32d3240a50e0d20',
    '7ec875ff923f7700e5d4b8d91c37e4bf0ae6a744',
    'be8891c7b4b6ab5f2b6edb3d35124d9d7e6c8dd9',
    '48b676553d8891f0799d3d7e94054fb300688d5f',
    '660603927dd53df315a78545f856cb3ef8878c01',
  ],
};

const WILD_WEST = {
  hideByDefault: true,
  name: 'wildwest',
  sortOrder: 999,
  icon: 'WildWest',
  label: 'Wild West',
  description: 'Boosted by user credits, this is what the community promotes on Odysee',
  image: 'https://player.odycdn.com/speech/category-wildwest:1.jpg',
  channelLimit: '1',
  searchLanguages: ['en'],
  excludedChannelIds: [
    '1f74709733f54cc6f9bb18368ee8b5309b7eba01',
    '3af71c945bcbc4531f3ea3ef28f9108c1081cc23',
    '6356414b6b5332503083722d03f4eaba212d2fc8',
    '1954e5634ec368fda3a6f80698582dcf04efb853',
    'b6e0e1173f66ef7200d07262a8efd34bfa62dd20',
    '3f71a26dbe80eab8d6a8516e7827fb31e9b54be3',
    'c718a85496d48288cdefab1f73014875c3e3f29e',
    '7d9e663e7341cc8874dded732b13229a8a29f5db',
    'fdffd6cc8a929deb329b6ebd029c1d6422422725',
    '82d98d1619686434beddfa03bc0831730770a16f',
    '113515e893b8186595595e594ecc410bae50c026',
    '49f1876b382a69414a330995908e326c7b96c132',
    '0d6c0685c595bda0d6154504fc6a54c3e30864fb',
    '6d4edd9891e79375d3b70b32cc008b47a9286a01',
    '64b49d4313887584160c1e491b9f469aa5a3318b',
    'b04bd5e53b5d72e912d68e09d7f326f5c14fce28',
    'b325961c50c986f2022313220e5b98da9b0004bc',
    'f0b146a66401cd3ed71741556bf90394877a4e26',
    'cde3b125543e3e930ac2647df957a836e3da3816',
    '2e1c5a027c1ee64cc16b5ca7624bd347eb1bf252',
    '5501e1e8b692b365903ebbb63930c1b53007d68b',
    '6abab163723b35e2f448346a21060b5a47ef357a',
    '2677aa4bb592d97c4be07b80124507db18a529f4',
    '73cf0a45263c0b999d7fb3bad53b87d550ef3f1f',
    'e0174047587a4a534f165969058e6374d00980fc',
    '879045866d54ddc6ec34dc2aa184b79e1d37aba5',
    '90237b022c5be1fef9d003187645da125ea353ff',
    '609bd74a6a72537ec790aa7e10e658076fc0fe76',
    '2f76634783147d068c03f4415bb7674c8b1fe1f6',
    '338bfae30a9828449616d33549a6995060c489bd',
    '4f54ad0b71c10fcb9ddacd116dc3668c14218481',
    '17a490118041aca036b076d6ccd1826cc236a315',
    '54426568a20e41ce499ae8b7240a19d2e38bbc57',
    'bef265cfbe3f3c45769cc9b163a41ac8f2e12369',
    '294f5c164da5ac9735658b2d58d8fee6745dfc45',
    'fc5e33c4e42fdc68b72cc11ea82f754b970fa817',
    'c18358e876c11952c14130749fcf4909e45430c9',
    '1ba795b388a8eb286673a1581383eb409dcc42b7',
    '86686aee71b98e6e7849f657fed39cdade9eeaec',
    '92f7d50aa1ff8f14ad2468736322e751a35a6e57',
    '665107828a09e21da855043d2b719d762558ea25',
    'b8c0540efb042cbc6e9da900a91cbcaae829d953',
    '7983464aeb37b09bf58f46ccbe5d2f6c631b58d3',
    'e76f2d2cd12de2739be54b169a92bb39e7c24b17',
    '0e8644a5d69058271dcdc816cf9cc917c69e0182',
    'f6ec70315c2681a3bad9a0eef1017478f6ebed3c',
    '18c2b13130552cccef4c271465f43b99e2bbfb06',
    'c800b3ca10d8893231f56dac8e4e8eda27a63dfa',
    '496730b88e41e74742fba5b6365435e5622dce92',
    'c1cdc9cbfd556fd0e80776a6d93ee5feddf46c92',
    'e063b0a1c3c738fec204b2eacc5c97953e7635cf',
    '6565000f02b5e9723f6bd76f73dfabe5646a994d',
    '9d4d9550b729bdac8f6f3b9ab9c918491e13996e',
    'ee1aaf96d05ddf21e96deb681d5ed2f5dea1d237',
    '2ccc51b97c55631a8050c82f73d96a47d0f1aa5b',
    '0355fabcb9e5977150366d3c9776252b13170d0c',
    'f3c08203448c28c41c61dd0d276ab0248407bb14',
    '811836156638dd7c78da8b0c956c526ee4ae3110',
    'a32a4806849b66c520542cb4c6028940436326eb',
    '28a5c47d8c5ffca32b979478cbe9ff1040d92d11',
    '267d71ea4a82aded607f6af710688a8578ee9d87',
    '9614a4fcf9d91e4588eb512165e9c0475f857555',
    '70e56234217f30317c0e67fd0eede6e82b74aea0',
    'c9da929d12afe6066acc89eb044b552f0d63782a',
    'ab524c5a1902d7a0f072ebc4d093a0e500704202',
    '21aab81cf6b7f3eac2a5d934d35328560ba6fc9f',
    '9b8a09c41c834f0d629cea4a083f86e4645feb0d',
    '29d63cc4b23073bb5143b15bfa7872bbce073a34',
    'c1d55ae7b8a67e7a26d1511a462203173122e61d',
    '5a7d88e0f510cdb2355bc4752bf530875d741491',
    'c101bac49ec048acca169fd6090e70f7488645b1',
    '3e4240ee54f465db9e41435628ed8a5895c9a654',
    '8b1c54250e1eb865f657d1b3d553d541a49ee4d8',
    'd34376986bc857846993ff0aa750875bf7cf1b4a',
    '840a5f8f0c5b7d1b87312f9915f7cd7a9283d572',
    '2556ed1cab9d17f2a9392030a9ad7f5d138f11bd',
    '19f251ac6d8f2990839173d932a47b1da62da484',
    'ddb27a205f0c5d17df73277367b6ef8256e0ed01',
    'e325ffd3e6b78f95f96a0e1fe4609539f62d0fe7',
    '5462a0ced61fc3f86225908608872f0fda2cbc32',
    'c4b5c6d71ac9597b69c5559ca0f03691f58370a9',
    '0475ed551e9513cbf81d2bec27488b9f5ea78d9a',
    '79162cdb9d5ae8d7502089a38bdaaf81f902bf0c',
    'b57fb7641efdeb7af52bf96abb280d84c45587cc',
    '833fa9985192d2a185806cad08d4dc9910b1b4ef',
    '5e7a90800637e828ef5a58ec747a68840a4038d8',
    '1576023fb1869655ebdebd9e5b45dc4b06adbb91',
    'bbab55add9f322f353649dd75e5129875d32aa7f',
    '5499c784a960d96497151f5e0e8434b84ea5da24',
    'b88d5fc723c5e26cb48f7aaa2b59963864a635e4',
    '1f70a98e6d088788ec49192bad900d0e71a6c320',
    '5706f07ecae35b14c0911663033cd255b40935e0',
    'c7cffc68dab8eaedd97348aa0c60767d75a10571',
    'c51219394356fcecf69611ee8f969061f5192f20',
    '87181665a50e0db33488e906c64c5717f5a39626',
    'eb5050ce27665029f5b76bc3499a7ab797c265af',
    '5ee9fbc36807b66c4274417cabf60ad854711ee2',
    '45ea87e331c3bba6083cc6e8eb3a5f4b2e413c60',
    '12f1522d55c06b9dacc4b69b9622c297ff2cdac8',
    '72aba91bfb3caa108b2ab915a80079c35ad2a8b0',
    '2e313fdf44a0f86ffd219844711fe0334495999c',
    'aee8476d102b3495be88dcd8a400d661eae9e42c',
    'c3e07602893f2096631b6179d97c71eab1d99adc',
    'ab00da5da59c7762ffa5fbe343965eff2a1f844a',
    'b543c4d0e05fd38f17814cd51310ceeed209a52f',
    'd4278ba332a768d3fbee367361a80f163608d2b0',
    '77b4425e0bb0f4f628d196145019e460c4eed882',
    'f33b63b8e6e43d64b9438c55183581fa72ccbcd8',
    'dbc3cdf6e45e1b5fd493baa5a92b97111e2a321e',
    'c523ec016c6bbd8a5606659471095afe8d10da86',
    'b72f83bdaad3f7c242abeb22dd7c64fce521a92a',
    'a96f6c5e5ac3cf02406e02282e9838efa4a40c0a',
    'ee453f8e3b92124c8fdbe7ece735cece47d2a538',
    '4c5229af2548a1f5f77ce9ce408b85dc1532f96f',
    '9ec3e7af22b15925d06eeecc95ef3cfff4e33934',
    '8d14799be9010136ae4e352c19456c1580e68d8d',
    '84edf7e474aba8a285d42a2ac1792801c5a2ca40',
    '1059bfd4d5d843ce7c2f1ea5ffe8c5f8b3f0600d',
    '1b2b7d354eeecbc369df4a215bc62fa769bf8979',
    '020bcc69a25b575d6dd7e139fb489d90055ba202',
    '381c10c66ff7a104b8f8859e9f54e34991cb23bb',
    '11c30ffdea4e45ab764e5d29eb4ed7f88e491f76',
    '9bf8f12ad4c6f555fc4a92e8914df6b254575375',
    '22b09b16001fdfcac1596673defd76e576295257',
    '00bf03150c1b20698783cca7265af9c78d9a7246',
    'bdf4873619fe8ea85ca64bfa934239b1a6d1d5c1',
    '9a9133c5306f500ed98caa6e944ac433c554045a',
    '9a1cdaeee5d42f39a26cd2e1aebb838988881edb',
    'ca7c39448b13ef42688567dd468a88111dc53e89',
    '29531b013aaee87b5b38557cadd56f84bf025372',
    '6382292613cc86893a9c2d949c394ef70fd3e072',
    '42e0913aacb8769fddb2bfaa3790c24e0aa6a43c',
    'f456460846ee417b9d8d333a5d71a5bd7867cc61',
    'af0e1ab44d9d29477da311d6d662bcd29b95595b',
    'e6315c25446e80bd0bc6077126dbb93019055532',
    '60300386be0010b7746179face10f65f4dee4291',
    '8a2b4065ac21b3ea19f0f6782d4e869ab60b3fac',
    '70c822b0b0a3f046e965ebfe700d69f95b55b148',
    '8a2223c9fb649314c7ae5c28953d8e686b5f4e5a',
    '35427f39b01ce296dc8e42f987f485162160317b',
    '6f86f75f67d74ac8d42422eb96cc32e6245b9ff0',
    '7e4feb7b361caa5c667fea99a203ddf5c4c0c0e6',
    '66d79d3d0f877e617acbcba7b196cbf09ecc041d',
    'ae8deefe0f895e3fa59f7dba432503ef5d4572d4',
    '656af8ebaead31b631e779ff2d86de03c2ac5609',
    'cf3047f58f96099cae696f4e04771020a36aff5d',
    '5435094db4cb902b48937c5131ef80787b5651e7',
    '27cf5d9c334f674cb4782c787898230c80775526',
    'ba5ed20a3b65859424a5e9ef500271f326cf5c62',
    '6167806f58aca150dd1721c63d769a009283ddb1',
    '77aacb120e1e6fd2d7e7092adce4862eaa0c9965',
    '7e3794fd44e6e696a12782b0326970bb4359d4f8',
    '9374f08e819acf0cc0338c7fb80a22cf1627926a',
    'd5dab8874e1752bbc139a884331899ebe568497b',
    'ae12172e991e675ed842a0a4412245d8ee1eb398',
    '3bfb2c2650151ac21ce2d6af2b0559eeebb0407a',
    '0af6267c66d95d8dad144d1c5822633b312b0462',
    '154fe78296fa4ece0d3e6d308905ce1a9342543c',
    'a23896c20e8d86145cbc30c75697829933c65ac2',
    '223fc76ee1de4fb57cea678c78b57e9a622421d8',
    '7907235004e01dc112cee07f1915d15f5c15d22b',
    '934ab920d288575b5b24ddb6fcaa98c01b40df45',
    'bc614074d3d37b953898936988dc6744de9a7633',
    '274ee717e6bbfa91c9457b2e5b5d7c19a6558ce7',
    '897be9fe2fe0ea311030c63eb5b58a9365e1f07b',
    '368065e96b70f58c00e048912dc091cc806080ac',
    '4c1a99be1ed15c6108b0210cde4740bf64432255',
    '28e34532c435c0a2ec6ed79c0c38165ceb40ce95',
    '25f6f40235bee8325f1821508d57c1af8e2b6f7c',
    'b06d6a817011fcbd065bffe25236e7c29f7044c8',
    'a906698b9a6ad29e3159bfd72657bbff07d0fb52',
    '79340a63409a1e60a4941c86358967edcc5db1da',
    '53d1950fb08d3a1dd5efc790ca869dcd1a32dc5b',
    '69d1f327fe669cac7e41c9ab23e1d16acccacbc2',
    '6bedf8137d81e9b622b51a97d50cabde5856bbba',
    'b29487e5a883bced206851bf976280b61f16dbda',
    '7928dc79dd8e8711c1e17809692e6176ddd591c6',
    'd3cc8e191458575532f495ad6ee547ab6394bb9c',
    '81e19bdec3dae405492bf83970f7fd21d912f55f',
    '123b420474adef3d142fc375a5cf94d5020664cc',
    'a81f87584fd77b288cd7680cefa4cb6a39fd8dfa',
    '9d92f21b55aa33b149231a9d1dc1db6217b6555c',
    '7c046debd80ed66cd2ea529caaf5552facb81d26',
    'a47c8ab1b56f7a74116f2ae8a716f525fc0315d1',
    '7318bf7f9883b1285067dbd41ef7fdfe2975325d',
    '9b625da99b5aa1fb7fbc1bc3eb99e51f276e4c39',
    'ddd467b2f6a777007b1b8719bbdca6122a8c7805',
    'e262325d25d468570d082cf6b6b22051c697e841',
    '13b951ee9fde736f732e5b27d8e16fc3bb5f876b',
    'a9e2f2cc25c37e1934d3c2c36f263cbdd1884767',
    'cb46a600ee192c497f26b26588fdf500f178dc82',
    'c83c2b5b9f2c42623b7d345a6fde943cbf0094d0',
    '08bcd667fc1ad46e08eadc0ceb585185bbffe748',
    '6d1173b12c14d756ffd268a401431cd2cd2401c3',
    '33e1509a783091a041485206386ae39239d13b1f',
    '5774289bd81edf5442aa23693007e611d9be14e4',
    'd6934ecf47581114b9d98ef0f4cb5b37a23d71b5',
    'c64f446ba359272f36c5b4abe02109d0a25bbbef',
    '211cd5ccf2e6911928f9009099a9f81986912b28',
    'ea86138fdfac9891a2e15cec9b2143ef64805435',
    '7f07b28908914885de58a94f847378ade9a77515',
    'f9efaa9351d3c8551bc330403516fe711e5edc6a',
    '0ed18be69abe94681f893f392bc6a32aabb4e380',
    '6317cf5874e1582f6a1994ada5e73f9c8eb3c9c5',
    '839b6a51afc17c1bb6199ff1a6ed3a092f8dafc8',
    '13edd7e7e2fbaf845699cf2f8f0b1c095bacb05f',
    'b6f8acd0f6e5fe589e63375eff8ddcea8bb3be6e',
    '93a92900bdb3cc3b5a1a3997b706b38d3c7ea538',
    '2a315473f886c2eccd417c3d55c517899dc7aa1d',
    '0f5a23d3795364a86efb152e045aa19a63088646',
    '82a225a148f1a8aa20393ec66fc8c1a66c02c797',
    'd504cf542d5e93af2812426aec690fcc111b6d83',
    '0f8078f7710ce929ac176e3df0e08cf0b6ab2125',
    '780abbacb30bffd0554a3d6e79764cdc3551a0a5',
    '014538f034f869e1761605e0c9e93727cdd2f830',
    '3fc783fae191c72eccd904a6e6ee9ec473cb6e7f',
    '4584a2c6a052c1d22ba3b1fff9b1d9b98f244853',
    '4caeb1b60a64ebdc507cb6c2af3a90f59b7c9353',
    '5500adf1106ca718cab58597c2c498f46585b2ee',
    '5cdad3dc2b3cdda9974f08e8a38abeb12fee6a7d',
    '7644fdb8342624f6c647c79de25610801573fa68',
    'd4a522b9d7cfe195cc8bfd13ed85a25b97540902',
    'de1bec96b0bf5897c731b41b0a71b7cef136a9c2',
    'e354ffe07c736621391df1209aa5a819c73d0eeb',
    '72de6f73f5fff59c05efbcfe095229b4c376ab2a',
    '8f5e84b5873494647c5780c4508a762e39219aad',
    '3939ef29357ed597bb2788d83ada9bf3805e110b',
    'a0954d24ba8f94b59a089d163596f6f0012eda82',
    'a34ac1c53485a16b1a4a6222dccf3ba4c6b656d1',
    '3b618efd95127644dda50ab55c124a0fea7c9992',
    '5c7fe1cfccba0b6caf796daf4a8f80eec034a9c5',
    'c47345f23cff68be588f7136ab2cb2a50fb9e247',
    '68eb3f3e884400f60080bb910055bc11fecd26d2',
    'cdd07bf467135d92d0ac0f79142fec6a8304a7b5',
    '2d814f22110d39941f2233f1520d20a5ae126331',
    'be19287e2271d2fd54395461e2badcc3b678da98',
    '8dd8a2cc33ca301a979daa2e8be58dd454efacd2',
    '4780fdd5cf0574348d43ad6d3034798057a00040',
    '0e64905a8452e24717b6b0dc79921a843ee7e478',
    'e89efc43359aeba6d342911d2d1f2cdd0986e8c0',
    '7003dd21474ea84e046baa8cced8ab53e4b29097',
    '4a9a8baae2e958e455a6ca9c9d1ec4843b0a4a4c',
    '8a35b459063c41b49423326c030a96162cf3071c',
    '72b0fb3fac24e0e815d89f0f8f356f18d47872fd',
    'dd594ea2e8c95df1e02af280434ee7607a65dc42',
    '4e430fef2f4a0ad3cae8558b50a83928e3baaf93',
    '6aeb25742ac06e5e7c71c973e616f0576123301a',
    '423e39029d831e0030de12de8a2b210d42bec7fe',
    'a53391b0a4653b778b80fe34de31570e0ed41913',
    '4f591c04cef6e6bec15a703deaa369fcbda31ac2',
    '5d2fce992dc34d6f09f8ce5137a49be9b06b27bc',
    '1ea00856f7a31b7e900ad6b3d82f76966673521e',
    '71a8a8d6c3151317d5a3a9f5352b10dbc6f8890f',
    'f068eaac430bcb522a604d7fd61c712abe691a4e',
    '8783b7d36dd37540004750ed7594c29f1a8ef834',
    'b7de36298bfbbb8fdb07f2c033e966ba9da2d486',
    'c4831a969f7451b29b90665644ea4d8aebb67d65',
    'be1d4495243d5c6ce7a8010a2456e2853a28b5ae',
    '8ee824ca5296e7c56df797f5090490591593577c',
    '3e83a51c9972a3c248c7c55d75793b3c97d11a48',
    'd4451b9594756af8453d6b212ee09f9cf2b41da6',
    'cfc4075c194c58430f4a2c8de993fe7c34503ae3',
    'cb4bcb9e540b8dc4064a475daad32565370602b4',
    '71ea448222984b75d8e9aacdd51d3ff79d839835',
    '87cd4e5f2f58ca6b0c44b9eacd391a0d4ed1d934',
    '3d57b63531920cff1fbabe023c9c03172ba7cd91',
    'e13b0ec260ac25a2bdc7d8737463b3eeda5d731d',
    'b243b5f8df379f069de16f0cd7b2d999f08973be',
    '0e01be25fee1122e0d353710dae28b1c59b6dcb3',
    '4caab2702dcfee0805d46be0cd549dd51c5687bc',
    'a3be51c7c03a690db63c73d5b943db2ebe83da25',
    '9be6b4221a5a1266d3f1df94c7be073e59e5f4f5',
    '275345ba84e96b282e62c19cf5decac52d5ff214',
    'b43b629fd1977e1e5187be0c8dbc3098eb263185',
    'cae3ecab9464c476a696e5b9ac6ff3232fa8aeb3',
    '46d7062cfdd3cb1c9e5e342a053a46fbb0a9a5a1',
    '70d122698cc6d3511036c7fb2e6dca5c358463de',
    '31992f46215043a244df76282305a28f18613360',
    '72c796c2fd82f34914bca296a0f5a69151ac0e37',
    'ac6784a36d27db15445b4049e8e5e24fb3dcfd66',
    'df75764e06247ccec50dbc87a9c4a8a95a32658d',
    '6de621b92bfa14e54ab56e3498a893e21be785cf',
    'e23e889c6c0e06b3f59602061cee513067b506b6',
    '8971c9199add01aa71f1bd060d2f9d6c4836fda0',
    'f8e7d2f87bd31d7f40589aa68e91fdefd3bcb84d',
    '83b03196ac53200c6fb773996fb0cc8ff1e40acf',
    '6305e1c776c9df6a321bba646d7e2b669bb17160',
    '0d84d83b76fc43f859773337ebc0635ef90a4a99',
    '9f638b94d11d879726ae55dd5a0923621b96a45b',
    '8c62bfde1569293cecdb3a24a09bb36786ca2b7c',
    '9dc6a41946cda5ae575afc3ef270a46aa4852131',
    'fc6d5924e6b84cf19a3abef171e82703806284e4',
    'fd2136a592d7fd000f0851317d28c26214c03f79',
    'c3a3c6e917bc78cd201b30c4937ef886023cdd97',
    '73fc2d7baffc42090939ecc86cf05f3a835eac70',
    '1846d0c223922a51d4db40a200de0a95d8af4d99',
    '01fb50e71ff9b7c729fa67cb8549f6bd03b2bf90',
    'b3af75d9a1f75199f8757ff5ae8002fcbf30e8fe',
    '09d4223819ac29d5462a3eea2b1f35b5482770a2',
    'eddb03451ff947e1e4ee076afd8fcf003ecd9c57',
    '4b7a0ce53a08252746eb55261063f83d72e3bb12',
    'adb3b554659f74c4b60b7aa98ca2aadaf1aaca01',
    '016949999abbbd688ff0be40fade586508763a38',
    '106a1f1abaa86da58d160acd79e9b07c6c0fa617',
    '5e142707996d61a5662f49a0b71b4fa1e3090105',
    'ae664f81e24b93e615ebe9c0964f069002c44d21',
    '2f851a487c999b29689d3c0bfdcbe314436406e1',
    '1675a104fb13900c9c4c5a4237204dd1548de784',
    '360ab3133b3578398e6055a4545b22198b12ce05',
    '31616aefa18bd8eb9c72c9930d08f19b8546f4ca',
    '99576e486f1fadace1444d5db74c3ac117aafa53',
    '37466939fe403fa0b16367cd46a729f174f9eaf4',
    'df051743d1bef467a64644a4ccb2b5bf40cc8b8d',
    'e11ea408007fcdb3c4ae25989324272549e09000',
    '19168d9085f63465a4a9892315313754f435a4ee',
    'c380a0a094484ac2412ea77e6708bf1e88b37243',
    'b41659b3f75b262b3b115f1df66506f229d94eae',
    'c109966d665d44e15314d646efff9d6cc9cd9080',
    '3f10e8c7c4171e3a46be4b8342205169672ac9fb',
    '3ebfda789b75502e5612fa96daf6dc30db3a15cd',
    '6eb10f1866a5c7a308cea372627d2368e33b21a9',
    '100f09278d6783aa4a6035afe64a48a5ce8b2668',
    'ba43a61283e794f484f22f2fdf4c05b80c8c1a52',
    '3714424d52e6dd134a6f63222b981ad16e01c94b',
    'acc0af2628df15f696d0d1f9835f166275ec2046',
    '12c7f12c44cb076fafd17aeb792b619a7da5d552',
    '2470281a9a12baabdb5742dbc7a568d67994aec9',
    'ebac2bd163f0039713a9994cbc7ad0aeee16732d',
    'a5821cc851de03f2e57747033713924ca6c951ed',
    'f85ec370543686bbe501f18d4040685afeec78a5',
    '8b56b097b8e7c086683e7cbf13f0d1f6e2410678',
    '3b29e6eab20700b93fdd937a1d667a08a377ead5',
    'bc992eceffaa12ba7f4d89063bdc2cb9e05ed81f',
    '17765b4151a00937bb54c6bba5e1de4b64afb6eb',
    'ad0458e5e2d1355744dc9b6dd9835d0f0afd9838',
    'e34e16e2674b8f03de37bbfc7dfe31bc197909c3',
    'd3b01081547ef997d07fa41cbb377623b1a27e47',
    'bf4d4d25480c7120ad7f8384c95466fc9c44dadc',
    '0a741ad90869dcd3e3e3a9e5d86f9ad8a1b96a26',
    '3fe3553949944d4bc43361df9e3283b56f69f43d',
    '1c54aca24dbccf9e57f31505aa752e4607804973',
    'ef321c04c4f96d3fef63b2dceaa6a3eea152d687',
    '008a9bbbadc7158a2f3ac2b04f4ea3faffb93c77',
    'f51742edae458aac77c96599a33feb2f5bd4d113',
    '8db1fdd2e15b7cc15afee543477998b1803dba4f',
    '39df0058f29a88f4d8c1dc8becc574d5ec996164',
    'a6e3f092c0b252c0b526879e9f919cda4cd45968',
    '9af5a65cf7a76ded2bdd1a74f3366b7a17118e1d',
    '6af77d6d3fc212325134c6784537e6782bbf7148',
    '5640bb7d84d8953d367841909967ab345f2c11ca',
    'dc64d63bdc3ef9c1e18877bbb989a6f7f5969ad2',
    '8a9c325887ef8bbed4a6a6a34199ded9d042899e',
    '925e071b21d96c323b7565decf7c1eac33c60474',
    'ed71e5d764c8552c276f08311ab02f8e26aebab5',
    '2781ba12f3d67c72a12f91d52264fc4ac628a600',
    'dca56fa1e1243cec4776eea5d1c2124a352199cb',
    '1683d813e54173d0f1d066bd9d3e320415284d88',
    'ba87ebc911224076e97aebfa1e824c68ceca1244',
    'f2936bb357315296c8f723805657f1051e41feb1',
    'c1e94fbfb9e63d591d77203e6c00e5cc527478a2',
    'c4a0ca7978aeeded5256efead2366296e0a5b36a',
    'd4a319f9309441d56b625ebc39624479c37ae766',
    '574b29143f24a95c21134e0fafcca3d44b4eeaab',
    '65b82db8e97c9a194dde2a5f3f1ae5b10487af5d',
    '5c568df750039c3d6f564221263ec7fe6baf9bc6',
    '8f3f67cae5c55cfb09c6e44d0ddaca89f2bc0f6f',
    '7fd9be937c97e76e122e7b650bb90caf1c19c765',
    '9ab4e0719a681da31fc73219bdec16630f4c69c7',
    '71f8bb53f441656e93ad27d89e76b8aac321c576',
    'db7d08ec3ad49d2053b785dda4053575837ea619', // The polish guy
    '9556648b80dd385f2432fd1f269a15d2307ee0ea', // All I'm Sayin'
    'f550a8962f9d8db66f18699cbea5bd361d97f516', // Cookie Monster
    '0f8e0ca38ddaa623eeeaa4f513333e4e7b406726', // The National Socialist
    '15ffb9508f1c6f7da2aa2f048419f2044951134e', // hans landa
    'ed2276215d64c70dcb5b625b399cf3faebdac12c', // Giuseppe The G-Man
    '0ce8e772774a08d9024aeb24acc6402711da24f7', // ϟϟ The Fourth Reich ϟϟ
    'f73e8f3e1ac86551294c9ba935f60e2983ead80a', // Derek Rants
    '3af7bd3b5aa89559c09a2263c09220e8768edac3', // oldssaxon8
    '617708681ccc7c79f495c74938146a2c18f3f450', // Lebensraum
    'f6658272a31848fbd7c996281e762bf4b53f08ef', // White Is Right!
    'aedc291f0b35833daf54ae73867aab9af4a08ef6', // White National Socialists 
    'b07991187607695ac9e05d472d73c5397d319c60', // Operation_G
    '480003890c7c093d2226637f35b78c61cd88b662', // icedcooly
    'a636748dc13d14bb3b12ff17942e701353dd1314', // Larry Ridgeway
    'a9ffbc0a4a892fbb071e68231976f3978df1665f', // CATFISH & WEAVER
    '8096832fff7a77c0c3a887639953d0b39386094e', // Mad_Max
    '40aa76ce764708fc2933f1a67642a6bc9459f123', // Floppa
    '3fda836a92faaceedfe398225fb9b2ee2ed1f01a', // lbry
    'f3da2196b5151570d980b34d311ee0973225a68e', // kauffj
    '0ed629d2b9c601300cacf7eabe9da0be79010212', // LBRYFoundation
    '732ec7b283a19ce7bf0e9e690303e8503f941f5f', // SusieChan
    '2b52288d974112a8d114935eb1c6aaa09b5f4925', // GuyOnABuffalo
    '2eac3b87cb7dac466b66e966a85120fc1b907ec7', // MartinezPerspective
    'd95a457fe751be1faa8ab8f266d5c04a04f5e600', // DragonFox
    '06ad40e24ff682ea2173606a81804c87426eeea0', // Jews are the Problem
    '0b920cd19675dbf294142b201519134e2c129446', // pRiNc3ss_P34cH
    'b3f8a943e055c0a3a2fc717334ef80a29d85b696', // ImOnSmoko
    'f8e828f635c1d4fc9b55e652eb1990e17972bcec', // Zeke kyle
    '26eb50f0d9832be52937bbeb10a2ab6c0ee74f1d', // JewsRTryingToStartWW3
    '5eaf6d0cfcba042da27f8ced2a9e923dd21a9612', // Microzymas
    '08a4055c8dfbc7f40e74f8c34821b1b6c9ef5e41', // Dave Shadow
    'a8dfb2234d5fc35d02f1114f864c909106448c8f', // unclemurry
    '323932076c27e4e80461fd2d4c249b790d18fe1f', // GOYGUNY
    'd8c39801f11dc6750990ee5638adab8b9526e0f8', // 𝗇𝖽𝗂𝗀𝖾𝗇𝗈𝗎𝗌
    'b181c5cff462621d1912cf9cf20b1ccaa76e686a', // jimsrelaxinglife
    'c1ddae5aef1e6eacab2971a30e821a6ecd555369', // NiggerballsJohnson
    '129f61edc1b1631aaffcf8e468ed64fe76ac6de9', // Dirty Thirty
    '8fddc15f3d74b079476762391539abeafaa83172', // FuckYou
    '04f0ac626678cba0e585620c8f29a9882b829454', // SansY0Lo
    '10e2e36b8982d3a6fa6d237d834191443b8d4d88', // davegreco33
    'aa242b791f06986ec72ab89a0a7365e8e55011aa', // HitlerFans
    '3bbcc0db27f49d4d2f76c3da07ef2258f0142134', // Gott mit
    '694afe0490915590f76862533800cda740d2eab6', // BIG PHILF
    '2fb52bc8b40d032f673eae80da9f2de7462e4ece', // Gott mit uns
    '945814f83d6f385e915c2ef0694b71e8395f3850', // THE SCHOCK TREATMENT
    '33c75e9ad073f2657b4cf20a969668e4d2a70d6f', // Rendern gechne Mobbing
    '2977afe7404e5fbb251556089ba2df4ed59f1104', // Third Reich Radio
    '80743d2a2849e9c55a8a9357eb7cd2d507541cc8', // EpicDanger
    'cb80d43349fc9f9174e77fa0c59bf293413f05f6', // Bastian
    '09cf4941f5647aa123034ce7c4854e6a8ce9012f', // Harold
    '2bbfb387258b4cad1e2eb75a137465c0965bd396', // Our Free Society
    'cd645584d4304aa2181c437d8fe82d32ddcdf9b5', // HoTpOcK
    '9614b595cc463e9f691c15fb561c824601a9d8e7', // The Wake Up Project
    '2dc281ec351e3f1c7079f0d91cc312c334a16b1a', // openyourmind
    '529ca0f8b6cc6189a1c2af58b354a61de2904f9b', // NS VIKING
    '0a29815117289541eb0d70323cc0ddcb22a75325', // NATFLIX
    'a7db08a1c2105444f559119ccc3e707ad4e2001a', // Dalibor Weimer
    'a9f42fa740ea941cdcedd680198c8281304199aa', // eternal extrapolations
    'aa3750492d3261f17e15b343ef1d1f60137714b1', // Israeli News Live | Jana
    '63ff3a4910e9af7a95362858e23de508f0c25571', // pl0t_sickens
    'c9005cc6845acc4c583fd78587f0238134c75065', // Micheal The Based88
    '9a990e07636f17c71128813d6c9fbea3a454cca2', // HOLOHOAX
    '2a05f2e908cd1103b959b9f6dfad9a0b85e40dbd', // Canadian
    '6888fd7425bade3c7960c3b050ba12193afdfd6d', // Yung_Dolf
    '8f747e58fcff35ab0ad9fd956bcafbe3fd2331c7', // You May Spank It Once
    '3898d8b473e9e0044154fdbe648a161d5c0471fa', // The Hitler Speeches Guy
    '56a9da88083bced678c9c186257f93f5e741230a', // Haiderfuchs
    'f853c14697b1a26da8efb66d5fc9d8c9fd488f06', // trutherbotJOKER
    'ba861a9e22de2dcdce101c140315dbdec100a2b3', // Oliver Janich
    'fbe92e813cf3d4e849bef1fcd022ab7da71c0096', // Dialogue Works
    'a8db393691e49118f49e3bea69023639d949cbfc', // Harry_knowledge123
    '2c546b424fb73808f066424435c1f7538082e697', // Our Interesting Times
    'eab6d0ec45127fac8a1e2719f3cb87ec9006b3eb', // Dot Connector Reports
    '5557e2f49ea80b2759fdda96af0f38236321e83f', // Irina Boyko Crutcher
    '6c2d3313ab85fcd1ecb51073147ae7a0959fe2bc', // KleckFiles
    '74fa1412efc5413ed78b0355a8765d68823d019a', // Follower of Christ777
    '0e5c196a2230164e93e9fa5de1f7c49b755b0d00', // alltheworldsastage
    '1c0e7e64c5fbef7a7f1ba12613e2e14550f9bde0', // RT Balkan
    'fdd11cb3ab75f95efb7b3bc2d726aa13ac915b66', // RT
    'ade3aef7c55da52c1c709b7ed296960f68a0609c', // Akhwayn
    'b9a1c9e6cff86db627797adeb2934f3decedce81', // Friggin
    'e79ff5f55055c9527139002b17981fbf43cae88e', // Islam si mal New
    'c449cde7d1c27b375db8b1248b3f06246effb38a', // Le patriote
    'b3efde6f153a7aab10144851e8113e9ca0dbf085', // ZVOSupporter
    '3fbe8c47d829c904ab5c96924c5c4e9ffe36f250', // catness sharing
    'fdeee8ef73ccea4d59ba7a3e31bd7c328a23f7f7', // REICH REVISIONIST
    'd51b7c88c7b41cd148fa855577bdd9abb07f56ea', // FlatEarth
    'd44716fcb8ef3697ee0d69cebb5125c698d82b5e', // Joe Marsh
    '0dc6175eb31028645bd9b1225b0d47a25d968756', // judenhass
    '9e4e881504064c103a1633ad8eccab4873046565', // Christ The King
    'a59812c2615f7d4616dddd07054eeb932a062044', // PrinceActarus74
    '371a9eb745d256d8249ba15b7867b3a7d49d603e', // carlosantonio Docus
    '05155f4800fd11a21f83b8efc6446fa3d5d7b619', // RT Arabic
    '03c05ab71be65d11d7f0fc186a296884e3a9a785', // Hayderia110
    '6a025873fc93d53a59bf2c734c288fce48ae6364', // the truth shall set you free
    '39c7bd9e63badf276a895e663563dc4eaf7daa94', // Pasión Sexy Ryona
    'a4a319a7d0282243b4b4d0a74f57dffe0bf1534b', // Sputnik Arabic
    '5754fdc9c5645fe8042f158709075b050ea58816', // Sputnik Italia
    '2b9d66bc7db1f7587de778781aaf258e48db8595', // Sputnik Česká republika
    '3abcd5048e9545393a9109525f55f8e5f0f919cd', // Sputnik Polska
    '1da8dda83523b9836ddcd984d1963eff84102877', // Sputnik Afghanistan
    'a03414877cf3f059baebdf2e196ef127eecd5c2f', // Sputnik Iran
    'd8cfc073e8cc2a5d28d8ad855b9975a5091afc3c', // News That Expands Views
    'ecfe66c4fdffb92e33229ab01371f97054890569', // Ahí les Va
    '4d225e4db86f32151317ffd0002f4519906e083d', // Антонимы
    'ad919fcc26c31c081bd535aea60b580d1b783e5e', // RT Россия
    'aa36543e7eb68c64e76e39402c9cc4c9668368cd', // RT Sport MMA
    'b20e30a8375e58a77f6d276e08d9e2c81b8ba981', // Вести Севастополь
    '33513c58b93d0ef88b4a9195574f97863091a120', // Sputnik Việt Nam
    'bab918fdbb3821adf112d6a5f36b0d4676ebe7b7', // Sputnik 日本
    'a6a125c99ba2a6c068a8b502fb08dda603f6f03a', // RT en francais
    'fe6e65a6d69c9093cb0482d4d098598ec4403d61', // Sputnik Afrique
    '2f98945fec7687d7ad2f8a47f88b1875ce102ca3', // Sputnik France
    'f73d3d42ae8c4f2cd347a81917a505340ef5fe47', // RT PARTAGE
    'c7aa6ca3ba6b0f17ffa83c8c8e69049908052cd8', // Sputnik Meedia
    '40b9c2158d9c59b9ef8ee6998915802b021e4ac8', // Sputnik Greece
    '6d895d997ad67fe61dc08cbaa43a2df2fc89508b', // Sputnik Türkiye
    '22c4cd79217ffc56078c4a17f49ccc73d1693ad3', // Sputnik News Agency
    '24c4b543ff7123c55b6ebd0635d09b9233a956c4', // Антон Красовский
    '65e2edd5155aeec2c67efe1bdd40516281e612f5', // RT PARTAGE
    'b0e7755a668b660c297d138ff00f3956e8f62552', // RTPlay
    'f39690b8ee92b040733ad42e0c77288de555acaf', // Ruptly News
    '02a3069396b298bb26fd5a00b70eb33cde6568dd', // RT en français
    '26bcbcc342a5b143578f27dbfaf000201f06e417', // Press TV
    '2cde3f01ac9b66cbea88596f423f984a4fe171d0', // Amazing Audits
    'e689e1e40b0b72e8e4b7219fbde2118610a3adb8', // U2Bheavenbound
    '4d41c19323f95adde0a5ad7e1cd4968fdb50b075', // Here's What I Heard w/ Laura D'Goddess
    '4e2d6e0949b52436905134fb497d6e9a49675ff2', // Captain Titus Frost
    'bfad9df431084925117c9f4ce16116bd34301872', // DarknessToLight.111
    '418c5694301c8c4290a4f86fd7a26a88507c0f9e', // HookNosedPhonics
    'c2bd6ca918dbdf2a8c9650c923f2c69db3d97caa', // Jack Frost Experience the real Truth
    'e95dd12e8cfc448cad1270feb3743938fefc3549', // Right Perspective Radio (with Davis Lurmann)
    '0fa6e6ac5d7341fa2583dcb556cf7eaf1383fb1b', // Christendumb2
    'ce40cb29f866f337b85c7fc98f5797753e06dcd8', // SubZero3639
    '3fb7dfbe8ce2898a63e38a18a1f15aa6d02cdf5a', // Carl Shvartz
    'd4c96aadc0de7e8b5faf7e4f99963644c8087514', // Scott Ritter/U.S. Tour of Duty
    '15560006e8a665f8211a18a8d603857379ce4d56', // Eldritch Penumbra
    '677a2be25cdccf1dd071d54f82c27e8709255120', // History
    '189cda319be61fd07357e5c395af80610b4f2efa', // Ideal Natural
    '90a23d91665735a3630d130d0fad7fe1476baa32', // Tipsy_McStagr
    '2088b599a915b734477942b18a3ef6f5e8ad6921', // Z_Kyle
    '8e74de95c5b7f0d7c6e9dc3c9882698ad808210c', // Gotcha RF04
    'bc0a6cd0f475e6c0bf1729fc38be2f4cdfd8830c', // Zoran Zoltanous
    '5b75476373f70ea0ce7669753bea49eb1a91f744', // Xyzern
    'aa037761e502fa502996976755ec0377eb6ba155', // revenas2005
    'd19bb68c49fdfe79cff5d7820cbd35017ca6c935', // BoerCast
    '7d839de5a4e292da8fef8dde361bf078097e0896', // Doomsayer
    '9170d35679278fe33727ab17550f4a4bebe3ced3', // Ray Cis
    '6fc26ec25136576894ee2d2ce3cfd852bb702b14', // 2M2M
    '3e7d70c99d1be4c8a78ec52e7e2d2f4682a4cd0e', // I-Rabbi-T
    'bd36f0472dcb53549c9f2200ff0e4e863495b5bd', // KStarockChannel
    '214ca1deeeeea6f248eb811d3955f816ec90b5dc', // UndeniableTruth
    '328033e8198ebc1dd27a41ce042ee8a4b47fcae7', // PsychodriIIa#2
    'e8677aa2259c2da7aafb44a39e8ab722892af5c7', // Land of the Fake - Home of the Gay
    'bfe9174110ce291d95d2459a965a7bd32e81189f', // Let There Be Light
    '58e5a032e438f625afb28780f566de06abb3b6e7', // Teknein (Official)
    '15bc2363e1988ee4bc3d7d37634678264bc0801b', // Scott Roberts
    '185da2079bbc9af1deb79aa66bf2694a984e93b4', // Hondaricer85
    '850fa4824138902daa242b94c27ddcee8924e01d', // Bottomless Bucket
    'fc6f92e9ee8a7240bd062209b43b6adfacec3773', // CaffeinatedKek
    '828f9bdb59ce634f5f381301b531baa1e30ac077', // Aussie Fighter
    '841d08d210a8ed123da38dc54e87a7e0fb586969', // Huntress
    'fffb5979351b772c84ca7ee3e449ca9c759137dc', // Kevin's Chaotic Coffee
    '99aeef8c441a383a19bf1463a01c9eb6f746671d', // Eva K Bartlett
    '5952cc61597c59802bcfa3c204cc0ac5eb4eaf9a', // Ponzi ♱ Mobile
    '613832b8f2b2b41222cf2e30c7afcbcd639ba581', // Unchained
    '15b1beb15614e2becb84e8f77ab30d0b03fc2be7', // RISE Attire USA
    'a879aa30bf91970ec0294f2f073822509dd5010d', // Steve Laws
    '9ed0a5203d573bfb6ae9fc85f6acb550f7305d99', // Kelamaty
    '092e9de923497733f1befeab8e2853dfb602a3ce', // MorningsWithMetokur
    '1bc052621ca0e372d294ba6c0f573418bf35a236', // bang
    '8cbd66d23982e5c9f5a4646742dd64c0fcebaaaf', // AntiCensorship
    '7f85eb1d2add93b4239b2ff2c023b05e0becfd82', // Cook
    '0747a9ba85cc6463699e58bebb6b3ecd9e0e737e', // Aussie Flyers.
    'a788f66e45de2d024493347dd5378645c85b94c1', // Russia Insight
    '9a0e015f8df9ab5ee3ce1a2dd7abbd3e9c12c7fb', // Colorado Ped Patrol
    '237f06a5b710dd7c2d180e0218b48f9017aab237', // ѕтσρ вισ ƒαѕ¢ιѕмє
    '368bd238dcc7221cff5d29149d204c73aa0cf528', // InMyMind Truth Is Truth
    '3feaa7808a5ef32829ea9eddbd2ad588b61f63e5', // No White Guilt Clips
    'f515f34c4286ec88ca02b5f5672f7a6af61d47cd', // YuBrat Navas
    'fd98ab75d0730dd79267f596a402cabdbecae04b', // Animated Catfights (COMMISSIONS OPEN)
    '47f6629318e7723bf2edc69a9bf6749c1780d5cf', // WeThePeople News
    '3b278dd26a61b95bb0e5fa19daed3c1f1d3ff484', // pistil
    'f3c237f90ea8e6ed7d46ece56357f27b6c1ebdc2', // Shane_St_Pierre
    '00300236ed2f14507edfe69b53e74f8721fc3217', // Radio Ruin
    '132962cbbd409e6abc458fbff86b685b3df4fe7f', // seek truth
    '0b2a2df3037c1a1841e4fedfa3a1c9fccf9e9ad5', // Spiritus Sanctus
    '97c359a9e9de5ed23e2bae67d2bdb7ca058033b6', // Mikael Hagenbo
    '364095a31ff9b1bb22b3a05c79ce877114759f25', // Austere Men 144
    '89ab8d62dc3c04269df1551d2c49ae49df0e0ae9', // Zionist Global communist
    '9c77eda857985cced58a95f5f080ef22d3998405', // Plazma
    '6604a09e8b11f98f5d82bba76d4fd1b195b796dd', // Jewish Subversion of Thought
    '79cc3b5fd747639c4185c561bc4e93bda4843954', // GLRYB2GD
    '3494024f382cbdeb247e1a28de77332f79689f67', // raynoth
    'bafed434056935e98913d4d1ac1647aab5f79ad5', // Western Critique
    '936617522d3f1205a51bbb1e23d57d72de740736', // Linda D
    'e1e9407c4cee54e8fb819b6c5d179068bd0a2ae5', // EarthNewspaper
    '034ae02cf188974e534f996f660e312b247ac8d3', // EL Nemo
    '2d6951cb87e5444d96de8993aa8f069172e7cf09', // LouisMarschalko
    '081aff1a8899bfaeaa3a396927cbe6fdd4e247ac', // Only HD Feet Remastered
    '91395cc5770b4c8d5fb678bd7caa967358eb54e8', // Panzer Unleashed
    'ee65d6a3576f8c108095774c2b746aa426848ffe', // PRO42O
    'f5bb0226f7eaf697966f8a2f6280a101e32b16f0', // E. Michael Jones
    'f684f1efd69381878fd037d8b666f44b921762ff', // The Gay and Racist Show
    'b18e268ed0c4bab48fce6d40079b480491aec5a1', // 幸福串串燒
    'bb5d59068e2464996f428f8771a61387fc00370d', // Retnec Ecivres
    'db141c0b3d3585c864bc2db242b4ceb358bde3aa', // ATOUCHOFCLASS
  ],
};

// ****************************************************************************
// Portals
// ****************************************************************************

const PORTAL_TAGLINE = 'Watch. Read. Look.';

const PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_VIDEOS = [
  '7618723f08716773878cfc5ee88259c80e73a3fc',
  '0b959ec5abe0699d48a49b702545e2070b673a33',
  '53f90d39c850b2273eababe0aead0ee3884efcdc',
  'a04cf2c73107a71ef44d73c9117b024853943434',
  '8de67afcb568b50b97bd34c707579089de01acc6',
  '2bf4d135802a3f4f52ad07c73e380b57f3d6ff2d',
  '7ece12805d753fb0086f602257de0bf7633aaf31',
  '22e63b093f6280d5432d1cd3ae0648c9d21099fa',
  'fe3d0e30d3c7fb573aefe81772100d3d3b8e0e8a',
  '5417db8c7948a8717f8acda1cab8a8a5b7ce218a',
  '7cd0f81141a6a620b04702ff628d09b9c10d694e',
  '4cdccca9e19fd8c12a708ac861ac818225d75f65',
  'c302b0c9abcf581e6c122caa38e6404286eb597d',
  'c4c1dddd4cc6a87b87b1fda45f722f7a3ffa95f1',
  '70f02ad0dca6b799f1f110edbed0639195601f1d',
  '4d36403d641ba153a59d2cc9dcb829600b7c21ca',
  'b983e7e72f00573b7d20a1390a11aaa7475f056b',
  'dadc567a9514e024c74448045666c8206e2a39bf',
  'a08ca7ff9c641b1d90c1b365e632fc89b81e28be',
  '8a5f9ab0f141bed5a13f222542e10123506bf84e',
  'bb2ef6a57cf2fbcfed48dea12d14be48c4d95b81',
  'd3463745e1c6553bd11bab41b0ed580aa99c6ed8',
  'ea181a1e413461aedde2c880582938c7ff76ff59',
  '0b8c04a5511326488fdaf978544a83f94c44c8f7',
  'fbac9b3060129a0c175abe457dff08c0af6f94f9',
  'ce8eaa8c0a739058f3e777c5383281af52be762c',
  '506ba5334134b8668569129dfbc31e489160e4d6',
  'a5065961b38852e96126dea90f0abef06e1070b3',
  '45fa2cac760b71f505aeea624953098c9dd1b936',
  '198491e8b4eb242f7a703e41cc28c00b792f8b74',
  '06d9d6b751e064ef66007cdbcf6734c3adc73736',
  '5ca959fe4c19799b099a311f13f2b9273e05176e',
  '8982e8f193af47e73feeeee309a610c29d48fd04',
  '18245955e6472eb6b81e49de727adb3231e8391a',
  'a6d89dbbca1a1a9737ea6544a29d5c59516cab93',
  'ffc5a766516c2b3c96a67773343cb254953ea558',
  '63f9d9177a8e80e63ab01df6ba72556bd85447c4',
  'fc5ba3a44a309187cedae25f68c00ff29f04c03e',
  'f12ffda32924bc7dc82bc671de2210618e56dd13',
  '1d39ebae42fffc20adb37d9bb4f3e6658ad0f814',
  'a0cecfea9cbd4912f3b1d3582401df1b7c582f25',
  '79034881ffbd573277d0435973d2e79f174be9ed',
  'c2380d7d25971f2ae63b119452adcc107bbe7078',
  '399b4d70cc62f911ad1dfc6c10ee956dcaa30d40',
  '6b8d32c477c26a90f222006e4e08ab028ce60d83',
  '02bf72ed65dcac71e3e3e74d30c3935e76fadd59',
  '5d8e34d797966aae75ccf897b7a831da20898fdd',
  'f1c995316f257a5bf24bf5980e5b0b6ec9a4d5a3',
  'bf54e561d0887eb58d7764c94c683f90b82fda71',
  'a4c44f4db51b2ff1e41202cc28f26a893d30a2f9',
];

const PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_CREATORS = [];
const PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_IMAGES = [];

const PORTAL_ADVENTUREADDICT_CLAIM_IDS_VIDEOS = [
  '18fb15d0d10589103423fc3569b49dca1de950e2',
  'f25a72d587f6ecaf140a30a49a1bb35e5f3198c1',
  '49fb976bf1cb3fee6ddbc6b4d8d63368288ab3ff',
  '5501bf2d2a97533df339f56ce008cc4d68970adb',
  '002ab342e006fccced71a8c751b08cd04400737b',
  'ec8158fc8d6adfdc0fbc3ac841ac37212dbf0958',
  '2b4a2f14644e4925caf0c5e6918616c658ade8dd',
  '4848f6fc3645a93f13331837303ae458a47755c7',
  'bd417866c32825529a27427557f99836478c9b44',
  'dd261d58ab64603c7710d465978c8774c2388a6e',
  'f60bff1c6c282ac8309e5ee72a3bcb6bf2d1fa7b',
  'df1542627da243d3f18d6b1cb91c03a30b1e9827',
  '2a44c9b3267cd1c204c5380327f6ea6cc76445ff',
  '25c990cdeb87912bd4eb9359339d64c0e216bd34',
  '43451a0dea7cbdc8c745d5b2ddef31cbde40ebe8',
  '7bdd85c1173b8d0ad437f613b3a1eba8be2d6c0b',
  '9acba9ecffb13170e3c70500fe8505ca44c53b36',
  '646523eb148ea6a64b06d89ecbe0b3ade4cc60ed',
  '0789676ed9650cc1ace73164a442fe492cea752c',
  'b778d417521651fb820c21154d2b89f5f799d11d',
  '6d99c2077d19ca60c4ade15856c1d7a72884f1f3',
  'e2cc73399659f98fb5d36018367db48272a3558c',
  '6cb2fc7bf9ebd782b8253e92fcbe1b6200484498',
  'e982bf6e31083e9d0c5c78cb2abec7c22b44a3a9',
  'd1a4c0e96cfa197e22f85cf0ffabe3f3626aea01',
  '6c38503473724194110842ea6796df0fd69a218d',
  '5250e98580fd41f76337cb017ac780a6fe7d60eb',
  '2a925c1800a7bfc4c15a4606cd1694ba9dd54010',
  '5efb8fbfdd704ed67bd7aa7c3a49e031198eab7a',
  'f6308ab93877363d8378a0c2f6f4403fd4820c07',
  '938b7c37a82ccf9b4f8f20286d1d8267b9965c1c',
  '5580c2d4cbb89bf58c48b63a849f1c5d0012f70e',
  '0b7540dee8a54ca58102e438d5f7cfc22e9b5803',
  '5da526c0172c7a41d952f6ff20749d3ee118f862',
  '2d3881f15758db5b4717847eff36431893bddcce',
  '5e4eb27ab1a662a94fadd745546f8c61a330e57f',
  'c9b59ca049c5d36e6f17ce32612a855003e818aa',
  '5eca291b79450fa9a94183e4fbae313a947fb3c7',
  '3c7e6ef036c62a887a01aa6ae8c340d14531a980',
  '0719e51af97e336713a22eae30ee3fa378c245fa',
  'b1b31ebad3e1b00e7161496bfe36f31b8a062272',
  '773e9701a8b27137bec7d2f03c23b5548b44ff71',
  '346a7f34b36e322adfbbe483caecf4d8d63c2d61',
  '6c22df1687c2ce6996a04992c25ee03323cc785e',
  '905a5876c46e24cd2d9724eb8c0ac6e4203fc129',
  'a94b190d57c6e4ef317fb34144501da6b9819cf9',
  '3a9809b8ebb7c33c02dd04e120ffb0d697dcf494',
  'b22a7cd61269b4cd7d5de1b2b2f6188632c807b3',
  '0089b4548a1a2dcdfbf3ca06149f45bc1636eed4',
  '4f087962966323638e96a9899c89d0453065572e',
];

const PORTAL_ADVENTUREADDICT_CLAIM_IDS_CREATORS = [];
const PORTAL_ADVENTUREADDICT_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_ADVENTUREADDICT_CLAIM_IDS_IMAGES = [];

const PORTAL_HORRORFREAK_CLAIM_IDS_VIDEOS = [
  '83df26d7c56690e873322e34281cc4dbf4329b66',
  '4fa65f22ea636276f494380f21e08e2789df7ba5',
  '23c055693498ba98ea746841e9f770ba13c79cbc',
  '84a01b201d900193b4616a9a282f86f666f5600b',
  'aaaf70e50c2053389dd0c656f9113a78d5bad6d8',
  '5c8b0c9fef424c1ba7aa37e944eb083d5c3f3be7',
  'bcf6ac45e280281d8f6906aa92d78905562e3db3',
  '84b09e5a9e50ee7da59978f5d920829b64096be4',
  'd283a51d88e32df34c0b1d7a43da21d17dacea37',
  '63df37ab95256778081a2d70aaff9c5d4560b358',
  'f0ca4105296cb67985eeec90b1d7cc538abf902d',
  'dc77e8b46b26439278cb9aee2c20832313ffaf51',
  '9130b1da9541ac007d75e4b37e0f9729810c5143',
  'b154d2b729ce19b3465be46ad1121b0144a1ef54',
  '5414aa5d0f7ff242ca8e952883f08d68242047be',
  '53a3d825ded122a954f704696f1393ba323471b1',
  'd3db66134c3113d908c07b135888340c1cbd8e94',
  '581a509c42eacfdd01898fc9f93d38347df3cf01',
  '0ce19c22c6f1a16d9711087812c8f91ab51d4d3d',
  '17ee093e4b76b1688ce9ffbb595070a74f12763b',
  '39cc1c408898338d2884fb3bcb6e827c492d11b0',
  '92815c779031fd78b47b47ecdf90ed045181324e',
  '9e4a2e88b214c4b071fd1e773531ce7a44de4b79',
  '303fafda19788e4ad2cf09d2832ce81ff9afd961',
  '1b4ccdf903a1ff20a0bd7a207bacfffa5cfa0b93',
  '0195cef6cfadc8aeec9ae6f7e1fb6a3a3072cfce',
  'e720e654d6c19c18c663d14ad3e1c46cb51f2bd3',
  'e40f2ee6d12e04aea6e2620da298af72aae4fb20',
  '05f073a205f91c17ca9ddbda338f7c8398d9cf6f',
  '33614f2f2a339efb99b67de3e7b811815e33653a',
  '5deb0cc9b95c8d6b25eca325917b24fdb3d5d89c',
  '4e159413203726e560ee05f8126d5bea6a93e7b5',
  '12df49314d713ef75a13657aac1dfd6466905686',
  '5ee132fdc75a854cb5b0d2487ffec6c4dd74383f',
  'dfcfb9de75cde697c54029043c387df4cef6aea3',
  '70eea7d05bc37634400840d2758a6388ad36fec8',
  '0c665e97d01f5f70da0a79e1ded4d74903d7ecbc',
  '5c198eda2141af56adf5acf9ad49f34efc48f6f4',
  'd99c94e54ad6b099f5801e6993852729e8dec54f',
  'a875dc12fff222e89bb2826f8f8b4f3e7e3be0cf',
  '257d27f4159726b12812a1bdad410462f9f43c91',
  '599d28dcda68260e5df8cd5d9d24b002b64e1cb6',
  '45e219c4480c8e3760bb1b7f9bda0dd239f6a4b3',
  'c2e63634239b4ca7c5ba454cf102ddb055001136',
  'e1381c3b73793e9cd97b50c159eb43e2c04bc1d9',
  '0f8269e06fa68d3a21c22debf6461bc8611dd5eb',
  '791b86abf481a87bbc4405ad7bb868027d4d2ed0',
  '125ea3db9bd05cd1644960213f89c6119a5c6e15',
  '65dfe26f198e617e551df415c632302708658971',
  '147511452a2ea6acc282880d7447ab50a974e1c5',
];

const PORTAL_HORRORFREAK_CLAIM_IDS_CREATORS = [];
const PORTAL_HORRORFREAK_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_HORRORFREAK_CLAIM_IDS_IMAGES = [];

const PORTAL_INNOVATIVEARTIST_CLAIM_IDS_VIDEOS = [
  'dadd39473421135cd15da6f4a430fb3070ef6fb1',
  '3b3724b9b92432c428473fd24069d5522fbb4b55',
  'eb4f1c17cc5195e51850f55eddc6dc73e6eb0191',
  'e1813bf32eedb978ea880dd1213ffe74d1bc06dc',
  'd86c6ef0e84c4b4d438f33197b5ca87f0664fd6a',
  'ba8f982a7b597674c00b689914764651a4ed09fb',
  'cfa3d1e0b314cc06047ab6ca987dc5cd84c75eef',
  '49bb83cea4012753b361d77ae6916163c16a43fb',
  'ae331b2dc972f9c76e50b220fbf52a0ed92e2c53',
  '3fd893396eb71c42079942445d3ac8b9816c6b0b',
  'd31bb49e9a778cfc345a50915a3c5f38066055d6',
  'd88f939600e02b27fe20af7ab5b9da94e31089d1',
  '4aed2d6497fb785d04faf8515c4366acf3961ab1',
  'b5b417c25510fe888dd619747a76c61b2bf44ae2',
  'c9ba424c60056d3a5f58ffb5e855a109eccfbb02',
  '9123cbce9135f3d6e6608eaa10d3891a02755efe',
  '945d2bcd6558fbd4e46b7b74fcb4faccce0f2082',
  'ebebcfc1a16736247767cd8ba970a9f1bd9f1085',
  'b387518230e9a1c9c1d498d6228a0a93f6d87d3d',
  '7619e2e753946f7008e7495f32838d6dd74256cd',
  '84eb7419213bcee10e3b74717bcad66b416877b9',
  'c9926eabb79f63d77ddb2333b72a93864b933375',
  '5cc7bb134801530e83f126bcc1e1c4e84edd8532',
  'b32161c6a47f8e92d483199eeb2ac3890c595573',
  '0a413843661f932f5079d1956455147593eae559',
  'e2da0f309645d58d6414b5205158fc5b6bcb0749',
  '76dc157278144dac5c129c31255f885d562afb4b',
  'ca5ada7013813ee202061f61b39634ef18bc8c8a',
  '180dab18eb8fe4ba6d0b2c0ae8be2a6496dcb0bc',
  '86a2e8d44ebdf13fee40f6d8d1e0f3f774b05acf',
  'd65f15c5d28e622f8480baf06c65c4ef25914dd0',
  'daf98e7030f43f612c2b7f0395a476c72a384f16',
  '2dc390df3dccec6c323d5bccb0fa010268a8f579',
  'ad1769858fda0af39da711efe2cfdc9397548b67',
  '15a7c3f87a255fd7c2aa0960a067b23782e9c76a',
  'e785e054d3a913b53af1aa9d01158038747ef059',
  'f750ca812cde00512a4f5106722313ddb7a54366',
  'a34d44e06a506fb2110c5fc7b0784e1bfedbdc63',
  'e7a4e0f835c071e06c152d63f79a15dfad2a86eb',
  '23c94635336c9d94932b56982c7f5b8f10505dbd',
  '24cef4a7d2e3625bc5bea86cca9449e47021ad83',
  'a2eb60744e80d8689d0648c77ee881220709759f',
  '2483fa335038cc91658d32a64dac37af54e0f031',
  '66ef92f51f7e8d6845b31473e0835c1d6c305ac8',
  '8c11d75dbd13b830e419e01e23c9a0a61f5f619e',
  '6b1c5c4a15b49c8366a4f55d21e6ab6a970f60f8',
  '534ce94d5f1ce9d4329b7c9d473baeb818de6714',
  '037e5e4ad8e8685aec62e14e9d8b9c82869c64a4',
  'c454eb42bdacb4b92f72683e9ce3f97c3fd7a7dd',
  '297a516ef26378a6b5eff39c237af8b73508338f',
];

const PORTAL_INNOVATIVEARTIST_CLAIM_IDS_CREATORS = [];
const PORTAL_INNOVATIVEARTIST_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_INNOVATIVEARTIST_CLAIM_IDS_IMAGES = [];

const PORTAL_TECHWIZARD_CLAIM_IDS_VIDEOS = [
  '5b2434693580164a50f26e0a31d2bb2bbfb5e5c3',
  'e4fe10c84101abe3337ad19bbe7b00b81d3ebf4f',
  '5a5aec3ad935b079c9998e51e8bff4baf0d4065c',
  'b52957417e1de2fb92e754e7211d9c416b98c082',
  '00ef37d88117d8789b1b1e45a1189503c1c08f9f',
  '2e685d5a80fdf78d71a7911d02630247665de333',
  '228b50165d8220af17219345a21985bab5cd3c8e',
  '991933922d6adcd2a4774b9e57288e26c8ab7e04',
  'c5fbd25ff1deaa0e01b84c33d85cc8a90964c9ae',
  '192cf4ed3cea86913d4db669d0d8839afdda392f',
  'ede4f259e9fb9475258cfc93326c3d5fea8a054b',
  'e40d215d01e0392dd1ffd5862b95c05aa8225722',
  '1f3d1f0b3cb08fd0739472d1148d9fbbb98e19f0',
  '81e39fc5939d830b378f0a51c5b5153da404bd18',
  '6d27f86e94ddbbea950d3b7d9a716db340686097',
  '8fd74b452d2ef12f84aad94cc7ae1eec46799a97',
  '0bec8d1f7664a5d7297384b728431362a426324b',
  '21e3e13b1453eacf708a59687b65a7b7e3782f57',
  'e6ca4da67e245ef3b18c9b4631675eef60d6c708',
  'f219bf48c1236df00afed0e88275f0bbc3b05ec6',
  'd1ace26f157069844b722584f87cfd9e60cd5b75',
  'cc092153c9052facb0a215b4bc2297669daff2bc',
  '3bba6ace7b8a1e1f8d5d3ab773817dc7de8b665a',
  'd084a63e7344610ad981483bb41fa0047cadf38b',
  '734e2609a467197200f0eb69088dd1decfade0ae',
  '73133d761c0a2c3766f75dbf14f5fbc70e7e62af',
  '97275aab03cc3824bbf077d8f173d60591fadd0e',
  'cc16b0daf503ba922c2d625b1e331aa7075fe138',
  '8db0cb7972f7beee41ca9772ca7fa0bb7babc6d3',
  '16ff86f5f2b11f9ea36f28936733774404339bd5',
  'f4732efba8c76115565a1257eb5e8883144338d7',
  '4f711165d194e6e4c09df681c3e9c4b905b29537',
  'd613800105b4c26b7b196fbc8368eab14b716abd',
  '8b5fafc51eb1d940fc848395b210e84bebad4371',
  '6691cadd4feecf8a2ad590c4378175ab7f991ee6',
  'e85356428f364b4b940dc8d0e803e092b1b8d628',
  'd0ee1cd236fde71e3120c84c9f96f27ebed5dbbd',
  '3a04b7bdbf50951c26ccc8c50780e142c2ab513c',
  '86a64f7f2fb6292518677e5c7d674f591b692192',
  '036df265cd01d1d7673881fdacacffafe1636ae4',
  '187ad322daf405a923a9139ad8afa16098dfad89',
  '0880f7ffcf8f8970ee1f29c47509df4f1c6c34b9',
  'eb4a3621c97cce3473feb6cde305ae1daf0c41ad',
  'ede9d522fd104b37af8c5c0127d9b62ac30ff564',
  '4e9d9e7bc25013a24060f06b75146186671ea6eb',
  'd2fa7709dbbdeed84d01ed8dc0e06d3266552aab',
  'ea71b12926d375f9e50c7c8b0f562f7c1bac1844',
  '0cd58f6e548067086eb1daaca4ed7ee00e5cb446',
  '17f8a1e3f76ef80d7ff90850bb5699551e38f33c',
  '2f540f8419b67971803782a45a78ba2871b5bb33',
];

const PORTAL_TECHWIZARD_CLAIM_IDS_CREATORS = [];
const PORTAL_TECHWIZARD_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_TECHWIZARD_CLAIM_IDS_IMAGES = [];

const PORTAL_OUTDOORDAD_CLAIM_IDS_VIDEOS = [
  '0b7f7881b21cdddfefe01255df2dbd556e7b33af',
  '6a2bd9b794970afc89af95cf3f3fdbf8bf65379b',
  'a571cd958fdfafeaade843565db4541ee3531fcc',
  'c89de8b5fcc0a0414cab514fcc1ac63d1050ad7e',
  'b67bd04ed2b40ed3549408df486785f4c8615b41',
  'f5f4500de7e2c2064d7227ac3f3619c414113403',
  '6384d9e3293c3bf67ebdd867e3bf68c45c48a249',
  'fbed507fcea0782538ee21aace554ab2d31a0f20',
  'c3c1a93e9dab9b4d769e55fce4eb0996b6036f5f',
  'b93d140b16b49324572fe6a03d67c294c2350773',
  '6b84ed9ca98d934028861afc77064b4aadbff0f9',
  '8c763434b713bfb6e05d4455014c9bfee2b1a12d',
  '5e3b8cc836d03675603777c3fb14690c35405dad',
  '9936c203f604af9100312931eec040eada580973',
  'd7dfa1b09d908a075937ceed2222f2d49f928725',
  'fcabec895d047c3f9fc507082203fd91fa5e849c',
  '6e172048fb489befcce1915086948f06a96ceadd',
  '98d7c39a3131968b599ce3927dde845b4d01da1f',
  '06b88c432e2d27a1737112dc8da5d62e9f783070',
  'fba797d96f20a1125ec7517704ec010e12baa6fb',
  '622085bdc2643c89f63a73ebc8b219a19b331fe0',
  'ec15587561b90ce9ba96f7c0aa0cdabfdbfd3861',
  'f349e0216815c6fa4005feb3937b29d825eacfec',
  'ded6dd348fae0a8a88ba68dcd7fa2b19180cdcc2',
  '45796013779761b66f753cb255913cd52cf7ff7d',
  'fda173f5862397849435ef93131b6ad5d3e1153c',
  '5f0bf2c8140e072a39aaba41835793f807ac381d',
  'a413599da842518c0e00abf0b10840b95be4b5de',
  '4fe21b3d6173cd20e65e3766b74a281692f13fd4',
  '4cee05e436b772b80329360f141af8221eb8b550',
  '098bf247c3fe0f253fd0b1459d53569b6018e503',
  'd1a7fd82bb985190c7184a3a55cbe46360f080e1',
  'ed11bab98f8b50d6c44bd0a8890cd92babc8747b',
  'c05dde39abc8cb19b48e9c00b788e7a4cc7be63a',
  '8569e3422a99c73e2465911e15045e52f4d911c1',
  '1c79e66a75dea4b6e6a9a08949b7613e371530b8',
  'ff449883ab1ea8c2cdcef7698755b8e6a4c7dcab',
  '8df7e3ca27dd9ceaf93d8e9ce02efcd3327e5f78',
  'a37bd42f7efc83dea57dc8b70174a9f241924c22',
  '243199aa933fc88ddc41d0f3a51dfe518d87b6a9',
  'b765602f1082cbf93a824c493d89cad19debf51e',
  '01ca22095c59d1505037ca8907c8cff8f6ce1198',
  'ad918a4d5deba90a3b2405f1481a651d3090b28b',
  'b19663f86e3a2a33a7c6917546eaccf9edcac019',
  '89439eef13a71fbf4a7194b025e9f58d1e2d3929',
  '4c4be8ff7c3c15b45cb7afc1bf05b05e66124bb5',
  '0d6bdb84744175d24f7c8fa879a2d8d426ac857a',
  '968abf29117ae1044edfeee01c22b172c61b6467',
  '0e6d964054a513fa5e9c8c5124e222cc97312693',
  'b996130d4bebb6e36497253df445df1da1ccc1e8',
];

const PORTAL_OUTDOORDAD_CLAIM_IDS_CREATORS = [];
const PORTAL_OUTDOORDAD_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_OUTDOORDAD_CLAIM_IDS_IMAGES = [];

const PORTAL_SNACKGUY_CLAIM_IDS_VIDEOS = [
  'a7734409b75bd7d5f6fa5694e609149955568883',
  '56adf435abf154b42779f6260232ee1c448b6293',
  '8b4d3160565c4fda634565c2d2b6cfa69e54d67f',
  '05b02adcd12833388ebb10267a1ff569253bc55c',
  '67cbdc831fac2d552494874746b4d93e1a81fde5',
  '6339aeb14171f52523b9193b33f508f2099c17a5',
  '653400164c92e70395ac619a62fd6e04e7468405',
  '59e988402c5420005fa4764615f7335742e1e7ac',
  '77406a86dafc0902f9720b743d80d4c6cbb22d9c',
  '23dd7c94945367b5e504d7d100c1c23fca96c18c',
  'aaeaace80d56f0fa39f4aa87564eb40b0e57074c',
  '9e97ad89c9213656543fb7452f8b4f3b42def747',
  'd7104e5ad5191724867226d55a3e1d1ba224482b',
  'fe08cb7ce99153f24681304254fc623c1fd68eca',
  'aa3ba534069d314d5ea6faa0353e57c35026546c',
  'd5de917bca2ba0023648de88dc0d646f48bc6844',
  '62d83ec7eac0a1fb637c2affb3e7800fc3bceae2',
  'deff59df53b851f566966520234abc21ca79e74d',
  'eddc37af5ef9a5f206a767531f93672823453a72',
  '89203eb3fc55a7209ad61bea75bcd5b33e094c89',
  '8f23dbd2fd97919282d66f0c202e4af24c829700',
  'a475d1fe0ad68b4e2ade32c891291fb7ccae9e59',
  '5e1b200cbccd6351b6dac0dea91f6be333935f9b',
  '78a337544b69a65c8b8aa51ba93eee896023bbfc',
  '2a94e6e59407e932fb4db4729178fa16cde5bf5b',
  '3770ca227ae993cf09eccbe74e3b04784364c513',
  'defd735b1493d4e47958abc3a89541500c5a92cc',
  '09e46034dc555b1a674e2b141fc9e09f880eae48',
  'e8359b9d5d54c409a20db2361ff21e32891d6d33',
  '21e4bb71003344e91012cf710b4348e60ac1e26e',
  '8fbb64bea70baecb09a09e6c351b13ee5c9962ca',
  'ed2d35c323a957e06bfff77f9adde73d4fd1a509',
  '975554adc747f342121d717fe5151463d14eb684',
  '87363a82a97e731e956e5a209babdb72d4a63623',
  '427444c8cf1091327070ffd592a0497fa2d8052e',
  'cd1abbeb7bb4de1553f520d1cce9ce422f5c27bf',
  'e367e9586eab4a0830f7db267acf93e72615908b',
  '3182336fc1999d4a87ae5ed8410a971bd6d095f1',
  '5ab35e9002f1a62cbf2ae841c21f72025b344811',
  'db34f02c5458b83f4e8c34a1ac42ea969bbf6975',
  'fc8d8fa720f4614f3cb4ce3b584eb6bcfa2e37ce',
  '77bc93907d2a5192f38e1b8f0893933af9bc99c4',
  '07a1acd4e0aa528417cf4b170132cdbd5bc1a86c',
  '8faa63ebc8a819056d5ebde88b290534c955e3e7',
  '17766a45191ac08c09b2089f173e4b4781840105',
  'acf8258d641982ef1caa1f654a795960333f7f68',
  '01baf9d1ae8890ae3d588612ed896f6b9e090b51',
  '1cf78fa55abb4b251c3d981bc65ed81d53ac3831',
  'd283a51d88e32df34c0b1d7a43da21d17dacea37',
  '23b2ca48af946735b0df66a32858b3e8f3cd669a',
];

const PORTAL_SNACKGUY_CLAIM_IDS_CREATORS = [];
const PORTAL_SNACKGUY_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_SNACKGUY_CLAIM_IDS_IMAGES = [];

const PORTAL_CHRISTIANCREATOR_CLAIM_IDS_VIDEOS = [
  '479a91df685d7a1a011f749059e029e1b77cfa32',
  'c90c85af994123d3e5a11e4019fc85ccc9535dc4',
  'c83c72eb093134d5df835a260d7191a316d42341',
  'ca9f393d0f7a72a6995704856cb8eae9d09091d5',
  '8d6b34f98c14b7e046a12b4c0ccb01cfa1c670ac',
  'cf7c36ee288219b50eddfee7d2e928be109d918b',
  'a91a8f767194de3ad3e6a952866c19bca497ec9a',
  'f0dac79a0236d9b047f96d2767ecebc5cc4c8f8f',
  'c8fb13ce0a0f135a5254e11abd002a29e65b2a24',
  'c1d788379c9106fee47a0a9545f76c4891b33c7f',
  'ce774ddcd7271185eb5e4d97cf717414a7c4bc6f',
  '0a5c7fac326a6187d0bd222a1109a67539217d64',
  '9e538512d985168faa28bee58ff1e2c435dd0211',
  '86f0b55fcaa3f4a4af3c932a5896667d0af3c3d1',
  '58a56d62dc9ad66f212c98008b96f1ab4f4401de',
  '85a2e6dcd3aa1cb9f97896420d3e234260c0571c',
  '6547435a63ced2a2510e8c72ea3223838029bbd6',
  'e0a3dea8113e1929ffc796ca7f7ccc0d1a298c51',
  '680bc12b1f0f8c6ffedef4ae414e817daceef9e1',
  '67c9e7550fbd308747e61f3fe0d16acc3841e37f',
  'f5d9294e110f51ac8df244906c649990e11d499e',
  '89a5520584fd2ebaf0838bb32bf02338396ba445',
  'ebd3a5e9d5dc002d9b679e8fd4ce79a45b4f284c',
  '13d642a6b58767d1409fd2df515ead3617a5c825',
  '858a0d6831fc8fe010628f851e8ec02c5e8d9dc9',
  'ec29fc094e3f86022e9a7fc9a7a3dbd0bdfa5d4f',
  'f1bde9a033b0cf8f6128ff815d8489bc0363fad0',
  'dea40dd16d6bb3a44ecad57de63f74645ec2f370',
  '0124b6388a9766d13dd765cbf3e578b7aeedbbe0',
  '94e300886f794688c02500b60fd7629c4aa4730b',
  '1a6af441b8d96d897397be4f7cae96c3e8a76e24',
  '087383667eb1a48adacfcf0bc0314ff78b62bacc',
  '1bf7fbf006655600c06e286520d82c01275e220f',
  '2b723efb3c9b09d791eb5814ad221b2e3ba94d4c',
  'fc9ac23a5bd9f3e39e12e3d4694db188a1e1e44b',
  'ab9ce9b358879c9f533b63813b7f1cd051aaaf5e',
  '38bf0dbc4dab6e2b524b2b0bbf2010a2bd3fc026',
  '847bb2191a5dc419e4956a7686d4fc5057ebac3c',
  'b902950067eb06d4c5f3b5bd2b048851d5ee37e0',
  '6ce5549cabef589e7673b5e29a8d0e9a5d74f235',
  '43aa3dd1c1bc8e50a398c7148bae75c30a38230e',
  '91393cd0e2006198bf9d077f9302e56b249f96cf',
  'f2b7fe8abeceb43ff7ea154674e9323acd276b15',
  '9319df871ad9bf854c7afde01835687fdb76b207',
  '600d7e0da2c065af10da8fef124619239a702bb2',
  'fb925d07cee2aec52f18b73dfe6d4e8f7b0da58b',
  'f797de66863b2f05a967e314c4b0eaca08b9f286',
  '217847d98f8a6893d22efdcb31567a64a2304417',
  'd43bc147e634bf8c328ce57ade9e7e0a9cbb3ebd',
  '20e4cf0ce729f54504e160f57f07e78a71dbd540',
];

const PORTAL_CHRISTIANCREATOR_CLAIM_IDS_CREATORS = [];
const PORTAL_CHRISTIANCREATOR_CLAIM_IDS_DOCUMENTS = [];
const PORTAL_CHRISTIANCREATOR_CLAIM_IDS_IMAGES = [];

// ****************************************************************************

const PORTAL_SNACKGUY = {
  claimIds: {
    videos: PORTAL_SNACKGUY_CLAIM_IDS_VIDEOS,
    creators: PORTAL_SNACKGUY_CLAIM_IDS_CREATORS,
    documents: PORTAL_SNACKGUY_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_SNACKGUY_CLAIM_IDS_IMAGES,
  },
  name: 'snackguy',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_AverageGuy.png',
  label: 'Snack Guy',
  description:
    'A guy who likes beer, sugary snacks, a dose of comedy and motivational strategies in the workplace.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '25, 217, 238' },
  sortOrder: 1,
};

const PORTAL_OUTDOORDAD = {
  claimIds: {
    videos: PORTAL_OUTDOORDAD_CLAIM_IDS_VIDEOS,
    creators: PORTAL_OUTDOORDAD_CLAIM_IDS_CREATORS,
    documents: PORTAL_OUTDOORDAD_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_OUTDOORDAD_CLAIM_IDS_IMAGES,
  },
  name: 'outdoordad',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_OutdoorDad.png',
  label: 'Outdoor Dad',
  description:
    'A married father of two who enjoys family activities, gun collecting, road trips, and supporting his local church.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '212,105,77' },
  sortOrder: 2,
};

const PORTAL_TECHWIZARD = {
  claimIds: {
    videos: PORTAL_TECHWIZARD_CLAIM_IDS_VIDEOS,
    creators: PORTAL_TECHWIZARD_CLAIM_IDS_CREATORS,
    documents: PORTAL_TECHWIZARD_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_TECHWIZARD_CLAIM_IDS_IMAGES,
  },
  name: 'techwizard',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_TechWizard.png',
  label: 'Tech Wizard',
  description:
    'An entrepreneur who is focused on transforming space exploration and electronic vehicles while fathering a brood of children.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '48,117,220' },
  sortOrder: 3,
};

const PORTAL_INNOVATIVEARTIST = {
  claimIds: {
    videos: PORTAL_INNOVATIVEARTIST_CLAIM_IDS_VIDEOS,
    creators: PORTAL_INNOVATIVEARTIST_CLAIM_IDS_CREATORS,
    documents: PORTAL_INNOVATIVEARTIST_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_INNOVATIVEARTIST_CLAIM_IDS_IMAGES,
  },
  name: 'innovativeartist',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_InnovativeArtist.png',
  label: 'Innovative Artist',
  description:
    'An articulate craftsman who seeks out how-to videos, artist galleries, museum exhibits and bargain craft tools.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '100,68,154' },
  sortOrder: 4,
};

const PORTAL_HORRORFREAK = {
  claimIds: {
    videos: PORTAL_HORRORFREAK_CLAIM_IDS_VIDEOS,
    creators: PORTAL_HORRORFREAK_CLAIM_IDS_CREATORS,
    documents: PORTAL_HORRORFREAK_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_HORRORFREAK_CLAIM_IDS_IMAGES,
  },
  name: 'horrorfreak',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_HorrorFreak.png',
  label: 'Horror Freak',
  description:
    'A Spanish speaking paranormal specialist who uncovers the dark side within crime, global mysteries and horror thrillers.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '239,25,112' },
  sortOrder: 5,
};

const PORTAL_SCIFIENTHUSIAST = {
  claimIds: {
    videos: PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_VIDEOS,
    creators: PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_CREATORS,
    documents: PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_SCIFIENTHUSIAST_CLAIM_IDS_IMAGES,
  },
  name: 'scifienthusiast',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_Sci-fi_Enthusiast.png',
  label: 'Sci-Fi Enthusiast',
  description: 'An argumentative and detailed dude who is into space, inventions, cyber security, IT, and virtual reality — but not in that order.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '48,117,220' },
  sortOrder: 6,
};

const PORTAL_ADVENTUREADDICT = {
  claimIds: {
    videos: PORTAL_ADVENTUREADDICT_CLAIM_IDS_VIDEOS,
    creators: PORTAL_ADVENTUREADDICT_CLAIM_IDS_CREATORS,
    documents: PORTAL_ADVENTUREADDICT_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_ADVENTUREADDICT_CLAIM_IDS_IMAGES,
  },
  name: 'adventureaddict',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_AdventureAddict.png',
  label: 'Adventure Addict',
  description:
    'A global traveling treasure hunter who gets motivation from documentaries, fighting bad guys and hitting up beautiful women.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '255,221,162' },
  sortOrder: 7,
};

const PORTAL_CHRISTIANCREATOR = {
  claimIds: {
    videos: PORTAL_CHRISTIANCREATOR_CLAIM_IDS_VIDEOS,
    creators: PORTAL_CHRISTIANCREATOR_CLAIM_IDS_CREATORS,
    documents: PORTAL_CHRISTIANCREATOR_CLAIM_IDS_DOCUMENTS,
    images: PORTAL_CHRISTIANCREATOR_CLAIM_IDS_IMAGES,
  },
  name: 'christiancreator',
  image: 'https://static.odycdn.com/images/portals/g_512x512p_RGB_PortalPersonas_ChristianCreator.png',
  label: 'Christian Creator',
  description:
    'A multi-talented artist and musician who enjoys creating beautiful things just like the ultimate Heavenly Creator.',
  background: 'https://player.odycdn.com/speech/featured:b.jpg',
  css: { rgb: '100,68,154' },
  sortOrder: 8,
};

const PORTAL_MAIN_IDS = [
  PORTAL_SNACKGUY,
  PORTAL_OUTDOORDAD,
  PORTAL_TECHWIZARD,
  PORTAL_CHRISTIANCREATOR,
  PORTAL_HORRORFREAK,
  PORTAL_SCIFIENTHUSIAST,
  PORTAL_ADVENTUREADDICT,
  //PORTAL_INNOVATIVEARTIST,
];

const PORTAL_MAIN = {
  name: 'list',
  label: 'Main listing',
  description: 'New content on the portals. Join and explore!',
  background: 'https://static.odycdn.com/images/portals/g_300x1200p_RGB_Background_Portals.jpg',
  portals: PORTAL_MAIN_IDS,
};

// ****************************************************************************
// Featured Sections / Banners
// ****************************************************************************

const FEATURED_1 = {
  label: `8-Bit Eric channel!`,
  image: 'https://thumbs.odycdn.com/a083017d3af2bc544fc3585d5640e0e4.webp',
  url: 'https://odysee.com/@8biteric:6',
};
const FEATURED_2 = {
  label: `Check out our memberships feature!`,
  image: 'https://thumbs.odycdn.com/fa24979e4267fa4c9e8ba8a6673a034f.webp',
  url: 'https://odysee.com/$/memberships',
};
const FEATURED_3 = {
  label: `The Arty Dans channel!`,
  image: 'https://thumbs.odycdn.com/5ec5d752a17c2ca67db6bd0de1a293b3.webp',
  url: 'https://odysee.com/@TheArtyDans:5',
};
const FEATURED_4 = {
  label: `Live Grow Green channel!`,
  image: 'https://thumbs.odycdn.com/baaec1d1d4cd609a88e8623c54ec46fc.webp',
  url: 'https://odysee.com/@LiveGrowGreen:d',
};
const FEATURED_5 = {
  label: `Patrick and the People channel!`,
  image: 'https://thumbs.odycdn.com/bb6b4b30c5d4f0eaa75b5e920c69e9b7.webp',
  url: 'https://odysee.com/@PatrickandthePeople:3',
};

// const FEATURED_6 = {
//   label: `dynamic`,
//   image: '',
//   url: '',
//   homepage: PRIMARY_CONTENT.name,
//   transitionTime: 5
// };

const FEATURED_MAIN_IDS = [FEATURED_1, FEATURED_2, FEATURED_3, FEATURED_4, FEATURED_5];

// ****************************************************************************
// Discover
// ****************************************************************************

function getDiscoverIds() {
  // Can be defined as a completely new array, or programmatically based on
  // existing arrays.
  return PRIMARY_CONTENT_CHANNEL_IDS.filter((x) => x !== REPOST_1);
}

function getDiscoverNewIds() {
  const categoryArrays = [
    POP_CULTURE_CHANNEL_IDS,
    TECHNOLOGY_CHANNEL_IDS,
    GAMING_CHANNEL_IDS,
    ART_CHANNEL_IDS,
    EDUCATION_CHANNEL_IDS,
    LIFESTYLE_CHANNEL_IDS,
    SPOOKY_CHANNEL_IDS,
    MUSIC_CHANNEL_IDS,
    UNIVERSE_CHANNEL_IDS,
    FINANCE_CHANNEL_IDS,
    SPIRITUALITY_CHANNEL_IDS,
  ];

  const newestChannelIds = [];

  for (const category of categoryArrays) {
    // Calculate 10% of the category's length
    const tenPercent = Math.max(1, Math.ceil(category.length * 0.1));
    
    // Get the newest 10% of entries from each category
    const categoryNewest = category.slice(-tenPercent);
    newestChannelIds.push(...categoryNewest);
  }

  // Filter out REPOST_1 if it exists in the array
  const filteredIds = newestChannelIds.filter(x => x !== REPOST_1);

  // Ensure the total number of channel IDs doesn't exceed 2047
  return filteredIds.slice(0, 2047);
}

// ****************************************************************************
// ****************************************************************************

module.exports = {
  categories: {
    EXPLORABLE_CHANNEL,
    PRIMARY_CONTENT,
    POP_CULTURE,
    ART,
    EDUCATION,
    LIFESTYLE,
    SPOOKY,
    GAMING,
    TECHNOLOGY,
    COMEDY,
    MUSIC,
    SPORTS,
    UNIVERSE,
    FINANCE,
    SPIRITUALITY,
    NEWS_AND_POLITICS,
    //DOCS,
    WILD_WEST,
  },
  portals: {
    tagline: PORTAL_TAGLINE,
    mainPortal: PORTAL_MAIN,
  },
  featured: {
    items: FEATURED_MAIN_IDS,
    transitionTime: 3,
  },
  meme: {
    text: "Please Upload Content Manually",
    url: 'https://odysee.com/@Odysee:8/pleaseuploadcontentmanually:8',
  },
  discover: getDiscoverIds(),
  discoverNew: getDiscoverNewIds(),
};
